import React, { useMemo } from 'react'

import useGetProjectUnits from 'hooks/useGetProjectUnits'

import InventoryListViewWrapper from '../InventoryListViewWrapper'
import { groupBy } from 'components/Inventory'

const UnitsInventoryListView = ({ projectId, buildingId, isFullHeight = false }) => {
  const { units, unitsLoading, refetchUnits } = useGetProjectUnits(projectId)
  const filteredUnits = useMemo(() => {
    const unitsData = units.filter(elm => elm.building._id === buildingId)

    const uCards = [...unitsData]
    const grouped = groupBy(uCards, (inventoryItem) => inventoryItem.floorNumber)
    // @ts-ignore
    const groupedKeyValuePair = {}
    grouped.forEach((value, key) => {
      return (groupedKeyValuePair[key] = value)
    })
    return ({ ...groupedKeyValuePair })
  }, [units, buildingId, refetchUnits])

  return (
    <InventoryListViewWrapper
      inventoryItems={filteredUnits}
      loading={unitsLoading}
      type='Unit'
      refetch={refetchUnits}
      isFullHeight={isFullHeight}
      selectedBuilding={buildingId}
    />
  )
}

export default UnitsInventoryListView
