/* eslint-disable */
// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react';
import Form from 'components/Form/Form';
import FormInput from 'components/Form/FormInput';
import { Box, Button, Modal } from '@material-ui/core';
import { DemandNotesContext } from '../context';
import CreateButton from './CreateButton';
import { CloseIcon, EditIcon } from 'components';
import CustomInput from 'components/CustomInput';
import { PlusSmIcon } from '@heroicons/react/outline';
import {
  GetDemandNotes,
  GetDemandNotesByName,
  UpsertDemandNote,
} from 'store/actions/demandNotes';
import { setIn } from 'formik';

const AddForm = ({ open, setOpen, isEdit, setIsEdit, setRefetch }) => {
  const { oldName, label, data, setRefresh } = useContext(DemandNotesContext);

  const [openModal, setOpenModal] = useState(false);
  const [demandNoteOptions, setDemandNoteOptions] = useState({});
  const [optionName, setOptionName] = useState('');
  const [editIndex, setEditIndex] = useState(null);
  const [editedName, setEditedName] = useState('');
  const [tempRefetch, setTempRefetch] = useState(false);
  const onSubmit = async () => {
    await UpsertDemandNote(initialValues);
    setOpen(false);
    setRefresh((prev) => !prev);
    setRefetch((prev) => !prev);
  };

  const [initialValues, setInitialValues] = useState({
    label: '',
    options: [],
  });

  // const handleOptions = () => {
  //   setTempOptions((prevState) => ({
  //     ...prevState,
  //     label: optionName,
  //     options: optionName,
  //   }));
  // };

  const handleOptions = async () => {
    setInitialValues((prevState) => ({
      ...prevState,
      options: [
        ...prevState.options,
        {
          label: optionName,
        },
      ],
    }));
    setOpenModal(false);
  };

  const getDemandNotes = async () => {
    if (isEdit) {
      const data = await GetDemandNotes(oldName);
      setInitialValues({
        label: data.label,
        options: data.options,
      });
    }
  };

  const handleSaveOption = () => {
    const updatedOptions = initialValues.options.map((op, i) =>
      i === editIndex ? { ...op, label: editedName } : op
    );
    setInitialValues({ ...initialValues, options: updatedOptions });
    setEditIndex(null);
    setEditedName('');
  };

  const handleEditOption = (index) => {
    setEditIndex(index);
    setEditedName(initialValues.options[index].label);
  };
  const handleRemoveParkingLevel = (indexToRemove) => {
    setInitialValues((prevState) => {
      const updateOptions = prevState.options.filter(
        (_, index) => index !== indexToRemove
      );
      return {
        ...prevState,
        options: updateOptions,
      };
    });
  };
  useEffect(() => {
    getDemandNotes();
  }, []);

  return (
    <Modal open={open}>
      <div className='flex flex-col top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-w-[40%] w-full justify-center absolute h-fit items-center bg-white p-4  gap-4 mt-8'>
        <div
          onClick={() => {
            setOpen(false);
            setIsEdit(false);
          }}
          className='flex cursor-pointer justify-end items-end w-full'
        >
          <CloseIcon />
        </div>
        <Box
          style={{
            fontWeight: 'bold',
            fontSize: '18px',
          }}
        >
          {isEdit ? 'Update' : 'Create New Demand Note'}
        </Box>
        <div className='items-center w-full flex flex-col gap-x-6'>
          <CustomInput
            label='Demand Notes Name'
            placeholder='Enter name'
            type='text'
            value={initialValues.label}
            classes='font-openSans w-full text-base'
            containerClasses='w-full'
            onChange={(e) => {
              setInitialValues((prev) => {
                return { ...prev, label: e.target.value };
              });
            }}
          />
        </div>
        <div className='w-full text-[17px] uppercase font-light text-softBlack70'>
          Options
        </div>
        {initialValues.options &&
          initialValues.options.map((option, index) => {
            return (
              <div className='cursor-pointer w-full'>
                {editIndex === index ? (
                  <div className='flex gap-x-2 items-center'>
                    <CustomInput
                      label=''
                      placeholder=''
                      type='text'
                      value={editedName}
                      onChange={(e) => setEditedName(e.target.value)}
                    />
                    <button
                      className='font-light text-[18px]'
                      onClick={handleSaveOption}
                    >
                      Save
                    </button>
                  </div>
                ) : (
                  <div className='flex gap-x-6 text-[16px] ml-2 items-center'>
                    {option.label}
                    <EditIcon
                      height={'20px'}
                      onClick={() => handleEditOption(index)}
                    />
                    <div
                      className='cursor-pointer'
                      onClick={() => {
                        handleRemoveParkingLevel(index);
                      }}
                    >
                      <svg
                        width='14'
                        height='22'
                        viewBox='0 0 14 18'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M1.50016 15.4167C1.50016 16.425 2.32516 17.25 3.3335 17.25H10.6668C11.6752 17.25 12.5002 16.425 12.5002 15.4167V4.41667H1.50016V15.4167ZM13.4168 1.66667H10.2085L9.29183 0.75H4.7085L3.79183 1.66667H0.583496V3.5H13.4168V1.66667Z'
                          fill='#2E2B2E'
                        />
                      </svg>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        <div className='w-full mt-2'>
          <button
            onClick={() => {
              setOpenModal(!openModal);
            }}
            className='flex cursor-pointer items-start  gap-x-2'
          >
            <PlusSmIcon height={24} className='' />
            <div className='text-[16px]'>Add Options</div>
          </button>
          {openModal && (
            <div className='mt-2'>
              <CustomInput
                type='text'
                error={true}
                errorMessage='required'
                label=''
                placeholder='Enter Option Name'
                classes='font-openSans text-base w-full'
                containerClasses='w-full'
                style={{ maxHeight: '48px' }}
                onChange={(e) => {
                  console.log(e.target.value);
                  setOptionName(e.target.value);
                }}
              />
              <button
                className='py-3 px-6 my-4 text-base bg-volt rounded font-medium gap-2 '
                onClick={handleOptions}
              >
                Add
              </button>
            </div>
          )}
        </div>
        <Button
          onClick={() => onSubmit()}
          style={{
            backgroundColor: '#EDDF65',
            width: '100%',
          }}
          variant='contained'
        >
          {isEdit ? 'Update' : 'Create New Demand Note'}
        </Button>
      </div>
    </Modal>
  );
};

export default AddForm;
