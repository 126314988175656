import React from 'react'

const CustomButton = ({
  disabled = false,
  handleClick = () => { },
  children,
  variant = 'default',
  className = '',
  ...props
}) => {
  const variants = {
    default: 'bg-[#18181B] border border-[#18181B] text-white',
    outline: 'bg-transparent border border-[#18181B] text-[#18181B]',
    ghost: 'bg-transparent border-none text-[#18181B] hover:bg-zinc-200',
    volt: 'bg-volt border border-volt text-softBlack',
    danger: 'bg-red-500 border border-red-500 text-white',
    ghost_2: 'bg-transparent border-none text-[#18181B] hover:bg-[#18181B3c]'
  }

  return (
    <button
      className={`${variants[variant]} rounded-md flex gap-1 items-center justify-center transition-all ${className}`}
      disabled={disabled}
      onClick={handleClick}
      {...props}
    >
      {children}
    </button>
  )
}

export default CustomButton
