/* eslint-disable */
// @ts-nocheck
import React, { useEffect, useState } from 'react'
import CopyIt from 'components/Utils/CopyToClipboard'
import { useSelector } from 'react-redux'

const DEFAULT_OFFER_FIELDS = [
  {
    key: 'buyer.0.firstName',
    label: 'Primary Buyer FirstName',
    sample: 'FirstName'
  },
  {
    key: 'buyer.0.lastName',
    label: 'Primary Buyer LastName',
    sample: 'LastName'
  },
  {
    key: 'buyer.0.email',
    label: 'Primary Buyer Email',
    sample: 'primary@gmail.com'
  },
  {
    key: 'buyer.1.firstName',
    label: 'Secondary Buyer FirstName',
    sample: 'FirstName'
  },
  {
    key: 'buyer.1.lastName',
    label: 'Secondary Buyer LastName',
    sample: 'LastName'
  },
  {
    key: 'buyer.1.email',
    label: 'Secondary Buyer Email',
    sample: 'secondary@gmail.com'
  },
  {
    key: 'deposit.discount.percentage',
    label: 'Deposit Discount Percentage',
    sample: '6%'
  },
  {
    key: 'deposit.discount.value',
    label: 'Deposit Discount Value',
    sample: '$72000.00'
  },
  {
    key: 'deposit.optionName',
    label: 'Deposit OptionName',
    sample: 'Option 2'
  },
  {
    key: 'deposit.installments.0.percentage',
    label: 'First Deposit Installments Percentage',
    sample: '10%'
  },
  {
    key: 'deposit.installments.0.value',
    label: 'First Deposit Installments Value',
    sample: '$112800.00'
  },
  {
    key: 'deposit.installments.1.percentage',
    label: 'Second Deposit Installments Percentage',
    sample: '20%'
  },
  {
    key: 'deposit.installments.1.value',
    label: 'Second Deposit Installments Value',
    sample: '$225600.00'
  },
  {
    key: 'deposit.installments.2.percentage',
    label: 'Third Deposit Installments Percentage',
    sample: '30%'
  },
  {
    key: 'deposit.installments.2.value',
    label: 'Third Deposit Installments Value',
    sample: '$338400.00'
  },
  {
    key: 'deposit.installments.3.percentage',
    label: 'Fourth Deposit Installments Percentage',
    sample: '40%'
  },
  {
    key: 'deposit.installments.3.value',
    label: 'Fourth Deposit Installments Value',
    sample: '$451200.00'
  },
  {
    key: 'totalCost',
    label: 'Total Cost',
    sample: '1128000'
  },
  {
    key: 'unit.unitNumber',
    label: 'Unit UnitNumber',
    sample: '1302'
  },
  {
    key: 'unit.strataLotNumber',
    label: 'Strata Lot Number',
    sample: '1302'
  },
  {
    key: 'unit.building.name',
    label: 'Unit Building Name',
    sample: 'Scotia Plaza'
  },
  {
    key: 'unit.building.project.projectName',
    label: 'Project Name',
    sample: '1818 Pacifica'
  },
  {
    key: 'unit.floorPlan.name',
    label: 'FloorPlan Name',
    sample: '101'
  },
  {
    key: 'unit.numberOfBedrooms',
    label: 'Number Of Bedrooms',
    sample: '1'
  },
  {
    key: 'unit.numberOfBathrooms',
    label: 'Number Of Bathrooms',
    sample: '2'
  },
  {
    key: 'status',
    label: 'Unit Status',
    sample: 'OfferCreated'
  },
  {
    key: 'offerDate',
    label: 'Unit Offer Date',
    sample: '2024-05-21T17:24:26.421Z'
  }
]

const FieldSelector = ({ contractType }) => {
  const customSections = useSelector((state) => state.appReducer.customSections)
  const availableMergeFields = {
    unit: [
      {
        key: 'firstName',
        label: 'First Name',
        sample: 'John'
      },
      {
        key: 'lastName',
        label: 'Last Name',
        sample: 'Doe'
      },
      {
        key: 'email',
        label: 'Email',
        sample: 'example@gmail.com'
      },
      {
        key: 'buyerData.city',
        label: 'Buyer City',
        sample: 'New York'
      },
      {
        key: 'buyerData.province',
        label: 'Buyer Province',
        sample: 'New York'
      },
      {
        key: 'buyerData.country',
        label: 'Buyer Country',
        sample: 'United States'
      },
      {
        key: 'buyerData.phoneNumber',
        label: 'Buyer Phone Number',
        sample: '+1234567890'
      },
      {
        key: 'buyerData.prefix',
        label: 'Buyer Prefix',
        sample: 'Mr'
      },
      {
        key: 'buyerData.occupation',
        label: 'Buyer Occupation',
        sample: 'Developer'
      },
      {
        key: 'buyerData.address',
        label: 'Buyer Address',
        sample: '123 Main St'
      },
      {
        key: 'buyerData.postalCode',
        label: 'Buyer Postal Code',
        sample: '12345'
      },
      {
        key: 'buyerData.legalName',
        label: 'Buyer Legal Name',
        sample: 'John Doe'
      },
      {
        key: 'buyerData.birthday',
        label: 'Buyer Birthday',
        sample: '1990-06-05'
      },
      {
        key: 'buyerData.placeOfBirth',
        label: 'Buyer Place of Birth',
        sample: 'New York'
      },
      {
        key: 'buyerData.maritalStatus',
        label: 'Buyer Marital Status',
        sample: 'Single'
      },
      {
        key: 'buyerData.nationality',
        label: 'Buyer National Status',
        sample: 'United States'
      }
    ],
    offer: DEFAULT_OFFER_FIELDS,
    parking: DEFAULT_OFFER_FIELDS,
    storage: DEFAULT_OFFER_FIELDS,
    upgrades: DEFAULT_OFFER_FIELDS,
    colors: DEFAULT_OFFER_FIELDS,
    reservation: [
      {
        key: 'primaryBuyer',
        label: 'Primary Buyer',
        sample: ''
      },
      {
        key: 'secondaryBuyer',
        label: 'Secondary Buyer',
        sample: ''
      },
      {
        key: 'createdDate',
        label: 'Created Date',
        sample: ''
      },
      {
        key: 'createdTime',
        label: 'Created Time',
        sample: ''
      },
      {
        key: 'manualReservationDate',
        label: 'Reservation Date',
        sample: ''
      },
      {
        key: 'manualReservationFee',
        label: 'Reservation Fee',
        sample: ''
      },
      {
        key: 'paymentAmount',
        label: 'Paid Amount',
        sample: ''
      },
      {
        key: 'address',
        label: 'Address',
        sample: ''
      },
      {
        key: 'city',
        label: 'City',
        sample: ''
      },
      {
        key: 'zipCode',
        label: 'ZipCode',
        sample: ''
      },
      {
        key: 'phone',
        label: 'Phone',
        sample: ''
      },
      {
        key: 'email',
        label: 'Email',
        sample: ''
      },
      {
        key: 'isBroker',
        label: 'Is Broker',
        sample: ''
      },
      {
        key: 'isNonBroker',
        label: 'Is NonBroker',
        sample: ''
      },
      {
        key: 'Broker',
        label: 'Broker',
        sample: ''
      },
      {
        key: 'BrokerCompanyName',
        label: 'Broker Company Name',
        sample: ''
      },
      {
        key: 'isFirstTitleCompany',
        label: 'Is First Title Company',
        sample: ''
      },
      {
        key: 'isSanJacintoTitleCompany',
        label: 'Is San Jacinto Title Company',
        sample: ''
      },
      {
        key: 'firstChoiceUnits',
        label: 'First Lot Choice',
        sample: ''
      },
      {
        key: 'secondChoiceUnits',
        label: 'Second Lot Choice',
        sample: ''
      },
      {
        key: 'thirdChoiceUnits',
        label: 'Third Lot Choice',
        sample: ''
      },
      {
        key: 'fourthChoiceUnits',
        label: 'Fourth Lot Choice',
        sample: ''
      },
      {
        key: 'fifthChoiceUnits',
        label: 'Fifth Lot Choice',
        sample: ''
      }
    ]
  }
  const [keyFilterText, setKeyFilterText] = useState('')
  const [fields, setFields] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const transformDataFields = (dataFieldsArray) => {
    return dataFieldsArray.map(section =>
      section.dataFields.map(field => ({
        key: `customSectionDetails.0.${field.customFieldName}`,
        label: field.title,
        sample: ""
      }))
    )
  }

  useEffect(() => {
    setIsLoading(true)
    if (customSections) {
      const customSectionMergeField = transformDataFields(customSections)
      const temp = customSections?.map((section, index) => {
        return {
          [section._id.toLowerCase()]: [...customSectionMergeField[index], ...DEFAULT_OFFER_FIELDS]
        }
      }).reduce((acc, current) => {
        return { ...acc, ...current }
      }, {})

      setFields({
        ...availableMergeFields,
        ...temp
      })
    }
    setIsLoading(false)
  }, [customSections])

  return (
    <div className='w-[300px] h-full flex flex-col bg-white'>
      <div className='flex p-2 gap-x-2 items-stretch border-y border-softBlack30'>
        <input value={keyFilterText} type='text' placeholder='Search merge fields' className='border border-softBlack30 rounded' onChange={(e) => setKeyFilterText(e.target.value)} />
        <button
          className='bg-volt px-3 py-1 rounded'
          onClick={() => {
            setKeyFilterText('')
          }}>
          Clear
        </button>
      </div>
      {
        fields && !isLoading &&
        <div className='flex-1 p-2 flex flex-col justify-start overflow-y-auto thin-scrollbar'>
          {fields[contractType]
            .filter(mergeTag => mergeTag.key.toLowerCase().includes(keyFilterText.toLowerCase()))
            .map((mergeTag, index) => {
              const key = `${mergeTag.key}`
              return <div key={index} className='flex justify-between bg-white hover:bg-zinc-100 rounded-md transition-all p-2 items-center'>
                <p className='text-base font-bold pb-0 mb-0'>{mergeTag.label}</p>
                <CopyIt size="sm" content={key}></CopyIt>
              </div>
            })}
        </div>
      }
    </div>
  )
}

export default FieldSelector
