/* eslint-disable */
// @ts-nocheck
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'
import { Oval } from 'react-loader-spinner'
import { FadedAnimatedDiv } from 'components'
import Searching from 'pages/contacts/Searching'
import { useSelector } from 'react-redux'
import standardStyle from 'assets/css/standardStyle';
import CustomInput from 'components/CustomInput';
import CustomTextArea from 'components/CustomTextArea';
import { ImageUploaderPrimary } from 'components/ImageUploader';
import { Modal } from '@material-ui/core'
import { createOrUpdateMapInventory, getAllMapInventoryByType } from 'store/actions/mapInventoryActions'
import ProjectFileSelector from 'components/ProjectFileSelector'
import CustomSelect from 'components/Inventory/components/CustomSelect'
import { getParkingInventory } from 'store/actions/parkingActions'
import { getStorageInventory } from 'store/actions/storageActions'

const LoadingProjectOptions = (type) => (
  <FadedAnimatedDiv className='min-h-[500px] w-full flex flex-col items-center mt-20'>
    <Searching title='Loading Project Details...' />
  </FadedAnimatedDiv>
)

const MapInventoryItem = ({
  values,
  setValues,
  loading = true,
  type,
  building,
  appProject,
  setSelectLevel
}) => {
  const [parkingLevel, setParkingLevel] = useState([])
  const [storageLevel, setStorageLevel] = useState([])

  const onChange = (field, value) => {
    setValues({
      ...values,
      [field]: value
    })
  }

  const handleImageChange = (image) => {
    onChange('imgSrc', image.url)
  }

  const _getParkingLevels = async () => {
    if (appProject) {
      let parkingInventory = await getParkingInventory(building)
      parkingInventory = parkingInventory.map((parking) => ({
        parkingLevels: parking.parkingLevels
      }))
      setParkingLevel(parkingInventory[0].parkingLevels.map((pl) => {
        return {
          value: pl,
          label: pl
        }
      }))
    }
  }

  const _getStorageLevels = async () => {
    if (appProject) {
      let storageInventory = await getStorageInventory(building)
      storageInventory = storageInventory.map((storage) => ({
        storageLevels: storage.storageLevels
      }))
      setStorageLevel(storageInventory[0].storageLevels.map((pl) => {
        return {
          value: pl,
          label: pl
        }
      }))
    }
  }

  useEffect(() => {
    if (type === 'Parking') {
      _getParkingLevels()
    } else if (type === 'Storage') {
      _getStorageLevels()
    }
  }, [appProject, building])

  return (
    <>
      {/* {
        type !== 'Unit' &&
        <h1 className='mt-6 font-black text-lg font-openSans capitalize mb-0 flex items-center gap-2'>
          {type} Level:
          <span className='text-white bg-softBlack px-2 rounded-md font-medium'>{values.level}</span>
        </h1>
      } */}
      <div className='grid grid-cols-2 gap-6 border-0 p-0 mt-6 rounded-none shadow-none'>
        <div>
          {
            type !== 'Unit' &&
            <div className='mb-6'>
              <CustomSelect
                label={`${type} Level*`}
                labelClass='text-xs italic font-openSans font-medium mb-1'
                value={values.level ?? ''}
                handleChange={(value) => {
                  onChange('level', value)
                  setSelectLevel(value)
                }}
                options={type === 'Parking' ? parkingLevel : type === 'Storage' ? storageLevel : []}
                placeholder={`select ${type} level*`}
                disabled={loading}
              />
            </div>
          }
          <h5 className='text-sm font-openSans font-normal uppercase'>Center Point Location</h5>
          <div className='mt-2 grid grid-cols-2 gap-6'>
            <CustomInput
              label='latitude *'
              labelClassName='text-xs italic font-openSans font-medium'
              placeholder='Enter latitude'
              value={values.centerPointLocation_lat ?? ''}
              onChange={(e) => onChange('centerPointLocation_lat', Number(e.target.value))}
              classes='font-openSans text-base'
              style={{ maxHeight: '48px' }}
              disabled={loading}
              type='number'
              min={0}
            />
            <CustomInput
              label='longitude *'
              labelClassName='text-xs italic font-openSans font-medium'
              placeholder='Enter longitude'
              value={values.centerPointLocation_lng ?? ''}
              onChange={(e) => onChange('centerPointLocation_lng', Number(e.target.value))}
              classes='font-openSans text-base'
              style={{ maxHeight: '48px' }}
              disabled={loading}
              type='number'
              min={0}
            />
          </div>
          <CustomInput
            label='Initial Zoom Level (0-100) *'
            labelClassName='mt-6 text-xs italic font-openSans font-medium'
            placeholder='Enter initial zoom level'
            value={values.initialZoomLevel ?? ''}
            onChange={(e) => onChange('initialZoomLevel', Number(e.target.value))}
            classes='font-openSans text-base'
            style={{ maxHeight: '48px' }}
            disabled={loading}
            type='number'
            min={0}
          />
        </div>
        <div>
          <CustomTextArea
            label={type + ' address *'}
            placeholder='Enter address'
            labelClasses='text-xs italic font-openSans font-light'
            value={values.address ?? ''}
            onChange={(e) => onChange('address', e.target.value)}
            style={{ height: '170px', color: standardStyle.colors.softBlack }}
            textareaClasses='font-openSans text-softBlack'
            disabled={loading}
          />
        </div>
      </div>
      <div className='mt-4'>
        <ProjectFileSelector
          title={'Image'}
          isRequired
          labelClassName='text-xs italic font-openSans font-normal uppercase'
          onSuccess={(selectedFileData) => {
            handleImageChange(JSON.parse(selectedFileData))
          }}
          defaultValue={values.imgSrc ?? ''}
        />
      </div>
      <div className='grid grid-cols-2 gap-6 border-0 p-0 mt-4 rounded-none shadow-none'>
        <CustomInput
          label="Image's width *"
          labelClassName='text-xs italic font-openSans font-medium'
          placeholder='Enter image width'
          value={values.width ?? ''}
          onChange={(e) => onChange('width', Number(e.target.value))}
          classes='font-openSans text-base'
          style={{ maxHeight: '48px' }}
          disabled={loading}
          type='number'
          min={0}
        />
        <CustomInput
          label="Image's height *"
          labelClassName='text-xs italic font-openSans font-medium'
          placeholder='Enter image height'
          value={values.height ?? ''}
          onChange={(e) => onChange('height', Number(e.target.value))}
          classes='font-openSans text-base'
          style={{ maxHeight: '48px' }}
          disabled={loading}
          type='number'
          min={0}
        />
      </div>
      <div className='border-b-2 border-black mt-7' />
    </>
  );
}

const AddOrUpdateMapInventoryModal = ({
  show,
  setShow,
  building,
  type,
  setType
}) => {
  const projectId = useSelector((state) => state.appReducer.appProject)
  const [loading, setLoading] = useState(false)
  const [allMapInventories, setAllMapInventories] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [selectLevel, setSelectLevel] = useState('')
  const [mapInventoryValues, setMapInventoryValues] = useState({
    type,
    level: '',
    address: '',
    centerPointLocation_lat: null,
    centerPointLocation_lng: null,
    imgSrc: '',
    width: null,
    height: null,
    initialZoomLevel: null,
    project: projectId,
    building
  })

  const _getAllMapInventoryByType = useCallback(() => {
    setIsLoading(true)
    getAllMapInventoryByType(projectId, building, type)
      .then((res) => {
        setAllMapInventories(res)
        if (type === 'Unit') {
          setSelectLevel('U1')
          setMapInventoryValues({
            ...mapInventoryValues,
            level: 'U1'
          })
        } else {
          setSelectLevel(res?.[0].level ?? '')
          setMapInventoryValues({
            ...mapInventoryValues,
            level: res?.[0].level ?? ''
          })
        }
        setIsLoading(false)
      })
      .catch((error) => {
        console.log('error: ', error)
        setIsLoading(false)
      })
  }, [])

  useEffect(() => {
    _getAllMapInventoryByType()
  }, [projectId, building, type])

  const handleClose = () => {
    setShow(false)
    setType('')
  }

  useEffect(() => {
    if (allMapInventories?.length > 0 && selectLevel) {
      const existedData = allMapInventories.filter((elm) => elm.level === selectLevel)[0]
      if (existedData) {
        setMapInventoryValues(existedData)
      } else {
        setMapInventoryValues({
          ...mapInventoryValues,
          _id: '',
          address: '',
          centerPointLocation_lat: null,
          centerPointLocation_lng: null,
          imgSrc: '',
          width: null,
          height: null,
          initialZoomLevel: null
        })
      }
    }
  }, [allMapInventories, selectLevel])

  const isMapExist = useMemo(() => {
    if (allMapInventories?.length > 0) {
      if (type === 'Unit') {
        return allMapInventories?.[0]?._id
      } else {
        return allMapInventories.filter((elm) => elm.level === selectLevel)?.[0]?._id
      }
    }
    return false
  }, [allMapInventories, selectLevel])

  const handleSubmit = () => {
    setLoading(true)
    createOrUpdateMapInventory(mapInventoryValues)
      .then((res) => {
        setLoading(false)
        handleClose()
      })
      .catch((error) => {
        console.log('error: ', error)
        setLoading(false)
      })
  }

  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div
        className='font-openSans relative bg-grey-8 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-10 rounded shadow-lg'
        style={{ width: 1015 }}
      >
        <button className='absolute right-10' onClick={handleClose}>
          <CrossIcon />
        </button>
        <h1 className='font-black text-2xl font-openSans capitalize'>Manage {type}'s Map Inventory</h1>
        <div className='border-b-2 border-black my-2' />
        {isLoading && <LoadingProjectOptions type={type} />}
        {!isLoading &&
          <>
            <MapInventoryItem
              values={mapInventoryValues}
              setValues={setMapInventoryValues}
              type={type}
              building={building}
              appProject={projectId}
              setSelectLevel={setSelectLevel}
              loading={loading}
            />
            <div>
              <button
                onClick={handleSubmit}
                className='col-span-2 ml-auto bg-volt px-6 py-3 mt-6 rounded flex items-center text-base font-medium'
              >
                {loading && <Oval height='18' width='18' color='black' />}
                {!isMapExist ? 'Create' : 'Update'}
              </button>
            </div>
          </>
        }
      </div>
    </Modal>
  )
}

export default AddOrUpdateMapInventoryModal
