// @ts-nocheck
/* eslint-disable */
import useContactContext from 'pages/newContacts/hooks/useContactContext';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

const SearchBox = () => {
  const { setSearch, state, resetFilters } = useContactContext()
  const [text, setText] = useState(state.search || '')

  useEffect(() => {
    const id = setTimeout(() => {
      resetFilters()
      setSearch(text)
    }, 400)
    return () => clearTimeout(id)
  }, [text/*, setSearch (do not uncomment this, it will break rendering) */])

  useEffect(() => {
    setText(state.search || '')
  }, [state.search])

  return (
    <input
      value={text}
      onChange={(e) => {
        if (e.currentTarget.value.includes('+')) {
          toast.error('Please enter a valid search term');
          return;
        }
        setText(e.currentTarget.value);
      }}
      className='bg-white border-gray-300 shadow-sm h-[38px] !mb-0 rounded w-full md:w-72 text-softBlack'
      placeholder='Search'
    />
  );
};

export default SearchBox;
