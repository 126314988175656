/* eslint-disable */
import Loader from 'pages/desktopMode/dynamicDropDown/Loader'
import React, { useEffect, useState } from 'react'
import { getUserListsByContactId, removeContactFromList } from 'store/actions/usersActions'
import { ReactComponent as ActivityIcon } from '../../../assets/icons/icn_ballot.svg'
import { HiOutlineDownload } from 'react-icons/hi'
import useContactContext from 'pages/newContacts/hooks/useContactContext'
import { Loader2 } from 'lucide-react'

const ListsView = ({ contact }) => {
  const { state, toggleCheckContact } = useContactContext()
  const [lists, setLists] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [removingFromList, setRemovingFromList] = useState({})

  useEffect(() => {
    if (!state.isAddToListDrawerOpen) {
      setIsLoading(true)
      getUserListsByContactId(contact?._id).then((res) => {
        setLists(res.data)
        setIsLoading(false)
      })
    }
  }, [state])

  return (
    <div className='p-4 flex-1 flex flex-col gap-y-3 text-xs items-center'>
      {
        isLoading
          ? <div className='h-full'>
            <Loader />
          </div>
          : <div className='h-full w-full flex flex-col justify-between'>
            <div className='flex-1'>
              {
                lists.map((data) => {
                  const { _id, title } = data
                  const systemList = data?.systemList
                  const canRemove = !systemList
                  return (
                    <div key={_id} className='flex items-center justify-between gap-2 hover:bg-gray-200 px-2'>
                      <div className='flex items-center gap-2'>
                        <ActivityIcon />
                        <h5 className='text-lg m-0 py-2'>{title}</h5>
                      </div>
                      {removingFromList[_id] && (
                        <div className=' text-red-300 flex items-center justify-center gap-x-1'>
                          <div className='animate-spin'>
                            <Loader2 size={20} />
                          </div>
                          Deleting
                        </div>
                      )}
                      {canRemove && !removingFromList[_id] && (
                        <button className='text-red-500' onClick={() => {
                          if (removingFromList[_id]) return
                          if (!confirm('Are you sure you want to remove this contact from this list?'))
                            return
                          setRemovingFromList({
                            ...removingFromList,
                            [_id]: true
                          })
                          removeContactFromList(
                            _id,
                            contact?._id,
                          ).then(() => {
                            getUserListsByContactId(contact?._id).then((res) => {
                              setLists(res.data)
                              setRemovingFromList({
                                ...removingFromList,
                                [_id]: false
                              })
                            })
                          }
                          )
                        }}>Delete</button>
                      )}
                    </div>
                  )
                })
              }
            </div>
            <button
              className='px-3 py-2 bg-volt rounded justify-center items-center inline-flex text-base font-medium mt-4'
              onClick={() => toggleCheckContact(contact?._id, true)}
            // disabled={state.checkedContacts?.[contact?._id]}
            >
              <HiOutlineDownload />
              <div className='ml-2 italic'>
                Add to Lists
              </div>
            </button>
          </div>
      }
    </div>
  )
}

export default ListsView
