// @ts-nocheck
import React, { useCallback, useEffect, useRef, useState } from 'react'

import { Oval } from 'react-loader-spinner'

import CustomInput from 'components/CustomInput'
import CustomSelect from 'components/CustomSelect'
import { getProjectUnits } from 'store/actions/unitActions'
import { useSelector } from 'react-redux'
import { createOrUpdateParking } from 'store/actions/parkingActions'
import { ADMIN_OFFER } from 'routes'
import { useHistory } from 'react-router-dom'

const EditParkingView = ({
  editDetails,
  setEditDetails,
  refetch,
  building,
  closeParkingInfoDrawer,
  isAbleToPurchase
}) => {
  // @ts-ignore
  const history = useHistory()
  const projectSelector = state => state.appReducer.appProject
  const appProject = useSelector(projectSelector)
  const userSelector = state => state.authReducer.userObject
  const userObject = useSelector(userSelector)
  const mountedRef = useRef(true)
  const [unitOptions, setUnitOptions] = useState([])

  useEffect(() => {
    _getProjectUnits()
  }, [appProject])

  const _getProjectUnits = async () => {
    if (appProject) {
      setUnitOptions([])

      let projectUnits = await getProjectUnits(appProject)

      projectUnits = projectUnits.map((unit) => ({
        ...unit,
        hasPermission: checkUnitPermission(unit)
      }))

      Promise.all([projectUnits]).then(() => {
        if (projectUnits.length) {
          setUnitOptions(projectUnits.map((unit) => (
            {
              value: unit.unitNumber,
              id: unit._id
            }
          )))
        }
      })
    }
  }

  const checkUnitPermission = useCallback(
    (unit, selectedTabFilter) => {
      const { _id } = userObject
      const { brokerAssigned, reservedTo } = unit

      const tmpBrokerAssigned = brokerAssigned || []

      return Boolean(
        (selectedTabFilter !== 'myUnits' &&
          unit.status !== 'ReservedBroker' &&
          unit.status !== 'ReservedBuyer') ||
        ((unit.status === 'ReservedBroker' ||
          unit.status === 'ReservedBuyer') &&
          ((tmpBrokerAssigned &&
            tmpBrokerAssigned.length &&
            tmpBrokerAssigned[0] !== 'HIDDEN') ||
            (reservedTo && reservedTo.length && reservedTo[0] !== 'HIDDEN') ||
            tmpBrokerAssigned.filter((broker) => broker === _id).length))
      )
    },
    [userObject]
  )

  const initialEmptyValues = {
    stall: null,
    price: null,
    floorNumber: null,
    unit: '',
    status: '',
    inBuilding: null,
    building
  }
  const [value, setValues] = useState({
    ...initialEmptyValues
  })

  const TEMP_AVAILABILITY_STATUS = {
    available: 'Available',
    assigned: 'Assigned',
    reserved: 'Reserved',
    allocated: 'Allocated',
    recision: 'Recision',
    sold: 'Sold'
  }

  const STATUS_OPTIONS = [
    ...Object.values(TEMP_AVAILABILITY_STATUS).map((value) => {
      return { value: value, id: value }
    })
  ]

  const isInsideBuildingOptions = [
    { id: 'true', value: 'Yes' },
    { id: 'false', value: 'No' }
  ]

  const handleSubmit = () => {
    if (validate()) {
      setLoading(true)
      const temp = {
        // @ts-ignore
        ...value
      }
      createOrUpdateParking(temp)
        .then((data) => {
          refetch()
          setLoading(false)
          setEditDetails(data)
          closeParkingInfoDrawer()
        })
        .catch((err) => {
          setLoading(false)
          console.log(err)
        })
    }
  }

  const onChange = (fieldName, newValue) => {
    setValues({ ...value, [fieldName]: newValue })
  }
  const [loading, setLoading] = useState(false)

  const validate = () => {
    for (const key in initialEmptyValues) {
      if (!value[key]) {
        return false
      }
    }
    return true
  }

  useEffect(() => {
    if (!mountedRef.current) return null
    if (editDetails?.type === 'Parking') {
      setValues({
        ...value,
        ...editDetails,
        status: STATUS_OPTIONS.filter(
          ({ id }) => id === editDetails.status
        )?.[0]?.id,
        inBuilding: editDetails.inBuilding ? 'true' : 'false'
      })
    } else {
      setValues(initialEmptyValues)
    }
  }, [editDetails])

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  const handleStatusChange = (id) => {
    const temp = STATUS_OPTIONS.filter((option) => option.id === id)[0]
    onChange('status', temp.id)
  }

  const handleUnitChange = (id) => {
    const temp = unitOptions.filter((option) => option.id === id)[0]
    onChange('unit', temp.id)
  }

  const handleIsInsideBuildingChange = (id) => {
    const temp = isInsideBuildingOptions.filter((option) => option.id === id)[0]
    onChange('inBuilding', temp.id)
  }

  return (
    <div
      className='font-openSans relative bg-grey-8 flex flex-col h-[calc(100%-64px)] overflow-hidden'
    >
      <div className='flex-1 flex flex-col gap-4 overflow-y-auto thin-scrollbar'>
        <CustomInput
          label='Stall*'
          placeholder='Enter number'
          labelClassName='italic'
          value={value.stall}
          onChange={(e) => {
            onChange('stall', e.target.value)
          }}
          type='number'
          required='required'
          errorMessage='Please enter stall number'
          containerClasses={undefined}
          classes={undefined}
        />
        <CustomSelect
          label='status*'
          inputClasses='h-11'
          labelClasses={undefined}
          options={STATUS_OPTIONS}
          selectedOption={value.status}
          setSelectedOption={(option) => {
            handleStatusChange(option)
          }}
        />
        <CustomInput
          label='price*'
          placeholder='Enter amount'
          labelClassName='italic'
          value={value.price}
          onChange={(e) => {
            onChange('price', e.target.value)
          }}
          type='number'
          required='required'
          errorMessage='Please enter price'
          containerClasses={undefined}
          classes={undefined}
        />
        <CustomSelect
          label='Unit*'
          inputClasses='h-11'
          labelClasses={undefined}
          options={unitOptions}
          selectedOption={value.unit}
          setSelectedOption={(option) => {
            handleUnitChange(option)
          }}
        />
        <CustomInput
          label='floor number*'
          placeholder='Enter number'
          labelClassName='italic'
          value={value.floorNumber}
          onChange={(e) => {
            onChange('floorNumber', e.target.value)
          }}
          type='number'
          required='required'
          errorMessage='Please enter floor number'
          containerClasses={undefined}
          classes={undefined}
        />
        <CustomSelect
          label='Is inside building*'
          labelClasses='italic'
          inputClasses='h-11'
          options={isInsideBuildingOptions}
          setSelectedOption={(value) => {
            handleIsInsideBuildingChange(value)
          }}
          selectedOption={value.inBuilding}
        />
      </div>
      <div className='mt-2 border-t border-softBlack/[30%] flex flex-col gap-4'>
        <button
          className='mt-2 w-full py-3 px-6 text-base bg-volt rounded font-medium m-auto flex gap-2 items-center justify-center'
          disabled={!validate() || loading}
          onClick={handleSubmit}
        >
          {loading && <Oval height='18' width='18' color='black' />}
          <div className='italic'>{value?._id ? 'Update' : 'Add'} Parking</div>
        </button>
        {
          isAbleToPurchase &&
          <button
            onClick={() => history.push(ADMIN_OFFER)}
            className='w-full py-3 px-6 text-base rounded font-medium border border-softBlack70'
          >
            Purchase
          </button>
        }
      </div>
    </div>
  )
}

export default EditParkingView
