// @ts-nocheck
import React, { memo, useEffect, useState } from 'react'
import clsx from 'clsx'

import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg'
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg'

import { ReactComponent as CheckedCheckbox } from 'assets/icons/CheckedCheckbox.svg'
import { formatMoney } from 'utils'
import { Info } from 'lucide-react'
import CustomButton from 'components/CustomButton'
import CustomModal from 'ui/customModal'
import { useSelector } from 'react-redux'

const InfoModal = memo(({ showModal, onCancel, onOk, details, definedAdditionalFields }) => {
  return (
    <CustomModal
      showModal={showModal}
      onCancel={onCancel}
      onOk={onOk}
    >
      <div className='min-w-[95%] md:max-w-[500px] md:min-w-[400px] min-h-[90%] md:min-h-[200px] flex flex-col gap-4'>
        <div className='flex flex-col gap-2'>
          <h2 className='text-2xl mb-0 border-b pb-2'>{details.title}</h2>
          <div>
            <p className='text-sm mb-0 flex gap-1'>
              <span className='font-medium'>
                Price:
              </span>
              {formatMoney(details.price)}
            </p>
            <p className='text-sm mb-0 flex gap-1'>
              <span className='font-medium'>
                Description:
              </span>
              {details?.description ?? '-'}
            </p>
          </div>
        </div>
        {
          definedAdditionalFields?.length > 0 &&
          <div className='flex flex-col gap-2'>
            <h3 className='text-lg mb-0 border-b pb-1'>Addition Details</h3>
            <div>
              {
                definedAdditionalFields.map((field, index) => (
                  <p key={index} className='text-sm mb-0 flex gap-1 break-words'>
                    {field.title}: {field.value}
                  </p>
                ))
              }
            </div>
          </div>
        }
        <div className='flex-1 w-full mt-6 flex items-end justify-center md:justify-end gap-3'>
          <CustomButton handleClick={onCancel}>
            <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
              Close
            </span>
          </CustomButton>
        </div>
      </div>
    </CustomModal>
  )
})

const OptionCard = ({
  id = 1,
  isActive = true,
  imgSrc,
  price = 10000,
  description = 'Here should go the description of the option. The length shouldn’t be more than 3 rows.',
  count = 0,
  increaseCount,
  decreaseCount,
  checked,
  setChecked,
  checkboxTitle = 'Default checkbox',
  containerClasses,
  title,
  additionalFields,
  type,
  unitName = '',
  isReview = false
}) => {
  const customSections = useSelector((state) => state.appReducer.customSections)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [definedAdditionalFields, setDefinedAdditionalFields] = useState({})

  useEffect(() => {
    if (customSections) {
      const temp = customSections
        .filter((section) => section._id === type)?.[0]?.dataFields?.filter((field) => (field?.isAdditional && !field?.isDeleted))
        .map((field) => ({
          ...field,
          value: additionalFields?.[field.customFieldName] ?? '-'
        }))
      setDefinedAdditionalFields(temp)
    }
  }, [customSections])

  if (isReview) {
    return (
      <div className='flex w-full flex-col'>
        <div className='flex flex-wrap w-full md:gap-6 text-gray-700'>
          <div className='flex w-full md:w-[436px] h-[321px] justify-center content-center item-center object-cover'>
            <img className='object-contain' src={imgSrc?.length > 0 ? imgSrc : '/placeholder-image.svg'} alt={title} />
          </div>
          <div className='flex flex-col grow w-full mt-4'>
            <div className='flex items-center gap-4'>
              <h1 className='text-softBlack text-2xl font-bold p-0 m-0'>
                {unitName}
              </h1>
              {
                additionalFields &&
                <div
                  className='rounded-md p-2 bg-zinc-200 hover:bg-zinc-300 transition-all cursor-pointer'
                  onClick={() => setIsInfoModalOpen(true)}
                >
                  <Info className='w-6 h-6' />
                </div>
              }
            </div>
            {description?.length > 0 && (
              <span className='mt-3 text-sm'>{description}</span>
            )}
            <div className='w-full border border-softBlack30 my-6' />
            <div className='mt-2'>
              <p className='text-xs font-bold text-gray-500'>TOTAL VALUE</p>
              <div className='flex flex-col'>
                <div className='flex justify-between items-baseline text-gray-800'>
                  {price && (
                    <span className='mt-1 font-bold' style={{ fontSize: 22 }}>
                      {formatMoney(price)}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <InfoModal
          showModal={isInfoModalOpen}
          onCancel={() => setIsInfoModalOpen(false)}
          onOk={() => setIsInfoModalOpen(false)}
          details={{
            title: unitName,
            price,
            description: description?.length > 0 ? description : 'Here should go the description of the option. The length shouldn’t be more than 3 rows.'
          }}
          definedAdditionalFields={definedAdditionalFields}
        />
      </div>
    )
  }

  return (
    <div className={clsx(`${containerClasses}`, {
      'border-2 border-softBlack': isActive
    })}>
      <div className='h-60'>
        <img className='w-full h-full' src={imgSrc?.length > 0 ? imgSrc : '/placeholder-image.svg'} alt={title} />
      </div>
      <div className='border-2 border-softBlack my-4 w-full' />
      <div className='w-full flex items-center gap-2 justify-between mb-4 overflow-hidden'>
        <div className='flex-1 overflow-hidden'>
          <p className='font-bold text-2xl my-0 break-words truncate'>{title}</p>
          <p className='text-sm font-medium mt-1 mb-0'>{formatMoney(price)}</p>
        </div>
        {
          additionalFields &&
          <div
            className='rounded-md p-2 bg-zinc-200 hover:bg-zinc-300 transition-all cursor-pointer'
            onClick={() => setIsInfoModalOpen(true)}
          >
            <Info className='w-6 h-6' />
          </div>
        }
      </div>
      <p className='mt-1 mb-0 text-base text-softBlack'>
        {description?.length > 0 ? description : 'Here should go the description of the option. The length shouldn’t be more than 3 rows.'}
      </p>
      <div className='flex items-center justify-between mt-4'>
        <div className='form-check flex items-center justify-between'>
          {checked ? <CheckedCheckbox className='cursor-pointer mr-3 transition duration-200 w-6 h-6' onClick={setChecked} /> : <div className='cursor-pointer w-6 h-6 border border-softBlack30 rounded-sm mr-3 transition duration-200' onClick={setChecked} />}
          <label className='mt-1 mb-0 text-base text-softBlack font-medium' htmlFor='flexCheckDefault'>
            {checkboxTitle}
          </label>
        </div>
        <div className={clsx('flex items-center justify-between gap-5', {
          'opacity-50 cursor-not-allowed': !checked
        })}>
          <span onClick={decreaseCount} className={clsx('rounded-sm cursor-pointer h-6 w-6 border border-softBlack70 flex justify-center items-center font-bold text-softBlack', {
            'opacity-50 cursor-not-allowed': !checked
          })}><MinusIcon /></span>
          <p className='my-0 min-w-6 text-base text-softBlack font-medium'>{count}</p>
          <span onClick={increaseCount} style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }} className={clsx('rounded-sm cursor-pointer h-6 w-6 flex justify-center items-center font-bold text-softBlack bg-volt', {
            'opacity-50 cursor-not-allowed': !checked
          })}><PlusIcon /></span>
        </div>
      </div>
      <InfoModal
        showModal={isInfoModalOpen}
        onCancel={() => setIsInfoModalOpen(false)}
        onOk={() => setIsInfoModalOpen(false)}
        details={{
          title,
          price,
          description: description?.length > 0 ? description : 'Here should go the description of the option. The length shouldn’t be more than 3 rows.'
        }}
        definedAdditionalFields={definedAdditionalFields}
      />
    </div>
  )
}
export default OptionCard
