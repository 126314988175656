import React, { useMemo, useState, useCallback, useEffect } from 'react'
import propTypes from 'prop-types'
import './style/style.css'
import { AdminPageWrapper, PageHeader } from 'components'
import { TFilledPlusIcon } from 'components/Icons'
import { connect } from 'react-redux'
import { Table } from 'ui'
import { formatDate } from 'utils'
import standardStyle from 'assets/css/standardStyle'
import { ReactComponent as DownIcon } from 'assets/icons/down.svg'
import { ReactComponent as UserCheck } from 'assets/icons/user-check.svg'
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'
import { Select } from 'antd'
import { api, ApiErrors, getTokenRequestHeader } from 'services/api'
import { useGetContactLists } from 'hooks/useGetContactLists'
import CustomModal from 'ui/customModal'
import TableGrid from 'components/TableGrid/Table'
import { getUsersPagination } from 'store/actions/usersActions'

const Button = ({ className, disabled, onClick, children }) => {
  return (
    <button className={`text-softBlack text-center text-base font-medium py-1.5 px-4 space-x-1.5 flex items-center rounded ${className}`}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

const TextField = ({ value }) => (
  <span className='font-medium text-base text-softBlack capitalize'>
    {value}
  </span>
)

const CardComponent = ({ IconSrc, title, count }) => (
  <div
    className='bg-white rounded'
    style={{ fontFamily: standardStyle.fonts.sfpro }}
  >
    <div className='p-6'>
      <div className='w-8 h-6'>{IconSrc}</div>
    </div>
    <div className='flex flex-col space-y-2 p-6'>
      <div className='text-xs text-softBlack_70 font-medium uppercase'>
        {title}
      </div>
      <div className='text-xl text-softBlack font-bold'>
        {count}
      </div>
    </div>
  </div>
)

const demoTemplateMessages = [
  {
    title: 'Exclusive Event Invitation',
    message: 'You are invited! Join us at our VIP Real Estate Event to explore exclusive offers and insights. RSVP now and be a part of something special. Details at www.1818pacifica.com'
  },
  {
    title: 'Weekend Promo Extravaganza',
    message: 'This weekend only! Special promotional deals on select properties. Do not miss out on these incredible savings. Visit www.1818pacifica.com for more information.'
  },
  {
    title: 'Sales Launch Alert',
    message: 'Be the first to know! Our latest property sales launch is happening soon. Exclusive early access for SMS subscribers. Stay updated at www.1818pacifica.com'
  },
  {
    title: 'Discover Your Dream Home',
    message: 'Your dream home awaits. Browse our latest listings and find the perfect match for your lifestyle. Start your journey at www.1818pacifica.com'
  },
  {
    title: 'Presentation Centre Opening',
    message: 'we are excited to announce the opening of our new Presentation Centre! Come and explore the future of living. For more details, visit www.1818pacifica.com'
  },
  {
    title: 'Limited-Time Offer',
    message: 'Act fast! Limited-time offer on our premium properties. Secure your dream home before its too late. Exclusive details at www.1818pacifica.com'
  }
]

const SMSBroadcast = (props) => {
  const { appProject } = props
  const { contactsLists, getContacts } = useGetContactLists()
  const [smsBroadcastList, setSmsBroadcastList] = useState([])
  const [showCreateSMSBroadcast, setShowCreateSMSBroadcast] = useState(false)
  const [smsBroadcastDetails, setSmsBroadcastDetails] = useState({
    message: 'This is a test message.',
    campaignName: 'Test SMS-broadcast Title'
  })
  const [isLoading, setIsLoading] = useState(true)
  const [selectedContactGroupId, setSelectedContactGroupId] = useState(null)
  const [contactGroup, setContactGroup] = useState(null)
  const [extraContacts, setExtraContacts] = useState([])

  const getAllSMSBroadcastList = useCallback(() => {
    return new Promise((resolve, reject) => {
      const token = getTokenRequestHeader()
      setIsLoading(true)
      // @ts-ignore
      api.setHeader('Authorization', token)

      api.get('sms/campaigns').then((response) => {
        if (response.ok) {
          resolve(response.data)
          setSmsBroadcastList(response.data.map((elm) => {
            return {
              broadcastName: elm.campaignName,
              sentDate: elm.updatedAt ?? elm.createdAt,
              numberOfRecipients: elm.report.length,
              delivered: elm?.report?.filter((message) => message.status === 'delivered').length,
              failed: elm?.report?.filter((message) => message.status === 'failed').length
            }
          }))
        } else {
          ApiErrors(response)
          reject(response)
        }
      })
      setIsLoading(false)
    })
  }, [])

  useEffect(() => {
    if (!contactsLists.length) {
      getContacts()
    }
    getAllSMSBroadcastList()
  }, [])

  useEffect(() => {
    const arr = []
    contactsLists.forEach((el) => {
      if (!arr.find((item) => el.title === item.name)) {
        arr.push({ id: el._id, name: el.title })
      }
    })
    setContactGroup(arr)
  }, [contactsLists])

  const RightContent = () => (
    <div className='flex flex-row items-center'>
      <Button
        disabled={!appProject}
        onClick={() => setShowCreateSMSBroadcast(!showCreateSMSBroadcast)}
        className={showCreateSMSBroadcast ? 'border border-softBlack_70 bg-transparent' : 'px-6 bg-volt'}
      >
        {
          showCreateSMSBroadcast
            ? <>Cancel</>
            : <>
              <TFilledPlusIcon className='h-6 w-6' />
              Create
            </>
        }
      </Button>
    </div>
  )

  const tableColumns = useMemo(
    () => [
      {
        Title: (
          <span className='flex items-center space-x-2'>
            <span className='leading-none'>Broadcast Name</span>
          </span>
        ),
        accessor: 'broadcastName',
        dataIndex: 'broadcastName',
        Cell: (props) => <TextField value={props.cell.value} />
      },
      {
        Title: (
          <span className='flex items-center space-x-2'>
            <span className='leading-none'>Date Sent</span>
          </span>
        ),
        accessor: 'sentDate',
        dataIndex: 'sentDate',
        Cell: (props) => <TextField value={formatDate(props.cell.value)} />
      },
      {
        Title: (
          <span className='flex items-center space-x-2'>
            <span className='leading-none'>Number of Recipients</span>
          </span>
        ),
        accessor: 'numberOfRecipients',
        dataIndex: 'numberOfRecipients',
        Cell: (props) => <TextField value={props.cell.value} />
      },
      {
        Title: (
          <span className='flex items-center space-x-2'>
            <span className='leading-none'>Delivered</span>
          </span>
        ),
        accessor: 'delivered',
        dataIndex: 'delivered',
        Cell: (props) => <TextField value={props.cell.value} />
      },
      {
        Title: (
          <span className='flex items-center space-x-2'>
            <span className='leading-none'>Failed</span>
          </span>
        ),
        accessor: 'failed',
        dataIndex: 'failed',
        Cell: (props) => <TextField value={props.cell.value} />
      }
    ],
    []
  )

  const SMSBroadcastTable = useCallback(() => {
    return (
      <div className='mt-5 w-full'>
        <Table
          className='rounded-lg'
          dataSource={smsBroadcastList}
          columns={tableColumns}
          tailwindTable
          loading={isLoading}
        />
      </div>
    )
  }, [smsBroadcastList, tableColumns, isLoading])

  const sendSMSBroadcast = useCallback(() => {
    return new Promise((resolve, reject) => {
      const token = getTokenRequestHeader()

      // @ts-ignore
      api.setHeader('Authorization', token)

      api.post('sms/list', { ...smsBroadcastDetails, listId: selectedContactGroupId, contacts: extraContacts ?? [] }).then((response) => {
        if (response.ok) {
          resolve(response)
          getAllSMSBroadcastList()
        } else {
          ApiErrors(response)
          reject(response)
        }
      })
      setShowCreateSMSBroadcast(false)
    })
  }, [getAllSMSBroadcastList, selectedContactGroupId, extraContacts, smsBroadcastDetails])

  const countRecipientsDeliveredAndFailed = useMemo(() => {
    let totalDelivered = 0
    let totalFailed = 0
    let totalRecipients = 0

    smsBroadcastList.forEach((elm) => {
      totalDelivered += elm.delivered
      totalFailed += elm.failed
      totalRecipients += elm.numberOfRecipients
    })

    return {
      totalDelivered,
      totalFailed,
      totalRecipients
    }
  }, [smsBroadcastList])

  return (
    <>
      <PageHeader
        title='SMS Broadcast'
        rightContent={<RightContent />}
      />
      <AdminPageWrapper style={{ height: 'calc(100vh - 164px)' }} fullscreen>
        {
          showCreateSMSBroadcast
            ? <div className='h-full py-8 flex gap-x-16'>
              <div className='w-1/2'>
                <h1 className='text-4xl font-bold'>SMS Broadcast</h1>
                <div className='flex flex-col gap-y-5'>
                  <div>
                    <h4>Message Title</h4>
                    <input
                      placeholder='Type your title here.'
                      value={smsBroadcastDetails?.campaignName ?? ''}
                      onChange={(e) => setSmsBroadcastDetails({
                        ...smsBroadcastDetails,
                        campaignName: e.target.value
                      })}
                    />
                  </div>
                  <div>
                    <h4>Your Message</h4>
                    <textarea
                      className='w-full h-[200px] rounded border border-softBlack_70/20'
                      placeholder='Type your message here.'
                      value={smsBroadcastDetails?.message ?? ''}
                      onChange={(e) => setSmsBroadcastDetails({
                        ...smsBroadcastDetails,
                        message: e.target.value
                      })}
                    />
                  </div>
                </div>
                <Button disabled={!selectedContactGroupId || !smsBroadcastDetails.message.length || !smsBroadcastDetails.campaignName.length} onClick={sendSMSBroadcast} className='mt-3 bg-volt'>
                  Send
                </Button>
              </div>
              <div className='w-1/2 h-full'>
                <div className='h-full flex flex-col gap-y-5'>
                  <div>
                    <h3 className='text-2xl font-semibold'>Contact Groups</h3>
                    <Select
                      showArrow
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      value={selectedContactGroupId ?? 'Select groups'}
                      onChange={(value) => {
                        setSelectedContactGroupId(value)
                      }}
                      className='ant-picker-input1'
                      suffixIcon={<DownIcon />}
                      bordered={false}
                      getPopupContainer={(node) => node.parentNode}
                    >
                      {contactGroup?.map((el) => (
                        <Select.Option key={el.id} label={el.name} value={el.id}>
                          {el.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                  <div
                    className='flex flex-col gap-y-1'
                  >
                    <ModelAddContacts
                      existing={extraContacts}
                      onAdded={(contacts) => {
                        if (!contacts || !contacts.length || !Array.isArray(contacts)) return
                        const updatedContacts = [...extraContacts, ...contacts]
                        const uniqueContacts = updatedContacts.filter(
                          (contact, index, self) => index === self.findIndex((t) => t === contact)
                        )
                        setExtraContacts(uniqueContacts)
                      }} />
                    <div>
                      {extraContacts?.length > 0 && (
                        <div>
                          Added {extraContacts?.length} contacts
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='flex-1 flex flex-col overflow-hidden'>
                    <h3 className='text-2xl font-semibold'>Template Messages</h3>
                    <div className='flex-1 grid grid-cols-2 gap-4 overflow-y-auto thin-scrollbar'>
                      {
                        demoTemplateMessages.map(({ title, message }) => (
                          <div className='p-4 col-span-1 rounded border border-softBlack_70/20 bg-white'>
                            <h6 className='text-lg'>{title}</h6>
                            <p>{message}</p>
                            <Button
                              className='mt-3 bg-volt'
                              onClick={() => setSmsBroadcastDetails({
                                message,
                                campaignName: title
                              })}
                            >
                              Copy
                            </Button>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            : <div className='h-full w-full flex flex-col mt-6 items-center'>
              <div className='grid grid-cols1 md:grid-cols-4 gap-4 w-full'>
                <CardComponent
                  IconSrc={
                    <svg
                      width='32'
                      height='32'
                      viewBox='0 0 32 32'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M22.6654 5.33301H9.33203V25.333H22.6654V5.33301Z'
                        fill='#2E2B2E'
                      />
                      <path d='M6.66667 8H4V22.6667H6.66667V8Z' fill='#2E2B2E' />
                      <path d='M27.9987 8H25.332V22.6667H27.9987V8Z' fill='#2E2B2E' />
                    </svg>
                  }
                  title='Total SMS Broadcast'
                  count={smsBroadcastList.length}
                />
                <CardComponent
                  IconSrc={
                    <svg
                      width='33'
                      height='32'
                      viewBox='0 0 33 32'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M24.5 17.3334H23.5933L20.9267 20.0001H23.4733L25.8333 22.6667H7.16667L9.54 20.0001H12.2733L9.60667 17.3334H8.5L4.5 21.3334V26.6667C4.5 28.1334 5.68667 29.3334 7.15333 29.3334H25.8333C27.3 29.3334 28.5 28.1467 28.5 26.6667V21.3334L24.5 17.3334ZM23.1667 10.6001L16.5667 17.2001L11.8467 12.4801L18.4467 5.88005L23.1667 10.6001ZM17.5133 3.05339L9.02 11.5467C8.5 12.0667 8.5 12.9067 9.02 13.4267L15.62 20.0267C16.14 20.5467 16.98 20.5467 17.5 20.0267L25.98 11.5467C26.5 11.0267 26.5 10.1867 25.98 9.66672L19.38 3.06672C18.8733 2.53339 18.0333 2.53339 17.5133 3.05339Z'
                        fill='#2E2B2E'
                      />
                    </svg>
                  }
                  title='Total Number of Recipients'
                  count={countRecipientsDeliveredAndFailed.totalRecipients}
                />
                <CardComponent
                  IconSrc={<UserCheck />}
                  title='Total Delivered'
                  count={countRecipientsDeliveredAndFailed.totalDelivered}
                />
                <CardComponent
                  IconSrc={<CrossIcon />}
                  title='Total Failed'
                  count={countRecipientsDeliveredAndFailed.totalFailed}
                />
              </div>
              <SMSBroadcastTable />
            </div>
        }
      </AdminPageWrapper>
    </>
  )
}

const mapStateToProps = (state) => ({
  appProject: state.appReducer.appProject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(SMSBroadcast)

SMSBroadcast.propTypes = {
  appProject: propTypes.string
}

Button.propTypes = {
  className: propTypes.string,
  disabled: propTypes.bool,
  onClick: propTypes.func,
  children: propTypes.node
}

// eslint-disable-next-line space-before-function-paren
function ModelAddContacts({ onAdded, existing }) {
  const [show, setShow] = React.useState(false)
  const handleClose = (contacts) => {
    setShow(false)
    if (onAdded && typeof onAdded === 'function') {
      onAdded(contacts)
    }
  }
  const tableRef = React.useRef({
    reloadTableData: () => { }
  })
  const [searchFilter, setSearchFilter] = React.useState('')
  useEffect(() => {
    if (!show) {
      if (onAdded && typeof onAdded === 'function') {
        onAdded([])
      }
    }
  }, [onAdded, show])
  return (
    <div className='w-full'>
      <button onClick={() => setShow(true)} className='bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded'>
        Add Contacts
      </button>
      <CustomModal
        showModal={show}
        onCancel={handleClose}
      >
        <div className='w-[100%] min-w-[600px] sm:min-w-[900px]'>
          <div className='flex justify-between w-full'>
            <div>
              Add Contacts to the list
            </div>
            <div>
              <button onClick={handleClose} className='bg-red-500 hover:bg-red-700 text-white font-bold py-0.5 px-2.5 rounded'>
                Cancel
              </button>
            </div>
          </div>
          <div>
            <input
              type='text'
              placeholder='Search by Name or email'
              className='w-full border border-gray-300 rounded-md px-2 my-1'
              value={searchFilter} onChange={(e) => setSearchFilter(e.target.value)} />
          </div>
          <div>
            <TableGrid
              multiple
              style={{ height: '470px', minHeight: '470px' }}
              allowSelect
              allowSelectAll
              rowSelectKey='id'
              columnDefs={[
                {
                  headerName: 'Email',
                  field: 'email',
                  sortable: true,
                  checkboxSelection: true,
                  flex: 1,
                  resizable: true
                },
                {
                  headerName: 'Name',
                  field: 'firstName',
                  sortable: true,
                  resizable: true,
                  cellRenderer: ({ data }) => {
                    return `${data.firstName} ${data.lastName}`
                  }
                },
                {
                  headerName: 'Phone',
                  field: 'buyerData.phoneNumber',
                  cellRenderer: ({ value }) => {
                    return value ?? 'N/A'
                  }
                },
                {
                  headerName: 'User Type',
                  field: 'userType',
                  sortable: true,
                  cellRenderer: ({ value }) => {
                    const userType = value
                    const availableTags = [
                      ['buyer', 'bg-blue-500 text-white'],
                      ['DeveloperAdmin', 'bg-red-500 text-white'],
                      ['CoopBroker', 'bg-green-500 text-white'],
                      ['LeadBroker', 'bg-green-500 text-white'],
                      ['SalesRep', 'bg-orange-500 text-white']
                    ]
                    return <div>
                      {/* eslint-disable-next-line array-callback-return */}
                      {availableTags.map(([tag, color]) => {
                        if (userType.toLowerCase() === tag.toLowerCase()) {
                          return (
                            <div className={
                              `capitalize text-gray-800 border border-gray-300 px-2 py-1 rounded-full text-xs ${color}`
                            }>
                              {tag}
                            </div>
                          )
                        }
                      })}
                    </div>
                  }
                }
              ]}
              actions={[
                {
                  label: 'Add Selected',
                  progressLabel: 'Adding...',
                  onlyWhenSelected: true,
                  apply: async (selectedRows) => {
                    handleClose(selectedRows)
                  }
                }
              ]}
              getData={async (
                filter,
                pagination,
                sorting
              ) => {
                // eslint-disable-next-line no-async-promise-executor
                return new Promise(async (resolve) => {
                  const searchText = {}
                  let search = searchFilter
                  if (search) {
                    if (!isNaN(Number(search))) {
                      search = ''
                    }
                    if (search) {
                      if (!searchText?.$or) {
                        searchText.$or = []
                      }
                      searchText.$or = [
                        { email: { $regex: search, $options: 'i' } },
                        { firstName: { $regex: search, $options: 'i' } },
                        { lastName: { $regex: search, $options: 'i' } }
                      ]
                    }
                  }
                  const { docs: users } = await getUsersPagination({
                    ...searchText,
                    ...filter
                  }, {
                    ...pagination,
                    sort: sorting
                  }, existing)
                  const list = users.filter(e => {
                    return !existing.find(i => i === e.id)
                  })
                  resolve(list)
                })
              }} >
              {(params) => {
                tableRef.current = params
              }}
            </TableGrid>
          </div>
        </div>
      </CustomModal>
    </div>
  )
}
