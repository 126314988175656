import { CopyIcon } from 'lucide-react'
import React, { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

// eslint-disable-next-line space-before-function-paren
export default function CopyIt({
  content,
  size = 'base',
  title = 'Copy',
  copyTitle = 'Copied'
}) {
  const [copied, setCopied] = useState(false)
  return (
    <CopyToClipboard text={content} onCopy={() => {
      setCopied(true)
      setTimeout(() => {
        setCopied(false)
      }, 2500)
    }}>
      <div>
        <button className={`flex gap-x-1 col-span-2  px-3 py-2 rounded font-semibold items-center justify-start ${copied ? 'bg-gray-400' : 'bg-volt'}`}>
          {!copied && <CopyIcon size={size === 'sm' ? 12 : 16} />}
          <span className={`${size === 'sm' ? 'text-xs' : ''}`}>{copied ? copyTitle : title}</span>
        </button>
      </div>
    </CopyToClipboard>
  )
}
