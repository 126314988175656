// import React, { useEffect, useRef } from 'react'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import picture from '../../../assets/img/buyerBackgroundImage.jpg'
import { ADMIN_ADD_BUILDING } from 'routes'

import BuildingCard from 'components/BuildingCard'
import { FadedAnimatedDiv } from 'components'

import { getBuildings } from 'store/actions/buildingActions'
import Searching from 'pages/contacts/Searching'
import { useSelector } from 'react-redux'

const LoadingDetails = ({ title }) => (
  <FadedAnimatedDiv className='h-full w-full flex flex-col items-center mt-20'>
    <Searching title={title} />
  </FadedAnimatedDiv>
)

const AdminBuildings = () => {
  // @ts-ignore
  const { appProject: projectId } = useSelector((state) => state.appReducer)
  const [buildings, setBuildings] = useState([])
  const [loading, setLoading] = useState(false)

  const _getBuildings = async (id) => {
    setLoading(true)
    try {
      const data = await getBuildings(`project/${projectId}`)
      setBuildings(data)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  useEffect(() => {
    _getBuildings(projectId)
  }, [projectId])

  const history = useHistory()
  const RightContent = () => (
    <div className='flex flex-row items-center'>
      <button
        className='cursor-pointer hover:text-yellow-400'
        onClick={() => history.push(ADMIN_ADD_BUILDING)}
      >
        <span className='text-2xl mr-2'>+</span>
        <span className='italic text-base font-light font-openSans'>
          Add Building
        </span>
      </button>
    </div>
  )

  const BuildingCardsLayout = ({ loading, buildings }) => (
    <div className='grid grid-cols-none lg:grid-cols-2 2xl:grid-cols-3 gap-0 w-full'>
      {loading
        ? (
        <div
          className='flex items-center justify-center w-full h-screen'
          style={{ gridColumn: '1 / -1' }}
        >
          <LoadingDetails title='Loading buildings' />
        </div>
          )
        : (
            buildings && buildings.length > 0 &&
          buildings.map((item, index) => (
            <BuildingCard
              key={item._id}
              id={item._id}
              index={index}
              propertyName={item.name}
              address={item.fullAddress}
              imgsrc={picture}
              price={`${item.totalDollarValue} ${item.totalDollarValueCurrencyType}`}
              noOfUnits={item.numberOfUnits}
            />
          ))
          )}
    </div>
  )

  return (
    <>
      <div className='bg-white min-h-screen relative'>
        <div className='p-6 flex justify-between items-center'>
          <span className='font-bold text-3xl font-openSans'>Buildings</span>
          <RightContent />
        </div>
        <div className='border-b-2 border-black' />
        <BuildingCardsLayout loading={loading} buildings={buildings} />
      </div>
    </>
  )
}

export default AdminBuildings
