/* eslint-disable space-before-function-paren */
import React, { useEffect, useState } from 'react'
import standardStyle from 'assets/css/standardStyle'
import { FadedAnimatedDiv, LottieWrapper, PageHeader } from 'components'
// @ts-ignore
import tableLoading from '../../assets/lottieJsons/tableLoading.json'
import { deleteEmailCampaigns, syncCampaignsReport, getAllEmailCampaigns } from 'store/actions/customEmailCampaignActions'
import DeleteModal from 'components/DeleteModal'
import CampaignCard from 'components/Campaigns/CampaignCard'

const Card = ({ title, value }) => (
  <div className='border border-border rounded'>
    <div
      className='bg-white rounded h-full p-6'
      style={{ fontFamily: standardStyle.fonts.sfpro }}
    >
      <div className='h-full flex flex-col justify-between space-y-2'>
        <div className='text-xs text-softBlack_70 font-medium uppercase'>
          {title}
        </div>
        <div className='text-xl text-softBlack font-bold'>{value}</div>
      </div>
    </div>
  </div>
)

const refreshIntervalSeconds = 60 * 5 // 5 minutes
export default function EmailCustomCampaignPage() {
  const [campaigns, setCampaigns] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deletedToBeCampaign, setDeletedToBeCampaign] = useState({})
  const [refresh, setRefresh] = useState(0)
  const [analytics, setAnalytic] = useState({
    total_campaign: 0,
    total_email_sent: 0,
    total_opened: 0,
    total_clicked: 0,
    total_bounced: 0,
    total_unsubscribed: 0
  })

  const getCampaigns = () => {
    setIsLoading(true)
    getAllEmailCampaigns()
      .then((campaigns) => {
        setCampaigns(campaigns)
        const report = campaigns.reduce((acc, campaign) => {
          acc.total_email_sent += campaign.sendGridReport?.delivered || 0
          acc.total_opened += campaign.sendGridReport?.unique_opens || 0
          acc.total_clicked += campaign.sendGridReport?.unique_clicks || 0
          acc.total_bounced += campaign.sendGridReport?.bounces || 0
          acc.total_unsubscribed += campaign.sendGridReport?.unsubscribes || 0
          return acc
        }, {
          total_email_sent: 0,
          total_opened: 0,
          total_clicked: 0,
          total_bounced: 0,
          total_unsubscribed: 0
        })
        setAnalytic({
          ...report,
          total_campaign: campaigns.length
        })
        setIsLoading(false)
      })
  }

  useEffect(() => {
    getCampaigns()
  }, [refresh])

  useEffect(() => {
    const interval = setInterval(() => {
      setRefresh((prev) => prev + 1)
    }, refreshIntervalSeconds * 1000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  const deleteCampaign = () => {
    // @ts-ignore
    deleteEmailCampaigns(deletedToBeCampaign.emailTemplateId._id, deletedToBeCampaign._id)
      .then((res) => {
        setIsLoading(true)
        setDeletedToBeCampaign({})
        getCampaigns()
        setShowDeleteModal(false)
      })
    setDeletedToBeCampaign({})
  }

  return (
    <>
      <PageHeader
        title='Email Campaigns Reports'
        rightContent={<RightContent setRefresh={setRefresh} />}
      />
      <div className='px-4 sm:px-6 lg:px-8 mt-4'>
        <div className='grid grid-cols-6 gap-4 pt-4'>
          <Card title={'Campaign Sent'} value={analytics.total_campaign} />
          <Card title={'Total Email Sent'} value={analytics.total_email_sent} />
          <Card title={'Total opened'} value={analytics.total_opened} />
          <Card title={'Total clicked'} value={analytics.total_clicked} />
          <Card title={'Total Bounces'} value={analytics.total_bounced} />
          <Card title={'Total Unsubscribed'} value={analytics.total_unsubscribed} />
        </div>
        <div className='mt-4 bg-white '>
          <div className='flex items-end justify-end w-full px-2'>
            {!isLoading && (
              <button className='text-softBlack gap-x-1 text-center  text-sm font-medium py-1 px-3 flex items-center border border-volt'
                onClick={() => {
                  if (isLoading) return
                  setRefresh((prev) => prev + 1)
                }}
              >
                Reload
              </button>
            )}
          </div>
          {isLoading &&
            <FadedAnimatedDiv
              key='loadingTable'
              className='w-full h-full flex items-center justify-center my-10 flex-col'
            >
              <LottieWrapper
                // @ts-ignore
                animation={tableLoading}
                loop
                className='h-16 w-16'
              />
              <span className='text-base'>loading...</span>
            </FadedAnimatedDiv>
          }
          {!isLoading &&
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 pb-5 my-2'>
              {campaigns
                .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
                .map((campaign, index) => {
                  const listName = campaign?.userList?.title
                  return (
                    <CampaignCard campaign={campaign} key={campaign._id} listName={listName} showPreviewDefault={index === 0} />
                  )
                })}
            </div>
          }
        </div>
      </div>
      <DeleteModal
        title='Are you sure?'
        description='Do you want to delete?'
        confirmButtonTitle='Delete Campaign'
        cancelButtonTitle='Cancel'
        isOpen={showDeleteModal}
        setIsOpen={setShowDeleteModal}
        onConfirm={deleteCampaign}
        loading={undefined}
      />
    </>
  )
}
function RightContent(
  { setRefresh }
) {
  const [syncing, setSyncing] = useState(false)
  const syncEmailCampaigns = () => {
    setSyncing(true)
    syncCampaignsReport().then(() => {
      alert('Synced successfully')
      setRefresh((prev) => prev + 1)
    }).finally(() => {
      setSyncing(false)
    })
  }
  return (
    <div className='flex flex-row items-center'>
      <button className='text-softBlack text-center bg-volt text-base font-medium py-2 px-6 flex items-center border border-volt' onClick={syncEmailCampaigns}>
        {syncing ? 'Syncing...' : 'Sync SendGrid Report'}
      </button>
    </div>
  )
}
