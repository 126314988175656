// @ts-nocheck
/* eslint-disable */
import React from 'react';
import ContactDetailDrawer from './ContactDetailDrawer/ContactDetailDrawer';
// import MainContactProvider from './context/MainContactContext'
// import FilterDrawer from './FilterDrawer/FilterDrawer'
import { ContactProvider } from 'pages/contacts/context/Contacts';
import AddToListDrawer from './AddToListDrawer/AddToListDrawer';
import ContactsTableV2 from './ContactsTable/ContactsTableV2';
import ContactDetailDrawerV2 from './ContactDetailDrawer/ContactDetailDrawerV2';
import useContactContext from './hooks/useContactContext';
import AddToProjectDrawer from './AddToProjectDrawer/AddToProjectDrawer';
import AssignSalesRepDrawer from './AssignSalesRepDrawer/AssignSalesRepDrawer';

const NewContactsV2 = () => {
  const {
    view,
  } = useContactContext();
  return (
    <div className='flex bg-white h-full'>
      {/* <FilterDrawer /> */}
      <ContactsTableV2 />
      <ContactDetailDrawerV2 resetPath={
        view === 'corporate' ? '/admin/corporate/contacts' : '/admin/contacts'
      } />
      <AddToListDrawer />
      <AssignSalesRepDrawer />
      <AddToProjectDrawer />
    </div>
  );
};

export default NewContactsV2;
