// @ts-nocheck
/* eslint-disable */
import { Box, Button } from '@material-ui/core';
import CustomSelect from 'components/VerticalCardProfile/components/CustomSelect';
import AddForm from 'pages/desktopMode/demandNotes/AddForm';
import ConfirmDelete from 'pages/desktopMode/demandNotes/confirmDelete';
import DemandNotesContextProvider, {
  DemandNotesContext,
} from 'pages/desktopMode/demandNotes/context';
import Table from 'pages/desktopMode/demandNotes/Table/index';
import React, { useEffect, useContext, useState } from 'react';
import { GetDemandNotes } from 'store/actions/demandNotes';
import { getUserDataByType } from 'utils/getUserDataByType';
import { ContactContext } from './context/Contacts';
import useUpdateUsers from 'hooks/useUpdateUsers';
import toast from 'react-hot-toast';

const RightArrowIcon = () => (
  <svg
    width='20'
    height='21'
    viewBox='0 0 20 21'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M7.15832 6.675L10.975 10.5L7.15833 14.325L8.33333 15.5L13.3333 10.5L8.33332 5.5L7.15832 6.675Z'
      fill='#2E2B2E'
    />
  </svg>
);

export const DemandTab = ({ contact, user }) => {
  const { userType } = contact ?? {};
  const userDataObject = getUserDataByType(userType, contact);
  const { dropdownData, updateContact } = useContext(ContactContext);
  const { createOrUpdateUser } = useUpdateUsers();
  const [open, setOpen] = React.useState(false);
  const { label } = useContext(DemandNotesContext);
  const [isEdit, setIsEdit] = React.useState(false);
  useEffect(() => {
    if (isEdit) {
      setOpen(true);
    }
  },[isEdit]);

  const [customDemands, setCustomDemands] = useState(
    userDataObject?.customDemands ?? []
  );
  const [extraDemands, setExtraDemands] = useState(null);

  const getCustomDemandFields = async () => {
    const data = await GetDemandNotes();
    if (data) {
      const formatedData = await data.map((item) => {
        return {
          label: item.label,
          options: item.options.map((option) => {
            return {
              label: option.label,
              value: option.value,
              _id: option.value,
            };
          }),
        };
      });
      setExtraDemands(formatedData);
    }
  };

  useEffect(() => {
    getCustomDemandFields();
  }, []);
  return (
    <DemandNotesContextProvider>
      <div className='p-4 md:p-5 h- full w-full gap-x-20 flex'>
        <ConfirmDelete />
        <div className='w-[35%] flex flex-col gap-y-4'>
          {extraDemands !== null ? (
            extraDemands.map((demand, index) => {
              return (
                <CustomSelect
                  key={index}
                  label={demand.label}
                  value={
                    customDemands.find(
                      (customDemand) => customDemand.label === demand.label
                    )?.value ?? ''
                  }
                  handleChange={(value) => {
                    const newCustomDemands = [...customDemands];
                    const demandIndex = newCustomDemands.findIndex(
                      (customDemand) => customDemand.label === demand.label
                    );
                    if (demandIndex === -1) {
                      newCustomDemands.push({
                        label: demand.label,
                        value: value,
                      });
                    } else {
                      newCustomDemands[demandIndex] = {
                        label: demand.label,
                        value: value,
                      };
                    }
                    setCustomDemands(newCustomDemands);
                    const newUserData = { ...contact };
                    if (newUserData?.userType === 'Buyer') {
                      newUserData.buyerData.customDemands = newCustomDemands;
                    } else if (newUserData?.userType === 'LeadBroker') {
                      newUserData.leadBrokerData.customDemands =
                        newCustomDemands;
                    }
                    try {
                      updateContact(newUserData);
                      createOrUpdateUser(newUserData, false, false);
                      toast.success(`${demand.label} updated successfully`);
                    } catch (err) {
                      setCustomDemands(customDemands);
                      console.error(`${demand.label} couldn't be updated`, err);
                    }
                  }}
                  options={demand.options}
                  isDemandNotes
                  isName
                />
              );
            })
          ) : (
            <div className='text-[20px]'>No Demand Notes</div>
          )}
        </div>
        <div className='mt-6 max-w-[200px]'>
          <Button
            onClick={() => {
              setOpen(!open);
            }}
            style={{
              backgroundColor: '#EDDF65',
              width: '100%',
              boxShadow: '0px 0px 0px rgba(0, 0, 0, 0)',
            }}
            variant='contained'
          >
            {isEdit ? 'Update Demand Note' : 'Create Demand Note'}
          </Button>
        </div>
        {open && (
          <AddForm
            open={open}
            setOpen={setOpen}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            setRefetch={getCustomDemandFields}
          />
        )}
      </div>
    </DemandNotesContextProvider>
  );
};
