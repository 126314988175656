/* eslint-disable */
// @ts-nocheck
import React, { useEffect, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../../../assets/css/ag-theme-balham.css';
import { classNames } from 'utils';
import { Card, Modal } from '@material-ui/core';
import useGetProjectUnits from 'hooks/useGetProjectUnits';
import { groupBy } from '..';
import { getFilteredDeals } from 'store/actions/dealAction';
import {
  getUnitsByBuildingId,
  getUnitsByBuildingIdTableView,
} from 'store/actions/unitActions';
import { useState } from 'react';
import moment from 'moment';
import { IoMdClose } from 'react-icons/io';
import CustomSelect from 'components/CustomSelect';
import CustomInput from 'components/CustomInput';
import { getParkingInventory } from 'store/actions/parkingActions';
import InventoryEditDrawer from './InventoryEditDrawer';
import Skeleton from 'react-loading-skeleton';
import { api } from 'services/api';

export default function TableView({
  selectedBuilding,
  projectId,
  showCards = true,
}) {
  const [filteredUnits, setFilteredUnits] = useState([]);
  const [tempTypesOfAllocatedParking, setTempTypesOfAllocatedParking] =
    useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [typesOfAllocatedParking, setTypesOfAllocatedParking] = useState([]);
  const [parkingType, setParkingType] = useState([]);
  const [allocationModal, setAllocationModal] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [editDetails, setEditDetails] = useState({});
  const [tempRefetch, setTempRefetch] = useState(false);
  const [parkingTypeData, setParkingTypeData] = useState([{}]);
  const [parkingAllocationValues, setParkingAllocationValues] = useState({});
  const parkingColor = ['#59b099', 'red', 'yellow', 'black'];
  const [totalAssignedParkingAllocate, setTotalAssignedParkingAllocate] =
    useState(null);
  const parkingTypeArray = () => {
    const parkingStatus = [
      { name: 'Available', value: value.totalParkings || 0 },
      { name: 'Sold', value: 0 },
      { name: 'Allocated', value: 0 },
      { name: 'Assigend', value: 0 },
    ];
    const parkingData = parkingStatus.map((parking, index) => {
      const color = parkingColor[index % parkingColor.length];
      return { name: parking.name, value: parking.value, color: color };
    });
    setParkingTypeData(parkingData);
  };
  //   const handleFilteredDeals = async () => {
  //     let data = await getFilteredDeals({});
  //     console.log(data);
  //   };
  const initialEmptyValues = {
    building: selectedBuilding,
    options: [],
    parkingLevels: ['P1', 'P2'],
    totalParkings: null,
  };
  const [value, setValues] = useState({
    ...initialEmptyValues,
  });

  const handleTotalParkingsAllocate = () => {
    const totalParking = parkingAllocationValues?.options?.reduce(
      (sum, obj) => {
        const objSum = obj.parkingTypes.reduce((objSum, parkingType) => {
          const typeSum = parkingType.options.reduce(
            (typeSum, option) => typeSum + Number(option.value),
            0
          );
          return objSum + typeSum;
        }, 0);
        return sum + objSum;
      },
      0
    );
    setTotalAssignedParkingAllocate(totalParking);
  };

  const parkingAllocationStatus = [
    {
      name: 'Allocated',
      value: totalAssignedParkingAllocate,
    },
    {
      name: 'Unallocated',
      value:
        parkingAllocationValues.allocatedParkings -
        totalAssignedParkingAllocate,
    },
  ];

  useEffect(() => {
    const handleParkingInventory = async () => {
      const { data } = await api.get(
        `/parking/${selectedBuilding}/allocateInventory`
      );
      const parkingLevelsAllocate = data.data[0].parkingLevels;
      const parkingLevels = value.parkingLevels;
      const fetchedOptions = data.data[0].options;

      setParkingAllocationValues({
        ...data.data[0],
      });
    };
    const handleBuildingInventory = async () => {
      try {
        const { data } = await api.get(
          `/parking/${selectedBuilding}/inventory`
        );
        if (data.data && data.data.length > 0) {
          setValues(data.data[0]);
        } else {
          setValues({ ...initialEmptyValues });
        }
      } catch (error) {
        console.error('Error fetching building inventory:', error);
      }
    };
    handleBuildingInventory();
    handleParkingInventory();
    parkingTypeArray();
  }, []);

  useEffect(() => {
    handleTotalParkingsAllocate();
  }, [parkingAllocationValues]);

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      filter: true,
      resizable: true,
    };
  }, []);

  const _getParkingType = async (parking) => {
    let parkingInventory = await getParkingInventory(selectedBuilding);
    parkingInventory = parkingInventory.map((parking) => ({
      parkingType: parking.options.map((op) => op.name),
    }));
    if (parkingInventory.length) {
      setParkingType(
        parkingInventory[0].parkingType.map((pt) => ({
          value: pt,
          id: pt,
        }))
      );
    }
  };

  useEffect(() => {
    _getParkingType();
  }, [selectedBuilding]);

  const handleAddParking = () => {
    const newId = tempTypesOfAllocatedParking.length + 1;
    const newBgColor = colors[newId % colors.length]; // Rotate through colors
    const newAllocation = {
      id: newId,
      count: 1,
      title: '',
      bgColor: newBgColor,
    };

    setTempTypesOfAllocatedParking((prev) => [...prev, newAllocation]);
  };

  const openAllocationModal = () => {
    setAllocationModal(true);
  };
  const colors = ['#FFBE9A', '#16A34A40', '#FFD700', '#FF0000'];
  useEffect(() => {
    setIsLoading(true);
    const units = getUnitsByBuildingIdTableView(selectedBuilding).then(
      (data) => {
        let mapped = data.map((unit) => {
          return {
            ...unit,
            unitNumber: unit.unitNumber,
            buyerName: unit.buyerName,
            soldAt: unit.avesdoData?.acceptedDate,
            floorNumber: unit.floorNumber,
            unitType: unit.unitType,
            status: unit.status,
            area: unit.area,
            isOnWaitlist: unit?.avesdoData,
            acceptedDate: unit?.avesdoData?.acceptedDate,
            totalParkingStalls:
              (unit?.numberParkingIncluded || 0) +
              (unit?.numberParkingOptional || 0),
          };
        });
        setFilteredUnits(mapped);
        setIsLoading(false);
      }
    );
  }, [selectedBuilding, tempRefetch]);

  const columnDefs = [
    {
      headerName: 'Unit Name',
      field: 'unitNumber',
      sortable: true,
      filter: true,
      width: 120,
      pinned: 'left',
      //   checkboxSelection: true,
    },
    {
      headerName: 'Waitlist Join Date',
      field: 'acceptedDate',
      sortable: true,
      filter: true,
      cellRenderer: (params) => {
        return (
          <div>
            {params.data?.avesdoData?.acceptedDate
              ? moment(params.data?.avesdoData?.acceptedDate).format(
                'MM/DD/YYYY'
              )
              : '-'}
          </div>
        );
      },
    },
    {
      headerName: 'Avesdo Parkings',
      field: 'unitType',
      sortable: true,
      filter: true,
      width: 400,
      cellRenderer: (params) => {
        const options = params.data?.avesdoData?.options || [];
        const modifiedParkings = options.filter((option) => {
          return (
            option.label.toLowerCase().includes('parking') ||
            option.header == 'Parking Waitlist'
          );
        });

        return (
          <div className='flex gap-x-2'>
            {modifiedParkings.map((parking, index) => {
              return (
                <div
                  key={index}
                  className='px-3 py-0.5 border-black border-[1px] rounded-md justify-center items-center gap-1 inline-flex'
                >
                  <div className='text-center text-sm text-black font-normal'>
                    {parking.header}
                  </div>
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      headerName: 'Allocated Parking Type',
      sortable: true,
      width: 250,
      filter: true,
      cellRenderer: (params) => {
        return (
          <div className='flex gap-x-2'>
            {params.data?.parkings?.allocated.map((parking, index) => (
              <div
                style={{
                  backgroundColor: colors[index % colors.length],
                }}
                className='px-3 py-0.5 rounded-md justify-center items-center gap-1 inline-flex'
                key={index}
              >
                <div className='text-center text-sm font-normal'>
                  {parking.quantity} {parking.name}
                </div>
              </div>
            ))}
          </div>
        );
      },
    },

    {
      headerName: 'Total Parking Stalls',
      field: 'totalParkingStalls',
      sortable: true,
      filter: true,
      cellRenderer: (params) => {
        let assignedParkingCount = params.data?.parkings?.assigned.length || 0;
        let allocatedParkingCount = params.data?.parkings?.allocated.reduce(
          (acc, curr) => acc + Number(curr.quantity),
          0
        );
        let avesdoParkingsCount =
          params.data?.avesdoData?.options.filter((item) => {
            return (
              item.header === 'Parking Waitlist' ||
              item.label.toLowerCase().includes('parking')
            );
          }).length || 0;
        return <div>{allocatedParkingCount + avesdoParkingsCount}</div>;
      },
    },

    {
      headerName: 'Parking Stalls to Assign',
      field: 'parkingStallToAssign',
      sortable: true,
      filter: true,
      cellRenderer: (params) => {
        let assignedParkingCount = params.data?.parkings?.assigned.length || 0;
        let allocatedParkingCount = params.data?.parkings?.allocated.reduce(
          (acc, curr) => acc + Number(curr.quantity),
          0
        );
        let avesdoParkingsCount =
          params.data?.avesdoData?.options.filter((item) => {
            return (
              item.header === 'Parking Waitlist' ||
              item.label.toLowerCase().includes('parking')
            );
          }).length || 0;
        return (
          <div>
            {allocatedParkingCount + avesdoParkingsCount - assignedParkingCount}
          </div>
        );
      },
    },
    {
      headerName: 'Assigned Parking Stalls',
      sortable: true,
      width: 200,
      filter: true,
      cellRenderer: (params) => {
        return (
          <div className='flex gap-x-2'>
            {params.data?.parkings?.assigned.map((parking, index) => (
              <div
                style={{
                  backgroundColor: 'green',
                }}
                className='px-3 py-0.5 rounded-md justify-center items-center gap-1 inline-flex'
                key={index}
              >
                <div className='text-center text-sm text-white font-normal'>
                  # {parking?.stall}
                </div>
              </div>
            ))}
          </div>
        );
      },
    },
    {
      headerName: 'Is on Waitlist',
      field: 'isOnWaitlist',
      sortable: true,
      filter: true,
      cellRenderer: (params) => {
        let waitlistedParkings = [];
        let options = params.data?.avesdoData?.options || [];
        const modifiedParkings = options
          .filter((option) => option.header === 'Parking Waitlist')
          .map((option, index) => ({
            ...option,
            option: option.label,
          }));
        waitlistedParkings = waitlistedParkings.concat(modifiedParkings);
        let unassignedWaitlistedParking = [];
        let assignedParkings = params.data?.parkings?.assigned || [];

        waitlistedParkings.forEach((waitlistParking) => {
          const isAssigned = assignedParkings.some((assigned) => {
            if (assigned.isAvesdo) {
              return (
                assigned.isAvesdo &&
                Number(assigned.avesdoId) === waitlistParking.id
              );
            }
          });

          if (!isAssigned) {
            unassignedWaitlistedParking.push({
              ...waitlistParking,
            });
          }
        });
        return (
          <div className='flex gap-x-2'>
            {unassignedWaitlistedParking.length > 0 ? (
              <div
                style={{
                  backgroundColor: 'green',
                }}
                className='px-3 py-0.5 rounded-md justify-center items-center gap-1 inline-flex'
              >
                <div className='text-center text-sm text-white font-normal'>
                  Yes
                </div>
              </div>
            ) : (
              <div
                style={{}}
                className='px-3 py-0.5 rounded-md bg-yellow-400 justify-center items-center gap-1 inline-flex'
              >
                <div className='text-center text-sm text-black font-normal'>
                  No
                </div>
              </div>
            )}
          </div>
        );
      },
      comparator: (valueA, valueB) => {
        const dateA = moment(valueA).toDate().getTime();
        const dateB = moment(valueB).toDate().getTime();
        return dateB - dateA;
      },
    },
    {
      headerName: 'Purchase Date',
      field: 'soldAt',
      sortable: true,
      filter: true,
      width: 150,
      cellRenderer: (params) => {
        return (
          <div>
            {params.data?.soldAt
              ? moment(params.data?.soldAt).format('MM/DD/YYYY')
              : '-'}
          </div>
        );
      },
    },

    {
      headerName: 'Allocate Parking',
      field: 'allocatedParkingBtn',
      sortable: true,
      filter: true,
      cellRenderer: (params) => {
        return (
          <button
            onClick={() => {
              setEditDetails(params.data);
              setIsDrawerOpen(true);
            }}
            className='py-1 px-3 bg-[#18181B] rounded-md items-center justify-center'
          >
            <div className='text-center text-sm text-white font-normal'>
              Allocate Parking
            </div>
          </button>
        );
      },
    },
    {
      headerName: 'Assign Parking',
      field: 'allocatedParkingBtn',
      sortable: true,
      filter: true,
      cellRenderer: (params) => {
        return (
          <button
            onClick={() => {
              setEditDetails(params.data);
              setIsDrawerOpen(true);
            }}
            className='py-1 px-3 bg-[#FFBE9A] rounded-md items-center justify-center'
          >
            <div className='text-center text-sm text-black font-[500]'>
              Assign Parking
            </div>
          </button>
        );
      },
    },
  ];

  return (
    <div className='flex w-full h-full'>
      {isLoading ? (
        <div className='h-full w-full flex flex-col gap-y-4'>
          <Skeleton height={130} />
          <Skeleton height={130} />
          <Skeleton height={130} />
          <Skeleton height={130} />
          <Skeleton height={130} />
        </div>
      ) : (
        <div className='w-full flex flex-col gap-y-5'>
          {showCards && (
            <div className='grid grid-cols-6 lg:grid-cols-12 col-span-12 gap-y-10 gap-x-10 mb-6'>
              <Card className='!shadow-none  col-span-6 !overflow-visible p-3 md:py-8 md:px-8 w-full border-[2px] border-[rgba(0,0,0,0.2)] rounded-[12px]'>
                <div className='flex justify-between'>
                  <div className='font-bold text-[18px]'>
                    Parking Allocations
                  </div>
                  {parkingAllocationValues.allocatedParkings !=
                  totalAssignedParkingAllocate ? (
                    <div className='bg-[#F6E4CA] max-w-[200px]  rounded-[8px]  gap-x-4 flex items-center pl-4 pr-3 py-2'>
                      <div className='h-4 w-4 rounded-full bg-[#DA952C]'></div>
                      <div className='text-[16px] flex-1 font-[500] leading-[20px]'>
                        {parkingAllocationValues.allocatedParkings -
                          totalAssignedParkingAllocate}{' '}
                        Inventory Unaccounted For
                      </div>
                    </div>
                  ) : (
                    <div className='bg-[#D6FABB] max-w-[200px]  rounded-[8px]  gap-x-4 flex items-center pl-4 pr-3 py-2'>
                      <div className='h-4 w-4 rounded-full bg-[#60AD83]'></div>
                      <div className='text-[16px] flex-1 font-[500] leading-[20px]'>
                        All Inventory Accounted for
                      </div>
                    </div>
                  )}
                </div>
                <div className='flex'>
                  <div className='flex mt-4 md:mt-0 flex-col'>
                    <div className='flex gap-x-6'>
                      <div className='h-full'>
                        <div className='flex flex-wrap h-full gap-x-4'>
                          {parkingAllocationStatus?.map((parking, index) => (
                            <>
                              <div className='flex flex-col'>
                                <div className='flex items-center gap-x-2'>
                                  <div
                                    style={{
                                      backgroundColor:
                                        parkingColor[
                                        index % parkingColor.length
                                        ],
                                    }}
                                    className={`rounded-full h-3 w-3`}
                                  ></div>
                                  <div className='text-sm col-span-2'>
                                    {parking.name}
                                  </div>
                                </div>
                                <div className='text-xl col-span-2 font-bold'>
                                  {parking.value}
                                </div>
                              </div>
                              {parkingAllocationStatus.length - 1 != index && (
                                <div className='w-[2px] bg-[rgba(0,0,0,0.4)]'></div>
                              )}
                            </>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className='flex gap-x-20 items-center mt-6'>
                      <div>
                        <div className='font-bold text-[16px] '>
                          Parking Types
                        </div>
                        <div className='flex flex-col md:flex-row gap-x-4 mt-6'>
                          {parkingAllocationValues?.options?.map(
                            (parking, index) => (
                              <>
                                <div className='flex flex-row gap-x-4 md:gap-x-0 md:flex-col'>
                                  <div className='flex items-center gap-x-2'>
                                    <div
                                      style={{
                                        backgroundColor:
                                          parkingColor[
                                            index % parkingColor.length
                                          ],
                                      }}
                                      className={`rounded-full h-3 w-3`}
                                    ></div>
                                    <div className='text-sm col-span-2'>
                                      {parking.name}
                                    </div>
                                  </div>
                                  <div className='text-xl col-span-2 font-bold'>
                                    {parking.parkingTypes?.reduce(
                                      (sum, parkingType) =>
                                        sum +
                                        parkingType.options.reduce(
                                          (subSum, option) =>
                                            subSum + Number(option.value),
                                          0
                                        ),
                                      0
                                    )}
                                  </div>
                                </div>
                                {parkingAllocationValues.options.length - 1 !=
                                  index && (
                                  <div className='w-[2px] bg-[rgba(0,0,0,0.4)]'></div>
                                )}
                              </>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
              <Card className='!shadow-none  col-span-6 !overflow-visible p-3 md:py-8 md:px-8 w-full border-[2px] border-[rgba(0,0,0,0.2)] rounded-[12px]'>
                <div className='font-bold text-[20px]'>Parking Inventory</div>
                <div className='flex gap-x-16 mt-6'>
                  <div className='font-bold text-[24px]'>
                    {value.totalParkings} Parkings
                  </div>
                  <div className='h-full'>
                    <div className='flex flex-wrap h-full gap-x-4'>
                      {parkingTypeData?.map((parking, index) => (
                        <>
                          <div className='flex flex-col'>
                            <div className='flex items-center gap-x-2'>
                              <div
                                style={{
                                  backgroundColor: parking.color,
                                }}
                                className={`rounded-full h-3 w-3`}
                              ></div>
                              <div className='text-sm col-span-2'>
                                {parking.name}
                              </div>
                            </div>
                            <div className='text-xl col-span-2 font-bold'>
                              {parking.value}
                            </div>
                          </div>
                          {parkingTypeData.length - 1 != index && (
                            <div className='w-[2px] bg-[rgba(0,0,0,0.4)]'></div>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                </div>
                <div className='flex gap-x-20 items-center mt-6'>
                  <div>
                    <div className='font-bold text-[16px] '>Parking Types</div>
                    <div className='flex gap-x-4 mt-6'>
                      {value?.options?.map((parking, index) => (
                        <>
                          <div className='flex flex-col'>
                            <div className='flex items-center gap-x-2'>
                              <div
                                style={{
                                  backgroundColor:
                                    parkingColor[index % parkingColor.length],
                                }}
                                className={`rounded-full h-3 w-3`}
                              ></div>
                              <div className='text-sm col-span-2'>
                                {parking.name}
                              </div>
                            </div>
                            <div className='text-xl col-span-2 font-bold'>
                              {parking.value.reduce(
                                (sum, currentValue) =>
                                  Number(sum) + Number(currentValue),
                                0
                              )}
                            </div>
                          </div>
                          {value.options.length - 1 != index && (
                            <div className='w-[2px] bg-[rgba(0,0,0,0.4)]'></div>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                  <div>
                    <div className='font-bold text-[16px]'>Parking Types</div>
                    <div className='flex gap-x-4 mt-6'>
                      {value.parkingLevels.map((parking, index) => (
                        <>
                          <div className='flex flex-col'>
                            <div className='flex items-center gap-x-2'>
                              <div
                                style={{
                                  backgroundColor:
                                    parkingColor[index % parkingColor.length],
                                }}
                                className={`rounded-full h-3 w-3`}
                              ></div>
                              <div className='text-sm col-span-2'>
                                {parking}
                              </div>
                            </div>
                            <div className='text-xl col-span-2 font-bold'>
                              {value.options.reduce((sum, op) => {
                                const opValue = op.value[index];
                                return (
                                  sum + (isNaN(opValue) ? 0 : Number(opValue))
                                );
                              }, 0)}
                            </div>
                          </div>
                          {value.parkingLevels.length - 1 != index && (
                            <div className='w-[2px] bg-[rgba(0,0,0,0.4)]'></div>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          )}
          <div
            className={classNames(
              'h-full ag-theme-alpine w-full transition-all relative',
              isDrawerOpen ? 'mr-[350px]' : 'mr-0'
            )}
          >
            <AgGridReact
              domLayout='autoHeight'
              defaultColDef={defaultColDef}
              rowHeight={40}
              columnDefs={columnDefs}
              rowData={filteredUnits}
            />
          </div>
        </div>
      )}
      <div className='absolute right-0 top-0 bottom-0 z-[999]'>
        <InventoryEditDrawer
          editDetails={editDetails}
          setEditDetails={setEditDetails}
          closeInfoDrawer={() => setIsDrawerOpen(false)}
          isInfoDrawerOpen={isDrawerOpen}
          isAbleToPurchase
          refetch={() => {
            setTempRefetch(!tempRefetch);
          }}
          type={'Unit'}
          selectedBuilding={selectedBuilding}
          isFullHeight={false}
        />
      </div>
    </div>
  );
}
