// @ts-nocheck
/*eslint-disable */
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Modal } from '@material-ui/core'

import ElectronicBillIcon from 'assets/icons/money_bill.svg'
import WireTransferIcon from 'assets/icons/wire_transfer.svg'
import CreditCardICon from 'assets/icons/credit_card.svg'
import BillCheckIcon from 'assets/icons/check_bill.svg'
import CryptoCurrencyIcon from 'assets/icons/crypto_currency.svg'
import WeChatIcon from 'assets/icons/we_chat.svg'
import standardStyle from 'assets/css/standardStyle'
import firstImgPath from 'assets/img/3rd.png'
import secondImgPath from 'assets/img/4th.png'
import thirdImgPath from 'assets/img/5th.png'
import fourthImgPath from 'assets/img/6th.png'
import CustomInput from 'components/CustomInput'
import { cadFormatter } from 'utils/formatters'
import { PurchaseParkingContext } from '../context/PurchaseParkingContext'
import useGetProjectDepositOptions from 'hooks/useGetProjectDepositOptions'
import stripeLogo from 'assets/img/logo-stripe.svg'
import { ReactComponent as SuccessIcon } from 'assets/icons/check-mark.svg'


const PaymentMethodModal = ({ showPaymentMethodModal, setShowPaymentMethodModal, paymentMethodId, successfulPayment, setSuccessfulPayment }) => {
  const [images] = useState([firstImgPath, secondImgPath, thirdImgPath, fourthImgPath])
  const [imgIndex, setImgIndex] = useState(1)
  const [sideImgPath, setSideImgPath] = useState(firstImgPath)
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState({})
  const [selectedDepositOption] = useState(null)
  const { selectedUnit } = useContext(PurchaseParkingContext)
  const { loading, depositOptions } = useGetProjectDepositOptions()
  const paymentMethods = [
    {
      id: 1,
      text: 'Electronic Fund Transfer',
      imgSrc: ElectronicBillIcon
    },
    {
      id: 2,
      text: 'Pay with Credit Card',
      imgSrc: CreditCardICon
    },
    {
      id: 3,
      text: 'Pay with Cheque',
      imgSrc: BillCheckIcon
    },
    {
      id: 4,
      text: 'Wechat / AliPay',
      imgSrc: WeChatIcon
    },
    {
      id: 5,
      text: 'Cryptocurrency',
      imgSrc: CryptoCurrencyIcon
    },
    {
      id: 6,
      text: 'Traditional Wire Transfer',
      imgSrc: WireTransferIcon
    }
  ]
  useEffect(() => {
    setCurrentPaymentMethod({ ...paymentMethods.filter((elm) => elm.id === paymentMethodId)[0] })
  }, [paymentMethodId])

  const selectedDepositOptionData = useMemo(() => {
    const result = depositOptions?.filter(
      (item) => item._id === selectedDepositOption
    )?.[0]
    return result
  }, [depositOptions, selectedDepositOption])

  const discountPrice = useMemo(() => {
    return (
      selectedUnit?.price *
      (selectedDepositOptionData?.discountPercentage / 100)
    )
  }, [selectedUnit, selectedDepositOptionData])

  const totalPrice = () => {
    return selectedUnit?.price - discountPrice || 0
  }

  const goToNextStep = () => {
    if (imgIndex <= 3) {
      setSideImgPath(images[imgIndex])
      setImgIndex(imgIndex + 1)
    } else {
      setImgIndex(1)
      setSideImgPath(firstImgPath)
      setSuccessfulPayment(true)
    }
  }

  return (
    <Modal
      key={'99'}
      open={showPaymentMethodModal}
      onClose={() => setShowPaymentMethodModal(false)}
      aria-labelledby="Offer Documents"
      aria-describedby="Offer documents list"
    >
      <div
        className="font-openSans bg-grey-8 absolute p-10 rounded shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        style={{ width: 1376, height: '830px', overflowY: 'auto' }}
      >
        {(currentPaymentMethod['text'] === 'Electronic Fund Transfer' && !successfulPayment) &&
          <div className="flex flex-row h-full">
            <div className='basis-5/12 bg-gray-1 h-full rounded-md flex flex-col justify-center items-center'>
              <img src={currentPaymentMethod['imgSrc']} alt="no image found" width={'50%'} />
              <h6 className="font-bold text-2xl mt-4 font-openSans" >{currentPaymentMethod.text}</h6>
            </div>
            <div className='basis-7/12 bg-gray-1 h-full ml-10 rounded-md flex justify-center items-center'>
              <img src={sideImgPath} alt="no image found" width={'57%'} className='cursor-pointer' onClick={goToNextStep} />
            </div>
          </div>
        }
        {(currentPaymentMethod['text'] === 'Pay with Credit Card' && !successfulPayment) &&
          <div className="flex justify-center items-center h-full">
            <div
              className='flex justify-center items-center flex-col'
              style={{ height: '100%' }}
            >
              <h2
              className='font-openSans'
                style={{
                  fontSize: '20px',
                  fontWeight: '700',
                  marginTop: '30px',
                }}
              >
                Pay Deposit With Credit Card
              </h2>
              <div
                className='flex gap-6 flex-wrap flex-col justify-center'
                style={{ margin: '30px', marginTop: '14px', width: '887px' }}
              >
                <CustomInput
                  label={'Name on Card'}
                  placeholder='Enter first name'
                  type='text'
                />
                <CustomInput
                  label={'Credit card number'}
                  placeholder='xxxx-xxxx-xxxx-xxxx'
                  type='text'
                />
                <div className='grid grid-cols-2 gap-4'>
                  <CustomInput
                    label={'Exipry date'}
                    placeholder='MM/YY'
                    type='text'
                  />
                  <CustomInput
                    label={'CVV Number'}
                    placeholder='***'
                    type='text'
                  />
                </div>
                <p
                  className='font-bold mt-2 text-softBlack text-left'
                  style={{ fontSize: 22, width: '100%' }}
                >
                  Due within 7 days
                </p>
                <div className='flex justify-between items-center gap-4 m-0 p-0'>
                  <p className='text-2xl p-0 m-0 font-bold text-softBlack'>
                    {cadFormatter.format(totalPrice())}
                  </p>
                  <div className='flex gap-6'>
                    <button
                      className='flex items-center justify-center rounded-md font-openSans font-medium bg-transparent'
                      style={{
                        width: 85,
                        height: 48,
                        fontStyle: 'italic',
                        border: '1px solid ' + standardStyle.colors.softBlack_70,
                        borderRadius: '4px',
                        fontWeight: 300
                      }}
                      onClick={() => setShowPaymentMethodModal(false)}
                      disabled={loading}
                    >
                      Close
                    </button>
                    <button
                      className='flex items-center justify-center rounded-md font-medium'
                      style={{
                        backgroundColor: '#EDDF65',
                        width: 230,
                        height: 48,
                        fontStyle: 'italic'
                      }}
                      onClick={() => {
                        // setShowPaymentMethodModal(false)
                        setSuccessfulPayment(true)
                      }}
                      disabled={loading}
                    >
                      Pay Now
                    </button>
                  </div>
                </div>
                <div className='flex justify-center'>
                  <img src={stripeLogo} alt={'stripe logo'} />
                </div>
              </div>
            </div>
          </div>
        }
        {successfulPayment &&
          <div className="flex flex-col justify-center items-center h-full">
            <SuccessIcon />
            <h6 className="font-bold text-2xl mt-4 font-openSans">Success</h6>
            <p className="font-normal text-base mt-4 font-openSans" >Offer flow completed</p>
            <button
              className='flex items-center justify-center rounded-md font-normal text-base mt-3'
              style={{
                backgroundColor: '#EDDF65',
                width: 230,
                height: 48,
                fontStyle: 'italic'
              }}
              onClick={() => {
                setShowPaymentMethodModal(false)
                setSuccessfulPayment(false)
              }}
              disabled={loading}
            >
              Return to Dashboard
            </button>
          </div>
        }
      </div>
    </Modal>
  )
}

export default PaymentMethodModal
