/* eslint multiline-ternary: ["error", "never"] */
// @ts-nocheck
/* eslint-disable */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { connect, useSelector } from "react-redux";
import { api, ApiErrors, getTokenRequestHeader } from "services/api";
import { useGetAPI } from "hooks/useGenericAPIs";
import PacificaLogo from "../../assets/img/pacifica-logo.png";
import { isNumber } from "utils/isNumber";
import { Table } from "ui";
import { ReactComponent as UserCheck } from "assets/icons/user-check.svg";
import { ReactComponent as CrossIcon } from "assets/icons/cross.svg";
import { ChartRecentLeads } from "pages/dashboard/components/ChartRecentLeads";
import {
  getAllSalesRepByProject,
  getCorporateDemandNotesReport,
  getLast30DaysContacts,
  getLeadsCountByProject,
  getLeadSourceReport,
  getLeadSourceReportBySalesRep,
  getLeadsReport,
  getLeadStatusReport,
  getLeadStatusReportBySalesRep,
  getProjectContactWiseList,
  getRatingScoreCountsAllProjects,
  getRatingScoreReport,
  getRatingScoreReportBySalesRep,
} from "store/actions/usersActions";
import { Loader } from "lucide-react";
import moment from "moment";
import {
  Bar,
  BarChart as BarChartComponent,
  Cell,
  LabelList,
  ResponsiveContainer,
  PieChart,
  Pie,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  Label,
} from "recharts";
import {
  getUserActivityAll,
  getUserActivityAllByGroup,
  getUserActivityBySalesRepByGroup,
} from "store/actions/userActivityActions";
import useBuyerScore from "hooks/useBuyerScore";
import { getProjectUnits, getUnits } from "store/actions/unitActions";
import CustomSelect from "components/CustomSelect";
import CustomSelectBuilding from "components/Inventory/components/CustomSelect";
import { getReservations } from "store/actions/reservationActions";
import UnitsInventoryListView from "components/Inventory/components/UnitsInventoryListView";
import useInventory from "components/Inventory/hooks/useInventory";
import { ProjectData } from "utils/projectConfig";
import { Drawer, makeStyles } from "@material-ui/core";
import { formatDate, formatMoney } from "utils";
import { getAllEmailCampaigns } from "store/actions/customEmailCampaignActions";
import standardStyle from "assets/css/standardStyle";
import { getBuildingsByProjectId } from "store/actions/buildingActions";
import useGetParkings from "hooks/useGetParkings";
import CustomParkingDetail from "pages/desktopMode/buildings/components/CustomParkingDetail";
import { useHistory } from "react-router-dom";
import { ADMIN_NEW_CONTACTS, ADMIN_NEW_CONTACTS_ID } from "routes";
import { ContactContext } from "pages/contacts/context/Contacts";
import useContactContext from "pages/newContacts/hooks/useContactContext";
import CustomButton from "components/CustomButton";
import { isMobile } from "react-device-detect";
import { ChevronDown, ChevronLeft, ChevronUp } from "lucide-react";
import ContactItem from "pages/contacts/ContactItem";
import ReservationDemandView from "./components/ReservationDemandView";
import CustomTable from "components/CustomTable";
import placeHolderBg from "../../assets/img/bg.png";
import Skeleton from "react-loading-skeleton";
import { BarList, DonutChart } from "@tremor/react";
import { BarChartShadCN } from "components/Charts/BarChart";
import { PieChartShadCN } from "components/Charts/PieChart";
import { ChartTooltipContent } from "components/ShadcnComponents/chart";
import { BarListShadCN } from "components/Charts/BarList";

const ShadCNWrapper = ({ children, className = "" }) => (
  <div
    className={`rounded-xl border bg-card text-card-foreground shadow bg-white overflow-hidden ${className}`}
  >
    {children}
  </div>
);

const CardUI = ({ title, number, Icon }) => (
  <ShadCNWrapper>
    <div className="p-6 flex flex-row items-center justify-between space-y-0 pb-2">
      <h3 className="tracking-tight text-sm font-medium truncate">{title}</h3>
      {Icon}
    </div>
    <div className="p-6 pt-0">
      <div className="text-2xl font-bold">{number}</div>
    </div>
  </ShadCNWrapper>
);

const HorizontalCardUI = ({ title, number, Icon }) => (
  <div className="bg-white w-full gap-y-3 basis-1/2 z-99 flex flex-col top-20 justify-between">
    <div className="flex items-center justify-between text-[14px] font-medium uppercase">
      {title}
      {Icon}
    </div>
    <div className="text-[24px] font-bold">{number}</div>
  </div>
);

const TabWrapper = ({ tabMenus, setSelectedTab, selectedTab }) => (
  <div className="w-full flex items-center md:justify-center rounded-lg bg-white p-1 text-zinc-600">
    {tabMenus.map((tab, index) => (
      <div
        key={index}
        onClick={() => {
          setSelectedTab(tab.id);
        }}
        className={`flex flex-1 w-full min-w-[110px] md:w-fit md:min-w-[160px] max-w-[180px] text-center justify-center items-center just px-3 py-[6px] cursor-pointer rounded-[4px] font-semibold text-[12px] ${
          selectedTab === tab.id
            ? "bg-[#18181B] text-white shadow-sm"
            : "text-zinc-600 hover:bg-zinc-100"
        } transition-all`}
      >
        {tab.name}
      </div>
    ))}
  </div>
);

const HorizontalLine = ({ className }) => (
  <div className={`w-full h-[1px] bg-zinc-200 ${className}`} />
);

const CorporateDashboard = (props) => {
  const {
    selectedContact,
    selectedContactObject,
    setSelectedContact,
    setSelectedContactObject,
  } = useContext(ContactContext);
  const projectId = useSelector((state) => state.appReducer.appProject);
  const userObject = useSelector((state) => state.authReducer.userObject);
  const permissionFalse = useMemo(() => {
    return Object.keys(userObject.permissions || {}).filter(
      (key) => !userObject.permissions[key]
    );
  }, [userObject.permissions]);
  const permissionTrue = useMemo(() => {
    return Object.keys(userObject.permissions || {}).filter(
      (key) => userObject.permissions[key]
    );
  }, [userObject.permissions]);

  const { data: fetchedBuyerBrokerCount, fetch: fetchBrokerBuyerCount } =
    useGetAPI();
  const oldTabMenus = [
    {
      id: "tab-1",
      name: "Leads",
      permission: "Leads",
    },
    {
      id: "tab-2",
      name: "Sales Activity",
      permission: "Sales Activity",
    },
    {
      id: "tab-3",
      name: "Reservations",
      permission: "Reservations",
    },
    {
      id: "tab-4",
      name: "Communications",
      permission: "Communications",
    },
    {
      id: "tab-5",
      name: "Parking",
      permission: "Parking",
    },
  ];
  const [tabMenus, setFilteredTabMenus] = useState([]);
  const [selectedTab, setSelectedTab] = useState(undefined);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const filteredFalse = oldTabMenus.filter((tab) => {
      const tabNameLower = tab.permission.toLowerCase();
      return !permissionFalse.some(
        (perm) => perm.toLowerCase() === tabNameLower
      );
    });

    const filteredTrue = oldTabMenus.filter((tab) => {
      const tabNameLower = tab.permission.toLowerCase();
      return permissionTrue.some((perm) => perm.toLowerCase() === tabNameLower);
    });

    if (filteredTrue.length === 0 && filteredFalse.length === 0) {
      setFilteredTabMenus(filteredFalse);
    }
    if (filteredTrue.length > 0) {
      setFilteredTabMenus(filteredTrue);
      if (filteredTrue.length > 0) {
        setSelectedTab(filteredTrue[0].id);
      } else {
        setSelectedTab(undefined);
      }
    } else {
      if (filteredFalse.length > 0) {
        setFilteredTabMenus(filteredFalse);
      }
      if (filteredFalse.length > 0) {
        setSelectedTab(filteredFalse[0].id);
      } else {
        setSelectedTab(undefined);
      }
    }

    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [permissionFalse, permissionTrue]);

  const [selectedTab2, setSelectedTab2] = useState("tab-2");
  const [salesRepTab, setSalesRepTab] = useState("tab-1");
  const [communicationTabState, setCommunicationTabState] = useState("tab-1");
  const [newLeadCount, setNewLeadCount] = useState(0);
  const [sevenDaysLead, setSevenDaysLead] = useState([]);
  const [last30DaysContacts, setLast30DaysContacts] = useState([]);
  const [showMoreLeads, setShowMoreLeads] = useState(false);
  const [showMoreReservation, setShowMoreReservation] = useState(false);
  const [showMoreSMSCampaign, setShowMoreCampaigns] = useState(false);
  const [showMoreEmailCampaign, setShowMoreEmailCampaigns] = useState(false);
  const [showMoreActivty, setShowMoreActivty] = useState(false);
  const [userActivity, setUserActivity] = useState([]);
  const [userActivtyGroupData, setUserActivtyGroupData] = useState([]);
  const [leadSourcesData, setLeadSourcesData] = useState([]);
  const [leadStatusData, setLeadStatusData] = useState([]);
  const [ratingSource, setRatingSource] = useState([]);
  const [unitsCount, setUnitsCount] = useState(0);
  const [totalAmountOfRemainingUnits, setTotalAmountOfRemainingUnits] =
    useState(0);
  const { formattedScores: scores } = useBuyerScore();
  const [salesRepData, setSalesRepData] = useState([]);
  const [selectedSalesRep, setSelectedSalesRep] = useState("");
  const [projectLogo, setProjectLogo] = useState(null);
  const [leadStatusReportBySalesRep, setLeadStatusReportBySalesRep] = useState(
    []
  );
  const [campaigns, setCampaigns] = useState([]);
  const [analytics, setAnalytic] = useState({
    total_campaign: 0,
    total_email_sent: 0,
    total_opened: 0,
    total_clicked: 0,
    total_bounced: 0,
    total_unsubscribed: 0,
  });
  const {
    state,
    handleInventoryChange,
    handleInventoryViewChange,
    setSelectedParkingLevel,
    setSelectedStorageLevel,
    setIsReportScreenOpen,
  } = useInventory();
  const [smsBroadcastList, setSmsBroadcastList] = useState([]);
  const [reservationData, setReservationData] = useState([]);
  const [reservationDataSigned, setReservationDataSigned] = useState([]);
  const [ratingSourceBySalesRep, setRatingSourceBySalesRep] = useState([]);
  const [leadSourcesDataBySalesRep, setLeadSourcesDataBySalesRep] = useState(
    []
  );
  const [loadingReportId, setLoadingReportId] = useState(null);
  const [allParkings, setAllParkings] = useState([]);
  const [selectedBuilding, setSelectedBuilding] = useState("");
  const [buildingOptions, setBuildingOptions] = useState([]);
  const [parkingRevenue, setParkingRevenue] = useState(0);
  const [stallSoldCount, setStallSoldCount] = useState(0);
  const [parkingDataByUnit, setParkingDataByUnit] = useState([]);
  const [parkingInventoryType, setParkingInventoryType] = useState([]);
  const [assignedParkingData, setAssignedParkingData] = useState([]);
  const [assignedParkingDataIsMore, setAssignedParkingDataIsMore] =
    useState(false);
  const [parkingByUnitIsMore, setParkingByUnitIsMore] = useState(false);
  const [assignedParkings, setAssignedParkings] = useState(0);
  const [parkingAllocateInventoryType, setParkingAllocateInventoryType] =
    useState(null);
  const [totalParking, setTotalParking] = useState(0);
  const [totalAllocatedParking, setTotalAllocatedParking] = useState(0);
  const parkingInventoryColors = ["black", "grey"];
  const history = useHistory();
  const [isContactDrawerOpen, setIsContactDrawerOpen] = useState(false);
  const [isLeadsLoading, setIsLeadsLoading] = useState(true);
  const appReducer = useSelector((state) => state.appReducer);
  const [projectName, setProjectName] = useState("");
  let id = localStorage.getItem("device_id");
  const [projectBackgroundImage, setProjectBackgroundImage] =
    useState(placeHolderBg);
  const {
    state: { statusColors },
  } = useInventory();
  const [leadsCountData, setLeadsCountData] = useState([]);
  const [ratingScoreCounts, setRatingScoreCounts] = useState([]);
  const [demandNotesReport, setDemandNotesReport] = useState([]);
  const [showAllDemandNotes, setShowAllDemandNotes] = useState(false);

  const parkingTypes = [
    {
      type: "Allocated",
      count: totalAllocatedParking,
    },
    {
      type: "Assigned",
      count: assignedParkings,
    },
    {
      type: "Available",
      count:
        Math.sign(totalParking - totalAllocatedParking - assignedParkings) === 1
          ? totalParking - totalAllocatedParking - assignedParkings
          : 0,
    },
    {
      type: "Recision",
      count: 0,
    },
    {
      type: "Reserved",
      count: 0,
    },
    {
      type: "Sold",
      count: stallSoldCount,
    },
  ];

  const currencyFormate = (value) => {
    if (value == null) return undefined;
    else {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });

      const formattedValue = formatter.format(value);
      return formattedValue;
    }
  };

  const getParkingInventoryDataByBuilding = async () => {
    if (selectedBuilding === "") return;
    const { data } = await api.get(
      `/parking/${selectedBuilding}/inventory`,
      {},
      { responseType: "json" }
    );
    if (data.data.length > 0) {
      const totalParkings = data.data[0].totalParkings;
      const parkingInventoryTypes = data.data[0].options.map((op, index) => {
        return {
          type: op.name,
          key: op._id,
          count: op.value.map((v) => v).reduce((a, b) => a + b, 0),
        };
      });
      setParkingInventoryType(parkingInventoryTypes);
      setTotalParking(totalParkings);
    }
  };
  const getParkingAllocateInventoryDataByBuilding = async () => {
    if (selectedBuilding === "") return;
    const { data } = await api.get(
      `/parking/${selectedBuilding}/allocateInventory`,
      {},
      { responseType: "json" }
    );
    if (data.data.length > 0) {
      setParkingAllocateInventoryType(data.data[0]);
      const totalAllocatedParking = data.data[0].options.reduce((sum, obj) => {
        const objSum = obj.parkingTypes.reduce((objSum, parkingType) => {
          const typeSum = parkingType.options.reduce(
            (typeSum, option) => typeSum + Number(option.value),
            0
          );
          return objSum + typeSum;
        }, 0);
        return sum + objSum;
      }, 0);
      setTotalAllocatedParking(totalAllocatedParking);
    }
  };

  const getAssignedParkingsFromUnit = async () => {
    if (selectedBuilding === "") return;
    const { data } = await api.get(`/parking/building/${selectedBuilding}`);
    if (data.length > 0) {
      let assignedParkings = data.filter((parking) => {
        return parking.unit;
      });
      setAllParkings(data);
      setAssignedParkingData(assignedParkings);
      setAssignedParkings(assignedParkings.length);
      const counts = assignedParkings.reduce((acc, obj) => {
        const { unit } = obj;
        const { _id, unitNumber } = unit;

        if (!acc[_id]) {
          acc[_id] = { count: 1, unitNumber };
        } else {
          acc[_id].count++;
        }
        return acc;
      }, {});
      const unitCountArray = Object.entries(counts).map(
        ([_id, { count, unitNumber }]) => ({
          _id,
          count,
          unitNumber,
        })
      );
      const unitByBuilding = await api.get(
        `/units/building/${selectedBuilding}`,
        {},
        { responseType: "json" }
      );
      const parkingsDataByUnit = unitByBuilding.data.data.map((item, index) => {
        return {
          unitNumber: item.unitNumber,
          key: index,
          parkings:
            (item?.numberParkingIncluded || 0) +
            (item?.numberParkingOptional || 0),
        };
      });
      unitCountArray.forEach(({ unitNumber, count }) => {
        const unit = parkingsDataByUnit.find(
          (item) => item.unitNumber === unitNumber
        );
        if (unit) {
          unit.parkings -= count;
          if (Math.sign(unit.parkings === -1)) unit.parkings = 0;
        }
      });
      setParkingDataByUnit(parkingsDataByUnit);
    }
  };

  useEffect(() => {
    getLeadsCountByProject("").then((res) => {
      let mapped = res.map((item, index) => {
        return {
          name: item._id || "Unassigned",
          value: item.count || 0,
          fill: `hsl(var(--chart-${index + 1}))`,
        };
      });
      setLeadsCountData(mapped);
    });

    getRatingScoreCountsAllProjects().then((res) => {
      let mapped = res.map((item, index) => {
        return {
          name: item?._id || "Unassigned",
          value: item.count || 0,
          fill: `hsl(var(--chart-${index + 1}))`,
        };
      });
      setRatingScoreCounts(mapped);
    });

    getCorporateDemandNotesReport().then((res) => {
      let mapped = res.map((item, index) => {
        let demandNoteName = item?.name;
        return {
          name: item?.name,
          data: item?.values.map((item, i) => {
            let arrayKey = Object.keys(item);
            return {
              name: arrayKey[0],
              value: item[arrayKey[0]],
              fill: `hsl(var(--chart-${i + 1}))`,
            };
          }),
        };
      });
      setDemandNotesReport(mapped);
    });
  }, []);

  useEffect(() => {
    getParkingInventoryDataByBuilding();
    getParkingAllocateInventoryDataByBuilding();
    getAssignedParkingsFromUnit();
  }, [selectedBuilding]);

  const NotFoundView = ({ text }) => (
    <div className="p-4 py-6 w-full flex justify-center items-center text-xl font-semibold">
      {text}
    </div>
  );

  const Card = ({ title, value }) => (
    <div className="">
      <div
        className="bg-white rounded h-full p-6"
        style={{ fontFamily: standardStyle.fonts.sfpro }}
      >
        <div className="h-full flex flex-col justify-between space-y-2">
          <div className="text-xs text-softBlack_70 font-medium uppercase">
            {title}
          </div>
          <div className="text-xl text-softBlack font-bold">{value}</div>
        </div>
      </div>
    </div>
  );
  const [sevenDaysLeadCount, setSevenDaysLeadCount] = useState([]);

  const headersForStatic = [
    {
      name: "Reservation #",
      id: "reservationNumber",
    },
    {
      name: "Prospective Purchaser",
      id: "prospectivePurchaser",
    },
    {
      name: "First Choice",
      id: "firstChoice",
    },
    {
      name: "Second Choice",
      id: "secondChoice",
    },
    {
      name: "Third Choice",
      id: "thirdChoice",
    },
    {
      name: "Fourth Choice",
      id: "fourthChoice",
    },
    {
      name: "Fifth Choice",
      id: "fifthChoice",
    },
    {
      name: "Date Reserved",
      id: "dateReserved",
    },
    {
      name: "Title Company: Deposit",
      id: "titleCompanyDeposit",
    },
    {
      name: "Broker",
      id: "broker",
    },
    {
      name: "Notes",
      id: "notes",
    },
  ];

  const colors = ["#EDDF65", "#59B099", "#54B5D1", "#DF6D50", "#646263"];
  const colorsPieChart = [
    "#EDDF65",
    "#59B099",
    "#54B5D1",
    "#DF6D50",
    "#646264",
  ];
  const tabMenus2 = [
    {
      id: "tab-1",
      name: "Last 24 hours",
    },
    {
      id: "tab-2",
      name: "Last 7 days",
    },
  ];

  const communicationTab = [
    {
      id: "tab-1",
      name: "SMS Campaigns",
    },
    {
      id: "tab-2",
      name: "Email Campaigns",
    },
  ];

  const salesRepMenu = [
    {
      id: "tab-1",
      name: "All Sales Reps",
    },
    {
      id: "tab-2",
      name: "Per Sales Rep",
    },
  ];

  const campaignTableColumns = [
    {
      key: "requests",
      title: "Requests",
      default: 0,
    },
    {
      key: "delivered",
      title: "Delivered",
      default: 0,
    },
    {
      key: "unique_opens",
      title: "Opens",
      default: 0,
    },
    // {
    //   key: 'unique_opens',
    //   title: 'Unique Opens',
    //   default: 0
    // },
    {
      key: "unique_clicks",
      title: "Clicks",
      default: 0,
    },
    // {
    //   key: 'unique_clicks',
    //   title: 'Unique Clicks',
    //   default: 0
    // },
    {
      key: "bounces",
      title: "Bounces",
      default: 0,
    },
    {
      key: "unsubscribes",
      title: "Unsubscribes",
      default: 0,
    },
  ];

  // const getAllActivity = async () => {
  //   const response = await getUserActivityAll();
  //   setUserActivity(response);
  // };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const getBuyers = async () => {
    const { data } = await api.get(
      `/users/filtered?userType=Buyer&project=${projectId}&leadStatus=New Lead&leadStatus=New`,
      {},
      { responseType: "json" }
    );
    setNewLeadCount(data.data?.length ?? 0);
  };
  const getAllSMSBroadcastList = useCallback(() => {
    return new Promise((resolve, reject) => {
      const token = getTokenRequestHeader();
      // @ts-ignore
      api.setHeader("Authorization", token);

      api.get("sms/campaigns").then((response) => {
        if (response.ok) {
          resolve(response.data);
          setSmsBroadcastList(
            response.data.map((elm) => {
              return {
                broadcastName: elm.campaignName,
                sentDate: elm.updatedAt ?? elm.createdAt,
                numberOfRecipients: elm.report.length,
                delivered: elm?.report?.filter(
                  (message) => message.status === "delivered"
                ).length,
                failed: elm?.report?.filter(
                  (message) => message.status === "failed"
                ).length,
              };
            })
          );
        } else {
          ApiErrors(response);
          reject(response);
        }
      });
    });
  }, []);

  const getCampaigns = () => {
    getAllEmailCampaigns().then((campaigns) => {
      setCampaigns(campaigns);
      const report = campaigns.reduce(
        (acc, campaign) => {
          acc.total_email_sent += campaign.sendGridReport?.delivered || 0;
          acc.total_opened += campaign.sendGridReport?.unique_opens || 0;
          acc.total_clicked += campaign.sendGridReport?.unique_clicks || 0;
          acc.total_bounced += campaign.sendGridReport?.bounces || 0;
          acc.total_unsubscribed += campaign.sendGridReport?.unsubscribes || 0;
          return acc;
        },
        {
          total_email_sent: 0,
          total_opened: 0,
          total_clicked: 0,
          total_bounced: 0,
          total_unsubscribed: 0,
        }
      );
      setAnalytic({
        ...report,
        total_campaign: campaigns.length,
      });
    });
  };

  const countRecipientsDeliveredAndFailed = useMemo(() => {
    let totalDelivered = 0;
    let totalFailed = 0;
    let totalRecipients = 0;

    smsBroadcastList.forEach((elm) => {
      totalDelivered += elm.delivered;
      totalFailed += elm.failed;
      totalRecipients += elm.numberOfRecipients;
    });

    return {
      totalDelivered,
      totalFailed,
      totalRecipients,
    };
  }, [smsBroadcastList]);

  // useEffect(() => {
  //   let projectId = process.env.REACT_APP_PROJECT_ID;
  //   if (projectId) {
  //     setProjectData(ProjectData.find((project) => project.id === projectId));
  //   }
  // }, []);

  useEffect(() => {
    handleInventoryChange("demandHeatMap");
    getBuyers();
    fetchBrokerBuyerCount(`/users/reports/count/contacts/all`);
    // getAllActivity();
    getAllSMSBroadcastList();
    getCampaigns();
  }, [projectId]);

  useEffect(() => {
    if (projectId) {
      getProjectUnits(projectId).then((res) => {
        setUnitsCount(res.length || 0);
        let totalAmount = 0;
        res.forEach((unit) => {
          totalAmount += unit?.price || 0;
        });
        setTotalAmountOfRemainingUnits(totalAmount);
      });
    }
  }, [projectId]);

  const statsData1 = useMemo(() => {
    if (
      !fetchedBuyerBrokerCount ||
      !Object.keys(fetchedBuyerBrokerCount).length
    ) {
      return [];
    }
    // @ts-ignore
    const { total, buyer, leadBroker } = fetchedBuyerBrokerCount;
    return [
      {
        title: "Buyer",
        number: isNumber(buyer),
        color: "#2e2b2b",
        total: total,
      },
      {
        title: "Brokers ",
        number: isNumber(leadBroker),
        color: "#EDDF65",
        total: total,
      },
    ];
  }, [fetchedBuyerBrokerCount]);

  const handlingSevenDaysLeadsPage = async () => {
    setIsLeadsLoading(true);
    let leadsReport = await getLeadsReport("7day", projectId);

    const buyers = leadsReport.contacts
      .sort((a, b) => {
        const dateA = new Date(a?.updatedAt);
        const dateB = new Date(b?.updatedAt);
        return dateB - dateA;
      })
      .map((lead) => ({
        ...lead,
        fullName: `${lead?.firstName ?? "-"} ${lead?.lastName ?? ""}`,
        leadSource: lead?.buyerData?.leadSource ?? "-",
        salesRepsName:
          lead?.userType === "Buyer"
            ? `${lead?.buyerData?.assignments[0]?.salesRep?.firstName ?? "-"} ${
                lead?.buyerData?.assignments[0]?.salesRep?.lastName ?? ""
              }`
            : `${
                lead?.leadBrokerData?.assignments[0]?.salesRep?.firstName ?? "-"
              } ${
                lead?.leadBrokerData?.assignments[0]?.salesRep?.lastName ?? ""
              }`,
        leadBrokerName:
          lead?.userType === "Buyer"
            ? `${
                lead?.buyerData?.assignments[0]?.leadBroker?.firstName ?? "-"
              } ${lead?.buyerData?.assignments[0]?.leadBroker?.lastName ?? ""}`
            : `${
                lead?.leadBrokerData?.assignments[0]?.leadBroker?.firstName ??
                "-"
              } ${
                lead?.leadBrokerData?.assignments[0]?.leadBroker?.lastName ?? ""
              }`,
      }));
    setSevenDaysLead(buyers);
    setIsLeadsLoading(false);
    const dayCounts = {};
    const yesterday = moment().subtract(1, "days").startOf("day");

    buyers.forEach((item) => {
      const day = moment(item.updatedAt).format("MMM DD");
      if (dayCounts[day]) {
        dayCounts[day]++;
      } else {
        dayCounts[day] = 1;
      }
    });
    const result = [];
    for (let i = 6; i >= 0; i--) {
      const day = moment(yesterday).subtract(i, "days").format("MMM DD");
      const count = dayCounts[day] || 0;
      result.push({ day, count });
    }
    setSevenDaysLeadCount(result);

    let leadSourceReport = await getLeadSourceReport("7day", projectId);
    setLeadSourcesData(leadSourceReport);

    let leadStatusReport = await getLeadStatusReport("7day", projectId);
    setLeadStatusData(leadStatusReport);

    let ratingScoreReport = await getRatingScoreReport("7day", projectId);
    const data = ratingScoreReport.map((res) => {
      return {
        name: res._id,
        uv: res.count,
      };
    });
    let newArray = scores.map((item) => {
      return {
        name: item.label,
        uv: 0,
      };
    });
    newArray.push({ name: "Unrated", uv: 0 });
    data.forEach((item) => {
      const index = newArray.findIndex((elem) => elem.name === item.name);
      if (index !== -1) {
        newArray[index].uv = item.uv;
      } else {
        newArray.push({ name: item.name, uv: item.uv });
      }
    });
    setRatingSource(newArray);
  };

  const handlingTodayLeadsPage = async () => {
    setIsLeadsLoading(true);
    let leadsReport = await getLeadsReport("today", projectId);
    const now = new Date();
    const last24Hours = now.setHours(now.getHours() - 24);

    const buyers = leadsReport.contacts
      .filter((lead) => {
        return (
          lead.userType === "Buyer" &&
          lead?.buyerData?.assignments[0]?.project === projectId
        );
      })
      .filter((lead) => new Date(lead.updatedAt).getTime() >= last24Hours)
      .sort((a, b) => {
        const dateA = new Date(a.updatedAt);
        const dateB = new Date(b.updatedAt);
        return dateB - dateA;
      })
      .map((lead) => ({
        ...lead,
        fullName: `${lead?.firstName ?? "-"} ${lead?.lastName ?? ""}`,
        leadSource: lead?.buyerData?.leadSource ?? "-",
        salesRepsName: `${
          lead?.buyerData.assignments[0]?.salesRep?.firstName ?? "-"
        } ${lead?.buyerData.assignments[0]?.salesRep?.lastName ?? ""}`,
        leadBrokerName: `${
          lead?.buyerData.assignments[0]?.leadBroker?.firstName ?? "-"
        } ${lead?.buyerData.assignments[0]?.leadBroker?.lastName ?? ""}`,
      }));
    setSevenDaysLead(buyers);
    setIsLeadsLoading(false);
    const dayCounts = {};
    const yesterday = moment().subtract(1, "days").startOf("day");

    buyers.forEach((item) => {
      const day = moment(item.createdAt).format("MMM DD");
      if (dayCounts[day]) {
        dayCounts[day]++;
      } else {
        dayCounts[day] = 1;
      }
    });
    const result = [];
    for (let i = 6; i >= 0; i--) {
      const day = moment(yesterday).subtract(i, "days").format("MMM DD");
      const count = dayCounts[day] || 0;
      result.push({ day, count });
    }
    setSevenDaysLeadCount(result);

    let leadSourceReport = await getLeadSourceReport("today", projectId);
    setLeadSourcesData(leadSourceReport);

    let leadStatusReport = await getLeadStatusReport("today", projectId);
    setLeadStatusData(leadStatusReport);

    let ratingScoreReport = await getRatingScoreReport("today", projectId);
    const data = ratingScoreReport.map((res) => {
      return {
        name: res._id,
        uv: res.count,
      };
    });
    let newArray = scores.map((item) => {
      return {
        name: item.label,
        uv: 0,
      };
    });
    newArray.push({ name: "Unrated", uv: 0 });
    data.forEach((item) => {
      const index = newArray.findIndex((elem) => elem.name === item.name);
      if (index !== -1) {
        newArray[index].uv = item.uv;
      } else {
        newArray.push({ name: item.name, uv: item.uv });
      }
    });
    setRatingSource(newArray);
  };

  const handleDownloadReport = async (campaignId) => {
    // @ts-ignore
    setLoadingReportId(campaignId);
    const response = await api.get(
      `/reports/email-campaigns/${campaignId}?deviceId=${id}`,
      {},
      { responseType: "blob" }
    );
    if (response.ok) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "campaign-report.xlsx");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setLoadingReportId(null);
    } else {
      console.error("Error fetching file:", response.status);
    }
  };

  useEffect(() => {
    if (selectedTab2 == "tab-2") {
      handlingSevenDaysLeadsPage();
    }
    if (selectedTab2 == "tab-1") {
      handlingTodayLeadsPage();
    }
  }, [selectedTab2, projectId]);

  useEffect(() => {
    if (selectedTab == "tab-2") {
      if (salesRepTab === "tab-1") {
        getAllSalesRepByProject(projectId).then((res) => {
          const result = res.map((item) => {
            return {
              value: (item?.firstName ?? "") + " " + (item?.lastName ?? ""),
              _id: item?._id,
            };
          });
          setSalesRepData(result);
          if (userObject.userType === "SalesRep") {
            setSalesRepTab("tab-2");
            setSelectedSalesRep(
              result.filter((elm) => elm._id === userObject._id)[0]?._id
            );
          }
        });

        if (selectedTab2 === "tab-2") {
          getUserActivityAllByGroup("7day").then((response) => {
            const groupedCounts = {};
            response.forEach((item) => {
              const firstWord = item?._id?.split("-")[0] || "-";
              const capitalizedFirstWord = capitalizeFirstLetter(firstWord);
              if (!groupedCounts[capitalizedFirstWord]) {
                groupedCounts[capitalizedFirstWord] = item.count;
              } else {
                groupedCounts[capitalizedFirstWord] += item.count;
              }
            });
            const final = Object.entries(groupedCounts).map(([key, value]) => ({
              _id: key,
              count: value,
            }));
            setUserActivtyGroupData([
              ...final,
              {
                _id: "Virtual Meetings Booked nylas",
                count: 0,
              },
              {
                _id: "Presentation Centre  Appointments Booked",
                count: 0,
              },
            ]);
          });
        }
        if (selectedTab2 === "tab-1") {
          getUserActivityAllByGroup("today").then((response) => {
            const groupedCounts = {};
            response.forEach((item) => {
              const firstWord = item._id.split("-")[0];
              const capitalizedFirstWord = capitalizeFirstLetter(firstWord);
              if (!groupedCounts[capitalizedFirstWord]) {
                groupedCounts[capitalizedFirstWord] = item.count;
              } else {
                groupedCounts[capitalizedFirstWord] += item.count;
              }
            });
            const final = Object.entries(groupedCounts).map(([key, value]) => ({
              _id: key,
              count: value,
            }));
            setUserActivtyGroupData([
              ...final,
              {
                _id: "Virtual Meetings Booked nylas",
                count: 0,
              },
              {
                _id: "Presentation Centre  Appointments Booked",
                count: 0,
              },
            ]);
          });
        }
      }
    }
  }, [selectedTab, selectedTab2, salesRepTab, projectId, userObject]);

  const handlingTodaySalesActivityTables = async () => {
    let statusData = await getLeadStatusReportBySalesRep(
      "today",
      projectId,
      selectedSalesRep
    );
    setLeadStatusReportBySalesRep(statusData);

    let activityData = await getUserActivityBySalesRepByGroup(
      "today",
      selectedSalesRep
    );
    const groupedCounts = {};
    if (activityData.lead === 0) {
      setUserActivtyGroupData([]);
    } else {
      activityData.forEach((item) => {
        const firstWord = item._id.split("-")[0];
        const capitalizedFirstWord = capitalizeFirstLetter(firstWord);
        if (!groupedCounts[capitalizedFirstWord]) {
          groupedCounts[capitalizedFirstWord] = item.count;
        } else {
          groupedCounts[capitalizedFirstWord] += item.count;
        }
      });
      const final = Object.entries(groupedCounts).map(([key, value]) => ({
        _id: key,
        count: value,
      }));
      setUserActivtyGroupData([
        ...final,
        {
          _id: "Virtual Meetings Booked nylas",
          count: 0,
        },
        {
          _id: "Presentation Centre  Appointments Booked",
          count: 0,
        },
      ]);
    }

    let ratingData = await getRatingScoreReportBySalesRep(
      "today",
      projectId,
      selectedSalesRep
    );
    const data = ratingData.map((res) => {
      return {
        name: res._id,
        uv: res.count,
      };
    });
    let newArray = scores.map((item) => {
      return {
        name: item.label,
        uv: 0,
      };
    });
    newArray.push({ name: "Unrated", uv: 0 });
    data.forEach((item) => {
      const index = newArray.findIndex((elem) => elem.name === item.name);
      if (index !== -1) {
        newArray[index].uv = item.uv;
      } else {
        newArray.push({ name: item.name, uv: item.uv });
      }
    });
    setRatingSourceBySalesRep(newArray);

    let leadsource = await getLeadSourceReportBySalesRep(
      "today",
      projectId,
      selectedSalesRep
    );

    setLeadSourcesDataBySalesRep(leadsource);
  };

  const handling7daysSalesActivityTables = async () => {
    let statusData = await getLeadStatusReportBySalesRep(
      "7days",
      projectId,
      selectedSalesRep
    );
    setLeadStatusReportBySalesRep(statusData);

    let activityData = await getUserActivityBySalesRepByGroup(
      "7days",
      selectedSalesRep
    );

    const groupedCounts = {};
    if (activityData.lead === 0) {
      setUserActivtyGroupData([]);
    } else {
      activityData.forEach((item) => {
        const firstWord = item._id.split("-")[0];
        const capitalizedFirstWord = capitalizeFirstLetter(firstWord);
        if (!groupedCounts[capitalizedFirstWord]) {
          groupedCounts[capitalizedFirstWord] = item.count;
        } else {
          groupedCounts[capitalizedFirstWord] += item.count;
        }
      });
      const final = Object.entries(groupedCounts).map(([key, value]) => ({
        _id: key,
        count: value,
      }));
      setUserActivtyGroupData([
        ...final,
        {
          _id: "Virtual Meetings Booked nylas",
          count: 0,
        },
        {
          _id: "Presentation Centre  Appointments Booked",
          count: 0,
        },
      ]);
    }

    let ratingData = await getRatingScoreReportBySalesRep(
      "7days",
      projectId,
      selectedSalesRep
    );
    const data = ratingData.map((res) => {
      return {
        name: res._id,
        uv: res.count,
      };
    });
    let newArray = scores.map((item) => {
      return {
        name: item.label,
        uv: 0,
      };
    });
    newArray.push({ name: "Unrated", uv: 0 });
    data.forEach((item) => {
      const index = newArray.findIndex((elem) => elem.name === item.name);
      if (index !== -1) {
        newArray[index].uv = item.uv;
      } else {
        newArray.push({ name: item.name, uv: item.uv });
      }
    });
    setRatingSourceBySalesRep(newArray);

    let leadsource = await getLeadSourceReportBySalesRep(
      "7days",
      projectId,
      selectedSalesRep
    );

    setLeadSourcesDataBySalesRep(leadsource);
  };

  useEffect(() => {
    if (salesRepTab === "tab-2" && selectedSalesRep != "") {
      if (selectedTab2 === "tab-1") {
        handlingTodaySalesActivityTables();
      }
      if (selectedTab2 === "tab-2") {
        handling7daysSalesActivityTables();
      }
    }
  }, [salesRepTab, selectedSalesRep, selectedTab2, projectId]);

  useEffect(() => {
    if (selectedTab === "tab-3") {
      getReservations(projectId).then((res) => {
        let updatedRes = res
          .sort((a, b) => {
            const dateA = new Date(
              a.manualReservationDate ? a.manualReservationDate : a.createdAt
            );
            const dateB = new Date(
              b.manualReservationDate ? b.manualReservationDate : b.createdAt
            );
            return dateB - dateA;
          })
          .map((reservation) => {
            const temBuyers = reservation.reservationHolders.map((user) =>
              user.firstName && user.lastName
                ? user.firstName + " " + user.lastName
                : "-"
            );
            return {
              ...reservation,
              reservation_holders: temBuyers.join(", "),
              finalLotChoice: [
                ...(reservation?.firstChoiceUnits ?? []),
                ...(reservation?.secondChoiceUnits ?? []),
                ...(reservation?.thirdChoiceUnits ?? []),
                ...(reservation?.fourthChoiceUnits ?? []),
                ...(reservation?.fifthChoiceUnits ?? []),
              ],
            };
          });

        setReservationData(updatedRes);
        let signedArr = res.filter((item) => {
          return item.reservationStatus !== "Pending";
        });
        setReservationDataSigned(signedArr);
      });
    }
  }, [selectedTab, projectId]);

  useEffect(() => {
    if (projectId.length > 0) {
      getBuildingsByProjectId(projectId)
        .then((buildings) => {
          const temp = buildings?.map((building) => ({
            value: building?._id,
            label: building?.name,
          }));
          setBuildingOptions(temp);
          setSelectedBuilding(temp?.length > 0 ? temp[0]?.value : "");
        })
        .catch((error) => console.error(error));
    }
  }, [projectId]);

  const [projectContacts, setProjectContacts] = useState([]);

  useEffect(() => {
    const fetchProjectContacts = async () => {
      try {
        const response = await getProjectContactWiseList();
        let mapped = response.map((item, index) => {
          return {
            ...item,
            fill: `hsl(var(--chart-${index + 1}))`,
          };
        });
        setProjectContacts(mapped);
      } catch (error) {
        console.error("Error fetching project contacts:", error);
      }
    };

    fetchProjectContacts();
  }, []);

  const useStyles = makeStyles((theme) => ({
    drawerPaper: {
      width: isMobile ? "100%" : "calc(100% - 80px)",
      height: "100vh",
      overflow: "hidden",
      boxShadow: "-10px 0px 15px 0px rgba(46, 43, 46, 0.05)",
    },
  }));

  useEffect(() => {
    const fetchLast30DaysContacts = async () => {
      try {
        const response = await getLast30DaysContacts();
        setLast30DaysContacts(response);
      } catch (error) {
        console.error("Error fetching last 30 days contacts:", error);
      }
    };
    fetchLast30DaysContacts();
  }, []);

  const classes = useStyles();

  const leadsTableColumns = useMemo(
    () => [
      {
        id: "updatedAt",
        label: "Updated Date",
        Title: (
          <span className="leading-none text-sm font-normal text-[#2E2B2E] text-opacity-70">
            Updated Date
          </span>
        ),
        accessor: "updatedAt",
        dataIndex: "updatedAt",
        isSort: true,
        sortingType: "string",
        Cell: (props) => (
          <div className="w-full flex flex-col">
            <p className="mb-0 truncate text-base font-normal">
              {moment(props.cell.value).format("MM/DD/YYYY")}
            </p>
          </div>
        ),
      },
      {
        id: "createdAt",
        label: "Created Date",
        Title: (
          <span className="leading-none text-sm font-normal text-[#2E2B2E] text-opacity-70">
            Created Date
          </span>
        ),
        accessor: "createdAt",
        dataIndex: "createdAt",
        isSort: true,
        sortingType: "string",
        Cell: (props) => (
          <div className="w-full flex flex-col">
            <p className="mb-0 truncate text-base font-normal">
              {moment(props.cell.value).format("MM/DD/YYYY")}
            </p>
          </div>
        ),
      },
      {
        id: "name",
        label: "Name",
        Title: (
          <span className="leading-none text-sm font-normal text-[#2E2B2E] text-opacity-70">
            Name
          </span>
        ),
        accessor: "fullName",
        dataIndex: "fullName",
        isSort: true,
        sortingType: "string",
        Cell: (props) => (
          <span className="flex items-center space-x-4">
            <span className="text-base font-normal capitalize">
              {props.cell.value?.length > 20
                ? props.cell.value.slice(0, 19) + "..."
                : props.cell.value}
            </span>
          </span>
        ),
      },
      {
        id: "leadSource",
        label: "Source",
        Title: (
          <span className="leading-none text-sm font-normal text-[#2E2B2E] text-opacity-70">
            Source
          </span>
        ),
        accessor: "leadSource",
        dataIndex: "leadSource",
        isSort: true,
        sortingType: "string",
        Cell: (props) => (
          <p className="mb-0 text-base font-normal">{props.cell.value}</p>
        ),
      },
      {
        id: "salesReps",
        label: "Sales Reps",
        Title: (
          <span className="leading-none text-sm font-normal text-[#2E2B2E] text-opacity-70">
            Sales Reps
          </span>
        ),
        accessor: "salesRepsName",
        dataIndex: "salesRepsName",
        isSort: true,
        sortingType: "string",
        Cell: (props) => (
          <p className="mb-0 text-base font-normal capitalize">
            {props.cell.value}
          </p>
        ),
      },
      {
        id: "leadBroker",
        label: "Broker",
        Title: (
          <span className="leading-none text-sm font-normal text-[#2E2B2E] text-opacity-70">
            Broker
          </span>
        ),
        accessor: "leadBrokerName",
        dataIndex: "leadBrokerName",
        isSort: true,
        sortingType: "string",
        Cell: (props) => (
          <p className="mb-0 text-base font-normal capitalize">
            {props.cell.value}
          </p>
        ),
      },
      {
        id: "actionBtn",
        label: "Action button",
        Title: "",
        accessor: "_id",
        isSort: false,
        Cell: (props) => (
          <div className="flex flex-row gap-2 text-center items-center justify-end">
            <CustomButton
              handleClick={() => {
                setSelectedContact(props.cell.value);
                setSelectedContactObject({
                  ...props.cell.row.original,
                  id: props.cell.value,
                });
                setIsContactDrawerOpen(true);
              }}
            >
              <span className="font-normal p-2 text-sm text-center">
                View Details
              </span>
            </CustomButton>
          </div>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    const list = appReducer?.listOfProjects;
    const selectedProjectId = appReducer?.appProject;

    if (!list || !selectedProjectId) return;

    const activeProject = list.find(
      (project) => project._id === selectedProjectId
    );
    setProjectName(activeProject?.projectName);
    setProjectBackgroundImage(activeProject?.backgroundImage);
    setProjectLogo(activeProject?.logoImage);
  }, [appReducer]);

  return (
    <div className="flex justify-center min-h-[calc(100vh-64px)]">
      <div className="z-[-1] fixed top-0 left-0 h-full overflow-hidden">
        <img
          src={
            projectBackgroundImage?.length > 0
              ? projectBackgroundImage
              : placeHolderBg
          }
          className="object-cover w-screen h-full"
          loading="lazy"
        />
        <div className="absolute top-0 left-0 w-screen h-full bg-black/50" />
      </div>
      {loading ? (
        <div></div>
      ) : (
        <div className="w-full my-10 container px-4 md:px-8 max-w-[1440px] flex flex-col gap-6 relative">
          {/* <div className="w-full items-center top-10 my-8 mb-2 flex justify-between gap-6">
            <div className="w-full flex items-center">
              <img
                src={projectLogo ?? PacificaLogo}
                className="w-auto h-[70px] object-contain"
              />
            </div>
          </div> */}
          <div className="w-full gap-6 lg:gap-y-0 flex flex-col lg:flex-row justify-between">
            {selectedTab === "tab-1" && (
              <div className="w-full lg:flex-1 flex flex-col gap-y-8 overflow-hidden">
                <div className="flex w-full gap-y-4 flex-col md:flex-row gap-x-8 justify-between">
                  <div className="w-full gap-y-4 grid grid-cols-3 gap-x-8">
                    <CardUI
                      title="TOTAL CONTACTS"
                      number={fetchedBuyerBrokerCount?.total || 0}
                      Icon={<img src="/total-sale.svg" className="w-5 h-5" />}
                    />
                    <CardUI
                      title="LEADS"
                      number={fetchedBuyerBrokerCount?.buyer || 0}
                      Icon={<img src="/unit-sold.svg" className="w-5 h-5" />}
                    />
                    <CardUI
                      title="REALTORS"
                      number={fetchedBuyerBrokerCount?.leadBroker || 0}
                      Icon={<img src="/avg-price.svg" className="w-5 h-5" />}
                    />
                  </div>
                </div>
                {/* <div className='w-fit max-w-[400px]'>
                  <TabWrapper
                    tabMenus={tabMenus2}
                    setSelectedTab={setSelectedTab2}
                    selectedTab={selectedTab2}
                  />
                </div> */}
                <BarChartShadCN
                  title={"Last 30 Days Contacts"}
                  description={
                    moment(new Date())
                      .subtract(31, "days")
                      .format("MMM DD YYYY") +
                    " - " +
                    moment(new Date()).format("MMM DD YYYY")
                  }
                  data={last30DaysContacts}
                  XAxisDataKey={"date"}
                  XAxisTickFormatter={(value) => moment(value).format("MMM DD")}
                  dataKey1={"buyers"}
                  dataKey2={"leadBrokers"}
                  tickStyle={{
                    fontSize: 10,
                  }}
                  angle={-45}
                  textAnchor="end"
                  height={50}
                  interval={0}
                  ChartContainerProps={{
                    className: "max-h-[300px] w-full",
                  }}
                />
                <div className="grid grid-cols-12 w-full gap-x-6">
                  <div className="col-span-4">
                    <PieChartShadCN
                      data={projectContacts}
                      dataKey="value"
                      nameKey="name"
                      title="Contacts by Project"
                      footer={
                        <div className="flex flex-wrap mt-4 items-center gap-2 text-sm">
                          {projectContacts.map((item, index) => {
                            return (
                              <div className="flex flex-row items-center gap-2">
                                <div
                                  className="w-3 h-3 rounded-full"
                                  style={{ backgroundColor: item.fill }}
                                />
                                <div>{item.name}</div>
                              </div>
                            );
                          })}
                        </div>
                      }
                    />
                  </div>
                  <div className="flex flex-col col-span-4 gap-y-2">
                    <BarChartShadCN
                      barWidth={40}
                      title={"Leads by Status"}
                      data={leadsCountData}
                      XAxisDataKey={"name"}
                      // XAxisTickFormatter={(value) =>
                      //   moment(value).format("MMM DD")
                      // }
                      angle={45}
                      textAnchor="start"
                      dataKey1={"value"}
                      tickStyle={{
                        fontSize: 12,
                      }}
                      height={100}
                      interval={0}
                      ChartContainerProps={{
                        className: "h-[300px] w-full",
                      }}
                    />
                  </div>
                  <div className="flex flex-col col-span-4 gap-y-2">
                    <BarListShadCN
                      data={ratingScoreCounts}
                      dataKey1={"name"}
                      XAxisDataKey={"name"}
                      dataKey2={"value"}
                      interval={0}
                      title={"Rating Source Report"}
                      tickStyle={{
                        fontSize: 12,
                      }}
                    />
                  </div>
                </div>
                <HorizontalLine className="my-4" />
                <div className="grid grid-cols-12 gap-x-6 gap-y-4">
                  {demandNotesReport
                    .slice(0, showAllDemandNotes ? demandNotesReport.length : 3)
                    .map((item, index) => {
                      return (
                        <div key={index} className="col-span-4">
                          <BarListShadCN
                            data={item.data}
                            dataKey1={"name"}
                            XAxisDataKey={"name"}
                            dataKey2={"value"}
                            interval={0}
                            title={item.name}
                            tickStyle={{
                              fontSize: 12,
                            }}
                          />
                        </div>
                      );
                    })}
                </div>
                {demandNotesReport.length > 3 && (
                  <div className="flex justify-center mt-4">
                    <button
                      onClick={() => setShowAllDemandNotes(!showAllDemandNotes)}
                      className="bg-volt text-softBlack px-4 py-2 rounded-md"
                    >
                      {showAllDemandNotes ? "Show Less" : "Show More"}
                    </button>
                  </div>
                )}
              </div>
            )}
            {selectedTab === "tab-2" && (
              <div className="w-full lg:flex-1 flex mb-8 flex-col gap-y-6">
                <div className="flex flex-wrap min-h-12 w-full gap-x-2 items-center">
                  {userObject.userType === "DeveloperAdmin" && (
                    <div className="items-center w-full md:w-fit md:max-w-[500px] flex flex-wrap gap-y-1 md:gap-x-2 rounded-md bg-white">
                      <TabWrapper
                        tabMenus={salesRepMenu}
                        setSelectedTab={(tabId) => {
                          setSalesRepTab(tabId);
                          if (tabId === "tab-1") {
                            setSelectedSalesRep("");
                          }
                        }}
                        selectedTab={salesRepTab}
                      />
                    </div>
                  )}
                  <div className="items-center w-full md:w-fit md:max-w-[500px] flex flex-wrap gap-y-1 md:gap-x-2 rounded-md bg-white">
                    <TabWrapper
                      tabMenus={tabMenus2}
                      setSelectedTab={setSelectedTab2}
                      selectedTab={selectedTab2}
                    />
                  </div>
                  {salesRepTab === "tab-2" &&
                    userObject.userType === "DeveloperAdmin" && (
                      <div className="flex-1 flex mt-2 md:mt-0 md:justify-end">
                        <div className="w-[200px]">
                          {/* <div className='text-[18px] font-bold'>Select Sales Rep</div> */}
                          <CustomSelect
                            inputClasses="bg-white"
                            selectedOption={selectedSalesRep}
                            setSelectedOption={(value) => {
                              setSelectedSalesRep(value);
                            }}
                            options={salesRepData}
                            fieldName="Sales Rep"
                          />
                        </div>
                      </div>
                    )}
                </div>

                {salesRepTab === "tab-1" && (
                  <div className="flex w-full gap-x-6">
                    <ShadCNWrapper className="basis-1/2">
                      <div className="w-full min-h-[350px] p-6">
                        <div className="text-lg font-semibold">
                          {selectedTab2 === "tab-1" ? "Today's" : "This Week's"}{" "}
                          Activity - All Sales Reps
                        </div>
                        <div className="text-[#646264] mt-1 mb-2 text-base">
                          {selectedTab2 === "tab-1"
                            ? moment(new Date()).format("MMM DD YYYY")
                            : moment(new Date()).format("MMM DD YYYY") +
                              " - " +
                              moment(new Date())
                                .subtract(7, "days")
                                .format("MMM DD YYYY")}
                        </div>
                        <HorizontalLine className="my-4" />
                        <div className="flex flex-col gap-y-2">
                          {userActivtyGroupData !== null &&
                            userActivtyGroupData != [] &&
                            userActivtyGroupData.map((item, index) => {
                              return (
                                <div
                                  className="flex gap-y-1 flex-col"
                                  key={index}
                                >
                                  <div className="basis-1/2 font-bold text-[20px]">
                                    {item.count}
                                  </div>
                                  <div className="basis-1/2 text-[16px] uppercase font-medium">
                                    {item._id}
                                  </div>
                                  <HorizontalLine className="my-2" />
                                </div>
                              );
                            })}
                          {(!userActivtyGroupData ||
                            userActivtyGroupData.length === 0) && (
                            <div className="my-10 text-base text-center flex items-center justify-center dark:text-white">
                              There are no items to be shown
                            </div>
                          )}
                        </div>
                      </div>
                    </ShadCNWrapper>
                  </div>
                )}
                {salesRepTab === "tab-2" && (
                  <div className="flex flex-col gap-y-6">
                    <div className="flex w-full gap-x-6">
                      <ShadCNWrapper className="basis-1/2">
                        <div className="w-full min-h-[350px] p-6">
                          <div className="text-lg font-semibold">
                            {selectedTab2 === "tab-1"
                              ? "Today's"
                              : "This Week's"}{" "}
                            Activity -{" "}
                            {
                              salesRepData.find(
                                (item) => item._id === selectedSalesRep
                              )?.value
                            }
                          </div>
                          <div className="text-[#646264] mt-1 text-base">
                            {selectedTab2 === "tab-1"
                              ? moment(new Date()).format("MMM DD YYYY")
                              : moment(new Date()).format("MMM DD YYYY") +
                                " - " +
                                moment(new Date())
                                  .subtract(7, "days")
                                  .format("MMM DD YYYY")}
                          </div>
                          <HorizontalLine className="my-4" />
                          <div className="flex flex-col gap-y-2">
                            {userActivtyGroupData !== null &&
                              userActivtyGroupData != [] &&
                              userActivtyGroupData.map((item, index) => {
                                return (
                                  <div
                                    className="flex gap-y-1 flex-col"
                                    key={index}
                                  >
                                    <div className="basis-1/2 font-bold text-[20px]">
                                      {item.count}
                                    </div>
                                    <div className="basis-1/2 text-[16px] uppercase font-medium">
                                      {item._id}
                                    </div>
                                    <HorizontalLine className="my-2" />
                                  </div>
                                );
                              })}
                            {(!userActivtyGroupData ||
                              userActivtyGroupData.length === 0) && (
                              <div className="my-10 text-base text-center flex items-center justify-center dark:text-white">
                                There are no items to be shown
                              </div>
                            )}
                          </div>
                        </div>
                      </ShadCNWrapper>
                      <ShadCNWrapper className="basis-1/2">
                        <div className="w-full min-h-[350px] p-6">
                          <div className="text-lg font-semibold">
                            {selectedTab2 === "tab-1"
                              ? "Today's"
                              : "This Week's"}{" "}
                            Leads By Status -{" "}
                            {
                              salesRepData.find(
                                (item) => item._id === selectedSalesRep
                              )?.value
                            }
                          </div>
                          <div className="text-[#646264] mt-1 text-base">
                            {selectedTab2 === "tab-1"
                              ? moment(new Date()).format("MMM DD YYYY")
                              : moment(new Date()).format("MMM DD YYYY") +
                                " - " +
                                moment(new Date())
                                  .subtract(7, "days")
                                  .format("MMM DD YYYY")}
                          </div>
                          <HorizontalLine className="my-4" />
                          <div className="flex flex-col gap-y-2">
                            {leadStatusReportBySalesRep !== null &&
                              leadStatusReportBySalesRep != [] &&
                              leadStatusReportBySalesRep.map((item, index) => {
                                return (
                                  <div
                                    className="flex gap-y-1 flex-col"
                                    key={index}
                                  >
                                    <div className="basis-1/2 font-bold text-[20px]">
                                      {item.count}
                                    </div>
                                    <div className="basis-1/2 text-[16px] uppercase font-medium">
                                      {item._id}
                                    </div>
                                    <HorizontalLine className="my-2" />
                                  </div>
                                );
                              })}
                            {(!leadStatusReportBySalesRep ||
                              leadStatusReportBySalesRep.length === 0) && (
                              <div className="my-10 text-base text-center flex items-center justify-center dark:text-white">
                                There are no items to be shown
                              </div>
                            )}
                          </div>
                        </div>
                      </ShadCNWrapper>
                    </div>
                    <div className="flex w-full gap-x-6">
                      <ShadCNWrapper className="basis-1/2">
                        <div className="w-full min-h-[350px] p-6">
                          <div className="text-lg font-semibold">
                            {selectedTab2 === "tab-1"
                              ? "Today's"
                              : "This Week's"}{" "}
                            Prospect Rating -{" "}
                            {
                              salesRepData.find(
                                (item) => item._id === selectedSalesRep
                              )?.value
                            }
                          </div>
                          <div className="text-[#646264] mt-1 mb-2 text-base">
                            {selectedTab2 === "tab-1"
                              ? moment(new Date()).format("MMM DD YYYY")
                              : moment(new Date()).format("MMM DD YYYY") +
                                " - " +
                                moment(new Date())
                                  .subtract(7, "days")
                                  .format("MMM DD YYYY")}
                          </div>
                          <HorizontalLine className="my-4" />
                          <div className="flex flex-col  w-full py-6 ">
                            <ResponsiveContainer width="95%" height={400}>
                              {ratingSourceBySalesRep.every(
                                (item) => item.uv === 0
                              ) ? (
                                <div className="my-10 text-base text-center flex items-center justify-center dark:text-white">
                                  There are no items to be shown
                                </div>
                              ) : (
                                <BarChartComponent
                                  data={ratingSourceBySalesRep}
                                  barSize={50}
                                  width={200}
                                  height={400}
                                  barCategoryGap={20}
                                >
                                  <Bar
                                    radius={4}
                                    isAnimationActive={false}
                                    dataKey="uv"
                                    name={"name"}
                                    color=""
                                  >
                                    {ratingSourceBySalesRep !== [] &&
                                      ratingSourceBySalesRep.map(
                                        (entry, index) => (
                                          <Cell
                                            key={`cell-${index}`}
                                            fill={colors[index % 20]}
                                          />
                                        )
                                      )}
                                    <LabelList
                                      className="text-xl pb-1"
                                      dataKey="uv"
                                      fill="black"
                                      position="top"
                                    />
                                    {/* <LabelList
                                    className='text-xl pb-1'
                                    dataKey='name'
                                    position='bottom'
                                  /> */}
                                  </Bar>
                                </BarChartComponent>
                              )}
                            </ResponsiveContainer>
                            <div className="flex mt-2 ml-6  gap-x-[2.4vw] 2xl:gap-x-[2.9vw] w-full">
                              {ratingSourceBySalesRep.every(
                                (item) => item.uv === 0
                              )
                                ? ""
                                : ratingSourceBySalesRep.map((item, index) => {
                                    return (
                                      <div className="flex items-center gap-x-2">
                                        <div
                                          style={{
                                            backgroundColor: colors[index % 20],
                                          }}
                                          className="rounded-[4px] w-4 h-4"
                                        ></div>
                                        <div className="text-[16px] font-bold">
                                          {item.name}
                                        </div>
                                      </div>
                                    );
                                  })}
                            </div>
                          </div>
                        </div>
                      </ShadCNWrapper>
                      <ShadCNWrapper className="basis-1/2">
                        <div className="w-full min-h-[350px] p-6">
                          <div className="text-lg font-semibold">
                            {selectedTab2 === "tab-1"
                              ? "Today's"
                              : "This Week's"}{" "}
                            Leads By Source -{" "}
                            {
                              salesRepData.find(
                                (item) => item._id === selectedSalesRep
                              )?.value
                            }
                          </div>
                          <div className="text-[#646264] mt-1 text-base">
                            {selectedTab2 === "tab-1"
                              ? moment(new Date()).format("MMM DD YYYY")
                              : moment(new Date()).format("MMM DD YYYY") +
                                " - " +
                                moment(new Date())
                                  .subtract(7, "days")
                                  .format("MMM DD YYYY")}
                          </div>
                          <HorizontalLine className="my-4" />
                          <div className="flex flex-col gap-y-2">
                            {leadSourcesDataBySalesRep !== null &&
                              leadSourcesDataBySalesRep !== [] &&
                              leadSourcesDataBySalesRep.map((item, index) => {
                                return (
                                  <div
                                    className="flex gap-y-1 flex-col"
                                    key={index}
                                  >
                                    <div className="basis-1/2 font-bold text-[20px]">
                                      {item.count}
                                    </div>
                                    <div className="basis-1/2 text-[16px] uppercase font-medium">
                                      {item._id}
                                    </div>
                                    <HorizontalLine className="my-2" />
                                  </div>
                                );
                              })}
                            {(!leadSourcesDataBySalesRep ||
                              leadSourcesDataBySalesRep.length === 0) && (
                              <div className="my-10 text-base text-center flex items-center justify-center dark:text-white">
                                There are no items to be shown
                              </div>
                            )}
                          </div>
                        </div>
                      </ShadCNWrapper>
                    </div>
                  </div>
                )}
              </div>
            )}
            {selectedTab === "tab-3" && (
              <div className="w-full flex mb-8 overflow-hidden flex-col gap-y-6">
                <ShadCNWrapper>
                  <div className="flex flex-col gap-y-[12px] p-6 w-full ">
                    <div className="text-lg font-semibold">Reservations</div>
                    <div className="overflow-auto mt-1">
                      <table className="w-full ">
                        <tr className="text-[#777777] relative uppercase w-full">
                          {headersForStatic.map((header, index) => {
                            return (
                              <th className="whitespace-nowrap" key={index}>
                                <div className="leading-none text-sm text-left font-normal text-[#2E2B2E] text-opacity-70 capitalize">
                                  {header.name}
                                </div>
                              </th>
                            );
                          })}
                          <HorizontalLine className="absolute -bottom-1 left-0" />
                        </tr>
                        {reservationData === [] && "No Data Available"}
                        {!showMoreReservation
                          ? reservationData
                              .slice(0, 4)
                              .map((reservation, index) => {
                                return (
                                  <tr
                                    key={index}
                                    className="font-[300] pt-6 text-[#2E2B2E] text-[16px]"
                                  >
                                    <td className="text-[#2E2B2E] font-[400] ">
                                      #{index}
                                    </td>
                                    <td className="w-full truncate">
                                      {reservation.reservation_holders}
                                    </td>
                                    <td>
                                      {reservation?.firstChoiceUnits
                                        ?.map((item) => {
                                          return item.unitNumber;
                                        })
                                        .join(",")}
                                    </td>
                                    <td>
                                      {reservation?.secondChoiceUnits
                                        ?.map((item) => {
                                          return item.unitNumber;
                                        })
                                        .join(",")}
                                    </td>
                                    <td>
                                      {reservation?.thirdChoiceUnits
                                        ?.map((item) => {
                                          return item.unitNumber;
                                        })
                                        .join(",")}
                                    </td>
                                    <td>
                                      {reservation?.fourthChoiceUnits
                                        ?.map((item) => {
                                          return item.unitNumber;
                                        })
                                        .join(",")}
                                    </td>
                                    <td>
                                      {reservation?.fifthChoiceUnits
                                        ?.map((item) => {
                                          return item.unitNumber;
                                        })
                                        .join(",")}
                                    </td>
                                    <td className="text-[#2E2B2E] font-[400] ">
                                      {moment(
                                        reservation.manualReservationDate
                                          ? reservation.manualReservationDate
                                          : reservation.createdAt
                                      ).format("MM/DD/YYYY")}
                                    </td>

                                    <td className="max-w-[140px] truncate">
                                      {reservation?.esscrowAgent
                                        ?.escrowAgentData?.companyName || "-"}
                                    </td>
                                    <td className="max-w-[140px] truncate"></td>

                                    <td>{reservation?.notes ?? "-"}</td>
                                  </tr>
                                );
                              })
                          : reservationData.map((reservation, index) => {
                              return (
                                <tr
                                  key={index}
                                  className="font-[300] pt-6 text-[#2E2B2E] text-[16px]"
                                >
                                  <td className="text-[#2E2B2E] font-[400] ">
                                    #{index}
                                  </td>
                                  <td className="w-full truncate">
                                    {reservation.reservation_holders}
                                  </td>
                                  <td>
                                    {reservation?.firstChoiceUnits
                                      ?.map((item) => {
                                        return item.unitNumber;
                                      })
                                      .join(",")}
                                  </td>
                                  <td>
                                    {reservation?.secondChoiceUnits
                                      ?.map((item) => {
                                        return item.unitNumber;
                                      })
                                      .join(",")}
                                  </td>
                                  <td>
                                    {reservation?.thirdChoiceUnits
                                      ?.map((item) => {
                                        return item.unitNumber;
                                      })
                                      .join(",")}
                                  </td>
                                  <td>
                                    {reservation?.fourthChoiceUnits
                                      ?.map((item) => {
                                        return item.unitNumber;
                                      })
                                      .join(",")}
                                  </td>
                                  <td>
                                    {reservation?.fifthChoiceUnits
                                      ?.map((item) => {
                                        return item.unitNumber;
                                      })
                                      .join(",")}
                                  </td>
                                  <td className="text-[#2E2B2E] font-[400] ">
                                    {moment(
                                      reservation.manualReservationDate
                                        ? reservation.manualReservationDate
                                        : reservation.createdAt
                                    ).format("MM/DD/YYYY")}
                                  </td>

                                  <td className="max-w-[140px] truncate">
                                    {reservation?.esscrowAgent?.escrowAgentData
                                      ?.companyName || "-"}
                                  </td>
                                  <td className="max-w-[140px] truncate"></td>

                                  <td>{reservation?.notes ?? "-"}</td>
                                </tr>
                              );
                            })}
                      </table>
                      {reservationData?.length > 4 && (
                        <div className="flex mx-auto mt-[10px]">
                          <div
                            onClick={() => {
                              setShowMoreReservation(!showMoreReservation);
                            }}
                            className="cursor-pointer flex gap-x-2 items-center mx-auto"
                          >
                            <div className="text-[#2E2B2E] font-[300]">
                              {!showMoreReservation ? "Show more" : "Show less"}
                            </div>
                            <div>
                              {!showMoreReservation ? (
                                <img src="/down-arrow.png" />
                              ) : (
                                <img src="/up-arrow.svg" />
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </ShadCNWrapper>
              </div>
            )}
            {selectedTab === "tab-4" && (
              <div className="w-full lg:flex-1 flex mb-8 flex-col gap-y-6">
                <div className="flex flex-wrap w-full md:w-fit max-w-[500px] gap-y-1 md:gap-x-2 rounded-md bg-white">
                  <TabWrapper
                    tabMenus={communicationTab}
                    setSelectedTab={setCommunicationTabState}
                    selectedTab={communicationTabState}
                  />
                </div>
                {communicationTabState === "tab-1" && (
                  <ShadCNWrapper>
                    <div className="flex flex-col gap-y-[12px] p-6 w-full ">
                      <div className="text-lg font-semibold">SMS CAMPAIGNS</div>
                      <div className="mt-1 w-full">
                        <table className="w-full ">
                          <tr className="text-[#777777] relative uppercase w-full">
                            <th>
                              <div className="leading-none text-sm text-left font-normal text-[#2E2B2E] text-opacity-70 capitalize">
                                BROADCAST NAME
                              </div>
                            </th>
                            <th>
                              <div className="leading-none text-sm text-left font-normal text-[#2E2B2E] text-opacity-70 capitalize">
                                DATE SENT
                              </div>
                            </th>
                            <th>
                              <div className="leading-none text-sm text-left font-normal text-[#2E2B2E] text-opacity-70 capitalize">
                                NUMBER OF RECIPIENTS
                              </div>
                            </th>
                            <th>
                              <div className="leading-none text-sm text-left font-normal text-[#2E2B2E] text-opacity-70 capitalize">
                                DELIVERED
                              </div>
                            </th>
                            <th>
                              <div className="leading-none text-sm text-left font-normal text-[#2E2B2E] text-opacity-70 capitalize">
                                FAILED
                              </div>
                            </th>
                            <HorizontalLine className="absolute -bottom-1 left-0" />
                          </tr>
                          {smsBroadcastList === [] && "No Data Available"}
                          {!showMoreSMSCampaign
                            ? smsBroadcastList.slice(0, 4).map((sms, index) => {
                                return (
                                  <tr
                                    key={index}
                                    className="font-[300] pt-6 text-[#2E2B2E]  text-[16px]"
                                  >
                                    <td className="text-[#2E2B2E] not-italic font-[400] ">
                                      {sms.broadcastName}
                                    </td>
                                    <td>
                                      {moment(sms.sentDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </td>
                                    <td>{sms?.numberOfRecipients ?? "-"}</td>
                                    <td>{sms.delivered}</td>
                                    <td>{sms.failed}</td>
                                  </tr>
                                );
                              })
                            : smsBroadcastList.map((sms, index) => {
                                return (
                                  <tr
                                    key={index}
                                    className="font-[300] pt-6 text-[#2E2B2E]  text-[16px]"
                                  >
                                    <td className="text-[#2E2B2E] not-italic font-[400] ">
                                      {sms.broadcastName}
                                    </td>
                                    <td>
                                      {moment(sms.sentDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </td>
                                    <td>{sms?.numberOfRecipients ?? "-"}</td>
                                    <td>{sms.delivered}</td>
                                    <td>{sms.failed}</td>
                                  </tr>
                                );
                              })}
                        </table>
                        <div className="flex mx-auto mt-[10px]">
                          <div
                            onClick={() => {
                              setShowMoreCampaigns(!showMoreSMSCampaign);
                            }}
                            className="cursor-pointer flex gap-x-2 items-center mx-auto"
                          >
                            <div className="text-[#2E2B2E] font-[300]">
                              {!showMoreSMSCampaign ? "Show more" : "Show less"}
                            </div>
                            <div>
                              {!showMoreSMSCampaign ? (
                                <img src="/down-arrow.png" />
                              ) : (
                                <img src="/up-arrow.svg" />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ShadCNWrapper>
                )}
                {communicationTabState === "tab-2" && (
                  <ShadCNWrapper>
                    <div className="flex flex-col gap-y-[12px] p-6 w-full ">
                      <div className="text-lg font-semibold">
                        EMAIL CAMPAIGNS
                      </div>
                      <div className="mt-1 w-full">
                        <table className="w-full ">
                          <tr className="text-[#777777] relative uppercase w-full">
                            <th>
                              <div className="leading-none text-sm text-left font-normal text-[#2E2B2E] text-opacity-70 capitalize">
                                Campaign Name
                              </div>
                            </th>
                            <th>
                              <div className="leading-none text-sm text-left font-normal text-[#2E2B2E] text-opacity-70 capitalize">
                                Template Name
                              </div>
                            </th>
                            <th>
                              <div className="leading-none text-sm text-left font-normal text-[#2E2B2E] text-opacity-70 capitalize">
                                Requests
                              </div>
                            </th>
                            <th>
                              <div className="leading-none text-sm text-left font-normal text-[#2E2B2E] text-opacity-70 capitalize">
                                Delivered
                              </div>
                            </th>
                            <th>
                              <div className="leading-none text-sm text-left font-normal text-[#2E2B2E] text-opacity-70 capitalize">
                                Opens
                              </div>
                            </th>
                            <th>
                              <div className="leading-none text-sm text-left font-normal text-[#2E2B2E] text-opacity-70 capitalize">
                                Clicks
                              </div>
                            </th>
                            <th>
                              <div className="leading-none text-sm text-left font-normal text-[#2E2B2E] text-opacity-70 capitalize">
                                Bounces
                              </div>
                            </th>
                            <th>
                              <div className="leading-none text-sm text-left font-normal text-[#2E2B2E] text-opacity-70 capitalize">
                                Unsubscribes
                              </div>
                            </th>
                            <th>
                              <div className="leading-none text-sm text-left font-normal text-[#2E2B2E] text-opacity-70 capitalize"></div>
                            </th>
                            <HorizontalLine className="absolute -bottom-1 left-0" />
                          </tr>
                          {campaigns === [] && "No Data Available"}
                          {!showMoreEmailCampaign
                            ? campaigns.slice(0, 10).map((campaign, index) => {
                                const { sendGridReport } = campaign;
                                const report = sendGridReport ?? {
                                  bounce_drops: 0,
                                  bounces: 0,
                                  clicks: 0,
                                  delivered: 0,
                                  invalid_emails: 0,
                                  opens: 0,
                                  requests: 0,
                                  spam_report_drops: 0,
                                  spam_reports: 0,
                                  unique_clicks: 0,
                                  unique_opens: 0,
                                  unsubscribes: 0,
                                };
                                return (
                                  <tr
                                    key={index}
                                    className="font-[300] pt-6 text-[#2E2B2E]  text-[16px]"
                                  >
                                    <td className="text-[#2E2B2E] not-italic font-[400] ">
                                      {campaign.name}
                                    </td>
                                    <td>{campaign?.emailTemplateId?.name}</td>
                                    {campaignTableColumns.map((column) => (
                                      <td
                                        key={column.key}
                                        className="whitespace-nowrap p-4 text-sm text-gray-900 text-center"
                                      >
                                        {report[column.key]
                                          ? report[column.key]
                                          : column.default}
                                      </td>
                                    ))}
                                    {campaign?.sendGridCampaignId && (
                                      <td className="text-[#2E2B2E] not-italic font-[400] ">
                                        <button
                                          onClick={() => {
                                            handleDownloadReport(campaign._id);
                                          }}
                                          className="text-softBlack text-center bg-volt text-base font-medium py-2 px-6 flex items-center border border-volt"
                                        >
                                          {loadingReportId === campaign._id ? (
                                            <Loader />
                                          ) : (
                                            "Download"
                                          )}
                                        </button>
                                      </td>
                                    )}
                                  </tr>
                                );
                              })
                            : campaigns.map((campaign, index) => {
                                const { sendGridReport } = campaign;
                                const report = sendGridReport ?? {
                                  bounce_drops: 0,
                                  bounces: 0,
                                  clicks: 0,
                                  delivered: 0,
                                  invalid_emails: 0,
                                  opens: 0,
                                  requests: 0,
                                  spam_report_drops: 0,
                                  spam_reports: 0,
                                  unique_clicks: 0,
                                  unique_opens: 0,
                                  unsubscribes: 0,
                                };
                                return (
                                  <tr
                                    key={index}
                                    className="font-[300] pt-6 text-[#2E2B2E]  text-[16px]"
                                  >
                                    <td className="text-[#2E2B2E] not-italic font-[400] ">
                                      {campaign.name}
                                    </td>
                                    <td>{campaign?.emailTemplateId?.name}</td>
                                    {campaignTableColumns.map((column) => (
                                      <td
                                        key={column.key}
                                        className="whitespace-nowrap p-4 text-sm text-gray-900 text-center"
                                      >
                                        {report[column.key]
                                          ? report[column.key]
                                          : column.default}
                                      </td>
                                    ))}
                                    {campaign?.sendGridCampaignId && (
                                      <td className="text-[#2E2B2E] not-italic font-[400] ">
                                        <button
                                          onClick={() => {
                                            handleDownloadReport(campaign._id);
                                          }}
                                          className="text-softBlack text-center bg-volt text-base font-medium py-2 px-6 flex items-center border border-volt"
                                        >
                                          {loadingReportId === campaign._id ? (
                                            <Loader />
                                          ) : (
                                            "Download"
                                          )}
                                        </button>
                                      </td>
                                    )}
                                  </tr>
                                );
                              })}
                        </table>
                        <div className="flex mx-auto mt-[10px]">
                          <div
                            onClick={() => {
                              setShowMoreEmailCampaigns(!showMoreEmailCampaign);
                            }}
                            className="cursor-pointer flex gap-x-2 items-center mx-auto"
                          >
                            <div className="text-[#2E2B2E] font-[300]">
                              {!showMoreEmailCampaign
                                ? "Show more"
                                : "Show less"}
                            </div>
                            <div>
                              {!showMoreEmailCampaign ? (
                                <img src="/down-arrow.png" />
                              ) : (
                                <img src="/up-arrow.svg" />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ShadCNWrapper>
                )}
              </div>
            )}
            {selectedTab === "tab-5" && (
              <div className="w-full lg:flex-1 flex flex-col gap-y-6 h-full">
                <div className="flex flex-col gap-y-6">
                  <ShadCNWrapper>
                    <div className="w-[200px] flex flex-col gap-y-3 p-6">
                      <div className="text-[18px] font-bold">
                        Select Building
                      </div>
                      <CustomSelectBuilding
                        value={selectedBuilding}
                        handleChange={(newValue) =>
                          setSelectedBuilding(newValue)
                        }
                        options={buildingOptions}
                        placeholder="Select Building"
                      />
                    </div>
                    <div className="px-6">
                      <div className="text-xl font-bold p-3 border-b">
                        Report
                      </div>
                    </div>
                    <div className="p-6 grid grid-cols-10 col-span-10 gap-x-4">
                      <div className="col-span-10 flex flex-col gap-x-4">
                        <div className="h-full flex  flex-col gap-2 justify-start">
                          <h5 className="text-base uppercase font-semibold mb-0 truncate">
                            Parking Inventory
                          </h5>
                          <h2 className="text-3xl font-bold mb-0">
                            {totalParking || 0} Parking stalls
                          </h2>
                        </div>
                        <div className="flex flex-1 w-[100%] mt-6 flex-col gap-6">
                          <div className="h-[27px] flex w-full gap-2 items-center rounded-md overflow-hidden">
                            {parkingTypes.map((pt, index) => (
                              <div
                                key={index}
                                className="h-full"
                                style={{
                                  width: `${(pt.count / totalParking) * 100}%`,
                                  backgroundColor:
                                    statusColors[pt.type.toLowerCase()]
                                      ?.bgColor,
                                }}
                              />
                            ))}
                          </div>
                          <div className="flex flex-wrap items-center gap-y-2">
                            {parkingTypes.map((elm, index) => (
                              <div
                                key={index}
                                className={`flex flex-col gap-y-1 px-4 ${
                                  index !== 0
                                    ? "border-l border-[#2E2B2E] border-opacity-25"
                                    : ""
                                } overflow-hidden`}
                              >
                                <div className="flex gap-1">
                                  <svg
                                    className="mt-1"
                                    width="11"
                                    height="11"
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <circle
                                      cx="6"
                                      cy="6"
                                      r="6"
                                      fill={
                                        statusColors[elm.type.toLowerCase()]
                                          ?.bgColor
                                      }
                                    />
                                  </svg>
                                  <h6 className="truncate text-sm mb-0 font-medium transition-all">
                                    {statusColors[elm.type.toLowerCase()]?.name}
                                  </h6>
                                </div>
                                <p className="text-xl mb-0 font-bold">
                                  {elm?.count}
                                </p>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </ShadCNWrapper>
                  <div className="flex flex-col md:flex-row col-span-9 gap-6">
                    <ShadCNWrapper className="basis-1/2">
                      <div className="p-6 flex flex-col gap-y-2">
                        <h5 className="text-lg uppercase font-semibold mb-0 truncate">
                          Assignment PARKING - UNIT
                        </h5>
                        <div className="my-2 border-b border-zinc-200">
                          <ul className="flex">
                            <li className="basis-1/3 leading-none text-sm text-left font-normal text-[#2E2B2E] text-opacity-70 capitalize">
                              Parking Stall
                            </li>
                            <li className="basis-1/3 leading-none text-sm text-center font-normal text-[#2E2B2E] text-opacity-70 capitalize">
                              Status
                            </li>
                            <li className="basis-1/3 leading-none text-sm text-center font-normal text-[#2E2B2E] text-opacity-70 capitalize">
                              Unit
                            </li>
                          </ul>
                        </div>
                        <div>
                          {assignedParkingData === []
                            ? "No Data Available"
                            : !assignedParkingDataIsMore
                            ? assignedParkingData
                                .slice(0, 5)
                                .map((item, index) => {
                                  return (
                                    <ul key={index} className="flex">
                                      <li className="uppercase basis-1/3 text-[14px] font-semibold">
                                        Parking #{item.stall}
                                      </li>
                                      <li className="uppercase basis-1/3 text-center text-[14px] font-semibold">
                                        Assigned
                                      </li>
                                      <li className="uppercase basis-1/3 text-center text-[14px] font-semibold">
                                        {item?.unit?.unitNumber}
                                      </li>
                                    </ul>
                                  );
                                })
                            : assignedParkingData.map((item, index) => {
                                return (
                                  <ul key={index} className="flex">
                                    <li className="uppercase basis-1/3 text-[14px] font-semibold">
                                      Parking #{item.stall}
                                    </li>
                                    <li className="uppercase basis-1/3 text-center text-[14px] font-semibold">
                                      Assigned
                                    </li>
                                    <li className="uppercase basis-1/3 text-center text-[14px] font-semibold">
                                      {item?.unit?.unitNumber}
                                    </li>
                                  </ul>
                                );
                              })}
                        </div>
                        {assignedParkingData.length > 5 && (
                          <div className="flex mx-auto mt-[10px]">
                            <div
                              onClick={() => {
                                setAssignedParkingDataIsMore(
                                  !assignedParkingDataIsMore
                                );
                              }}
                              className="cursor-pointer flex gap-x-2 items-center mx-auto"
                            >
                              <div className="text-[#2E2B2E] font-[300]">
                                {!assignedParkingDataIsMore
                                  ? "Show more"
                                  : "Show less"}
                              </div>
                              <div>
                                {!assignedParkingDataIsMore ? (
                                  <img src="/down-arrow.png" />
                                ) : (
                                  <img src="/up-arrow.svg" />
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </ShadCNWrapper>
                    <ShadCNWrapper className="basis-1/2">
                      <div className="p-6 flex flex-col gap-y-2">
                        <h5 className="text-lg uppercase font-semibold mb-0 truncate">
                          Allocated
                        </h5>
                        <div className="my-2 border-b border-zinc-200">
                          <ul className="flex">
                            <li className="basis-1/3 leading-none text-sm text-left font-normal text-[#2E2B2E] text-opacity-70 capitalize">
                              unit
                            </li>
                            <li className="basis-1/3 leading-none text-sm text-center font-normal text-[#2E2B2E] text-opacity-70 capitalize">
                              Status
                            </li>
                            <li className="basis-1/3 leading-none text-sm text-center font-normal text-[#2E2B2E] text-opacity-70 capitalize">
                              Assignments
                            </li>
                          </ul>
                        </div>
                        <div>
                          {parkingDataByUnit === []
                            ? "No Data Available"
                            : !parkingByUnitIsMore
                            ? parkingDataByUnit
                                .slice(0, 5)
                                .map((item, index) => {
                                  return (
                                    <ul key={index} className="flex">
                                      <li className="uppercase basis-1/3 text-[14px] font-semibold">
                                        {item.unitNumber}
                                      </li>
                                      <li className="uppercase basis-1/3 text-center text-[14px] font-semibold">
                                        pending
                                      </li>
                                      <li className="uppercase basis-1/3 text-center text-[14px] font-semibold">
                                        {item?.parkings}
                                      </li>
                                    </ul>
                                  );
                                })
                            : parkingDataByUnit.map((item, index) => {
                                return (
                                  <ul key={index} className="flex">
                                    <li className="uppercase basis-1/3 text-[14px] font-semibold">
                                      {item.unitNumber}
                                    </li>
                                    <li className="uppercase basis-1/3 text-center text-[14px] font-semibold">
                                      pending
                                    </li>
                                    <li className="uppercase basis-1/3 text-center text-[14px] font-semibold">
                                      {item?.parkings}
                                    </li>
                                  </ul>
                                );
                              })}
                        </div>
                        {parkingDataByUnit.length > 5 && (
                          <div className="flex mx-auto mt-[10px]">
                            <div
                              onClick={() => {
                                setParkingByUnitIsMore(!parkingByUnitIsMore);
                              }}
                              className="cursor-pointer flex gap-x-2 items-center mx-auto"
                            >
                              <div className="text-[#2E2B2E] font-[300]">
                                {!parkingByUnitIsMore
                                  ? "Show more"
                                  : "Show less"}
                              </div>
                              <div>
                                {!parkingByUnitIsMore ? (
                                  <img src="/down-arrow.png" />
                                ) : (
                                  <img src="/up-arrow.svg" />
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </ShadCNWrapper>
                  </div>
                  {allParkings?.length > 0 ? (
                    <ShadCNWrapper className="p-6 flex flex-col gap-6">
                      {allParkings?.map((item) => (
                        <CustomParkingDetail key={item._id} parking={item} />
                      ))}
                    </ShadCNWrapper>
                  ) : (
                    <NotFoundView text="Parkings not Found" />
                  )}
                </div>
              </div>
            )}
          </div>
          {selectedTab === "tab-3" && (
            <ShadCNWrapper className="-mt-8">
              <ReservationDemandView projectId={projectId} />
            </ShadCNWrapper>
          )}
          <Drawer
            open={isContactDrawerOpen}
            variant="persistent"
            anchor="right"
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className="pt-16 relative h-full">
              <button
                onClick={() => {
                  setIsContactDrawerOpen(false);
                  setSelectedContact(null);
                  setSelectedContactObject({});
                }}
                className="absolute left-[1.25rem] z-[10] top-[5.5rem]"
              >
                <ChevronLeft className="w-5 h-5" />
              </button>
              {selectedContact && selectedContactObject?._id && (
                <ContactItem showBackButton={false} />
              )}
            </div>
          </Drawer>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CorporateDashboard);
