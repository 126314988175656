// @ts-nocheck
import { PageHeader } from 'components'
import React, { useEffect, useState } from 'react'
import { EmailEditor } from 'react-email-editor'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import CreateEmailCampaign from './CreateEmailCampaign'
import { createOrUpdateEmailTemplates, getEmailTemplates } from 'store/actions/emailTemplateActions'
import SendTestEmail from './SendTestEmail'
import { EyeIcon, SendHorizonal } from 'lucide-react'
import MergeFieldsAndTemplates from './MergeFieldsAndTemplates'
import EmailTemplatePreview from '../TemplatePreview'

const availableMergeFields = [
  {
    key: 'firstName',
    label: 'First Name',
    sample: 'John'
  },
  {
    key: 'lastName',
    label: 'Last Name',
    sample: 'Doe'
  },
  {
    key: 'email',
    label: 'Email',
    sample: 'example@gmail.com'
  },
  {
    key: 'buyerData.city',
    label: 'Buyer City',
    sample: 'New York'
  },
  {
    key: 'buyerData.province',
    label: 'Buyer Province',
    sample: 'New York'
  },
  {
    key: 'buyerData.country',
    label: 'Buyer Country',
    sample: 'United States'
  },
  {
    key: 'buyerData.phoneNumber',
    label: 'Buyer Phone Number',
    sample: '+1234567890'
  },
  {
    key: 'buyerData.prefix',
    label: 'Buyer Prefix',
    sample: 'Mr'
  },
  {
    key: 'buyerData.occupation',
    label: 'Buyer Occupation',
    sample: 'Developer'
  },
  {
    key: 'buyerData.address',
    label: 'Buyer Address',
    sample: '123 Main St'
  },
  {
    key: 'buyerData.postalCode',
    label: 'Buyer Postal Code',
    sample: '12345'
  },
  {
    key: 'buyerData.legalName',
    label: 'Buyer Legal Name',
    sample: 'John Doe'
  },
  {
    key: 'buyerData.birthday',
    label: 'Buyer Birthday',
    sample: '1990-06-05'
  },
  {
    key: 'buyerData.placeOfBirth',
    label: 'Buyer Place of Birth',
    sample: 'New York'
  },
  {
    key: 'buyerData.maritalStatus',
    label: 'Buyer Marital Status',
    sample: 'Single'
  },
  {
    key: 'buyerData.nationality',
    label: 'Buyer National Status',
    sample: 'United States'
  }
]

const mergeTags = availableMergeFields.reduce((acc, field) => {
  acc[field.key] = {
    ...field,
    key: `{{${field.key}}}`
  }
  return acc
}, {})

const EditEmailTemplate = () => {
  const history = useHistory()
  const { templateId } = useParams()
  const [template, setTemplate] = useState()
  const [isSliderOpen, setIsSliderOpen] = useState(false)
  const [isEmailsPreviewOpen, setIsEmailsPreviewOpen] = useState(false)
  const [isSendTestEmailOpen, setIsSendTestEmailOpen] = useState(false)
  const [emailEditor, setEmailEditor] = useState()
  useEffect(() => {
    getEmailTemplates(templateId)
      .then((response) => setTemplate(response.data))
      .catch((error) => console.log(error))
  }, [])

  const editTemplate = () => {
    emailEditor?.exportHtml((data) => {
      createOrUpdateEmailTemplates({
        ...template,
        unlayerConfig: data
      })
        .catch((error) => console.log(error))
    })
  }

  const onLoad = (editor) => {
    setEmailEditor(editor)
    if (!template) return
    if (!template.unlayerConfig) return
    if (!template.unlayerConfig.design) return
    editor.loadDesign(template.unlayerConfig.design)
    editor.setMergeTags(mergeTags)
  }

  const RightContent = () => (
    <>
      <button
        className='text-softBlack text-center text-base font-medium py-2 px-6 rounded flex items-center focus:ring-0 hover:bg-softBlack30 transition-all'
        onClick={() => history.goBack()}
      >
        Go back
      </button>
      <button
        className='text-softBlack text-center text-base font-medium py-2 px-6 rounded flex items-center border border-black'
        onClick={() => setIsSliderOpen(true)}
      >
        Create Campaign
      </button>
      <button
        className='gap-x-1 text-softBlack text-center text-base font-medium py-2 px-3.5 rounded flex items-center border border-black'
        onClick={() => setIsSendTestEmailOpen(true)}
      >
        <SendHorizonal size={16} />
        <span>
          Test
        </span>
      </button>
      <button
        className='gap-x-1 text-softBlack text-center text-base font-medium py-2 px-3.5 rounded flex items-center border border-black'
        onClick={() => {
          setIsEmailsPreviewOpen(true)
        }}
      >
        <EyeIcon size={16} />
        <span>
          Preview
        </span>
      </button>
      <button
        className='flex justify-center items-center font-bold p-3 px-6 bg-volt rounded cursor-pointer'
        onClick={editTemplate}
      >
        Save
      </button>
    </>
  )

  return (
    <>
      <div className='flex flex-col relative h-full'>
        <PageHeader
          title={template?.name ? `Edit Email Template: ${template?.name}` : 'Edit Email Template'}
          rightContent={<RightContent />}
        />
        <div className='flex flex-row h-full'>
          <MergeFieldsAndTemplates mergeTags={availableMergeFields} />
          {template && (
            <EmailEditor
              options={{
                displayMode: 'email',
                mergeTags: {
                  'board.name': {
                    name: 'Board Name',
                    value: '{{board.name}}',
                    sample: '{{board.name}}'
                  }
                }
              }}
              onReady={() => {
                console.log('onReady')
              }}
              onLoad={onLoad}
              appearance={{
                theme: 'modern_light'
              }}
            />
          )}
        </div>
      </div>
      <CreateEmailCampaign onClose={() => setIsSliderOpen(false)} open={isSliderOpen} />
      <SendTestEmail
        templateId={templateId}
        onClose={() => setIsSendTestEmailOpen(false)} open={isSendTestEmailOpen} />
      <EmailTemplatePreview selectedTemplate={template} open={isEmailsPreviewOpen} onClose={() => {
        setIsEmailsPreviewOpen(false)
      }} />
    </>
  )
}

export default EditEmailTemplate
