/* eslint-disable */
// @ts-nocheck

import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { AnimatePresence } from 'framer-motion'
import { pdfjs } from 'react-pdf'
import { Divider, Row } from 'antd'
import { ZoomInIcon, ZoomOutIcon } from '@heroicons/react/solid'
import { FadedAnimatedDiv, LottieWrapper } from 'components'
import loadingContractsAnimation from 'assets/lottieJsons/loadingDoc.json'
import PdfDocument from './components/PdfDocument'
import ContractThumbnail from './components/ContractThumbnail'
import ContractsCounterAndFilter from './components/ContractsCounterAndFilter'
import { classNames } from 'utils'
import {
  ContractsWrapper,
  ListOfContractsWrapper,
  ContractsCarouselWrapper,
  ContractWrapper,
  ZoomControlWrapper,
  ZoomIconWrapper,
  LeftWrapper,
  RightWrapper,
  DocumentBtnWrapper,
  PreviewHeader
} from './styles'
import ContractSignedPanel from './components/ContractSignedPanel'
import { DocusealForm } from '@docuseal/react'
import { getDocuSealDocumentPreview } from 'store/actions/reservationActions'
// import Button from 'components/Buttons'

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export const isSelectedContractSignedByUser = (selectedContract, userRole) => {
  if (!selectedContract) {
    return false
  }
  const isSinged =
    selectedContract?.signatures?.filter(
      (signature) =>
        signature.userType === userRole &&
        signature.status === 'signed'
    )?.length > 0
  return isSinged
}

const CustomButton = ({ children, buttonBGColor = null, ...rest }) => (
  <button
    // style={primaryBtnStyle}
    className={classNames(
      'italic font-light px-6 py-4 border text-sm text-softBlack m-auto flex items-center justify-center rounded',
      buttonBGColor ?? 'bg-volt'
    )}
    {...rest}
  >
    {children}
  </button>
)

const ReservationDocuments = (props) => {
  const {
    onGettingContracts,
    executeWhenAllDocsSigned,
    userObject,
    isBtnShow = true,
    setCloseDocument = () => { },
    setPrimaryBtnAction = null,
    isPublicPath = false,
    reservationId
  } = props
  const [gettingContracts, setGettingContracts] = useState(false)
  const [originalListOfContracts, setOriginalListOfContracts] = useState()
  const [listOfContracts, setListOfContracts] = useState()
  const [selectedContract, setSelectedContract] = useState()
  const [docuSignUrl, setDocuSignUrl] = useState('')
  const [pdfPageNumber, setPdfPageNumber] = useState(1)
  const [pdfScale, setPdfScale] = useState(1.2)
  const [isSigning, setIsSigning] = useState(null)
  const [needsToSignDocument, setNeedsToSignDocument] = useState(false)
  const [selectedContractSignatureId, setSelectedContractSignatureId] =
    useState('')
  const [docsAnalyzedCounter, setDocsAnalyzedCounter] = useState(0)
  const [allDocsSigned, setAllDocsSigned] = useState(false)
  const [loading, setLoading] = useState(false)

  const getLoggedInUserType = useMemo(() => {
    return userObject.role
  }, [userObject])

  useEffect(() => {
    let _needsToSign = false
    let _docuSealUrl = ''
    let _signatureId = ''

    if (!selectedContract?.mustSign) {
      _needsToSign = false
      _docuSealUrl = ''
      _signatureId = ''
    }

    if (selectedContract?.signatures?.length) {
      const userDocuments = selectedContract?.signatures.filter(
        (signature) =>
          signature.userType === getLoggedInUserType &&
          signature.status === 'awaiting_signature'
      )

      if (userDocuments && userDocuments.length) {
        const { url, signatureId } = userDocuments[0]

        _needsToSign = true
        _docuSealUrl = url
        _signatureId = signatureId
      }
    }

    setNeedsToSignDocument(_needsToSign)
    setDocuSignUrl(_docuSealUrl)
    setSelectedContractSignatureId(_signatureId)
  }, [selectedContract, userObject])

  useEffect(() => {
    const userDocs = listOfContracts
      ?.filter?.((contract) => {
        const signatureRequires =
          contract?.signatures?.filter(
            (signature) => signature.userType === getLoggedInUserType
          ).length > 0
        return contract.mustSign && signatureRequires
      })
      ?.map((contract) => {
        const tmpContract = { ...contract }
        tmpContract.signed = false
        const contractSigned = tmpContract.signatures?.filter(
          (signature) =>
            signature.userType === getLoggedInUserType &&
            signature.status === 'signed'
        )
        tmpContract.signed = Boolean(contractSigned.length)
        return tmpContract
      })
    const pendingDocs = Boolean(userDocs?.filter((doc) => !doc.signed).length)
    const allDocsAnalyzed = listOfContracts?.length === docsAnalyzedCounter

    if (listOfContracts?.length && !pendingDocs && allDocsAnalyzed) {
      setAllDocsSigned(true)
      if (executeWhenAllDocsSigned) executeWhenAllDocsSigned()
    }
  }, [
    listOfContracts,
    userObject,
    docsAnalyzedCounter,
    executeWhenAllDocsSigned
  ])

  const _getOfferContracts = useCallback(() => {
    if (reservationId) {
      setGettingContracts(true)
      getDocuSealDocumentPreview(reservationId)
        .then((response) => {
          const { contracts } = response
          if (contracts?.length) {
            setOriginalListOfContracts(contracts)
            setListOfContracts(contracts)
            setSelectedContract(contracts[0])
          }
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          setGettingContracts(false)
        })
    }
  }, [reservationId, userObject, isPublicPath])

  useEffect(() => {
    _getOfferContracts()
  }, [_getOfferContracts])

  useEffect(() => {
    onGettingContracts && onGettingContracts(gettingContracts)
  }, [gettingContracts, onGettingContracts])

  const manageNumberOfPagesList = useCallback(
    ({ contractTemplateId, numPages }) => {
      const tmpListOfContracts = listOfContracts?.map?.((contract) => {
        const tmpContract = { ...contract }

        if (tmpContract.contractTemplateId === contractTemplateId) {
          tmpContract.pdfNumPages = numPages
        }

        return tmpContract
      })

      // This counter will say if the all of the documents were analyzed to collect the number of pages
      setDocsAnalyzedCounter(docsAnalyzedCounter + 1)
      setListOfContracts(tmpListOfContracts)

      // Must not update the originalLIst
      // if was already updated at the first time
      if (originalListOfContracts[0].pdfNumPages === undefined) {
        setOriginalListOfContracts(tmpListOfContracts)
      }
    },
    [listOfContracts, originalListOfContracts, docsAnalyzedCounter]
  )

  const isPrimaryBuyerSingedSelectedContract = useMemo(() => {
    if (isPublicPath) {
      return isSelectedContractSignedByUser(selectedContract, userObject.role)
    } else {
      return isSelectedContractSignedByUser(selectedContract, 'primaryBuyer')
    }
  }, [selectedContract, isPublicPath, userObject])

  const isSecondaryBuyerSingedSelectedContract = useMemo(() => {
    if (isPublicPath) {
      return isSelectedContractSignedByUser(selectedContract, userObject.role)
    } else {
      return isSelectedContractSignedByUser(selectedContract, 'secondaryBuyer')
    }
  }, [selectedContract, isPublicPath, userObject])

  const setDocSigned = useCallback(() => {
    let contractToSelect = {}
    const tmpContracts = listOfContracts?.map?.((contract) => {
      const tmpContract = { ...contract }
      if (
        contract.contractTemplateId === selectedContract?.contractTemplateId
      ) {
        tmpContract.signatures = tmpContract?.signatures?.map((signature) => {
          const tmpSignature = { ...signature }

          if (
            tmpSignature.status === 'awaiting_signature' &&
            tmpSignature.userType === isSigning
          ) {
            tmpSignature.status = 'signed'
          }

          return tmpSignature
        })
        tmpContract.signed = true

        contractToSelect = { ...tmpContract }
      }
      return tmpContract
    })

    setSelectedContract(contractToSelect)
    setListOfContracts(tmpContracts)
    setOriginalListOfContracts(tmpContracts)
    setIsSigning(null)
    if (isPublicPath) {
      setCloseDocument()
    }
  }, [listOfContracts, selectedContract, isSigning])

  const onSelectDoc = useCallback((docProps) => {
    setPdfPageNumber(1)
    setIsSigning(null)
    setSelectedContract(docProps)
  }, [])

  const onSignDocumentClicked = useCallback(
    async (role) => {
      if (selectedContract) {
        setIsSigning(role)
        try {
          const userDocuments = selectedContract?.signatures.filter(
            (signature) => signature.userType === role
          )
          if (userDocuments && userDocuments.length) {
            const { url } = userDocuments[0]
            setDocuSignUrl(url)
          }
        } catch (err) {
          console.error(err)
        }
      }
    },
    [selectedContract]
  )
  const isAllSigned = useMemo(() => {
    return listOfContracts?.every((contract) => {
      return contract.signatures?.every((signature) =>
        signature.userType === 'Buyer'
          ? signature.status === 'signed'
          : isPublicPath || signature.status === 'signed'
      )
    })
  }, [listOfContracts, isPublicPath])

  const isAllSingedForSelectedContact = isPrimaryBuyerSingedSelectedContract && isSecondaryBuyerSingedSelectedContract

  const onSignNextContractClicked = useCallback(() => {
    if (!listOfContracts?.length) {
      return
    }
    const nextContractToSign =
      listOfContracts?.filter((contract) => {
        return (
          contract.signatures?.some((signature) =>
            isPublicPath
              ? signature.status !== 'signed' &&
              signature.userType === 'Buyer'
              : signature.status !== 'signed'
          ) ?? null
        )
      })?.[0] ?? null
    if (nextContractToSign) {
      onSelectDoc(nextContractToSign)
    }
  }, [listOfContracts, isPublicPath])
  return (
    <AnimatePresence exitBeforeEnter initial={false}>
      {gettingContracts && (
        <FadedAnimatedDiv
          key='loadingContracts'
          className='flex flex-col justify-center items-center h-full'
        >
          <LottieWrapper
            animation={loadingContractsAnimation}
            loop
            autoplay
            className='max-w-lg'
          />
          <span className='text-xl text-center'>
            We are now working to show you the contracts generated for this
            Offer
          </span>
        </FadedAnimatedDiv>
      )}
      {!gettingContracts && (
        <FadedAnimatedDiv
          key='contracts'
          className='h-full'
          style={{ overflowY: 'hidden' }}
        >
          <ContractsWrapper>
            <LeftWrapper xs={24} sm={24} md={6} lg={6}>
              <ContractsCounterAndFilter
                listOfContracts={listOfContracts}
                originalListOfContracts={originalListOfContracts}
                onFilter={setListOfContracts}
              />
              <ListOfContractsWrapper>
                {listOfContracts?.length &&
                  listOfContracts?.map?.((contract, index) => {
                    return (
                      <ContractThumbnail
                        key={index}
                        contractData={contract}
                        index={index}
                        selected={
                          (contract?._id &&
                            contract._id === selectedContract._id) ||
                          (contract?.contractId &&
                            contract.contractId === selectedContract.contractId)
                        }
                        onSelectDoc={onSelectDoc}
                        onChangePdfPage={setPdfPageNumber}
                        onLoadSuccess={manageNumberOfPagesList}
                        isPublicPath={isPublicPath}
                      />
                    )
                  })}
              </ListOfContractsWrapper>
            </LeftWrapper>
            <RightWrapper>
              <PreviewHeader>Contract Preview</PreviewHeader>
              {!isAllSingedForSelectedContact && (
                <Row
                  justify='start'
                  style={{
                    width: '100%',
                    position: 'sticky',
                    top: 0,
                    right: 0,
                    zIndex: 10
                  }}
                >
                  <ZoomControlWrapper>
                    {!isSigning?.length && (
                      <>
                        <ZoomIconWrapper
                          onClick={() => setPdfScale(pdfScale + 0.1)}
                        >
                          <ZoomInIcon className='h-8 w-8' />
                        </ZoomIconWrapper>
                        <Divider
                          style={{ height: '60%', background: 'white' }}
                          type='vertical'
                        />
                        <ZoomIconWrapper
                          onClick={() => setPdfScale(pdfScale - 0.1)}
                        >
                          <ZoomOutIcon className='h-8 w-8' />
                        </ZoomIconWrapper>
                      </>
                    )}
                  </ZoomControlWrapper>
                </Row>
              )}
              <ContractsCarouselWrapper>
                <AnimatePresence
                  exitBeforeEnter
                  initial={false}
                  className='absolute'
                >
                  {isSigning?.length && docuSignUrl && (
                    <FadedAnimatedDiv
                      style={{ height: '100%', width: '100%' }}
                      key='hellosignAnimatedDiv'
                    >
                      <DocusealForm
                        src={docuSignUrl}
                        withTitle={false}
                        withDownloadButton={false}
                        withSendCopyButton={false}
                        allowToResubmit={false}
                        onComplete={() => {
                          setDocSigned()
                          setIsSigning(null)
                        }}
                      />
                    </FadedAnimatedDiv>
                  )}
                  {!isSigning?.length && (
                    <FadedAnimatedDiv
                      key='pdfPreview'
                      style={{ height: '100%', width: '100%' }}
                    >
                      <ContractWrapper>
                        {isAllSingedForSelectedContact && (
                          <ContractSignedPanel
                            contract={selectedContract}
                            onCloseScreenClicked={() => {
                              if (isAllSigned) {
                                setCloseDocument()
                              }
                            }}
                            isAllSigned={isAllSigned}
                            onSignNextContractClicked={() => {
                              if (!isAllSigned) {
                                onSignNextContractClicked()
                              }
                            }}
                          />
                        )}
                        {!isAllSingedForSelectedContact && (
                          <PdfDocument
                            pageProps={{ scale: pdfScale }}
                            documentProps={{
                              file: selectedContract?.url
                            }}
                            pageNumber={pdfPageNumber}
                          />
                        )}
                      </ContractWrapper>
                    </FadedAnimatedDiv>
                  )}
                </AnimatePresence>
              </ContractsCarouselWrapper>
              <DocumentBtnWrapper>
                {isBtnShow && (
                  <>
                    {!isPublicPath && !isSigning?.length && (
                      <>
                        {
                          !isPrimaryBuyerSingedSelectedContract &&
                          <CustomButton
                            onClick={() => onSignDocumentClicked('primaryBuyer')}
                            disabled={loading}
                          >
                            Sign as Primary Buyer
                          </CustomButton>
                        }
                        {
                          !isSecondaryBuyerSingedSelectedContract &&
                          <CustomButton
                            onClick={() => onSignDocumentClicked('secondaryBuyer')}
                            disabled={loading}
                          >
                            Sign as Secondary Buyer
                          </CustomButton>
                        }
                      </>
                    )}
                    {isPublicPath && !isSigning?.length && !isPrimaryBuyerSingedSelectedContract && (
                      <>
                        <CustomButton
                          onClick={() => onSignDocumentClicked(userObject.role)}
                          disabled={loading}
                        >
                          Sign now
                        </CustomButton>
                      </>
                    )}
                    {isSigning?.length && !isPrimaryBuyerSingedSelectedContract && (
                      <CustomButton
                        onClick={() => setIsSigning(null)}
                        disabled={loading}
                      >
                        Back to Document Viewer
                      </CustomButton>
                    )}
                    {isPublicPath && !isAllSigned && (
                      <CustomButton
                        onClick={setCloseDocument}
                        disabled={loading}
                      >
                        Go Back
                      </CustomButton>
                    )}
                  </>
                )}
              </DocumentBtnWrapper>
            </RightWrapper>
          </ContractsWrapper>
        </FadedAnimatedDiv>
      )}
    </AnimatePresence>
  )
}

export default ReservationDocuments
