// @ts-nocheck
/*eslint-disable */

import PdfDocument from 'components/OfferDocuments/components/PdfDocument'
import React, { useState } from 'react'
import parkingContract from '../../../assets/parking-contract-demo.pdf'
import { classNames } from 'utils'
import { message } from 'antd'
import PaymentTypeModal from './PaymentTypeModal'
import PaymentMethodModal from './PaymentMethodModal'

const CustomButton = ({ children, buttonBGColor = null, ...rest }) => (
  <button
    className={classNames(
      'italic font-light px-6 py-4 border text-sm text-softBlack flex items-center justify-center rounded',
      buttonBGColor ?? 'bg-volt'
    )}
    {...rest}
  >
    {children}
  </button>
)

const SignContract = ({ setStep }) => {
  const [showPaymentTypeModal, setShowPaymentTypeModal] = useState(false)
  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false)
  const [paymentMethodId, setPaymentMethodId] = useState(null)
  const [successfulPayment, setSuccessfulPayment] = useState(false)

  const onBack = () => setStep((prev) => prev - 1)

  const onNext = () => {
    setStep((prev) => prev + 1)
  }

  return (
    <div>
      <div className="pb-4 border-b-2 border-softBlack">
        <p className="my-0 text-xl font-bold">Sign Contract</p>
      </div>
      <div className="mt-6 z-[-1] h-[550px] overflow-y-auto">
        <PdfDocument
          pageProps={{ scale: 1.2 }}
          documentProps={{
            file: parkingContract
          }}
          pageNumber={1}
        />
      </div>
      <div className="flex justify-center gap-4 mt-6">
        <button
          className="py-3 px-6 text-base border border-softBlack text-softBlack rounded font-medium"
          onClick={onBack}
        >
          Back
        </button>
        <CustomButton
          onClick={() => {
            message.success('Working on it (testing)')
          }}
          buttonBGColor={'bg-blue-500'}
        >
          Send to Developer
        </CustomButton>
        <CustomButton
          onClick={() => {
            message.success('Working on it (testing)')
          }}
          buttonBGColor={'bg-blue-500'}
        >
          Developer Sign
        </CustomButton>
        <CustomButton
          onClick={() => setShowPaymentTypeModal(true)}
        >
          Take Payment
        </CustomButton>
      </div>
      <PaymentTypeModal
        {...{
          showPaymentTypeModal,
          setShowPaymentTypeModal,
          setShowPaymentMethodModal,
          setPaymentMethodId,
          setSuccessfulPayment
        }}
      />
      <PaymentMethodModal
        {...{
          showPaymentMethodModal,
          setShowPaymentMethodModal,
          paymentMethodId,
          successfulPayment,
          setSuccessfulPayment
          // setShowPaymentModal
        }}
      />
    </div>
  )
}

export default SignContract
