/*eslint-disable */
// @ts-nocheck
import React from 'react';
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DownloadIcon,
} from '@heroicons/react/outline';
import useContactContext from 'pages/newContacts/hooks/useContactContext';
import { isMobile } from 'react-device-detect';
import { FiDownload } from 'react-icons/fi';
import { Select, Tooltip } from 'antd';
import { Filter, ListIcon, RefreshCw } from 'lucide-react';
import { FilterIcon } from '@heroicons/react/solid';
import { BiSort } from 'react-icons/bi';
import { ReactComponent as DownIcon } from 'assets/icons/down.svg';
import useContactContextV2 from 'pages/newContacts/hooks/useContactContextV2';
import { set } from 'lodash';
// import FilterDrawer from 'pages/newContacts/FilterDrawer/FilterDrawer';
import FilterBox from 'pages/newContacts/FilterComponent/FilterBox';
import FilterDrawer from 'pages/newContacts/FilterDrawer/FilterDrawer';
import useGetLists from 'hooks/useGetLists';
import { placeholder } from 'glamor';
import { DeleteOutlined } from '@ant-design/icons';
import { Modal } from '@material-ui/core';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import CustomButton from 'components/CustomButton';
import { updateUserDeleteStatusMultiple } from 'store/actions/usersActions';

const TablePaginationV2Bottom = ({ customContacts = null, contacts }) => {
  const {
    state,
    setFilters,
    page,
    setPage,
    LIMIT,
    openDownloadContactsModal,
    openCreateContactModal,
    applyBuyerFilter,
    applyBrokerFilter,
    applyDefaultFilter,
    openFilterDrawer,
    toggleFilterDrawer,
    openAddToListDrawer,
    openAssignSalesRep,
    refetchFilteredUser,
    emptyCheckedSelections,
  } = useContactContext();
  const [tempTest, setTempTest] = React.useState('All');
  const [tempPageValue, setTempPageValue] = React.useState(1);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const getEndLimitForPages = () =>
    Math.ceil(state?.totalRecords / state.filters.limit);
  const { lists, isLoading, refetch } = useGetLists();
  const toPreviousPage = () => {
    if (page <= 1) return;
    setPage(page - 1);
    setFilters({ ...state.filters, page: page - 2 });
    if (state.search === '') {
      setFilters({ ...state.filters, page: page - 2 });
    } else {
      setSearch(state.search, { ...state.filters, page: page - 2 });
    }
  };

  const toNextPage = () => {
    if (page * state.filters.limit >= state?.totalRecords) return;
    setPage(page + 1);
    setFilters({ ...state.filters, page });
    if (state.search === '') {
      setFilters({ ...state.filters, page });
    } else {
      setSearch(state.search, { ...state.filters, page });
    }
  };

  const toSpecificPage = (pageNumber) => {
    setPage(pageNumber);
    if (state.search === '') {
      setFilters({ ...state.filters, page: pageNumber - 1, limit: 10 });
    } else {
      setSearch(state.search, {
        ...state.filters,
        page: pageNumber - 1,
      });
    }
  };

  const toFirstPage = () => {
    setPage(1);
    setFilters({ ...state.filters, page: 0 });
  };

  const toLastPage = () => {
    setPage(getEndLimitForPages());
    setFilters({
      ...state.filters,
      page: getEndLimitForPages() - 1,
    });
  };
  const isValidPage = (pageNumber) => pageNumber > 0;

  const openDeleteContactsModal = () => {
    setOpenDeleteModal(true);
  };

  const handleSoftDelete = async () => {
    let contactIds = Object.keys(state.checkedContacts).filter(
      (key) => state.checkedContacts[key] === true
    );
    try {
      const response = await updateUserDeleteStatusMultiple(contactIds).then(
        () => {
          setOpenDeleteModal(false);
          refetchFilteredUser();
        }
      );
    } catch (error) {
      console.log('Error in deleting user', error);
    }
  };

  return (
    <div className='flex flex-row gap-y-4 flex-wrap px-6 py-4 bg-white rounded-tb-[8px] border-[rgb(46,43,46,0.4)] gap-x-4 justify-between items-center'>
      <div className=''>
        <nav
          className='isolate inline-flex items-center gap-x-5 rounded-[8px] '
          aria-label='Pagination'
        >
          {(!state.isContactDetailDrawerOpen || isMobile) &&
            !state.isAddToListDrawerOpen && (
              <div className='text-sm text-gray-700 '>
                Showing{' '}
                <span className='font-medium'>
                  {page * state.filters.limit >= state?.totalRecords
                    ? state?.totalRecords
                    : page * state.filters.limit}
                </span>{' '}
                of <span className='font-medium'>{state?.totalRecords}</span>{' '}
              </div>
            )}
          <div className='flex items-center gap-x-3'>
            <div className='text-sm text-gray-700'>Page Size: </div>
            <div className='min-w-[80px]'>
              <select
                value={state.filters.limit}
                className='h-[38px] font-bold border-gray-300 shadow-sm rounded-[8px]'
                onChange={(e) => {
                  setFilters({
                    ...state.filters,
                    page: 0,
                    limit: e.target.value,
                  });
                  setPage(1);
                }}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={500}>500</option>
              </select>
            </div>
          </div>

          <a
            onClick={toFirstPage}
            className='relative inline-flex items-center rounded-[8px] border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-black hover:bg-gray-50 focus:z-20'
          >
            <Tooltip title='First Page'>
              <span className='sr-only'>First</span>
              <ChevronDoubleLeftIcon className='h-5 w-5' aria-hidden='true' />
            </Tooltip>
          </a>
          <div className='flex items-center'>
            <a
              onClick={toPreviousPage}
              className='relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-black hover:bg-gray-50 focus:z-20'
            >
              <span className='sr-only'>Previous</span>
              <ChevronLeftIcon className='h-5 w-5' aria-hidden='true' />
            </a>
            <div className='relative h-[38px] w-[38px] inline-flex items-center border-[1px] border-gray-300 bg-white text-sm font-medium text-black hover:bg-gray-50 focus:z-20'>
              <input
                onChange={(e) => {
                  const value = parseInt(e.target.value, 10);
                  if (
                    isNaN(value) ||
                    value < 1 ||
                    value > getEndLimitForPages()
                  )
                    return;
                  setFilters({
                    ...state.filters,
                    page: value - 1,
                  });
                  setPage(value);
                }}
                value={page}
                type='text'
                className='border-none !mb-0 p-1 text-sm flex text-center items-center justify-center'
              />
            </div>
            <a
              onClick={toNextPage}
              className='relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-black hover:bg-gray-50 focus:z-20'
            >
              <span className='sr-only'>Next</span>
              <ChevronRightIcon className='h-5 w-5' aria-hidden='true' />
            </a>
          </div>
          <a
            onClick={toLastPage}
            className='relative inline-flex items-center rounded-[8px] border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-black hover:bg-gray-50 focus:z-20'
          >
            <Tooltip title='Last Page'>
              <span className='sr-only'>Last</span>
              <ChevronDoubleRightIcon className='h-5 w-5' aria-hidden='true' />
            </Tooltip>
          </a>
        </nav>
      </div>
    </div>
  );
};

export default TablePaginationV2Bottom;
