// @ts-nocheck

import React, { useContext, useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import CustomRadio from 'components/CustomRadio'

import LineText from './LineText'
import UpgradeAndOptionsCard from 'components/UpgradeAndOptionsCard'
import useGetProjectUpgradeAndOptions from 'hooks/useGetProjectUpgradeAndOptions'
import { CreateOfferContext } from '../context/CreateOfferContext'

const SelectStorage = ({ setStep }) => {
  const { selectedUnit, selectedUpgrades, setSelectedUpgrades } =
    useContext(CreateOfferContext)

  const [purchaseUpgrade, setPurchaseUpgrade] = useState(true)
  const { loading, upgrades } = useGetProjectUpgradeAndOptions()
  const [unitUpgradeQty, setUnitUpgradeQty] = useState(0)
  const [unitUpgradeType, setUnitUpgradeType] = useState('')

  const onBack = () => setStep((prev) => prev - 1)

  const onNext = () => {
    ;((onlySelectedUpgrades().length > 0 && purchaseUpgrade) ||
      !purchaseUpgrade) &&
      setStep((prev) => prev + 1)
  }

  const onlySelectedUpgrades = () =>
    selectedUpgrades.filter((upgrade) => upgrade.isSelected)

  const onChangeUpgradeOption = (value) => setPurchaseUpgrade(value)

  const onUpdateCheck = (id, value) => {
    const updatedData = selectedUpgrades.map((upgrade) =>
      upgrade._id === id
        ? { ...upgrade, isSelected: value, count: value ? 1 : 0 }
        : upgrade
    )
    setSelectedUpgrades(updatedData)
  }

  const onIncreaseCount = (id, value) => {
    const updatedData = selectedUpgrades.map((upgrade) =>
      upgrade._id === id
        ? { ...upgrade, count: value, isSelected: value }
        : upgrade
    )
    setSelectedUpgrades(updatedData)
  }

  const onDecreaseCount = (id, value) => {
    if (value >= 0) {
      const updatedData = selectedUpgrades.map((upgrade) =>
        upgrade._id === id
          ? { ...upgrade, count: value, isSelected: value }
          : upgrade
      )
      setSelectedUpgrades(updatedData)
    }
  }

  useEffect(() => {
    if (selectedUnit.floorPlan) {
      const { UpgradeQty, UpgradeType } = selectedUnit.floorPlan

      setUnitUpgradeQty(UpgradeQty)
      setUnitUpgradeType(UpgradeType)
    }
  }, [selectedUnit])

  useEffect(() => {
    if (selectedUpgrades.length === 0) {
      const temp = upgrades.map((upgrade) => ({
        ...upgrade,
        isSelected: false,
        count: 0
      }))
      setSelectedUpgrades(temp)
    }
  }, [upgrades])

  return (
    <div>
      <div className='pb-4 border-b-2 border-softBlack'>
        <p className='my-0 text-xl font-bold'>Storage</p>
      </div>
      <div className='mt-10'>
        <h2 className='font-medium text-lg'>
          {Number(unitUpgradeQty) > 0
            ? `Your Purchase of ${selectedUnit.unitNumber} includes ${unitUpgradeQty}
            ${unitUpgradeType}. Would you like to purchase another storage?`
            : 'Your Unit has no Storage included. Would you like to purchase a upgrade?'}
        </h2>
        <div className='flex gap-10 mt-6'>
          <CustomRadio
            label='Yes'
            checked={purchaseUpgrade}
            onClick={() => onChangeUpgradeOption(true)}
          />
          <CustomRadio
            label='No'
            checked={!purchaseUpgrade}
            onClick={() => onChangeUpgradeOption(false)}
          />
        </div>
      </div>
      {purchaseUpgrade && (
        <>
          <LineText title='select upgrades' />

          <div className='grid grid-cols-2 xl:grid-cols-3 gap-6'>
            {loading &&
              Array.from(Array(3).keys()).map((a, index) => (
                <Skeleton key={index} height={280} width='100%' />
              ))}
            {!loading &&
              selectedUpgrades.map((upgrade, index) => (
                <UpgradeAndOptionsCard
                  key={index}
                  checked={selectedUpgrades[index].isSelected}
                  setChecked={() => {
                    onUpdateCheck(upgrade._id, !upgrade.isSelected)
                  }}
                  count={selectedUpgrades[index].count}
                  increaseCount={() => {
                    onIncreaseCount(upgrade._id, upgrade.count + 1)
                  }}
                  decreaseCount={() => {
                    onDecreaseCount(upgrade._id, upgrade.count - 1)
                  }}
                  isActive={false}
                  checkboxTitle={'Add Storage'}
                  id={upgrade._id}
                  description={upgrade.description}
                  imgSrc={upgrade.images?.[0]}
                  price={upgrade.value}
                  title={upgrade.name}
                />
              ))}
          </div>
        </>
      )}
      <div className='flex justify-between mt-32'>
        <button
          className='py-3 px-6 text-base border border-softBlack text-softBlack rounded font-medium'
          onClick={onBack}
        >
          Back
        </button>
        <button
          className={
            (onlySelectedUpgrades().length > 0 && purchaseUpgrade) ||
            !purchaseUpgrade
              ? 'py-3 px-6 text-base bg-volt rounded font-medium'
              : 'py-3 px-6 text-base bg-volt rounded font-medium opacity-50'
          }
          onClick={onNext}
        >
          Next
        </button>
      </div>
    </div>
  )
}

export default SelectStorage
