import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { ADMIN_PARKING_SALES_AND_WAIT_LIST } from 'routes'

import CancelModal from './CancelModal'
import { H1 } from 'ui'

const Title = ({ children }) => (
  <H1 className='mb-0 font-bold text-3xl '>{children}</H1>
)

const Header = () => {
  const history = useHistory()
  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false)

  const onCancel = () => {
    setIsOpenCancelModal(true)
  }

  // const onSaveExit = () => {
  //   setIsOpenCancelModal(false)
  //   history.push(ADMIN_OFFERS)
  // }

  const onConfirm = () => {
    setIsOpenCancelModal(false)
    history.push(ADMIN_PARKING_SALES_AND_WAIT_LIST)
  }

  return (
    <>
      <div
        className='bg-menuGray fixed flex justify-between items-center mx-auto px-4 sm:px-6 lg:px-8 h-[100px] border-b-2 border-softBlack'
        style={{ width: 'calc(100% - 88px)' }}
      >
        <Title>Parking Sales & Waitlist</Title>
        <div className='flex flex-row gap-3'>
          {/* <button
            className='text-base font-medium px-4 py-2 rounded border border-softBlack70'
            onClick={onSaveExit}
          >
            Save & Exit
          </button> */}
          <button
            className='text-base font-medium px-4 py-2 rounded border border-softBlack70'
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
      </div>
      <CancelModal
        {...{ isOpenCancelModal, setIsOpenCancelModal, onConfirm }}
      />
    </>
  )
}

export default Header
