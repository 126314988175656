/* eslint-disable no-unused-vars */
import { Drawer, makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import useContactContext from '../hooks/useContactContext'
import { IoMdClose } from 'react-icons/io'
import { isMobile } from 'react-device-detect'
import { useSelector } from 'react-redux'
import { getUsers, updateSalesRepBulk } from 'store/actions/usersActions'
import CustomSelect from 'components/CustomSelect'
import toast from 'react-hot-toast'

const ROUND_ROBIN_ALGORITHM = 'round-robin-algorithm'

const AssignSalesRepDrawer = () => {
  // @ts-ignore
  const projectId = useSelector((state) => state.appReducer.appProject)
  const { state, closeAssignSalesRep } = useContactContext()
  const [width80, setWidth80] = useState(window.innerWidth)
  const [salesReps, setSalesReps] = useState([])
  const [selectedSalesRep, setSelectedSalesRep] = useState('')
  const [updating, setUpdating] = useState(false)

  useEffect(() => {
    setWidth80(window.innerWidth < 1400 ? window.innerWidth * 0.25 : window.innerWidth * 0.25)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth])

  const getSalesReps = () => {
    getUsers('', '?userType=SalesRep')
      .then((users) => {
        const salesReps = users.map((user) => ({ id: user._id, value: `${user.firstName} ${user.lastName}` }))
        setSalesReps([
          {
            id: ROUND_ROBIN_ALGORITHM,
            value: '(Assign using Round Robin)'
          }, ...salesReps
        ])
      })
  }

  const assignSelectedSalesRep = () => {
    // assign sales rep to contacts
    const checkedContacts = state.checkedContacts
    const contactIds = Object.keys(checkedContacts).filter((key) => checkedContacts[key])
    setUpdating(true)
    updateSalesRepBulk({
      salesRep: selectedSalesRep,
      contacts: contactIds,
      projectId
    }).then(() => {
      getSalesReps()
      // show success message
      toast.success('Sales Rep assigned successfully')
    }).finally(() => {
      setSelectedSalesRep('')
      setUpdating(false)
      closeAssignSalesRep()
    })
  }

  useEffect(() => {
    getSalesReps()
  }, [projectId])

  const useStyles = makeStyles((theme) => ({
    hide: {
      display: 'none'
    },
    drawer: {
      width: isMobile ? '100%' : width80,
      flexShrink: 0
    },
    drawerPaper: {
      width: isMobile ? '100%' : width80,
      height: '100%'
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end'
    }
  }))

  const classes = useStyles()
  const checkedContacts = state.checkedContacts
  const contactIds = Object.keys(checkedContacts).filter((key) => checkedContacts[key])
  return (
    <Drawer
      open={state.isAssignSalesRepDrawerOpen}
      variant="persistent"
      anchor='right'
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <div className='h-screen mt-16 relative overflow-hidden w-full'>
        <button onClick={() => {
          closeAssignSalesRep()
        }} className='absolute top-3 right-5'><IoMdClose fontSize="22px" /></button>
        <div className='h-full w-full flex flex-row p-5'>
          <div className='flex flex-1 flex-col gap-4 w-full'>
            <div className='text-3xl font-bold'>
              Assign to Sales Rep
            </div>
            <div>
              Total <span className='font-bold text-xl'>{contactIds.length}</span> contacts selected
            </div>
            <div className='flex w-full'>
              <CustomSelect
                label={'Choose Sales Rep to Assign'}
                options={salesReps}
                selectedOption={selectedSalesRep}
                setSelectedOption={setSelectedSalesRep}
                labelClasses={'w-full'}
                inputClasses={undefined}
                defaultValue={undefined}
              />
            </div>
            <div>
              {selectedSalesRep === ROUND_ROBIN_ALGORITHM &&
                <div className='text-sm text-gray-500'>
                  <span className='font-bold pr-1'>{contactIds.length}</span>Contacts will be assigned to sales reps using the Round Robin algorithm.
                </div>
              }
            </div>
            <div>
              {selectedSalesRep && <button
                disabled={updating}
                onClick={assignSelectedSalesRep} className='bg-gray-800 text-white py-2 px-4 rounded-md'>
                {updating ? 'Assigning...' : 'Assign'}
              </button>}
            </div>
          </div>
        </div>
      </div>
    </Drawer >
  )
}

export default AssignSalesRepDrawer
