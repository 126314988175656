// @ts-nocheck
/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react';
import AddOrEditSingleContact from 'components/AddOrEditContact';
import AddOrEditBulkContact from 'components/AddBulkContact';
import ContactEditMethodModal from 'components/ContactEditMethodModal';
import { useHistory } from 'react-router-dom';
import AddReferralAgent from 'components/AddReferralAgent/AddReferralAgent';
import useContactContext from 'pages/newContacts/hooks/useContactContext';
import DownloadContactModal from 'components/DownloadContactModal';
import ReportWaitingModal from 'components/ReportDownloadWaitingModal';
import { useSelector } from 'react-redux';
import {
  bindChannel,
  subscribeToChannel,
  unbindChannel,
  unsubscribeToChannel,
} from 'services/pusher';

const ContactModalV2 = () => {
  const history = useHistory();
  const { refetch, state, closeDownloadContactsModal, closeCreateContactModal, waitingModal, setWaitingModal } = useContactContext();
  const projectId = useSelector((state) => state.appReducer.appProject)
  const [isOpenContactEditMethodModal, setIsOpenContactEditMethodModal] =
    useState(false);
  const [isOpenCreateSingleContactModal, setIsOpenCreateSingleContactModal] =
    useState(false);
  const [singleContactModalType, setSingleContactModalType] = useState(null);
  const [isOpenCreateBulkContactModal, setIsOpenCreateBulkContactModal] =
    useState(false);
  const [isOpenReferralAgentModal, setIsOpenReferralAgentModal] =
    useState(false);
  const [isDownloadContactsModal, setIsDownloadContactsModal] = useState(false);
  const userId = useSelector((state) => state.authReducer.userObject);

  const openContactEditMethodModal = () => {
    setIsOpenContactEditMethodModal(true);
  };

  const openContactsDownloadModal = () => {
    setIsDownloadContactsModal(true);
  };

  const openCreateSingleContactModal = (type) => {
    setSingleContactModalType(type);
    setIsOpenCreateSingleContactModal(true);
  };

  const onCreateSingleContact = (_id) => {
    if (history) {
      history.push(`/admin/contacts?_id=${_id}&tab=activity`,);
    }
  };

  const onCreateBulkContact = () => {
    setIsOpenCreateBulkContactModal(true);
  };

  // const onNewCallBackReport = useCallback((newCall) => {
  //   if (newCall?.status === 'success') {
  //     setWaitingModal(false);
  //   }
  // },[setWaitingModal, userId?._id]);

  // useEffect(() => {
  //   let closeChannel = null;

  //   try {
  //     closeChannel = subscribeToChannel(`report-${userId?._id}`);
  //     if (closeChannel) {
  //       bindChannel(closeChannel, onNewCallBackReport);
  //     }
  //   } catch (error) {
  //     console.error('Error subscribing to channel:', error);
  //   }

  //   return () => {
  //     try {
  //       if (closeChannel) {
  //         unbindChannel(closeChannel, onNewCallBackReport);
  //       }
  //       unsubscribeToChannel(`report-${userId?._id}`);
  //     } catch (error) {
  //       console.error('Error unsubscribing from channel:', error);
  //     }
  //   };
  // }, [onNewCallBackReport, userId?._id]);

  return (
    <>
      {state.createContactModal && (
        <ContactEditMethodModal
          show={state.createContactModal}
          setShow={closeCreateContactModal}
          onMethodSelected={(selectedMethod) => {
            if (selectedMethod === 'referral') {
              setIsOpenReferralAgentModal(true);
            } else if (selectedMethod === 'LeadBroker') {
              openCreateSingleContactModal('LeadBroker');
            } else if (selectedMethod === 'Buyer') {
              openCreateSingleContactModal('Buyer');
            } else if (selectedMethod === 'bulk') {
              onCreateBulkContact();
            }
          }}
        />
      )}
      {isOpenCreateBulkContactModal && (
        <AddOrEditBulkContact
          project={projectId}
          show={isOpenCreateBulkContactModal}
          setShow={setIsOpenCreateBulkContactModal}
          onSuccess={refetch}
        />
      )}
      {isOpenReferralAgentModal && (
        <AddReferralAgent
          show={isOpenReferralAgentModal}
          setShow={setIsOpenReferralAgentModal}
          contact={null}
          onSuccess={''}
        />
      )}
      {isOpenCreateSingleContactModal && (
        <AddOrEditSingleContact
          show={isOpenCreateSingleContactModal}
          setShow={setIsOpenCreateSingleContactModal}
          userType={singleContactModalType}
          contact={null}
          onSuccess={onCreateSingleContact}
          invalideCacheOnUpdate
        />
      )}
      {state.downloadContactsModal && (
        <DownloadContactModal
          show={state.downloadContactsModal}
          handleClose={closeDownloadContactsModal}
          setWaitingModal={setWaitingModal}
        />
      )}
      {/* <button
        className='w-1/2 md:w-[129px] h-[35px] md:h-[35px] px-3 py-2 bg-volt rounded justify-center items-center gap-2 inline-flex'
        onClick={openContactEditMethodModal}
      >
        <span className='text-zinc-800 text-sm font-semibold leading-[21px]'>
          +<span className='ml-2 font-medium italic'>Add Contact</span>
        </span>
      </button> */}
      {/* <button
        className='w-1/2 md:w-[200px] h-[35px] md:h-[45px] px-3 py-2 bg-volt rounded justify-center items-center gap-2 inline-flex'
        onClick={openContactsDownloadModal}
      >
        <span className='text-zinc-800 text-sm font-semibold leading-[21px]'>
          <span className='ml-2 font-medium italic'>Download Contacts</span>
        </span>
      </button> */}
    </>
  );
};

export default ContactModalV2;
