// @ts-nocheck
/* eslint-disable */
import React, { useState } from 'react'
import { Modal } from '@material-ui/core'
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'
import UseCSV from '@usecsv/react'
import useContactContext from 'pages/newContacts/hooks/useContactContext'

const dynamicColumnsForBuyer = [
  {
    name: 'firstName',
    displayName: 'First Name',
    example: 'John',
    // mustBeMatched: true,
    // valueCannotBeBlank: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'lastName',
    displayName: 'Last Name',
    example: 'Doe',
    // mustBeMatched: true,
    // valueCannotBeBlank: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'email',
    displayName: 'Email',
    example: 'john@example.com',
    // mustBeMatched: true,
    // valueCannotBeBlank: true,
    validationFormat: { type: 'email' },
  },
  {
    name: 'project',
    displayName: 'Project',
    example: 'Project X',
    // mustBeMatched: true,
    // valueCannotBeBlank: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'userType',
    displayName: 'User Type',
    example: 'Buyer',
    // mustBeMatched: true,
    // valueCannotBeBlank: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'phoneNumber',
    displayName: 'Phone Number',
    example: '123-456-7890',
    // mustBeMatched: true,
    // valueCannotBeBlank: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'leadSource',
    displayName: 'Lead Source',
    example: 'Source',
    // mustBeMatched: true,
    // valueCannotBeBlank: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'leadBroker',
    displayName: 'Lead Broker',
    example: 'Broker',
    // mustBeMatched: true,
    // valueCannotBeBlank: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'salesRep',
    displayName: 'Sales Rep',
    example: 'Rep',
    // mustBeMatched: true,
    // valueCannotBeBlank: true,
    validationFormat: { type: 'string' }
  }
]

const AddBulkContact = ({ show, onSuccess, setShow, project }) => {
  const [isCSVModalDisplay, setIsCSVModalDisplay] = useState(false)
  const { view } = useContactContext()
  const renderButton = (openModal) => {
    return (
      <button
        className={`bg-volt p-2 px-4 text-lg rounded font-medium ${isCSVModalDisplay ? 'hidden' : 'block'}`}
        onClick={() => {
          openModal()
          setIsCSVModalDisplay(true)
        }}
      >
        Start importing bulk contacts
      </button>
    )
  }

  const handleClose = () => {
    setShow(false)
    setIsCSVModalDisplay(false)
    onSuccess()
  }

  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby='Add Or Edit Bulk Contact Modal'
      aria-describedby='Add Or Edit Bulk Contact description'
    >
      <div
        className='relative flex flex-col font-openSans bg-grey-8 p-10 rounded shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
        style={{ width: 'calc(100% - 50px)', height: 'calc(100% - 50px)' }}
      >
        <button className='absolute right-10' onClick={handleClose}>
          <CrossIcon />
        </button>
        <h1 className='font-bold text-xl text-center'>Bulk Upload Contacts</h1>
        <div className='relative w-full h-full flex justify-center items-center'>
          <UseCSV
            importerKey={process.env.REACT_APP_BULK_CONTACT_IMPORTER_KEY}
            dynamicColumns={dynamicColumnsForBuyer}
            render={(openModal) => renderButton(openModal)}
            importerDisplay='inline'
            onClose={handleClose}
            metadata={{
              project: view === 'project' ? project : null,
            }}
          />
          {
            isCSVModalDisplay &&
            <div
              style={{
                position: 'relative',
                width: '100%',
                height: '100%',
                marginTop: '20px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              id='usecsv-importer-inline-wrapper'
            />
          }
        </div>
      </div>
    </Modal>
  )
}

export default AddBulkContact