/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import { StartExternalProviderConfiguration } from 'components'
import { getUserEmails, getUserThreads } from 'store/actions/usersActions'
import { sanitizeCode } from 'utils'
import EmailModal from 'pages/contacts/EmailModal'
import { isMobile } from 'react-device-detect'
import EmailsCard from 'components/EmailCard/version_2'
import DashboardProvider from 'pages/dashboard/context/DashboardContext'

const EmailInbox = (props) => {
  const [showEmailBox, setShowEmailBox] = useState(false)
  const [gettingListOfEmails, setGettingListOfEmails] = useState(false)
  const [listOfEmails, setListOfEmails] = useState([])
  const [composingEmailSubject, setComposingEmailSubject] = useState('')
  const [composingEmailRecipient, setComposingEmailRecipient] = useState([])
  const [composingEmailBody, setComposingEmailBody] = useState('')
  const [selectedEmailIndex, setSelectedEmailIndex] = useState(0)
  const [selectedThreadId, setSelectedThreadId] = useState('')
  const [selectedThreadContent, setSelectedThreadContent] = useState(null)
  const [gettingThreadContent, setGettingThreadContent] = useState(false)
  const [showMessageList, setShowMessageList] = useState(isMobile)
  const [searchFilter, setSearchFilter] = useState('')
  const refLocalListOfEmails = useRef(listOfEmails)
  const [selectedEmail, setSelectedEmail] = useState({})

  const updateListOfEmails = (newState) => {
    refLocalListOfEmails.current = newState
    setListOfEmails(newState)
  }

  const {
    userObject: {
      providerToken
    }
  } = props

  useEffect(() => {
    if (selectedEmailIndex !== -1 && refLocalListOfEmails.current) {
      const selectedEmailThreadId =
        refLocalListOfEmails.current[selectedEmailIndex]?.messages?.[0]
          ?.threadId ?? null
      setSelectedThreadId(selectedEmailThreadId)
    }
  }, [selectedEmailIndex])

  const getEmails = useCallback(() => {
    if (selectedThreadId) {
      setGettingThreadContent(true)
      getUserEmails({ id: selectedThreadId, search: searchFilter })
        .then(({ data }) => {
          setSelectedThreadContent({
            subject: data[0].subject,
            senderName: `${data[0].from[0].name && data[0].from[0].name}`,
            senderEmail: data[0].from[0].email,
            items: data.map((email, index) => {
              const emailDate = new Date(email.createdAt * 1000)
              return ({
                id: index,
                messageId: email.id,
                snippet: email.snippet,
                threadId: email.threadId,
                author: email.from[0].name || 'Unknown sender',
                date: `${emailDate.toLocaleDateString('en-US', { weekday: 'long' })} at ${emailDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })}`,
                datetime: email?.date || email?.createdAt,
                body: sanitizeCode(email.body),
                files: email.attachments
              })
            })
          })
          setGettingThreadContent(false)
        })
        .catch(() => setGettingThreadContent(false))
    }
  }, [selectedThreadId])

  useEffect(getEmails, [selectedThreadId])

  const getThreads = useCallback((manually, withQuery) => {
    setGettingListOfEmails(true)
    getUserThreads({ offset: manually ? 0 : refLocalListOfEmails.current.length, limit: 50, search: (withQuery ? searchFilter : '') })
      .then((newEmails) => {
        let currentEmails = []

        if (!manually) {
          currentEmails = [...refLocalListOfEmails.current, ...newEmails.data]
        } else {
          currentEmails = [...newEmails.data]
        }

        const listOfEmails = currentEmails.map((email) => ({
          lastMessageTimestamp: email.last_message_timestamp || email.latestMessageReceivedDate,
          messages: [email.latestDraftOrMessage],
          snippet: email.snippet,
          subject: email.subject
        }))
        setGettingListOfEmails(false)
        updateListOfEmails(listOfEmails)

        if (listOfEmails.length) {
          setSelectedEmailIndex(-1)
          setTimeout(() => {
            setSelectedEmailIndex(0)
          }, 500)
        }
      })
      .catch(() => setGettingListOfEmails(false))
  }, [searchFilter])

  useEffect(() => {
    if (providerToken) {
      getThreads(true, true)
    }
  }, [getThreads, providerToken])

  const childProps = {
    selectedEmailIndex,
    setSelectedEmailIndex,
    selectedThreadContent,
    setSelectedThreadContent,
    composingEmailSubject,
    setComposingEmailSubject,
    showMessageList,
    setShowMessageList,
    isMobile,
    refLocalListOfEmails,
    gettingListOfEmails,
    getEmails,
    selectedThreadId,
    setSelectedThreadId,
    showEmailBox,
    setShowEmailBox,
    gettingThreadContent,
    setGettingThreadContent,
    composingEmailBody,
    setComposingEmailBody,
    composingEmailRecipient,
    setComposingEmailRecipient,
    listOfEmails,
    searchFilter,
    setSearchFilter,
    showFilterOption: false,
    showLoadMore: false,
    selectedEmail,
    setSelectedEmail
  }

  return (
    <DashboardProvider>
      {showEmailBox && <EmailModal show={showEmailBox} handleClose={() => setShowEmailBox(false)} contact={composingEmailRecipient ? { firstName: composingEmailRecipient?.[0]?.name, email: composingEmailRecipient?.[0]?.email } : null} />}
      {
        !providerToken
          ? <StartExternalProviderConfiguration pageName='Inbox' />
          : <>
            {listOfEmails?.length > 0 && <EmailsCard childProps={childProps} showUserInfo />}
          </>
      }
    </DashboardProvider>
  )
}

const mapStateToProps = state => ({
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(EmailInbox)

EmailInbox.propTypes = {
  userObject: propTypes.shape({
    providerToken: propTypes.string,
    email: propTypes.string,
    firstName: propTypes.string,
    lastName: propTypes.string
  })
}
