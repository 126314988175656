/* eslint-disable no-unused-vars */
// @ts-nocheck
import React, { useContext, useState } from 'react'

import LargeUnitCard from 'components/LargeUnitCard'
import UserInfoCard from 'components/UserInfoCard'
import PurchaseParkingSuccessModal from './PurchaseParkingSuccessModal'

import { PurchaseParkingContext } from '../context/PurchaseParkingContext'
import { formatMoney } from 'utils/formatters'
import standardStyle from 'assets/css/standardStyle'

import { message } from 'antd'
import { MessageModal } from 'components/MessageModal'
import { Modal } from '@material-ui/core'
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'
import AdditionalBuyerItem from './AdditionalBuyerItem'
import PaymentTypeModal from './PaymentTypeModal'
import { isMobile } from 'react-device-detect'
import PaymentMethodModal from './PaymentMethodModal'
import ParkingDocuments from './ParkingDocuments'

const white = '#FCFCFA'
const SelectReview = ({
  setStep,
  contextUsed = true,
  purchaseParkingObject = {}
}) => {
  const {
    selectedUnit,
    selectedBuyers,
    selectedParkings,
    selectedUpgrades,
    additionalBuyers
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = contextUsed ? useContext(PurchaseParkingContext) : purchaseParkingObject
  const [loading, setLoading] = useState(false)
  const [isSuccessModal, setIsSuccessModal] = useState(false)
  const [parkingRes, setParkingRes] = useState({
    status: 'ParkingPurchase',
    newStatus: 'Prepared',
    buyerIncentives: [],
    contracts: [
      '65d49d9aecf014acec1e16cc',
      '65d49dd91071c555674ebfcd',
      '65d49df01071c555674ebfe8'
    ]
  })
  const [showDocSignMessageModal, setShowDocSignMessageModal] = useState(false)
  const [showDocumentsModal, setShowDocumentsModal] = useState(false)
  const [parkingId, setParkingId] = useState('')
  const [showPaymentTypeModal, setShowPaymentTypeModal] = useState(false)
  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false)
  const [paymentMethodId, setPaymentMethodId] = useState(null)
  const [successfulPayment, setSuccessfulPayment] = useState(false)

  const onPurchaseParking = async () => {
    const parkingObj = {
      unit: selectedUnit._id,
      buyer: selectedBuyers?.map((_buyer) => _buyer.id),
      totalCost: totalPrice(),
      // paymentRounds: [
      //   {
      //     days: 7,
      //     perc: 10,
      //     value: totalPrice() * 0.1,
      //     type: 'Card'
      //   }
      // ],
      options: [...parkingOptions(), ...upgradeOptions()]
    }
    console.log('parkingObj: ', parkingObj)
    setIsSuccessModal(true)

    // try {
    //   setLoading(true)
    //   const response = await createOrUpdateOffer(offerObj, false)
    //   serOfferRes(response)
    //   setOfferId(response._id)
    //   setIsSuccessModal(true)
    //   setLoading(false)
    // } catch (err) {
    //   setLoading(false)
    //   console.log('===>> err', err)
    //   message.error('there is some issue to create offer')
    // }
  }

  const totalPrice = () => {
    const parkingsPriceArr = selectedParkings
      .filter((_parking) => _parking.isSelected)
      ?.map((_parking1) => _parking1.value)

    const priceArr = selectedUpgrades
      .filter((_upgrade) => _upgrade.isSelected)
      ?.map((_upgrade1) => _upgrade1.value)

    const parkingPrice = parkingsPriceArr.reduce((prev, curr) => prev + curr, 0)
    const upgradePrice = priceArr.reduce((prev, curr) => prev + curr, 0)
    return (
      selectedUnit?.price + parkingPrice + upgradePrice || 0
    )
  }

  const parkingOptions = () => {
    const parkings = selectedParkings
      .filter((_parking) => _parking.isSelected)
      .map((_parking1) => {
        const { isSelected, count, ...remValues } = _parking1
        return remValues
      })
    return parkings
  }

  const upgradeOptions = () => {
    const upgrades = selectedUpgrades
      .filter((_upgrade) => _upgrade.isSelected)
      .map((_upgrade1) => {
        const { isSelected, count, ...remValues } = _upgrade1
        return remValues
      })
    return upgrades
  }

  const onGoToContract = () => {
    setIsSuccessModal(false)
    setShowDocumentsModal(true)
  }

  const onBack = () => setStep((prev) => prev - 1)

  return (
    <div>
      <PurchaseParkingSuccessModal
        {...{
          open: isSuccessModal,
          setOpen: setIsSuccessModal,
          onConfirm: onGoToContract
        }}
      />
      {contextUsed && (
        <div className='pb-4 border-b-2 border-softBlack'>
          <p className='my-0 text-xl font-bold'>Review</p>
        </div>
      )}

      {/* Unit Details */}
      <div className='my-10'>
        <p className='text-softBlack font-bold' style={{ fontSize: 22 }}>
          Unit Details
        </p>
        <LargeUnitCard
          imgsrc={selectedUnit?.floorPlan?.image}
          unitName={`#${selectedUnit?.unitNumber}`}
          noOfBedrooms={selectedUnit?.numberOfBedrooms}
          noOfBathrooms={selectedUnit?.numberOfBathrooms}
          balcony={selectedUnit?.balcony}
          noOfParkingSlot={selectedUnit?.floorPlan?.parkingQty}
          building={selectedUnit?.building?.name}
          price={selectedUnit?.price}
        />
      </div>

      {selectedParkings.filter((_parking) => _parking.isSelected).length >
        0 && (
          <div>
            <div className='w-full border-b-2 pb-0'></div>

            {/* Parking Details */}
            <div className='my-10'>
              <p className='text-softBlack font-bold' style={{ fontSize: 22 }}>
                Parking
              </p>
              {selectedParkings
                .filter((_parking) => _parking.isSelected)
                .map((parking, index) => (
                  <div
                    className={
                      selectedParkings.length === index ? 'pb-0' : 'pb-8'
                    }
                  >
                    <LargeUnitCard
                      key={parking._id}
                      imgsrc={parking.images[0]}
                      unitName={parking.name}
                      description={parking.description}
                      price={parking?.value}
                    />
                  </div>
                ))}
            </div>
          </div>
      )}
      {selectedUpgrades.filter((_upgrade) => _upgrade.isSelected).length >
        0 && (
          <div>
            <div className='w-full border-b-2 pb-0'></div>

            {/* Upgrade Options Details */}
            <div className='my-10'>
              <p className='text-softBlack font-bold' style={{ fontSize: 22 }}>
                Upgrades
              </p>
              {selectedUpgrades
                .filter((_upgrade) => _upgrade.isSelected)
                .map((_pgrade, index) => (
                  <div
                    className={
                      selectedUpgrades.length === index ? 'pb-0' : 'pb-8'
                    }
                  >
                    <LargeUnitCard
                      key={_pgrade._id}
                      imgsrc={_pgrade.images[0]}
                      unitName={_pgrade.name}
                      description={_pgrade.description}
                      price={_pgrade?.value}
                    />
                  </div>
                ))}
            </div>
          </div>
      )}

      <div className='w-full border-b-2 pb-0'></div>

      {/* Buyer Details */}
      <div className='my-10'>
        <p className='text-softBlack font-bold' style={{ fontSize: 22 }}>
          Buyer
        </p>
        <div className='flex gap-4 flex-wrap'>
          {selectedBuyers?.map((buyer) => (
            <UserInfoCard
              key={buyer?._id}
              firstName={buyer?.firstName}
              lastName={buyer?.lastName}
              email={buyer?.email}
              selected={false}
              userAvatar={buyer?.userAvatar}
              showSelectedRing={false}
              className={'w-72'}
              backgroundColor={contextUsed === false && white}
            />
          ))}
        </div>
        {Object.keys(additionalBuyers?.[0] ?? {})?.length > 0 && (
          <AdditionalBuyerItem
            onChange={() => { }}
            editMode={false}
            buyerTag={'Buyer 2 '}
            defaultData={additionalBuyers?.[0]}
          />
        )}
        {Object.keys(additionalBuyers?.[1] ?? {})?.length > 0 && (
          <AdditionalBuyerItem
            onChange={() => { }}
            editMode={false}
            buyerTag={'Buyer 3 '}
            defaultData={additionalBuyers[1]}
          />
        )}
      </div>
      <div className='w-full border-b-2 pb-0'></div>

      <p className='font-bold mt-10 text-softBlack' style={{ fontSize: 22 }}>
        Total Value
      </p>

      <div className='flex justify-between items-center gap-4 m-0 p-0'>
        <p className='text-2xl p-0 m-0 font-bold text-softBlack'>
          {formatMoney(totalPrice())}
        </p>
        <div className='flex gap-6'>
          <button
            className='flex items-center justify-center rounded-md font-medium'
            style={{
              backgroundColor: standardStyle.colors.softBlack_10,
              width: 113,
              height: 48,
              fontStyle: 'italic'
            }}
            disabled={loading}
            onClick={onBack}
          >
            Go Back
          </button>
          <button
            className='flex items-center justify-center rounded-md font-medium'
            style={{ backgroundColor: '#EDDF65', width: 143, height: 48 }}
            onClick={onPurchaseParking}
            disabled={loading}
          >
            Purchase Parking
          </button>
        </div>
      </div>
      <Modal
        open={showDocumentsModal}
        onClose={() => setShowDocumentsModal(false)}
        aria-labelledby='Parking Documents'
        aria-describedby='Parking documents list'
      >
        <div
          className={`font-openSans relative bg-grey-8 py-0 rounded shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-[calc(100%-5px)] overflow-auto ${isMobile ? 'w-full' : 'w-[calc(100%-5px)]'
            }`}
        >
          <button
            className='absolute right-9 top-9'
            style={{ right: '2rem', top: '2rem' }}
            onClick={() => setShowDocumentsModal(false)}
          >
            <CrossIcon />
          </button>
          <ParkingDocuments
            contracts={parkingRes?.contracts}
            btnsShow={true}
            setCloseDocument={setShowDocumentsModal}
            setPrimaryBtnAction={setShowDocSignMessageModal}
            parkingId={parkingId}
          />
        </div>
      </Modal>
      <MessageModal
        show={showDocSignMessageModal}
        setShow={setShowDocSignMessageModal}
        title='Success'
        message={'All Documents signed'}
        primaryBtn={true}
        primaryBtnText={'Proceed to payment'}
        setPrimaryBtnAction={(value) => setShowPaymentTypeModal(value)}
      />
      <PaymentTypeModal
        {...{
          showPaymentTypeModal,
          setShowPaymentTypeModal,
          setShowPaymentMethodModal,
          setPaymentMethodId,
          setSuccessfulPayment
        }}
      />
      <PaymentMethodModal
        {...{
          showPaymentMethodModal,
          setShowPaymentMethodModal,
          paymentMethodId,
          successfulPayment,
          setSuccessfulPayment
          // setShowPaymentModal
        }}
      />
    </div>
  )
}

export default SelectReview
