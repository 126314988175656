import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { ADMIN_OFFERS } from 'routes'

import CancelModal from './CancelModal'

const Header = () => {
  const history = useHistory()
  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false)

  const onCancel = () => {
    setIsOpenCancelModal(true)
  }

  const onSaveExit = () => {
    setIsOpenCancelModal(false)
    history.push(ADMIN_OFFERS)
  }

  const onConfirm = () => {
    setIsOpenCancelModal(false)
    history.push(ADMIN_OFFERS)
  }

  return (
    <>
      <div className='bg-white w-full fixed flex justify-between items-center px-8 py-5 border-b-2 border-softBlack'>
        <p className='my-0 text-4xl font-bold'>Buy Unit</p>
        <div className='flex flex-row gap-3'>
          <button
            className='text-base font-medium px-4 py-2 rounded border border-softBlack70'
            onClick={onSaveExit}
          >
            Save & Exit
          </button>
          <button
            className='text-base font-medium px-4 py-2rounded border border-softBlack70'
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
      </div>
      <CancelModal
        {...{ isOpenCancelModal, setIsOpenCancelModal, onConfirm }}
      />
    </>
  )
}

export default Header
