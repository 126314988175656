/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable */
// @ts-nocheck
import React, { useState, useEffect } from 'react'
import PhoneInput from 'react-phone-number-input'
import * as Yup from 'yup'

import { ReactComponent as CalendarIcon } from 'assets/icons/calender.svg'
import { getProjects } from 'store/actions/projectActions'
import { useSelector } from 'react-redux'

const CustomInput = ({
  label,
  value,
  onChange,
  placeholder = '',
  containerClasses = '',
  labelClassName = '',
  classes = '',
  onBlur = () => {},
  errorMessage = '',
  error = false,
  isDarkFont = false,
  disabled = false,
  disabledClassName = 'opacity-40',
  ...props
}) => {
  const [isShowError, setIsShowError] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isRangeError, setIsRangeError] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState('')
  const projectId = useSelector((state) => state.appReducer.appProject)
  useEffect(()=>{
    getProjects(projectId).then((data) => {
      let countryCode = data?.totalDollarValueCurrencyType.slice(0, 2)
      setSelectedCountry(countryCode.toUpperCase())
    })
  },[projectId])
  useEffect(() => {
    handleValidation()
  }, [value, isShowError])

  const handleValidation = async () => {
    if (isShowError && props?.type === 'email') {
      const isEmailValid = await Yup.string().email().isValid(value)
      setIsError(!isEmailValid)
    } else if (isShowError && props?.required === 'required') {
      let isValid = true
      if (props.type === 'number') {
        if (props.min) {
          isValid = await Yup.number().min(props.min).isValid(value)
          setIsRangeError(!isValid)
        }
        if (props.max) {
          isValid = await Yup.number().max(props.max).isValid(value)
          setIsRangeError(!isValid)
        }
        if (value === '') {
          isValid = await Yup.number().isValid(value)
          setIsError(!isValid)
        } else {
          isValid = await Yup.number().isValid(value)
          setIsError(!isValid)
        }
      } else {
        isValid = await Yup.string().min(1).isValid(value)
        setIsError(!isValid)
      }
    }
  }

  const handleBlur = () => {
    if (!isShowError) {
      setIsShowError(true)
    }
    onBlur?.()
  }

  useEffect(() => {
    if (error) {
      setIsShowError(true)
      handleValidation()
      onBlur?.()
    } else {
      setIsShowError(false)
    }
  }, [error])
  return (
    <div className={`text-left relative ${containerClasses} ${disabled ? disabledClassName : 'opacity-100'}`}>
      {label?.length > 0 && (
        <p
          className={`mt-0 mb-2 ${isDarkFont ? 'text-softBlack' : 'text-softBlack70'} text-sm font-light uppercase ${labelClassName}`}
          style={{ letterSpacing: '0.16px' }}
        >
          {label}
        </p>
      )}
      <div className='relative'>
        {props?.type === 'phone'
          ? (
              <PhoneInput
                placeholder={placeholder}
                defaultCountry={selectedCountry}
                value={value}
                onChange={(_value) => {
                  onChange({ target: { value: _value } })
                }}
                className={`border h-12 rounded text-softBlack font-normal placeholder-softBlack60 border-softBlack30 pl-2 ${classes}`}
                onBlur={handleBlur}
                disabled={disabled}
                {...props}
              />
            )
          : (
              <input
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                className={`border h-12 rounded font-normal text-softBlack placeholder-softBlack60 border-softBlack30 ${classes}`}
                onBlur={handleBlur}
                disabled={disabled}
                {...props}
              />
            )
        }
        {['date', 'datetime-local'].includes(props?.type) && (
          <div className='absolute right-3 top-3'>
            <CalendarIcon />
          </div>
        )}
      </div>
      <div className='absolute left-0'>
        {isError && isShowError && props.type === 'email' && (
          <p className='my-0 text-xs mt-1 text-red-500'>email not valid</p>
        )}
        {isError && isShowError && props.required === 'required' && (
          <p className='my-0 text-xs mt-1 text-red-500'>
            {errorMessage || 'This field is required'}
          </p>
        )}
        {isRangeError &&
          props.min &&
          props.max &&
          isShowError &&
          props.required === 'required' && (
            <p className='my-0 text-xs mt-1 text-red-500'>
              {'Value must be in between ' + props.min + ' and ' + props.max}
            </p>
        )
        }
        {isRangeError &&
          props.min &&
          !props.max &&
          isShowError &&
          props.required === 'required' && (
            <p className='my-0 text-xs mt-1 text-red-500'>
              {'Value must be at least ' + props.min}
            </p>
        )
        }
        {isRangeError &&
          !props.min &&
          props.max &&
          isShowError &&
          props.required === 'required' && (
            <p className='my-0 text-xs mt-1 text-red-500'>
              {'Value must be at max ' + props.max}
            </p>
        )
        }
      </div>
    </div>
  )
}

export default CustomInput
