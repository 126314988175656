// @ts-nocheck
import React, { useContext, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import clsx from 'clsx'

import useGetProjectBuyers from 'hooks/useGetProjectBuyers'
import AddOrEditSingleContact from 'components/AddOrEditContact'

import { TableFilter } from 'components'
import UserInfoCard from 'components/UserInfoCard'
import BuyerCard from 'components/BuyerCard'

import { ReactComponent as UserIcon } from 'assets/icons/user.svg'
// import { ReactComponent as CorporationIcon } from 'assets/icons/corporation.svg'
// import { ReactComponent as LinkIcon } from 'assets/icons/link.svg'
import { PurchaseParkingContext } from '../context/PurchaseParkingContext'

// import { useHistory } from 'react-router-dom'

const SelectBuyer = ({ setStep }) => {
  // const history = useHistory()
  const { setSelectedBuyers } = useContext(PurchaseParkingContext)
  const [filteredBuyers, setFilteredBuyers] = useState([])
  const [activeBuyerIds, setActiveBuyerIds] = useState([])

  const { loading, buyers, refetch: refetchBuyers } = useGetProjectBuyers()
  const [isOpenCreateSingleContactModal, setIsOpenCreateSingleContactModal] =
    useState(false)
  const [singleContactModalType, setSingleContactModalType] = useState(null)

  const openCreateSingleContactModal = (type) => {
    setSingleContactModalType(type)
    setIsOpenCreateSingleContactModal(true)
  }

  // const onCreateSingleContact = (_id) => {
  //   if (history) {
  //     history.push({
  //       pathname: '/admin/newcontacts',
  //       search: `?id=${_id}&userType=${singleContactModalType}`
  //     })
  //   }
  // }

  const selectBuyer = (buyer) => {
    if (checkIfAlreadyExists(buyer._id)) {
      if (activeBuyerIds.length > 0) {
        const filteredArray = activeBuyerIds.filter(
          (item) => item._id !== buyer._id
        )
        setActiveBuyerIds(filteredArray)
      }
    } else {
      const temp = [...activeBuyerIds, buyer]
      setActiveBuyerIds(temp)
    }
  }

  const checkIfAlreadyExists = (id) => {
    return activeBuyerIds.some((buyer) => buyer._id === id)
  }

  const createNewBuyer = () => {
    openCreateSingleContactModal('Buyer')
  }
  // const createNewCorporation = () => {}
  // const linkExistingBuyer = () => {}

  const onBack = () => setStep((prev) => prev - 1)
  const onNext = () => {
    activeBuyerIds?.length > 0 && setStep((prev) => prev + 1)
    setSelectedBuyers(activeBuyerIds)
  }

  return (
    <div>
      <div className='pb-4 border-b-2 border-softBlack'>
        <p className='my-0 text-xl font-bold'>Select Buyers</p>
      </div>
      <p className='my-0 mt-4 mb-5 text-softBlack text-base'>
        You can add new buyers or select from the recent ones
      </p>
      <div className='mb-10'>
        <TableFilter
          dataToFilter={buyers}
          setData={setFilteredBuyers}
          placeHolder='You can filter the units below by spacing words'
          label=''
          loading={loading}
        />
      </div>
      <div className='grid grid-cols-3 gap-6 mt-10'>
        {loading &&
          Array.from(Array(3).keys()).map((a, index) => (
            <Skeleton key={index} height={180} width='100%' />
          ))}
        {!loading &&
          filteredBuyers?.map((_buyer, index) => (
            <UserInfoCard
              {..._buyer}
              key={index}
              onSelect={() => selectBuyer(_buyer)}
              isActive={checkIfAlreadyExists(_buyer._id)}
              className=''
            />
          ))}
      </div>
      <div className='relative flex items-center my-10'>
        <div className='flex-grow border-t border-softBlack30'></div>
        <span className='flex-shrink px-6 text-softBlack text-base font-medium'>
          or create new buyers
        </span>
        <div className='flex-grow border-t border-softBlack30'></div>
      </div>
      <div className='flex gap-6 w-full justify-between'>
        <BuyerCard
          title='Create New Buyer'
          Icon={UserIcon}
          onClick={createNewBuyer}
          classes='w-full' // 'w-1/3'
        />
        {/* <BuyerCard
          title='Create New Corporation'
          Icon={CorporationIcon}
          onClick={createNewCorporation}
          classes='w-1/3'
        />
        <BuyerCard
          title='Link with Existing Buyer'
          Icon={LinkIcon}
          onClick={linkExistingBuyer}
          classes='w-1/3'
        /> */}
      </div>
      <div className='flex justify-between mt-32'>
        <button
          className='py-3 px-6 text-base border border-softBlack text-softBlack rounded font-medium'
          onClick={onBack}
        >
          Back
        </button>
        <button
          className={clsx('py-3 px-6 text-base bg-volt rounded font-medium', {
            'opacity-50': activeBuyerIds.length === 0
          })}
          onClick={onNext}
        >
          Next
        </button>
      </div>
      {isOpenCreateSingleContactModal && (
        <AddOrEditSingleContact
          show={isOpenCreateSingleContactModal}
          setShow={setIsOpenCreateSingleContactModal}
          userType={singleContactModalType}
          contact={null}
          onSuccess={() => {
            refetchBuyers()
          }}
          invalideCacheOnUpdate
        />
      )}
    </div>
  )
}

export default SelectBuyer
