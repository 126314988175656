/* eslint-disable */
// @ts-nocheck
import React, { useCallback, useEffect, useState } from 'react';
import './styles/style.css';
import propTypes from 'prop-types';
import { Select } from 'antd';
import { connect } from 'react-redux';
import { getParkings } from 'store/actions/parkingActions';
import FilterDrawer from './components/FilterDrawer';
import ParkingInfoDrawer from './components/ParkingInfoDrawer';
import { AVAILABILITY_BG_COLOR, AVAILABILITY_STATUS, classNames } from 'utils';
import useParkingInventoryMapContext from './hooks/useParkingInventoryMapContext';
import { api } from 'services/api';
import DollarIcon from '../../assets/icons/dollar-icon.svg';
import SqftIcon from '../../assets/icons/sqft-icon.svg';
import { getBuildings } from 'store/actions/buildingActions';

const demoEditDetails = {
  _id: '6603e990faa41bb490cbcc97',
  stall: 111,
  price: 1100,
  floorNumber: 1,
  unit: '65d49935100b4b77bd5ad263',
  status: 'Available',
  inBuilding: true,
  building: '65d498de100b4b77bd5ad119',
  createdAt: '2024-03-27T09:40:32.380Z',
  updatedAt: '2024-03-27T09:40:32.380Z',
  __v: 0,
  type: 'Parking',
};

const filterOptions = [
  {
    value: AVAILABILITY_STATUS.open,
    bgColor: AVAILABILITY_BG_COLOR.available,
  },
  {
    value: AVAILABILITY_STATUS.reservedBroker,
    bgColor: AVAILABILITY_BG_COLOR.reserved,
  },
  {
    value: AVAILABILITY_STATUS.soldCommitted,
    bgColor: AVAILABILITY_BG_COLOR.sold,
  },
  {
    value: AVAILABILITY_STATUS.underContract,
    bgColor: AVAILABILITY_BG_COLOR.undercontract,
  },
  {
    value: AVAILABILITY_STATUS.developerHeld,
    bgColor: AVAILABILITY_BG_COLOR.developerheld,
  },
];

const getValuesFromLocalStorage = (key) => {
  const localData = localStorage.getItem(key);
  if (!localData) {
    localStorage.setItem(key, JSON.stringify([]));
    return [];
  }
  return JSON.parse(localData);
};

const ParkingInventoryMap = ({ appProject: projectId }) => {
  const { state, openParkingInfoDrawer, closeParkingInfoDrawer } =
    useParkingInventoryMapContext();
  const [gettingData, setGettingData] = useState(true);
  // Parkings Data
  const [parkingsData, setParkingsData] = useState([]);
  const [recentSaleData, setRecentSaleData] = useState([]);
  const [parkingRevenue, setParkingRevenue] = useState(0);
  const [stallSoldCount, setStallSoldCount] = useState(0);
  const [projectParkings, setProjectParkings] = useState({});
  const [parkingTypeData, setParkingTypeData] = useState([]);
  const [parkingInventoryTotal, setParkingInventoryTotal] = useState(0);
  const MAP_API_KEY = 'AIzaSyDCj_iKzflRXqK8NGXj_WUMbcqmiwTSVIU';
  const YOUR_METERS_PER_PIXEL = 0.02;
  const POLYGON_YOUR_METERS_PER_PIXEL = 0.0001;
  const YOUR_METERS_PER_DEGREE_LAT = 5000; // 10000
  const YOUR_METERS_PER_DEGREE_LNG = 5000;

  const [map, setMap] = useState(null);
  const [drawingManager, setDrawingManager] = useState(null);
  const [polygonsData, setPolygonsData] = useState();
  const floors = [
    {
      id: 'floor-1',
      name: 'P1',
      imgSrc: 'sample-unit-inventory-graph-first-floor.png',
      localStorageKey: 'polygonsData_parking_p1',
    },
    {
      id: 'floor-2',
      name: 'P2',
      imgSrc: 'sample-unit-inventory-graph-second-floor.png',
      localStorageKey: 'polygonsData_parking_p2',
    },
    {
      id: 'report',
      name: 'Report',
    },
  ];

  const parkingColor = ['#59b099', 'red', 'yellow'];

  const parkingType = ['Single stall', 'Tandem stall', 'Tandem & vault'];

  const [selectedFloor, setSelectedFloor] = useState({
    id: 'floor-1',
    name: 'P1',
    imgSrc: 'sample-unit-inventory-graph-first-floor.png',
    localStorageKey: 'polygonsData_parking_p1',
  });

  const currencyFormate = (value) => {
    if (value == null) return undefined;
    else {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });

      const formattedValue = formatter.format(value);
      return formattedValue;
    }
  };

  const formatDateDifference = (endDateStr) => {
    const endDate = new Date(endDateStr);
    const today = new Date();
    const difference = endDate.getTime() - today.getTime();
    const daysDifference = Math.ceil(difference / (1000 * 60 * 60 * 24));

    return `${daysDifference} days`;
  };

  const getParkingDataByProjectId = async () => {
    const { data } = await api.get(
      `/parking/projectId`,
      {},
      { responseType: 'json' }
    );
    console.log(data)
    let parkingIdData = [];
    parkingIdData = data.data.filter(
      (parking) => parking.building.project.id === projectId
    );
    console.log(parkingIdData)
    setProjectParkings(parkingIdData);
  };
  const fetchDataByGroupParking = async () => {
    const { data } = await api.get('/deals', {}, { responseType: 'json' });
    if(data.data.length > 0) {
    const filteredDeals = data.data.filter(
      (deal) => deal.formatedAcceptedDate !== ''
    );
    let parkingGroup = [];
    filteredDeals.forEach((deal) => {
      const { options } = deal;
      const modifiedGroups = options
        .filter((option) => option.label.toLowerCase().includes('parking'))
        .map((option, index) => ({
          label: option.label,
          price: option.price,
          index: index,
        }));
      parkingGroup = parkingGroup.concat(modifiedGroups);
    });
    const totalPrice = parkingGroup.reduce(
      (total, obj) => total + obj.price,
      0
    );
    const totalCount = parkingGroup.length;
    setParkingRevenue(totalPrice);
    setStallSoldCount(totalCount);
  }
  };

  const updatePolygons = () => {
    if (!polygonsData?.length || !map) {
      return;
    }
    const colorCodes = ['#FFB7FF', '#3BF4FB', '#CAFF8A', '#826AED'];
    const polygons = polygonsData.map((data, index) => {
      const val = {
        coordinates: data,
        fillColor: colorCodes[index % colorCodes.length],
      };
      return val;
    });

    // Add a click event listener to show a custom info-window
    const infoWindowContent =
      '<div><h3>Custom Info-Window</h3><p>Additional information goes here.</p></div>';
    const infoWindow = new window.google.maps.InfoWindow({
      content: infoWindowContent,
    });

    polygons.forEach((polygonData, index) => {
      const selectedParkingsData = parkingsData?.[index] ?? {};
      const {
        sector,
        salesLotNumber,
        lotType,
        collection,
        buildableDimensions,
      } = selectedParkingsData?.lotData ?? {};

      const customPolygon = new window.google.maps.Polygon({
        paths: polygonData.coordinates,
        strokeColor: '#FF0000',
        strokeOpacity: 1, //0.8,
        strokeWeight: 2,
        fillColor: polygonData.fillColor,
        fillOpacity: 1, // 0.35,
        map: map,
      });

      window.google.maps.event.addListener(
        customPolygon,
        'click',
        function (event) {
          infoWindow.setPosition(event.latLng);
          infoWindow.setContent(`<div style="inset: 0px auto auto 0px;">
            <div style="display: flex; flex-direction: column; color: #2E2B2E;">
                <div style="font-weight: medium; font-size: 12px; padding: 4.5px 8px; width: 171px; height: 27px; background-color: rgb(184, 224, 74); text-transform: uppercase;">Available</div>
                <div style="font-weight: bold; font-size: 24px; margin-top: 8px;">Parking #111</div>
                <div style="font-weight: medium; font-size: 16px; margin-top: 9px;">CAD$1,253,880</div>
            </div>
          </div>`);
          // infoWindow.setContent(ReactDOM.render(<Content />, document.getElementById('popover')))
          // infoWindow.setContent(ReactDOM.render(<Content />, document.getElementsByClassName('gm-style-iw gm-style-iw-c')[0]))
          infoWindow.open(map);
          openParkingInfoDrawer();
        }
      );
    });
  };

  const overlayClickListener = (event) => {
    const cPolygon = event.overlay
      .getPath()
      .getArray()
      .map((a) => ({ lat: a.lat(), lng: a.lng() }));
    setPolygonsData([...polygonsData, cPolygon]);
  };

  useEffect(() => {
    if (drawingManager) {
      google.maps.event.addListener(
        drawingManager,
        'overlaycomplete',
        overlayClickListener
      );
    }

    if (polygonsData?.length > 0) {
      localStorage.setItem(
        selectedFloor.localStorageKey,
        JSON.stringify(polygonsData)
      );
      updatePolygons();
    }
    return () => {
      if (drawingManager) {
        google.maps.event.clearListeners(drawingManager, 'overlaycomplete');
      }
    };
  }, [polygonsData, drawingManager]);

  const initMap = () => {
    // Geocode the address to get coordinates
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode(
      {
        address:
          '14945 S Padre Island Dr, Corpus Christi, TX 78418, Parkinged States',
      },
      (results, status) => {
        if (status === 'OK' && results[0].geometry) {
          // const location = results[0].geometry.location
          const centerPoint = {
            // lat: location.lat(),
            // lng: location.lng(),
            lat: 49.30802539363068,
            lng: -123.06184709072114,
          };

          // Image size in pixels
          // const imageSize = { width: 1385, height: 786 }
          const imageSize = { width: 380, height: 110 };

          // Calculate half of the width and height in meters
          const halfWidth = imageSize.width * YOUR_METERS_PER_PIXEL;
          const halfHeight = imageSize.height * YOUR_METERS_PER_PIXEL;

          // Calculate the southwest and northeast corners of the image
          const imageSW = {
            lat: centerPoint.lat - halfHeight / YOUR_METERS_PER_DEGREE_LAT,
            lng: centerPoint.lng - halfWidth / YOUR_METERS_PER_DEGREE_LNG,
          };

          const imageNE = {
            lat: centerPoint.lat + halfHeight / YOUR_METERS_PER_DEGREE_LAT,
            lng: centerPoint.lng + halfWidth / YOUR_METERS_PER_DEGREE_LNG,
          };

          // Output the values
          const newMap = new google.maps.Map(document.getElementById('map'), {
            zoom: 18,
            center: centerPoint,
            rotateControl: true, // Enable rotate control
            rotateControlOptions: {
              position: window.google.maps.ControlPosition.TOP_CENTER, // Position of the rotate control
            },
            heading: 90, // In
            // mapTypeId: "satellite",
          });
          // Add a marker at the geocoded location
          // const marker = new window.google.maps.Marker({
          //   position: location,
          //   map: newMap,
          //   title: 'Marker at Geocoded Location',
          // })

          // Add a custom image overlay
          const bounds = new window.google.maps.LatLngBounds(
            new window.google.maps.LatLng(imageSW.lat, imageSW.lng),
            new window.google.maps.LatLng(imageNE.lat, imageNE.lng)
          );
          // The photograph is courtesy of the U.S. Geological Survey.
          const srcImage =
            // `${window.location.origin}/abc.jpg`
            `${window.location.origin}/${selectedFloor.imgSrc}`;
          // 'https://whitecaplivingnpi.com/wp-content/uploads/2023/07/Phase1-Homesite-Map.jpg'

          // const newMap = new window.google.maps.Map(
          //   document.getElementById('map'),
          //   {
          //     center: centerPoint,
          //     zoom: 14,
          //   },
          // )

          // const customImageOverlay = new window.google.maps.GroundOverlay(
          //   // 'project-map.jpg',
          //   'https://whitecaplivingnpi.com/wp-content/uploads/2023/07/Phase1-Homesite-Map.jpg',
          //   imageBounds,
          // )
          class USGSOverlay extends google.maps.OverlayView {
            bounds_;
            image_;
            div_;
            constructor(bounds, image) {
              super();
              // Initialize all properties.
              this.bounds_ = bounds;
              this.image_ = image;
              // Define a property to hold the image's div. We'll
              // actually create this div upon receipt of the onAdd()
              // method so we'll leave it null for now.
              this.div_ = null;
            }
            /**
             * onAdd is called when the map's panes are ready and the overlay has been
             * added to the map.
             */
            onAdd() {
              this.div_ = document.createElement('div');
              this.div_.style.borderStyle = 'none';
              this.div_.style.borderWidth = '0px';
              this.div_.style.position = 'absolute';

              // Create the img element and attach it to the div.
              const img = document.createElement('img');

              img.src = this.image_;
              img.style.width = '100%';
              img.style.height = '100%';
              img.style.position = 'absolute';
              this.div_.appendChild(img);

              // Add the element to the "overlayLayer" pane.
              const panes = this.getPanes();

              panes.overlayLayer.appendChild(this.div_);
            }
            draw() {
              // We use the south-west and north-east
              // coordinates of the overlay to peg it to the correct position and size.
              // To do this, we need to retrieve the projection from the overlay.
              const overlayProjection = this.getProjection();
              // Retrieve the south-west and north-east coordinates of this overlay
              // in LatLngs and convert them to pixel coordinates.
              // We'll use these coordinates to resize the div.
              const sw = overlayProjection.fromLatLngToDivPixel(
                this.bounds_.getSouthWest()
              );
              const ne = overlayProjection.fromLatLngToDivPixel(
                this.bounds_.getNorthEast()
              );

              // Resize the image's div to fit the indicated dimensions.
              if (this.div_) {
                this.div_.style.left = sw.x + 'px';
                this.div_.style.top = ne.y + 'px';
                this.div_.style.width = ne.x - sw.x + 'px';
                this.div_.style.height = sw.y - ne.y + 'px';
              }
            }
            /**
             * The onRemove() method will be called automatically from the API if
             * we ever set the overlay's map property to 'null'.
             */
            onRemove() {
              if (this.div_) {
                this.div_.parentNode.removeChild(this.div_);
                this.div_ = null;
              }
            }
          }

          const overlay = new USGSOverlay(bounds, srcImage);
          overlay.setMap(newMap);

          // Add a custom polygon with info-window
          // const polygonCoordinates = [
          //   { lat: imageSW.lat, lng: imageSW.lng },
          //   // { lat: imageSW.lat, lng: imageSW.lng },
          //   // { lat: imageSW.lat, lng: imageSW.lng },
          //   // Add more polygon vertices as needed
          // ]

          function getRandomColor(value) {
            var letters = '0123456789ABCDEF';
            var color = '#';
            for (var i = 0; i < 6; i++) {
              color += letters[Math.floor((value / 10) * 16)];
            }
            return color;
          }

          // let currentCenterPoint = centerPoint

          const _drawingManager = new google.maps.drawing.DrawingManager({
            drawingMode: google.maps.drawing.OverlayType.POLYGON,
            drawingControl: true,
            drawingControlOptions: {
              position: google.maps.ControlPosition.TOP_CENTER,
              drawingModes: [google.maps.drawing.OverlayType.POLYGON],
            },
            polygonOptions: {
              editable: true,
            },
          });
          _drawingManager.setMap(newMap);

          setMap(newMap);
          updatePolygons();
          setDrawingManager(_drawingManager);
        } else {
          console.error('Geocoding failed:', status);
        }
      }
    );
  };

  useEffect(() => {
    if (selectedFloor.id === 'floor-1' || selectedFloor.id === 'floor-2') {
      const script = document.createElement('script');
      document.head.appendChild(script);
      if (!gettingData) {
        script.src = `https://maps.googleapis.com/maps/api/js?key=${MAP_API_KEY}&libraries=drawing`;
        script.async = true;
        script.onload = () => {
          initMap();
        };
      }
      // Clean up the script on component unmount
      return () => {
        document.head.removeChild(script);
      };
    }
  }, [projectId, parkingsData, gettingData, selectedFloor]);

  const _getParkings = useCallback(() => {
    setGettingData(true);
    getParkings()
      .then((parkings) => {
        setParkingsData(parkings);
        setGettingData(false);
      })
      .catch(() => {
        setGettingData(false);
      });
  }, []);

  const handlingParkingTypeData = async () => {
    const buildings = await getBuildings();
    const currentProjectBulding = buildings.filter(
      (building) => building.project.id === projectId
    );
    const buildingId = currentProjectBulding[0]._id;
    const { data } = await api.get(`/parking/${buildingId}/inventory`);
    if(data.data.length > 0) {
    const parkingTypeArray = data.data[0].options.map((parking, index) => {
      const color = parkingColor[index % parkingColor.length];
      return { name: parking.name, value: parking.value, color: color };
    });
    setParkingTypeData(parkingTypeArray);
    const totalParking = parkingTypeArray.reduce((sum, obj) => {
      const objSum = obj.value.reduce((objSum, val) => objSum + val, 0);
      return sum + objSum;
    }, 0);
    setParkingInventoryTotal(totalParking || 0)
  }
  };

  useEffect(() => {
    _getParkings();
  }, [_getParkings]);

  useEffect(() => {
    setPolygonsData(getValuesFromLocalStorage(selectedFloor.localStorageKey));
  }, [selectedFloor]);

  useEffect(() => {
    const recentSalesData = async () => {
      const { data } = await api.get('/deals', {}, { responseType: 'json' });
      if (data.data.length === 0) {
        setRecentSaleData([]);
      } else {
        const latestThreeSales = data.data.slice(0, 3);
        setRecentSaleData(latestThreeSales);
        return data.data;
      }
    };
    recentSalesData();
    fetchDataByGroupParking();
    getParkingDataByProjectId()
  }, []);


  useEffect(() => {
    handlingParkingTypeData();
  }, []);

  return (
    <div className='w-full h-full'>
      <div className='flex w-full h-full'>
        <div
          className={classNames(
            `relative flex flex-col w-full max-h-full overflow-auto transition-all`,
            // state.isFilterDrawerOpen ? 'ml-[280px]' : 'ml-0',
            state.isParkingInfoDrawerOpen ? 'mr-[300px]' : 'mr-0'
          )}
        >
          <div
            className={`flex flex-row py-3 items-center gap-4 justify-start`}
          >
            <div className='flex items-center rounded-lg bg-white'>
              {floors.map((floor) => (
                <div
                  key={floor.id}
                  onClick={() => {
                    setSelectedFloor(floor);
                    closeParkingInfoDrawer();
                  }}
                  className={`flex items-center justify-center min-w-[160px] px-4 py-1.5 cursor-pointer rounded-md font-medium text-base ${
                    selectedFloor.id === floor.id
                      ? 'bg-softBlack text-white shadow-sm'
                      : 'bg-white text-softBlack'
                  } transition-all`}
                >
                  {floor.name}
                </div>
              ))}
            </div>
            {/* <FilterToggle /> */}
            {/* <SearchBox /> */}
            {/* <CustomSelect
              value={contact?.buyerData?.leadStatus}
              handleChange={handleLeadStatusChange}
              options={leadStatuses}
              isLeadStatus
            /> */}
            {/* {filterOptions.map((item, index) => {
              return (
                <button
                  value={item.value}
                  key={`${item.value}-${index}`}
                  className={`flex w-[113px] rounded-sm items-center h-full content-center capitalize`}
                  onClick={() => {
                  }}
                  style={{
                    backgroundColor: item.bgColor,
                    opacity: 1.0
                  }}
                >
                  <span
                    className={
                      'w-full text-center font-medium text-sm text-softBlack'
                    }
                  >
                    {item.value}
                  </span>
                </button>
              );
            })} */}
            {/* <button
                className='w-[129px] h-[45px] px-3 py-2 bg-volt rounded justify-center items-center inline-flex font-medium'
                key={index}
                onClick={onClick}
              >
                <div className='ml-2 italic'>{title}</div>
              </button>
            ))} */}
          </div>
          {selectedFloor.id === 'floor-1' || selectedFloor.id === 'floor-2' ? (
            <div id='map' style={{ width: '100%', height: '95%' }}></div>
          ) : (
            <div></div>
          )}
          {selectedFloor.id === 'report' && (
            <div style={{ width: '100%', height: '95%' }} className=''>
              <div className='grid grid-cols-12 gap-x-10 cols-span-12 h-full'>
                <div className='col-span-3 border-[1px] border-[rgb(0,0,0,0.2)] rounded-[8px] p-3 h-full'>
                  <div className='bg-white rounded-[8px] flex flex-col gap-y-6 px-4 py-8'>
                    <div className='flex flex-col gap-2'>
                      <div className='text-4xl'>Morrison Walk</div>
                      <div className='text-lg'>North Vancouver</div>
                    </div>
                    <div className='flex items-center gap-4 rounded-[8px] bg-[#D6FABB] px-4 py-2 w-fit'>
                      <div className='rounded-full bg-black h-3 w-3'></div>
                      <div className='text-xl font-semibold'>Live</div>
                    </div>
                    <div>
                      <div className='text-2xl font-[500]'>Recent Sales</div>
                      <div className='mt-7  gap-y-4 flex flex-col'>
                        {recentSaleData === [] && 'Loading..'}
                        {recentSaleData.map((sale, index) => (
                          <div
                            className={`px-4 py-4 gap-y-4  ${
                              sale.status === 'Sold Firm' ? 'bg-[#D6FABB]' : ''
                            } flex flex-col border-[1px] border-[rgb(0,0,0,0.2)] rounded-[8px]`}
                          >
                            <div className='text-xl font-[600]'>
                              {sale.unitNumber}
                            </div>
                            <div className='flex text-[14px] gap-x-4'>
                              <div className='flex gap-2 items-center'>
                                <img src={DollarIcon} />
                                <div className=''>
                                  {currencyFormate(sale.soldPrice)}
                                </div>
                              </div>
                              <div className='flex gap-2'>
                                <img src={SqftIcon} />
                                <div>{sale.sqftArea} Sqft</div>
                              </div>
                            </div>
                            <div
                              className={`bg-white items-center rounded-[4px] w-fit ${
                                sale.status === 'Rescission Period'
                                  ? ''
                                  : 'px-4'
                              } py-1 flex gap-x-2`}
                            >
                              <div className='text-md font-bold'>
                                {sale.status}
                              </div>
                              {sale.status === 'Rescission Period' && (
                                <div className='text-[12px]  flex-1'>
                                  {formatDateDifference(
                                    sale.rescissionExpireDate
                                  )}{' '}
                                  Left
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-span-9 border-[1px] border-[rgb(0,0,0,0.2)] rounded-[8px] py-3 px-8 h-full'>
                  <div className='bg-white rounded-[8px] flex flex-col gap-y-6 px-6 py-8'>
                    <div className='flex justify-between items-center'>
                      <div className='text-xl font-bold'>Report</div>
                      <div className='flex gap-3'>
                        <Select
                          value={['Today']}
                          showSearch={true}
                          onChange={(value) => {}}
                          className='ant-picker-input1 w-fit'
                          // getPopupContainer={(node) => node.parentNode}
                        >
                          {/* <Select.Option>
                          <div className='flex gap-2 items-center'>
                            <span>Tomorrow</span>
                          </div>
                        </Select.Option> */}
                        </Select>
                        <Select
                          value={['US-West']}
                          showSearch={true}
                          onChange={(value) => {}}
                          className='ant-picker-input1 w-fit'
                          getPopupContainer={(node) => node.parentNode}
                        >
                          {/* <Select.Option>
                          <div className='flex gap-2 items-center'>
                            <span>Tomorrow</span>
                          </div>
                        </Select.Option> */}
                        </Select>
                      </div>
                    </div>
                    <div className='w-full h-[1px] bg-[rgb(0,0,0,0.2)]'></div>
                    <div className='bg-white  grid grid-cols-10 col-span-10   px-4'>
                      <div className='col-span-3  relative  gap-y-4 pr-3 flex justify-center flex-col'>
                        <div className='rounded-[8px] py-4 px-4 border-[2px] border-[rgb(0,0,0,0.2)]'>
                          <div>Total Parking Revenue</div>
                          <div className='text-2xl font-bold'>
                            {currencyFormate(parkingRevenue || 0)}
                          </div>
                        </div>
                        <div className='rounded-[8px] py-4 px-4 border-[2px] border-[rgb(0,0,0,0.2)]'>
                          <div>Number of stalls sold</div>
                          <div className='text-2xl font-bold'>
                            {stallSoldCount || 0}
                          </div>
                        </div>
                        <div className='rounded-[8px] py-4 px-4 border-[2px] border-[rgb(0,0,0,0.2)]'>
                          <div>Available Parking</div>
                          <div className='text-2xl font-bold'>
                            {projectParkings.length - stallSoldCount || 0}
                          </div>
                        </div>
                        {/* <div className='h-full w-[1px] col-span-1 absolute right-0 flex justify-center items-center  bg-[rgb(0,0,0,0.2)]'></div> */}
                      </div>
                      <div className='col-span-7 rounded-[8px] border-[2px] border-[rgb(0,0,0,0.2)] grid grid-cols-7'>
                        <div className='flex flex-col col-span-7'>
                          <div className='gap-y-2 flex-col ml-8 mt-8  flex'>
                            <div className='text-xl font-[500]'>
                              Parking Inventory
                            </div>
                            <div className='text-3xl font-bold'>
                              {parkingInventoryTotal} Parkings
                            </div>
                          </div>
                          <div className='gap-y-2 flex flex-col ml-8 mt-10 '>
                            <div className='text-lg mb-3 font-semibold'>
                              Parking Types
                            </div>
                            <div className='flex flex-wrap gap-x-4'>
                              {parkingTypeData === [] && 'Loading..'}
                              {parkingTypeData.map((parking, index) => (
                                <>
                                  <div className='flex flex-col'>
                                    <div className='flex items-center gap-x-2'>
                                      <div
                                        style={{
                                          backgroundColor: parking.color,
                                        }}
                                        className={`rounded-full h-3 w-3`}
                                      ></div>
                                      <div className='text-sm col-span-2'>
                                        {parking.name}
                                      </div>
                                    </div>
                                    <div className='text-xl col-span-2 font-bold'>
                                      {(parking.value[0] || 0) + (parking.value[1] || 0)}
                                    </div>
                                  </div>
                                  {parkingTypeData.length - 1 != index && (
                                    <div className='h-[100%] w-[2px] bg-[rgba(0,0,0,0.4)]'></div>
                                  )}
                                </>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <FilterDrawer />
        </div>
        <ParkingInfoDrawer
          editDetails={demoEditDetails}
          closeParkingInfoDrawer={closeParkingInfoDrawer}
          isParkingInfoDrawerOpen={state.isParkingInfoDrawerOpen}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  appProject: state.appReducer.appProject,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ParkingInventoryMap);

ParkingInventoryMap.propTypes = {
  appProject: propTypes.string,
};
