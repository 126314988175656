// @ts-nocheck
/* eslint-disable */
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { api } from "services/api";
import { Building2Icon, CalendarCheck, Download } from "lucide-react";
import { BsPeople } from "react-icons/bs";
import useContactContext from "pages/newContacts/hooks/useContactContext";
// import { getAllBuildings } from "store/actions/buildingActions";
import Skeleton from "react-loading-skeleton";
import { Tooltip } from "antd";
import { DocumentReportIcon } from "@heroicons/react/solid";
import { getBuildings } from "store/actions/buildingActions";
import { getProjects } from "store/actions/projectActions";
import { getAllEmailCampaigns } from "store/actions/customEmailCampaignActions";
// import { getAllProjects } from "store/actions/projectActions";

const ReportCard = ({ icon, title, description, handleDownload }) => {
  return (
    <div className="bg-white relative col-span-12 lg:col-span-4 border p-4 md:p-6">
      <div className="flex flex-col gap-y-3">
        <div>{icon}</div>
        <div className="flex flex-col gap-y-1">
          <div className="font-semibold text-lg">{title}</div>
          <p>{description}</p>
        </div>

        <div
          onClick={handleDownload}
          className="absolute right-4 bottom-4 cursor-pointer h-7 w-auto"
        >
          <Download />
        </div>
      </div>
    </div>
  );
};

const ReportCardBuilding = ({
  icon,
  title,
  description,
  handleDownloadParking,
  handleDownloadUnit,
  handleDownloadStorage,
}) => {
  return (
    <div className="bg-white relative col-span-12 lg:col-span-4 border p-4 md:p-8">
      <div className="flex flex-col gap-y-3">
        <div>{icon}</div>
        <div className="flex flex-col gap-y-1">
          <div className="font-semibold text-lg">{title}</div>
          <p>{description}</p>
        </div>
        <div className="flex gap-x-2 absolute right-4 bottom-2">
          <Tooltip title="Download Units Report">
            <div
              className="border text-[18px] rounded-md flex justify-center items-center h-8 w-8 font-bold cursor-pointer"
              onClick={handleDownloadUnit}
            >
              U
            </div>
          </Tooltip>
          <Tooltip title="Download Parking Report">
            <div
              className="border text-[18px] rounded-md flex justify-center items-center h-8 w-8 font-bold cursor-pointer"
              onClick={handleDownloadParking}
            >
              P
            </div>
          </Tooltip>
          <Tooltip title="Download Storage Report">
            <div
              className="border text-[18px] rounded-md flex justify-center items-center h-8 w-8 font-bold cursor-pointer"
              onClick={handleDownloadStorage}
            >
              S
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

const ReportCorporate = () => {
  const userId = useSelector((state) => state.authReducer.userObject);
  const [isLoading, setIsLoading] = useState(false);
  const { waitingModal, setWaitingModal } = useContactContext();
  const [buildingOptions, setBuildingOptions] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [isLoadingCampaign, setIsLoadingCampaign] = useState(false);
  const [projects, setProjects] = useState([]);
  let id = localStorage.getItem("device_id");

  useEffect(() => {
    setIsLoading(true);
    getBuildings()
      .then((buildings) => {
        const temp = buildings?.map((building) => ({
          value: building?._id,
          label: building?.name,
          projectId: building?.projectId,
        }));
        setBuildingOptions(temp);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });

    getProjects()
      .then((projects) => {
        setProjects(projects);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const getCampaigns = () => {
    setIsLoadingCampaign(true);
    getAllEmailCampaigns().then((campaigns) => {
      setCampaigns(campaigns);
      setIsLoadingCampaign(false);
    });
  };

  useEffect(() => {
    getCampaigns();
  }, []);

  const handleDownloadLeadReport = async (type) => {
    setWaitingModal(true);
    setTimeout(() => {
      setWaitingModal(false);
    }, 10000);
    try {
      let query = `?report_type=${type}&deviceId=${id}`;
      const response = await api.get(
        `/reports/contacts${query}`,
        {},
        { responseType: "blob" }
      );
    } catch (e) {
      console.log(e);
    }
  };

  const handleDownloadInventoryReport = async (type, buildingId) => {
    setWaitingModal(true);
    setTimeout(() => {
      setWaitingModal(false);
    }, 10000);
    try {
      const response = await api.get(
        `/reports/${type}/${buildingId}?deviceId=${id}`,
        {},
        { responseType: "blob" }
      );
    } catch (e) {
      console.log(e);
    }
  };

  const handleDownloadReservationReport = async () => {
    setWaitingModal(true);
    setTimeout(() => {
      setWaitingModal(false);
    }, 10000);
    try {
      const response = await api.get(
        `/reports/reservation?deviceId=${id}`,
        {},
        { responseType: "blob" }
      );
    } catch (e) {
      console.log(e);
    }
  };

  const handleDownloadSalesReport = async () => {
    setWaitingModal(true);
    setTimeout(() => {
      setWaitingModal(false);
    }, 10000);

    const response = await api.get(
      `/reports/sales-transaction?deviceId=${id}`,
      {},
      { responseType: "blob" }
    );
  };

  const handleDownloadReportCampaign = async (campaignId) => {
    setWaitingModal(true);
    setTimeout(() => {
      setWaitingModal(false);
    }, 10000);
    const response = await api.get(
      `/reports/email-campaigns/${campaignId}?deviceId=${id}`,
      {},
      { responseType: "blob" }
    );
  };

  return (
    <div className="min-h-full bg-zinc-100 relative flex flex-col">
      <div className="px-6 py-3 flex justify-between items-center">
        <span className="font-semibold text-3xl">Corporate Reports</span>
      </div>
      <div className="border-b-2 border-black" />
      <div className="flex flex-col gap-y-4 px-5 sm:px-10 ">
        <div className="flex flex-col gap-y-3 py-4 mt-4">
          <div className="text-[20px] font-semibold">Lead Report</div>
          <div className="grid grid-cols-12 col-span-12 gap-6">
            <ReportCard
              icon={<BsPeople size={24} />}
              title="All contacts"
              description="Download the report for all contacts across all projects"
              handleDownload={() => {
                handleDownloadLeadReport("all_contacts");
              }}
            />
          </div>
        </div>
        <div className="h-[1px] bg-[#a1a1a1]" />
        <div className="flex flex-col gap-y-3 py-4">
          <div className="text-[20px] font-semibold">Inventory Report</div>
          {isLoading ? (
            <Skeleton height={130} count={1} />
          ) : buildingOptions.length > 0 ? (
            <div className="grid grid-cols-12 col-span-12 gap-6">
              {buildingOptions.map((building) => (
                <ReportCardBuilding
                  key={building.value}
                  icon={<Building2Icon size={28} />}
                  title={building.label}
                  description={`Download the report for ${building.label}`}
                  handleDownloadParking={() => {
                    handleDownloadInventoryReport("parking", building.value);
                  }}
                  handleDownloadUnit={() => {
                    handleDownloadInventoryReport("units", building.value);
                  }}
                  handleDownloadStorage={() => {
                    handleDownloadInventoryReport("storage", building.value);
                  }}
                />
              ))}
            </div>
          ) : (
            <div className="flex justify-center items-center h-32">
              <span className="text-lg">No buildings found</span>
            </div>
          )}
        </div>
        <div className="h-[1px] bg-[#a1a1a1]" />
        <div className="flex flex-col gap-y-3 py-4">
          <div className="text-[20px] font-semibold">Reservation Report</div>
          <div className="grid grid-cols-12 col-span-12 gap-6">
            <ReportCard
              icon={<CalendarCheck size={28} />}
              title="Reservation Report"
              description="Download the Reservation Report for all projects"
              handleDownload={() => {
                handleDownloadReservationReport();
              }}
            />
          </div>
        </div>
        <div className="h-[1px] bg-[#a1a1a1]" />
        <div className="flex flex-col gap-y-3 py-4">
          <div className="text-[20px] font-semibold">
            Sales Transaction Report
          </div>
          <div className="grid grid-cols-12 col-span-12 gap-6">
            <ReportCard
              icon={<DocumentReportIcon height={28} />}
              title="Sales Report"
              description="Download the Sales Transaction Report for all projects"
              handleDownload={() => {
                handleDownloadSalesReport();
              }}
            />
          </div>
        </div>
        <div className="h-[1px] bg-[#a1a1a1]" />
        <div className="flex flex-col gap-y-3 py-4">
          <div className="text-[20px] font-semibold">Email campaign Report</div>
          {isLoadingCampaign ? (
            <Skeleton className="col-span-12" height={130} count={1} />
          ) : campaigns.length > 0 ? (
            <div className="grid grid-cols-12 col-span-12 gap-6">
              {campaigns.map((campaign) => (
                <ReportCard
                  key={campaign._id}
                  icon={<DocumentReportIcon height={28} />}
                  title={campaign.name}
                  description={
                    campaign?.emailTemplateId?.name +
                    " - " +
                    new Date(campaign?.createdAt).toLocaleDateString()
                  }
                  handleDownload={() => {
                    handleDownloadReportCampaign(campaign._id);
                  }}
                />
              ))}
            </div>
          ) : (
            <div className="flex justify-center items-center h-32">
              <span className="text-lg">No campaigns found</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReportCorporate;
