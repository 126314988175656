import React, { useMemo, useState } from 'react'
import Card from './components/Card'
import { isMobile } from 'react-device-detect'
import { isNumber } from 'utils/isNumber'
import { useHistory } from 'react-router-dom'
import { ADMIN_CONTACTS } from 'routes'
import { useSalesrepDashboard } from 'hooks/useSalesrepDashboard'

const HorizontalStats = () => {
  const {
    opportunitiesWon = 0,
    totalContacts = 0,
    opportunitiesLost = 0,
    totalOpportunities = 0,
    totalReservations = 0,
    newContactsToday = 0
  } = useSalesrepDashboard()

  const [activeIndex, setActiveIndex] = useState(0)
  const history = useHistory()
  const tempStatsData = useMemo(() => {
    return [
      { title: 'New Leads Today', number: isNumber(newContactsToday) },
      {
        title: 'My Contacts',
        number: isNumber(totalContacts),
        onClick: () => {
          history.push(ADMIN_CONTACTS)
        }
      },
      { title: 'Opportunities', number: isNumber(totalOpportunities) },
      { title: 'Reservations', number: isNumber(totalReservations) },
      { title: 'Contract Writing', number: '0' },
      { title: 'Recision period ', number: '0' },
      { title: 'Won', number: isNumber(opportunitiesWon) },
      { title: 'Lost', number: isNumber(opportunitiesLost) }
    ]
  }, [
    history,
    opportunitiesWon,
    totalContacts,
    opportunitiesLost,
    totalOpportunities,
    totalReservations
  ])

  return (
    <div
      className={`${
        isMobile
          ? 'grid grid-cols-2 gap-2 mt-2'
          : 'flex flex-row overflow-y-hidden overflow-x-auto gap-10'
      } w-full  pb-3`}
    >
      {tempStatsData?.length &&
        tempStatsData.map((item, index) => (
          <Card
            key={index}
            item={item}
            setActive={(idx) => setActiveIndex(idx)}
            index={index}
            active={index === activeIndex}
          />
        ))}
    </div>
  )
}

export default HorizontalStats
