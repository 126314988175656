// @ts-nocheck
/* eslint-disable */
import {
  ArrowNarrowRightIcon,
  ArrowSmRightIcon,
  ChevronDownIcon,
} from '@heroicons/react/solid';
import { SettingsIcon, TFilledEmailIcon } from 'components';
import React, { useState, useEffect, useCallback } from 'react';
import Skeleton from 'react-loading-skeleton';
import { getUserActivity } from 'store/actions/contactActions';
// import { getSubscriberActivity } from 'store/actions/emailCampaignActions'
import moment from 'moment';
import { message, Tooltip } from 'antd';
import { ReactComponent as SMSIcon } from '../../assets/icons/icn_text_snippet.svg';
import NotificationIcon from '../../assets/icons/notification.png';
import { CalendarCheckIcon, PhoneCallIcon } from 'lucide-react';
import CustomButton from './CustomButton';
import { ADMIN_RESERVATION_NEW } from 'routes';
import { useHistory } from 'react-router-dom';
import { ReactComponent as NotesIcon } from '../../assets/icons/icn_write.svg';
import { ReactComponent as TasksIcon } from '../../assets/icons/icn_check_circle.svg';
import { ReactComponent as FormIcon } from '../../assets/icons/checkform.svg';
import WebsiteIcon from '../../assets/icons/visit.svg';
import {
  bindChannel,
  subscribeToChannel,
  unbindChannel,
  unsubscribeToChannel,
} from 'services/pusher';
import { getCampaignActivityById } from 'store/actions/emailCampaignActions';

const NoActivityView = () => {
  return (
    <div className='h-full flex flex-col justify-center items-center'>
      <svg
        width='24'
        height='24'
        viewBox='0 0 18 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10 6.5H15V4.5H10V6.5ZM10 13.5H15V11.5H10V13.5ZM16 18H2C0.9 18 0 17.1 0 16V2C0 0.9 0.9 0 2 0H16C17.1 0 18 0.9 18 2V16C18 17.1 17.1 18 16 18ZM3 8H8V3H3V8ZM4 4H7V7H4V4ZM3 15H8V10H3V15ZM4 11H7V14H4V11Z'
          fill='#2E2B2E'
        />
      </svg>
      <div className='font-semibold mb-2 mt-4'>
        You don't have any activities yet
      </div>
    </div>
  );
};

const ActivityTab = ({
  contact,
  setSelectedHeader,
  setSelectedEmailThread,
}) => {
  const history = useHistory();
  const [isLoadingActivity, setIsLoadingActivity] = useState(true);
  const [activities, setActivities] = useState();
  const [emailStats, setEmailStats] = useState([]);
  const stats = {
    sent: 0,
    open: 0,
    click: 0,
  };
  // const getUSerEmailStats = async () => {
  //   if (activities && activities.length > 0) {
  //     const emailStats = activities.filter(
  //       (activity) => activity.activityType === 'Email-campaign'
  //     );
  //     for (let i = 0; i < emailStats.length; i++) {
  //       const data = await getCampaignActivityById(
  //         emailStats[i]?.threadId,
  //         contact._id
  //       );
  //       console.log(data, 'data');
  //       const statusCounts = data.reduce((acc, item) => {
  //         if (!acc[item.data.event]) {
  //           acc[item.data.event] = 0;
  //         }
  //         acc[item.data.event]++;
  //         return acc;
  //       }, {});
  //       console.log(statusCounts, 'statusCounts');
  //       setActivities((prev) => {
  //         return prev.map((activity) => {
  //           if (activity.threadId === emailStats[i]?.threadId) {
  //             console.log('here');
  //             return { ...activity, stats: statusCounts };
  //           }
  //           return activity;
  //         });
  //       });
  //     }
  //   }
  // };

  const fetchActivities = async () => {
    try {
      setIsLoadingActivity(true);
      const _data = await getUserActivity(contact?.id);
      setActivities(_data.data);

      // const emailStats = _data.data.filter(
      //   (activity) => activity.activityType === 'Email-campaign'
      // );
      // const emailStatsMap = new Map();
      // for (const emailStat of emailStats) {
      //   const data = await getCampaignActivityById(
      //     emailStat?.threadId,
      //     contact._id
      //   );
      //   emailStatsMap.set(emailStat?.threadId, data);
      // }
      // const formattedData = _data.data.map((activity) => {
      //   if (emailStatsMap.has(activity.threadId)) {
      //     return { ...activity, stats: emailStatsMap.get(activity.threadId) };
      //   }
      //   return activity;
      // });
      // setActivities(formattedData);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoadingActivity(false);
    }
  };

  const onNewCallCallBack = useCallback(
    (newActivity) => {
      setActivities((currentActivities) => [newActivity, ...currentActivities]);
    },
    [activities, setActivities]
  );

  useEffect(() => {
    let activityChannel = null;
    if (contact?.id) {
      activityChannel = subscribeToChannel(`activity-${contact?.id}`);
      if (activityChannel) {
        bindChannel(activityChannel, onNewCallCallBack);
      }
    }
    return () => {
      if (contact?.id) {
        if (activityChannel) {
          unbindChannel(activityChannel, onNewCallCallBack);
        }
        unsubscribeToChannel(`activity-${contact?.id}`);
      }
    };
  }, [contact?.id, onNewCallCallBack]);

  useEffect(() => {
    fetchActivities();
    // const fetch = async () => {
    //   try {
    //     setIsLoadingActivity(true)
    //     setLoadError(null)
    //     const _data = await getSubscriberActivity(contact?.email)
    //     setActivities(_data)
    //   } catch (e) {
    //     console.log(e)
    //     setLoadError(JSON.stringify(e))
    //   } finally {
    //     setIsLoadingActivity(false)
    //   }
    // }
    // fetch()
  }, []);

  // useEffect(() => {
  //   getUSerEmailStats();
  // }, []);

  const AccordionItem = ({
    item: {
      _id,
      title,
      createdAt,
      updatedAt,
      displayMessage,
      activityType,
      activity,
      stats,
      threadId,
    },
  }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <div
        key={_id}
        className={`flex flex-col relative z-[10] bg-[rgb(240,238,233)] rounded-[8px] py-4 px-3 justify-start items-start overflow-hidden w-full max-w-[1000px] ${
          isOpen ? 'shadow border-2 border-volt' : ''
        }`}
      >
        <div className=' w-full flex flex-col justify-between gap-y-2'>
          <div className='flex flex-wrap  gap-3'>
            <div className='h-10 w-10 rounded-full flex items-center justify-center  z-[50] bg-zinc-300'>
              {activityType === 'Email' ? (
                <TFilledEmailIcon className='h-6' />
              ) : activityType === 'Email-campaign' ? (
                <TFilledEmailIcon className='h-6' />
              ) : activityType === 'Event/Meeting' ? (
                <CalendarCheckIcon className='h-6' />
              ) : activityType === 'Call' ? (
                <PhoneCallIcon className='h-5' />
              ) : activityType === 'Notes Created' ? (
                <NotesIcon className='h-5' />
              ) : activityType === 'Note Created' ||
                activityType?.toLowerCase().includes('note') ? (
                <NotesIcon className='h-5' />
              ) : activityType?.toLowerCase().includes('sms') ? (
                <SMSIcon className='h-6' />
              ) : activityType === 'Task Created' ? (
                <TasksIcon className='h-5' />
              ) : activityType === 'Form Submission' ? (
                <FormIcon className='h-5' />
              ) : activityType === 'Website Activity' ? (
                <img src={WebsiteIcon} className='h-5' />
              ) : (
                <img
                  className='h-5'
                  src={NotificationIcon}
                  alt='notification'
                />
              )}
            </div>
            <Tooltip
              className='absolute right-4 top-4'
              title={moment(createdAt).format('MMM DD, YYYY [at] hh:mm a')}
            >
              <span className='pl-[1px] text-[#2E2B2E] text-opacity-70 text-[10px] md:text-[14px] font-normal leading-none truncate'>
                {moment(createdAt).fromNow()}
                {!(
                  moment(new Date()).format('MMM DD, YYYY') ===
                  moment(createdAt).format('MMM DD, YYYY')
                ) && ` ${moment(createdAt).format('[at] hh:mm a')}`}
              </span>
            </Tooltip>
            <div className='flex justify-center flex-col flex-1'>
              <div className='flex flex-col gap-y-2'>
                <span className='text-[#2E2B2E] text-base font-bold leading-none'>
                  {activityType === 'Email' ? (
                    <div>Subject: {title}</div>
                  ) : activityType === 'Email-campaign' ? (
                    <div>Email Sent - {title}</div>
                  ) : activityType === 'Event/Meeting' ? (
                    <div> {title}</div>
                  ) : activityType === 'Call' ||
                    activityType?.toLowerCase().includes('call') ? (
                    <div> {title}</div>
                  ) : activityType === 'Notes Created' ||
                    activityType === 'Note Created' ? (
                    <div> {title}</div>
                  ) : activityType?.toLowerCase().includes('sms') ? (
                    <div> {title}</div>
                  ) : activityType === 'Task Created' ? (
                    <div> {title}</div>
                  ) : (
                    <div> {title}</div>
                  )}
                </span>
              </div>
              <div className='flex mt-2 flex-col gap-y-[16px] ml-4'>
                {activityType === 'Email' && activity ? (
                  activity.map((item) => {
                    return (
                      <div className='flex flex-wrap gap-x-4'>
                        <div className='flex flex-col gap-y-[4px]'>
                          <div className='flex gap-x-2 items-center'>
                            <div className='font-bold'>{item.type} </div>
                            <Tooltip
                              title={moment(item.timestamp).format(
                                'MMM DD, YYYY [at] hh:mm a'
                              )}
                            >
                              <span className='text-[#2E2B2E] text-opacity-70 text-[14px] font-normal leading-none'>
                                · {moment(item.timestamp).fromNow()}
                                {!(
                                  moment(new Date()).format('MMM DD, YYYY') ===
                                  moment(item.timestamp).format('MMM DD, YYYY')
                                ) &&
                                  ` ${moment(item.timestamp).format(
                                    '[at] hh:mm a'
                                  )}`}
                              </span>
                            </Tooltip>
                          </div>
                          <div>
                            {item.message && item.message.length > 200
                              ? item.message.slice(0, 200) + '...'
                              : item.message ?? ''}
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : activityType === 'Email-campaign' && activity ? (
                  activity.map((item) => {
                    return (
                      <div className='flex -ml-4 flex-wrap gap-x-4'>
                        <div className='flex flex-col gap-y-[4px]'>
                          <div className='flex gap-x-2 items-center'>
                            <div className='font-[500] text-[#374151] capitalize text-[16px]'>
                              {item.type === 'email-delivered'
                                          ? 'Sent'
                                          : item.type === 'email-processed'
                                          ? 'Processed'
                                          : item.type === 'email-open'
                                          ? 'Opened'
                                          : item.type}
                            </div>
                            <div className='text-[16px]'>
                            <Tooltip
                              title={moment(item.timestamp).format(
                                'MMM DD, YYYY [at] hh:mm a'
                              )}
                            >
                              <span className='text-[#2E2B2E] text-opacity-70 text-[14px] font-normal leading-none'>
                                · {moment(item.timestamp).fromNow()}
                                {!(
                                  moment(new Date()).format('MMM DD, YYYY') ===
                                  moment(item.timestamp).format('MMM DD, YYYY')
                                ) &&
                                  ` ${moment(item.timestamp).format(
                                    '[at] hh:mm a'
                                  )}`}
                              </span>
                            </Tooltip>
                            </div>
                          </div>
                          <div className='flex ml-2 relative flex-col mt-2 gap-y-4'>
                            {
                              stats?.map((stat) => {
                                if (item.event === 'processed') {
                                  return null;
                                }
                                return (
                                  <>
                                    <div className='h-full absolute left-[3.4px] w-[1px] z-[-1] bg-[rgb(0,0,0,0.2)]'></div>
                                    <div className='flex gap-x-4 items-center'>
                                      <div className='bg-[rgb(95,95,95)] rounded-full h-2 w-2'></div>
                                      <div className='font-[500] uppercase z-1 text-[#27272ab3] italic'>
                                        {item.event === 'delivered'
                                          ? 'Sent'
                                          : item.event === 'open'
                                          ? 'Opened'
                                          : item.event === 'click'
                                          ? 'Clicked'
                                          : item.event}
                                      </div>
                                      <div>
                                        <span className='text-[#2E2B2E] text-opacity-70 text-[12px] font-normal leading-none'>
                                          · {moment(item.timestamp).fromNow()}
                                          {!(
                                            moment(new Date()).format(
                                              'MMM DD, YYYY'
                                            ) ===
                                            moment(item.timestamp).format(
                                              'MMM DD, YYYY'
                                            )
                                          ) &&
                                            ` ${moment(item.timestamp).format(
                                              '[at] hh:mm a'
                                            )}`}
                                        </span>
                                      </div>
                                    </div>
                                  </>
                                );
                              })
                              // <div className='flex gap-x-6 items-center'>
                              //   <div className='flex gap-x-2 items-center'>
                              //     <div>Delivered - </div>
                              //     <div className='font-bold'>
                              //       {stats?.delivered ?? 0}
                              //     </div>
                              //   </div>

                              // </div>
                            }
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : activityType === 'Event/Meeting' && activity ? (
                  activity.map((item) => {
                    return (
                      <div className='flex gap-x-4'>
                        <div className='flex flex-col gap-y-[4px]'>
                          <div className='flex gap-x-2 items-center'>
                            <div className='font-bold'>{item.type}</div>
                            {item.timestamp && (
                              <Tooltip
                                title={moment(item?.timestamp).format(
                                  'MMM DD, YYYY [at] hh:mm a'
                                )}
                              >
                                <span className='text-[#2E2B2E] text-opacity-70 text-[14px] font-normal leading-none'>
                                  · {moment(item.timestamp).fromNow()}
                                  {!(
                                    moment(new Date()).format(
                                      'MMM DD, YYYY'
                                    ) ===
                                    moment(item.timestamp).format(
                                      'MMM DD, YYYY'
                                    )
                                  ) &&
                                    ` ${moment(item.timestamp).format(
                                      '[at] hh:mm a'
                                    )}`}
                                </span>
                              </Tooltip>
                            )}
                          </div>
                          {item.startTime && item.endTime && (
                            <div>
                              Date:{' '}
                              {moment(item.startTime).format(
                                'MMM DD, YYYY - hh:mm a'
                              )}
                              {' To '} {moment(item.endTime).format('hh:mm a')}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })
                ) : activityType === 'Call' ||
                  activityType?.toLowerCase().includes('call') ? (
                  <div className='text-[14px] text-[#2e2b2e]'>
                    <Tooltip placement='bottomRight' title={displayMessage}>
                      {displayMessage && displayMessage.length > 40
                        ? displayMessage.slice(0, 40) + '...'
                        : displayMessage ?? ''}
                    </Tooltip>
                  </div>
                ) : activityType === 'Notes Created' ||
                  activityType === 'Note Created' ? (
                  <div className='text-[14px] text-[#2e2b2e]'>
                    <Tooltip placement='bottomRight' title={displayMessage}>
                      {displayMessage && displayMessage.length > 40
                        ? displayMessage.slice(0, 40) + '...'
                        : displayMessage ?? ''}
                    </Tooltip>
                  </div>
                ) : activityType?.toLowerCase().includes('sms') ? (
                  <div className='text-[14px] text-[#2e2b2e]'>
                    <Tooltip placement='bottomRight' title={displayMessage}>
                      {displayMessage && displayMessage.length > 40
                        ? displayMessage.slice(0, 40) + '...'
                        : displayMessage ?? ''}
                    </Tooltip>
                  </div>
                ) : activityType === 'Task Created' ? (
                  <div className='text-[14px] text-[#2e2b2e]'>
                    <Tooltip placement='bottomRight' title={displayMessage}>
                      {displayMessage && displayMessage.length > 100
                        ? displayMessage.slice(0, 100) + '...'
                        : displayMessage ?? ''}
                    </Tooltip>
                  </div>
                ) : (
                  <div className='text-[14px] text-[#2e2b2e]'>
                    <Tooltip placement='bottomRight' title={displayMessage}>
                      {displayMessage && displayMessage.length > 100
                        ? displayMessage.slice(0, 100) + '...'
                        : displayMessage ?? ''}
                    </Tooltip>
                  </div>
                )}
              </div>
            </div>
            {(activityType != 'Website Activity' ||
              activityType != 'Form Submission') && (
              <ArrowNarrowRightIcon
                onClick={() => {
                  setSelectedEmailThread(threadId);
                  if (activityType === 'Email') {
                    setSelectedHeader('Email');
                  }
                  if (activityType === 'Event/Meeting') {
                    setSelectedHeader('Calendar');
                  }
                  if (
                    activityType === 'Call' ||
                    activityType?.toLowerCase().includes('call')
                  ) {
                    setSelectedHeader('Calls');
                  }
                  if (
                    activityType === 'Notes Created' ||
                    activityType === 'Note Created' ||
                    activityType?.toLowerCase().includes('note')
                  ) {
                    setSelectedHeader('Notes');
                  }
                  if (activityType?.toLowerCase().includes('sms')) {
                    setSelectedHeader('SMS');
                  }
                  if (activityType === 'Task Created') {
                    setSelectedHeader('Tasks');
                  }
                }}
                height={'20px'}
                className='flex text-end absolute right-0 bottom-2 w-auto mr-4 cursor-pointer'
              />
            )}
          </div>
        </div>

        {isOpen && (
          <div className='self-stretch p-5 bg-white flex-col justify-start items-start gap-4 flex'>
            <p className='self-stretch text-softBlack_70/100 text-base font-normal leading-normal m-0'>
              Please review the document below for approval
            </p>
            <div className='self-stretch p-6 bg-white rounded border border-neutral-200 justify-start items-center gap-4 inline-flex'>
              <div className='grow shrink basis-0 justify-start items-center gap-4 flex'>
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M20.41 8.41L15.58 3.58C15.21 3.21 14.7 3 14.17 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V9.83C21 9.3 20.79 8.79 20.41 8.41ZM7 7H14V9H7V7ZM17 17H7V15H17V17ZM17 13H7V11H17V13Z'
                    fill='#323232'
                  />
                </svg>
                <div className='flex-col justify-center items-start gap-1 inline-flex'>
                  <h6 className='text-blue-600 text-base font-semibold leading-normal m-0'>
                    Purchase and Sale Agreement_20231117_V3_AC.docx
                  </h6>
                  <p className='text-[#2E2B2E] text-opacity-70 text-base font-normal leading-normal m-0'>
                    Received from Sales on Nov 12, 2023 at 2:30 PM
                  </p>
                </div>
              </div>
              <svg
                className='cursor-pointer'
                width='20'
                height='20'
                viewBox='0 0 20 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <g clip-path='url(#clip0_1_2674)'>
                  <path
                    d='M9.99967 6.66659C10.9163 6.66659 11.6663 5.91659 11.6663 4.99992C11.6663 4.08325 10.9163 3.33325 9.99967 3.33325C9.08301 3.33325 8.33301 4.08325 8.33301 4.99992C8.33301 5.91659 9.08301 6.66659 9.99967 6.66659ZM9.99967 8.33325C9.08301 8.33325 8.33301 9.08325 8.33301 9.99992C8.33301 10.9166 9.08301 11.6666 9.99967 11.6666C10.9163 11.6666 11.6663 10.9166 11.6663 9.99992C11.6663 9.08325 10.9163 8.33325 9.99967 8.33325ZM9.99967 13.3333C9.08301 13.3333 8.33301 14.0833 8.33301 14.9999C8.33301 15.9166 9.08301 16.6666 9.99967 16.6666C10.9163 16.6666 11.6663 15.9166 11.6663 14.9999C11.6663 14.0833 10.9163 13.3333 9.99967 13.3333Z'
                    fill='#323232'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_1_2674'>
                    <rect width='20' height='20' fill='white' />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <p className='self-stretch text-softBlack_70/100 text-base font-normal leading-normal m-0'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
            <div className='self-stretch pt-4 justify-start items-start gap-2 inline-flex'>
              <div className='grow shrink basis-0 justify-start items-center gap-4 flex'>
                <button className='px-4 py-2 rounded border border-softBlack_70/100 border-opacity-70 justify-start items-center gap-2 flex'>
                  <svg
                    width='20'
                    height='21'
                    viewBox='0 0 20 21'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <g clip-path='url(#clip0_100_332)'>
                      <path
                        d='M3.25033 10.4999C3.25033 9.07492 4.40866 7.91658 5.83366 7.91658H9.16699V6.33325H5.83366C3.53366 6.33325 1.66699 8.19992 1.66699 10.4999C1.66699 12.7999 3.53366 14.6666 5.83366 14.6666H9.16699V13.0833H5.83366C4.40866 13.0833 3.25033 11.9249 3.25033 10.4999ZM6.66699 11.3333H13.3337V9.66659H6.66699V11.3333ZM14.167 6.33325H10.8337V7.91658H14.167C15.592 7.91658 16.7503 9.07492 16.7503 10.4999C16.7503 11.9249 15.592 13.0833 14.167 13.0833H10.8337V14.6666H14.167C16.467 14.6666 18.3337 12.7999 18.3337 10.4999C18.3337 8.19992 16.467 6.33325 14.167 6.33325Z'
                        fill='#2E2B2E'
                      />
                    </g>
                    <defs>
                      <clipPath id='clip0_100_332'>
                        <rect
                          width='20'
                          height='20'
                          fill='white'
                          transform='translate(0 0.5)'
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <span className='text-center text-softBlack_70/100 text-sm font-medium leading-[21px]'>
                    Share document
                  </span>
                </button>
                <button className='px-4 py-2 rounded border border-softBlack_70/100 border-opacity-70 justify-start items-center gap-2 flex'>
                  <svg
                    width='20'
                    height='21'
                    viewBox='0 0 20 21'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <g clip-path='url(#clip0_1_2680)'>
                      <path
                        d='M7.50033 7.16667H12.5003V12.1667H15.8337L10.0003 18L4.16699 12.1667H7.50033V7.16667ZM4.16699 5.5H15.8337V3.83333H4.16699V5.5Z'
                        fill='#323232'
                      />
                    </g>
                    <defs>
                      <clipPath id='clip0_1_2680'>
                        <rect
                          width='20'
                          height='20'
                          fill='white'
                          transform='matrix(1 0 0 -1 0 20.5)'
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <span className='text-center text-softBlack_70/100 text-sm font-medium leading-[21px]'>
                    Download document
                  </span>
                </button>
              </div>
              <button className='px-4 py-2 bg-volt rounded justify-start items-center gap-2 flex'>
                <svg
                  width='20'
                  height='21'
                  viewBox='0 0 20 21'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <g clip-path='url(#clip0_100_154)'>
                    <path
                      d='M7.49994 14L4.58327 11.0833C4.25827 10.7583 3.7416 10.7583 3.4166 11.0833C3.0916 11.4083 3.0916 11.925 3.4166 12.25L6.90827 15.7416C7.23327 16.0666 7.75827 16.0666 8.08327 15.7416L16.9166 6.91664C17.2416 6.59164 17.2416 6.07498 16.9166 5.74998C16.5916 5.42498 16.0749 5.42498 15.7499 5.74998L7.49994 14Z'
                      fill='#2E2B2E'
                    />
                  </g>
                  <defs>
                    <clipPath id='clip0_100_154'>
                      <rect
                        width='20'
                        height='20'
                        fill='white'
                        transform='translate(0 0.5)'
                      />
                    </clipPath>
                  </defs>
                </svg>
                <span className='text-center text-softBlack_70/100 text-sm font-medium leading-[21px]'>
                  Approve
                </span>
              </button>
            </div>
          </div>
        )}
      </div>
    );
  };

  const AccordionWrapper = ({ items }) => {
    return (
      <>
        {items.length && (
          <div className='self-stretch flex-col gap-y-8 justify-start items-start relative inline-flex'>
            {items.map((item) => (
              <div className='w-full'>
                <AccordionItem item={item} />
                {/* {items[items.length - 1] !== item && (
                )} */}
              </div>
            ))}
            <div className=' bg-[grey] ml-8 -bottom-4 h-full absolute top-0 w-[1px]'></div>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {isLoadingActivity ? (
        <div className='flex flex-col gap-6 p-4 pt-8'>
          <Skeleton height={100} />
        </div>
      ) : (
        <>
          {activities?.length > 0 ? (
            <div className='w-full p-4 md:p-6 bg-white flex-col justify-start items-start gap-4 inline-flex'>
              <div className='self-stretch pb-4 justify-start items-center gap-3 md:gap-4 flex flex-col md:flex-row'>
                <CustomButton
                  handleClick={() => history.push(ADMIN_RESERVATION_NEW)}
                  disabled={isLoadingActivity}
                  className='w-full md:w-fit'
                >
                  <span className='p-2 md:px-6 justify-center items-center gap-2 flex'>
                    <svg
                      width='22'
                      height='22'
                      viewBox='0 0 22 22'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g clip-path='url(#clip0_100_633)'>
                        <path
                          d='M17.4168 11.9166H11.9168V17.4166H10.0835V11.9166H4.5835V10.0833H10.0835V4.58331H11.9168V10.0833H17.4168V11.9166Z'
                          fill='#FFF'
                        />
                      </g>
                      <defs>
                        <clipPath id='clip0_100_633'>
                          <rect width='22' height='22' fill='white' />
                        </clipPath>
                      </defs>
                    </svg>
                    <span className='text-center text-xs md:text-sm font-medium italic'>
                      Create reservation
                    </span>
                  </span>
                </CustomButton>
                <div className='flex gap-3 md:gap-4 w-full md:w-fit'>
                  <CustomButton
                    handleClick={() => setSelectedHeader('Tasks')}
                    disabled={isLoadingActivity}
                    className='w-1/2 md:w-fit'
                    variant='outline'
                  >
                    <span className='p-2 md:px-6 justify-center items-center gap-2 flex'>
                      <svg
                        width='22'
                        height='22'
                        viewBox='0 0 22 22'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <g clip-path='url(#clip0_100_633)'>
                          <path
                            d='M17.4168 11.9166H11.9168V17.4166H10.0835V11.9166H4.5835V10.0833H10.0835V4.58331H11.9168V10.0833H17.4168V11.9166Z'
                            fill='#2E2B2E'
                          />
                        </g>
                        <defs>
                          <clipPath id='clip0_100_633'>
                            <rect width='22' height='22' fill='white' />
                          </clipPath>
                        </defs>
                      </svg>
                      <span className='text-center text-xs md:text-sm font-medium italic leading-normal'>
                        Add task
                      </span>
                    </span>
                  </CustomButton>
                  <CustomButton
                    handleClick={fetchActivities}
                    disabled={isLoadingActivity}
                    className='w-1/2 md:w-fit'
                    variant='outline'
                  >
                    <span className='p-2 md:px-6 justify-center items-center gap-2 flex'>
                      <svg
                        width='22'
                        height='22'
                        viewBox='0 0 22 22'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <g clip-path='url(#clip0_100_603)'>
                          <path
                            fill-rule='evenodd'
                            clip-rule='evenodd'
                            d='M11.9167 8.70833H16.5V6.875H11.9167V8.70833ZM11.9167 15.125H16.5V13.2917H11.9167V15.125ZM17.4167 19.25H4.58333C3.575 19.25 2.75 18.425 2.75 17.4167V4.58333C2.75 3.575 3.575 2.75 4.58333 2.75H17.4167C18.425 2.75 19.25 3.575 19.25 4.58333V17.4167C19.25 18.425 18.425 19.25 17.4167 19.25ZM5.5 10.0833H10.0833V5.5H5.5V10.0833ZM6.41667 6.41667H9.16667V9.16667H6.41667V6.41667ZM5.5 16.5H10.0833V11.9167H5.5V16.5ZM6.41667 12.8333H9.16667V15.5833H6.41667V12.8333Z'
                            fill='#2E2B2E'
                          />
                        </g>
                        <defs>
                          <clipPath id='clip0_100_603'>
                            <rect width='22' height='22' fill='white' />
                          </clipPath>
                        </defs>
                      </svg>
                      <span className='text-center text-xs md:text-sm font-medium italic leading-normal'>
                        Log activity
                      </span>
                    </span>
                  </CustomButton>
                </div>
              </div>
              {/* <div className='self-stretch pb-6 flex-col justify-start items-start gap-4 inline-flex'>
                <h2 className='text-softBlack_70/100 text-xl font-semibold leading-none m-0'>Next step</h2>
                <AccordionItem name='Sales' subText='requested your review for approval · just now' />
              </div> */}
              <div className='self-stretch pb-6 flex-col justify-start items-start gap-4 inline-flex'>
                <h2 className='self-stretch m-0 text-softBlack_70/100 text-xl font-semibold leading-none'>
                  Activity log
                </h2>
                <AccordionWrapper items={activities} />
              </div>
            </div>
          ) : (
            <NoActivityView />
          )}
        </>
      )}
    </>
  );
};

export default ActivityTab;
