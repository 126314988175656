// @ts-nocheck
/* eslint-disable */
import { Drawer, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import useGetLists from 'hooks/useGetLists';
import { useParams, useHistory } from 'react-router-dom';
import { createOrUpdateEmailCampaign } from 'store/actions/customEmailCampaignActions';
import { ADMIN_EMAIL_CUSTOM_CAMPAIGNS_BUILDER } from 'routes';
import CustomSelectBuyerModal from 'components/CustomSelectBuyerModal';
import useGetProjectBuyers from 'hooks/useGetProjectBuyers';
import { ArrowDownIcon } from 'components';

const CreateEmailCampaign = ({ onClose, open }) => {
  const history = useHistory();
  const [emailCampaign, setEmailCampaign] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { lists, isLoading } = useGetLists();
  const { loading: loadingBuyers, buyers } = useGetProjectBuyers();
  const { templateId } = useParams();
  const [selectedBuyers, setSelectedBuyers] = useState([]);
  const [formattedList, setFormattedList] = useState([]);
  const handleSubmit = () => {
    console.log(emailCampaign);
    createOrUpdateEmailCampaign({
      ...emailCampaign,
      _id: templateId,
    }).then(() => history.push(ADMIN_EMAIL_CUSTOM_CAMPAIGNS_BUILDER));
    onClose();
  };

  useEffect(() => {
    setFormattedList(
      lists?.map?.((val) => ({
        label: val.title,
        value: val._id,
        id: val._id,
      })) || null
    );
  }, [lists]);

  return (
    <>
      <Drawer closable={false} placement='right' onClose={onClose} open={open}>
        <div className='px-8 py-12'>
          <h1 className='text-2xl font-semibold'>Create an Email Campaign</h1>
          <div className='flex flex-col gap-6 my-10 mb-14'>
            <div className='flex flex-1 flex-col justify-left'>
              <div className='font-bold text-softBlack_70 italic'>
                Name of your Email Campaign
              </div>
              <input
                onChange={(e) =>
                  setEmailCampaign({
                    ...emailCampaign,
                    name: e.target.value,
                  })
                }
                placeholder='Email Campaign Name'
              />
            </div>
            <div className='flex flex-1 flex-col justify-left'>
              <div className='font-bold text-softBlack_70 italic'>
                Email Subject
              </div>
              <input
                onChange={(e) =>
                  setEmailCampaign({
                    ...emailCampaign,
                    subject: e.target.value,
                  })
                }
                placeholder='Email Subject'
              />
            </div>
            <div className='flex-col justify-left '>
              <div className='font-bold text-softBlack_70 italic'>
                Select Lists
              </div>
              <Select
                suffixIcon={<ArrowDownIcon />}
                showArrow
                getPopupContainer={trigger => trigger.parentNode}
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toString()
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onChange={(value) =>
                  setEmailCampaign({
                    ...emailCampaign,
                    list: value,
                  })
                }
                value={emailCampaign?.list || 'Select from here'}
                placeholder='Select from here'
                className='rating-select text-sm bg-white border border-softBlack30 rounded text-softBlack placeholder-softBlack70 h-10'
              >
                {formattedList !== [] && formattedList?.map?.((_item) => (
                  <Select.Option
                    value={_item?.value}
                    key={_item.value}
                    label={_item?.label}
                  >
                    <div className='flex gap-2 items-center'>
                      <span className='h-full'>{_item.label}</span>
                    </div>
                  </Select.Option>
                ))}
              </Select>
            </div>
            {selectedBuyers.length > 0 && (
              <div className='flex flex-1 flex-col justify-left'>
                <div className='font-bold text-softBlack_70 italic'>
                  {selectedBuyers.length} Additional Buyers{' '}
                </div>
              </div>
            )}
            <button
              className='text-softBlack text-base font-medium py-2 px-6 rounded flex justify-center items-center bg-volt'
              onClick={() => setIsModalOpen(true)}
            >
              Select Additional Buyers
            </button>
          </div>
          <div className='flex justify-end gap-2'>
            <button
              className='text-softBlack text-center text-base font-medium py-2 px-6 rounded flex items-center border border-black'
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              className='text-softBlack text-center text-base font-medium py-2 px-6 rounded flex items-center bg-volt'
              onClick={handleSubmit}
            >
              Create
            </button>
          </div>
        </div>
      </Drawer>
      <CustomSelectBuyerModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        loading={loadingBuyers}
        buyers={buyers}
        selectedBuyers={selectedBuyers}
        setSelectedBuyers={(buyers) => {
          setSelectedBuyers(buyers);
          setEmailCampaign({
            ...emailCampaign,
            users: buyers,
          });
        }}
      />
    </>
  );
};

export default CreateEmailCampaign;
