// @ts-nocheck
import CustomInput from 'components/CustomInput'
import {
  Search,
  ChevronsUpDown,
  Pen,
  Download,
  Bell
} from 'lucide-react'
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import Checkbox from '../../Checkbox'
import { H5 } from 'ui'
import SubText from '../../SubText'
import ContractsTable from '../../ContractTable'
import Button from '../../Button'
import { getOfferContractsPreview } from 'store/actions/offerActions'

const CustomCheckBox = () => {
  const [isChecked, setIsChecked] = useState(false)

  return (
    <Checkbox
      checked={isChecked}
      handleChange={() => setIsChecked(!isChecked)}
      iconClasses='self-start my-1'
    />
  )
}

const MemoTable = ({
  dataSource,
  columns,
  loading,
  idBeingDeleted,
  deleting,
  showTablePagination
}) => {
  return (
    <ContractsTable
      className='rounded-lg'
      dataSource={dataSource}
      columns={columns}
      tailwindTable
      loading={loading}
      idBeingDeleted={idBeingDeleted}
      deleting={deleting}
      isFromContracts
      showTablePagination={showTablePagination}
      noMargin
    />
  )
}

const ContractsTab = ({ openDocumentsModal, transactionId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [contracts, setContracts] = useState([])
  const [search, setSearch] = useState('')
  const tabHeaders = [
    {
      id: 'all',
      title: 'All'
    },
    {
      id: 'forApproval',
      title: 'For approval'
    },
    {
      id: 'requiresNegotiation',
      title: 'Requires negotiation'
    },
    {
      id: 'pendingSignature',
      title: 'Pending signature'
    },
    {
      id: 'needsReview',
      title: 'Needs review'
    }
  ]
  const [selectedTabHeader, setSelectedTabHeader] = useState(tabHeaders[0].title)
  const menus = [
    {
      id: 'tab-1',
      name: 'Default order'
    },
    {
      id: 'tab-2',
      name: 'Order for buyer'
    }
  ]
  const [selectedMenu, setSelectedMenu] = useState('tab-1')

  const _getOfferContracts = useCallback(() => {
    if (transactionId) {
      setIsLoading(true)
      getOfferContractsPreview(transactionId)
        .then((response) => {
          if (response.contracts?.length) {
            setContracts(response.contracts)
          }
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [transactionId])

  useEffect(() => {
    _getOfferContracts()
  }, [_getOfferContracts])

  // const tableDataSource = useMemo(() => {
  //   return [
  //     {
  //       id: 1,
  //       title: 'Disclosure of representation in trading services [DRTS]',
  //       type: 'Compliance',
  //       firstParty: 'Signed',
  //       secondParty: 'Signed'
  //     },
  //     {
  //       id: 2,
  //       title: 'Disclosure of risk to unrepresented parties [DRUP]',
  //       type: 'Compliance',
  //       firstParty: 'Signed',
  //       secondParty: '—'
  //     },
  //     {
  //       id: 3,
  //       title: 'Disclosure to sellers of unexpected remueneration [DSER]',
  //       type: 'Compliance',
  //       firstParty: 'Signed',
  //       secondParty: '—'
  //     },
  //     {
  //       id: 4,
  //       title: 'Disclosure of Interest in Trade [DIT]',
  //       type: 'Compliance',
  //       firstParty: 'Signed',
  //       secondParty: '—'
  //     },
  //     {
  //       id: 5,
  //       title: 'FINTRAC | Individual, Corporation, Beneficial, Politically Exposed',
  //       type: 'Compliance',
  //       firstParty: 'Signed',
  //       secondParty: '—'
  //     },
  //     {
  //       id: 6,
  //       title: 'FINTRAC | Receipt of Funds',
  //       type: 'Compliance',
  //       firstParty: '—',
  //       secondParty: '—'
  //     },
  //     {
  //       id: 7,
  //       title: 'Acknowledgement of Receipt',
  //       type: 'Contract',
  //       firstParty: '—',
  //       secondParty: '—'
  //     },
  //     {
  //       id: 8,
  //       title: 'Deposit Receipt',
  //       type: 'Contract',
  //       firstParty: '—',
  //       secondParty: '—'
  //     },
  //     {
  //       id: 9,
  //       title: 'Offer or purchase and Sale',
  //       type: 'Contract',
  //       firstParty: '—',
  //       secondParty: '—'
  //     }
  //   ]
  // }, [])

  const mandatoryDocsTableDataSource = useMemo(() => {
    return contracts?.map((contract) => {
      return {
        id: contract.contractId,
        name: contract.contractTemplate.name,
        buyer: contract.signatures[0].status,
        devAdmin: contract.signatures[1].status,
        url: contract.url
      }
    })
  }, [contracts])

  const optionalDocsTableDataSource = useMemo(() => {
    return []
  }, [])

  // const tableColumns = useMemo(
  //   () => [
  //     {
  //       Title: (
  //         <div className='items-center inline-flex'>
  //           <CustomCheckBox />
  //           <SubText className='text-center text-zinc-500 text-xs font-medium leading-none' label='Document' />
  //           <ChevronsUpDown className='w-4 h-4 text-zinc-500 dark:text-zinc-300' />
  //         </div>
  //       ),
  //       accessor: 'title',
  //       dataIndex: 'title',
  //       Cell: (props) => (
  //         <div className='items-center flex'>
  //           <CustomCheckBox />
  //           <SubText className='mb-0 text-zinc-950 text-sm font-normal leading-tight truncate' label={props.cell.value} />
  //         </div>
  //       )
  //     },
  //     {
  //       Title: (
  //         <div className='items-center inline-flex'>
  //           <SubText className='text-center text-zinc-500 text-xs font-medium leading-none' label='Type' />
  //           <ChevronsUpDown className='w-4 h-4 text-zinc-500 dark:text-zinc-300' />
  //         </div>
  //       ),
  //       accessor: 'type',
  //       dataIndex: 'buyer',
  //       Cell: (props) => (
  //         <div className='px-[11px] py-[3px] rounded-md border border-zinc-200 dark:border-zinc-500 justify-center items-center gap-2 inline-flex'>
  //           <SubText className='text-zinc-950 text-xs font-semibold leading-none capitalize mb-0' label={props.cell.value} />
  //         </div>
  //       )
  //     },
  //     {
  //       Title: (
  //         <div className='items-center inline-flex'>
  //           <SubText className='text-center text-zinc-500 text-xs font-medium leading-none' label='Party 1' />
  //           <ChevronsUpDown className='w-4 h-4 text-zinc-500 dark:text-zinc-300' />
  //         </div>
  //       ),
  //       accessor: 'firstParty',
  //       dataIndex: 'firstParty',
  //       Cell: (props) => <SubText className='mb-0 text-zinc-950 text-sm font-normal leading-tight capitalize' label={props.cell.value} />
  //     },
  //     {
  //       Title: (
  //         <div className='items-center inline-flex'>
  //           <SubText className='text-center text-zinc-500 text-xs font-medium leading-none' label='Party 2' />
  //           <ChevronsUpDown className='w-4 h-4 text-zinc-500 dark:text-zinc-300' />
  //         </div>
  //       ),
  //       accessor: 'secondParty',
  //       dataIndex: 'secondParty',
  //       Cell: (props) => <SubText className='mb-0 text-zinc-950 text-sm font-normal leading-tight capitalize' label={props.cell.value} />
  //     },
  //     {
  //       Title: '',
  //       accessor: 'id',
  //       Cell: (props) => (
  //         <div className='w-full justify-end items-start gap-2 inline-flex'>
  // <button className='p-2 rounded-md justify-center items-center gap-2 flex cursor-pointer bg-white dark:bg-zinc-900 dark:hover:bg-zinc-900/90 border border-zinc-200 dark:text-white shadow'>
  //   <Pen className='w-4 h-4' />
  // </button>
  // <button className='p-2 rounded-md justify-center items-center gap-2 flex cursor-pointer bg-white dark:bg-zinc-900 dark:hover:bg-zinc-900/90 border border-zinc-200 dark:text-white shadow'>
  //   <Download className='w-4 h-4' />
  // </button>
  //           <Button
  //             label='Send reminder'
  //             isIcon
  //             Icon={Bell}
  //           />
  //         </div>
  //       )
  //     }
  //   ],
  //   []
  // )

  const tableColumns = useMemo(
    () => [
      {
        Title: (
          <div className='items-center inline-flex'>
            <CustomCheckBox />
            <SubText className='text-center text-zinc-500 text-xs font-medium leading-none' label='Document' />
            <ChevronsUpDown className='w-4 h-4 text-zinc-500 dark:text-zinc-300' />
          </div>
        ),
        accessor: 'name',
        dataIndex: 'name',
        Cell: (props) => (
          <div className='items-center flex'>
            <CustomCheckBox />
            <SubText className='mb-0 text-zinc-950 text-sm font-normal leading-tight truncate' label={props.cell.value} />
          </div>
        )
      },
      {
        Title: (
          <div className='items-center inline-flex'>
            <SubText className='text-center text-zinc-500 text-xs font-medium leading-none' label='Buyer' />
            <ChevronsUpDown className='w-4 h-4 text-zinc-500 dark:text-zinc-300' />
          </div>
        ),
        accessor: 'buyer',
        dataIndex: 'buyer',
        Cell: (props) => <SubText className='mb-0 text-zinc-950 text-sm font-normal leading-tight capitalize' label={props.cell.value} />
      },
      {
        Title: (
          <div className='items-center inline-flex'>
            <SubText className='text-center text-zinc-500 text-xs font-medium leading-none' label='Developer Admin' />
            <ChevronsUpDown className='w-4 h-4 text-zinc-500 dark:text-zinc-300' />
          </div>
        ),
        accessor: 'devAdmin',
        dataIndex: 'devAdmin',
        Cell: (props) => <SubText className='mb-0 text-zinc-950 text-sm font-normal leading-tight capitalize' label={props.cell.value} />
      },
      {
        Title: '',
        accessor: 'id',
        Cell: (props) => (
          <div className='w-full flex flex-row justify-end items-center gap-2 text-center'>
            <button
              className='p-2 rounded-md justify-center items-center gap-2 flex cursor-pointer bg-white dark:bg-zinc-900 dark:hover:bg-zinc-900/90 border border-zinc-200 dark:text-white shadow'
              onClick={() => openDocumentsModal({ id: transactionId })}
            >
              <Pen className='w-4 h-4' />
            </button>
            <a href={props.row.original.url} download={props.row.original.name} target='_blank'>
              <button className='p-2 rounded-md justify-center items-center gap-2 flex cursor-pointer bg-white dark:bg-zinc-900 dark:hover:bg-zinc-900/90 border border-zinc-200 text-black dark:text-white shadow'>
                <Download className='w-4 h-4' />
              </button>
            </a>
            <Button
              label='Send reminder'
              isIcon
              Icon={Bell}
            />
          </div>
        )
      }
    ],
    []
  )

  const TabView = () => (
    <div className='w-full justify-start items-center gap-1 xl:gap-2 inline-flex overflow-x-auto hide-scrollbar'>
      {
        tabHeaders.map((item) => (
          <div
            key={item.id}
            className={`px-2 py-1 ${selectedTabHeader === item.title ? 'bg-zinc-200' : ''} rounded-[100px] justify-center items-center flex cursor-pointer`}
            onClick={() => setSelectedTabHeader(item.title)}
          >
            <SubText
              className={`${selectedTabHeader === item.title ? 'text-zinc-900 dark:text-zinc-900' : 'text-zinc-500'} text-xs xl:text-sm font-normal leading-[1rem] mb-0 truncate`}
              label={item.title}
            />
          </div>
        ))
      }
    </div>
  )

  return (
    <div className='w-full flex-col justify-start items-start gap-3 xl:gap-6 flex'>
      <div className='self-stretch gap-2 flex-col xl:flex-row xl:justify-between xl:items-center flex'>
        <div className='self-stretch justify-start items-center gap-4 flex'>
          <H5 className='text-zinc-950 text-base font-semibold leading-none mb-0'>Contracts</H5>
        </div>
        <div className='flex-1 xl:flex-none xl:justify-end xl:items-center gap-2 xl:gap-4 flex'>
          <div className='flex-1 self-stretch p-1.5 xl:px-4 xl:py-2 bg-white dark:bg-zinc-800 rounded-md shadow border border-zinc-200 dark:border-zinc-500 justify-start items-center gap-2 flex'>
            <Search className='w-4 h-4 dark:text-white' />
            <div className='flex-1'>
              <CustomInput
                classes='w-full xl:w-48 grow shrink basis-0 text-sm font-normal leading-tight border-none p-0 h-4 focus:outline-none focus:ring-0 rounded-none dark:bg-zinc-800 dark:text-white'
                placeholder='Search'
                onChange={(e) => setSearch(e.target.value)}
                value={search}
                isDarkFont
              />
            </div>
          </div>
          <div className='w-fit flex items-center gap-x-2 p-1 rounded-lg bg-[#C1C0C1] dark:bg-zinc-800 bg-opacity-20'>
            {
              menus.map((tab) => (
                <div
                  key={tab.id}
                  onClick={() => setSelectedMenu(tab.id)}
                  className={`flex items-center px-2 py-1 cursor-pointer rounded-md font-medium text-xs xl:text-sm dark:text-white ${selectedMenu === tab.id && 'bg-white dark:bg-zinc-600 shadow-sm'} transition-all`}
                >
                  {tab.name}
                </div>
              ))
            }
          </div>
        </div>
      </div>
      <TabView />
      <div className='self-stretch flex-col justify-start items-start gap-2 flex'>
        <SubText
          variant='light'
          className='text-zinc-500 text-sm font-normal leading-tight'
          label={`Mandatory (${mandatoryDocsTableDataSource?.length})`}
        />
        <div className='w-full'>
          <MemoTable
            dataSource={mandatoryDocsTableDataSource}
            columns={tableColumns}
            loading={isLoading}
            idBeingDeleted={''}
            deleting={false}
            showTablePagination={false}
          />
        </div>
      </div>
      <div className='self-stretch flex-col justify-start items-start gap-2 flex'>
        <SubText
          variant='light'
          className='text-zinc-500 text-sm font-normal leading-tight'
          label={`Optional (${optionalDocsTableDataSource?.length})`}
        />
        <div className='w-full'>
          <MemoTable
            dataSource={optionalDocsTableDataSource}
            columns={tableColumns}
            loading={isLoading}
            idBeingDeleted={''}
            deleting={false}
            showTablePagination={false}
          />
        </div>
      </div>
    </div>
  )
}

export default ContractsTab
