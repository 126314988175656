import { Drawer } from 'antd'
import React, { useState } from 'react'

const CreateEmailTemplateSlider = ({ onSubmit, onClose, open }) => {
  const [templateName, setTemplateName] = useState('')
  const handleSubmit = () => {
    onSubmit(templateName)
    setTemplateName('')
  }

  return (
    <Drawer closable={false} placement="right" onClose={onClose} open={open}>
      <div className='px-8 py-12'>
        <h1 className='text-2xl font-semibold'>Create a New Email Template</h1>
        <div className='flex flex-1 flex-col justify-left my-10'>
          <div className='font-bold text-softBlack_70 italic'>Name of your new Email Template</div>
          <input className='' value={templateName} onChange={(e) => setTemplateName(e.target.value)} placeholder='Email Template Name' />
        </div>
        <div className='flex justify-end gap-2'>
          <button
            className='text-softBlack text-center text-base font-medium py-2 px-6 rounded flex items-center border border-black'
            onClick={() => {
              setTemplateName('')
              onClose()
            }}
          >
            Cancel
          </button>
          <button
            className='text-softBlack text-center text-base font-medium py-2 px-6 rounded flex items-center bg-volt'
            onClick={handleSubmit}
          >
            Create
          </button>
        </div>
      </div>
    </Drawer>
  )
}

export default CreateEmailTemplateSlider
