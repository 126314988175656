// @ts-nocheck
import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { message } from 'antd'
import { Oval } from 'react-loader-spinner'
import CustomModal from 'components/Modal'
import CustomInput from 'components/CustomInput'
import CustomTextArea from 'components/CustomTextArea'
import { createOrUpdateCalendarEvent, deleteCalendarEvent } from 'store/actions/usersActions'
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'
import { getCalenderDateMomentUsingObjectType, classNames } from 'utils'
import { isMobile } from 'react-device-detect'
import moment from 'moment'
import { ParticipantsSelector } from 'pages/calendar/ParticipantsSelector'

const emptyStates = {
  title: '',
  location: '',
  start_at: '',
  end_at: '',
  description: '',
  participants: []
}

const NewMeeting = ({ isOpen, setOpen, contact, meeting = null, onSuccess, buyers = null, canDelete = false }) => {
  // @ts-ignore
  const appProject = useSelector((state) => state.appReducer.appProject)
  const [state, setState] = useState({})
  const [loading, setLoading] = useState(false)
  const [loadingDelteEvent, setLoadingDeleteEvent] = useState(false)

  useEffect(() => {
    if (meeting && contact) {
      const initialValues = {
        title: meeting?.title ?? emptyStates.title,
        location: meeting?.location ?? emptyStates.location,
        start_at: (meeting?.when?.startTime ?? meeting?.when?.startDate) ? moment(getCalenderDateMomentUsingObjectType(meeting?.when?.object, (meeting?.when?.startTime || meeting?.when?.startDate))).format('YYYY-MM-DDTHH:mm') : emptyStates.start_at,
        end_at: (meeting?.when?.endTime ?? meeting?.when?.endDate) ? moment(getCalenderDateMomentUsingObjectType(meeting?.when?.object, (meeting?.when?.endTime || meeting?.when?.endDate))).format('YYYY-MM-DDTHH:mm') : emptyStates.end_at,
        description: meeting?.description ?? emptyStates.description,
        participants: meeting?.participants?.length > 0 ? meeting?.participants.filter((_p) => _p.email !== contact?.email) : emptyStates.participants
      }
      setState({ ...initialValues })
    }
  }, [meeting, contact])

  const onChange = useCallback((fieldName, value) => {
    setState((prevState) => { return { ...prevState, [fieldName]: value } })
  }, [state])

  const handleClose = () => {
    setState({ ...emptyStates })
    setOpen(false)
  }

  const onValidate = () => {
    const requiredFields = ['title', 'location', 'start_at', 'end_at']
    for (let i = 0; i < requiredFields.length; i++) {
      if (state[requiredFields[i]] === '') {
        return false
      }
    }
    return true
  }

  const onDelete = async () => {
    setLoadingDeleteEvent(true)
    try {
      await deleteCalendarEvent(meeting?._id)
      setLoadingDeleteEvent(false)
      onSuccess()
      if (!meeting?._id) {
        setState({ ...emptyStates })
      }
      handleClose()
    } catch (err) {
      setLoadingDeleteEvent(false)
    }
  }

  const onSave = async () => {
    if (!onValidate()) {
      message.error('Please fill all all required fields')
      return
    }

    /* eslint-disable camelcase */
    const { firstName: name, email, phone_number } = contact
    const params = {
      _id: meeting?.id ?? meeting?._id ?? undefined,
      project: appProject,
      /* eslint-disable camelcase */
      participants: [...state.participants, { name, email, phone_number }],
      title: state.title,
      location: state.location,
      description: state.description,
      notify_participants: true,
      when: {
        start_time: (new Date(state.start_at)) * (meeting?._id ? 1 / 1000 : 1),
        end_time: (new Date(state.end_at)) * (meeting?._id ? 1 / 1000 : 1),
        object: 'timespan'
      }
    }

    setLoading(true)
    try {
      await createOrUpdateCalendarEvent(params)
      setLoading(false)
      onSuccess()
      if (!meeting?._id) {
        setState({ ...emptyStates })
      }
      handleClose()
    } catch (err) {
      setLoading(false)
    }
  }

  return (
    <CustomModal isOpen={isOpen} closeModal={handleClose} contentLabel='New Meeting Modal' >
      <div
        className='w-full font-openSans relative bg-grey-8 p-5 md:p-10 rounded shadow-lg'
        style={{ width: isMobile ? '95vw' : 544 }}
      >
        <button className='absolute right-5 md:right-10' onClick={handleClose}><CrossIcon /></button>

        <h1 className='font-bold text-xl text-center p-0 m-0'>{meeting?._id ? 'Edit Meeting' : 'New Meeting'}</h1>

        <div className={`flex flex-col ${isMobile ? 'gap-2' : 'gap-6 my-8'}`}>
          <CustomInput placeholder='Enter topic' label='Topic *' value={state.title} classes='text-base' onChange={(e) => onChange('title', e.target.value)} />
          <CustomInput placeholder='Enter location' label='Location *' value={state.location} classes='text-base' onChange={(e) => onChange('location', e.target.value)} />
          <CustomInput type='datetime-local' placeholder={isMobile ? 'Time' : 'Enter time'} value={(state.start_at)} label='START AT *' classes='text-base'
            onChange={(e) => onChange('start_at', moment(e.target.value).format('YYYY-MM-DDTHH:mm'))}
          />
          <CustomInput type='datetime-local' placeholder={isMobile ? 'Time' : 'Enter time'} value={(state.end_at)} label='FINISH AT *' classes='text-base'
            onChange={(e) => onChange('end_at', moment(e.target.value).format('YYYY-MM-DDTHH:mm'))}
          />
          <CustomTextArea label='Description' placeholder='Enter Description' value={state.description} onChange={(e) => onChange('description', e.target.value)} style={{ color: '#2E2B2E' }} />
          <ParticipantsSelector selectedParticipants={state.participants} buyers={buyers.filter((_b) => _b.email !== contact?.email)} setSelectedParticipants={(value) => {
            onChange('participants', value)
          }} />
          <div className='flex flex-row gap-2 items-center justify-center'>
            {canDelete && meeting?._id
              ? <button onClick={onDelete} className='flex gap-2 py-3 px-6 text-base bg-red-500 rounded font-medium'>{loadingDelteEvent && <Oval height='18' width='18' color='black' />}Delete</button>
              : null
            }
            <button
              onClick={onSave}
              disabled={!onValidate()}
              className={classNames('flex gap-2 py-3 px-6 text-base bg-volt rounded font-medium',
                !meeting?._id && 'flex m-auto')
              }>
              {loading && <Oval height='18' width='18' color='black' />}
              {meeting?._id ? 'Update' : 'Save'}
            </button>
          </div>
        </div>
      </div>
    </CustomModal>
  )
}

export default NewMeeting
