import React, { Suspense, useEffect, useRef } from 'react'
import propTypes from 'prop-types'
import { Route, Switch, Redirect } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import {
  Loader,
  AdminContainer,
  BuyerContainer,
  AnimatedTransitionWrapper,
  BrokerContainer,
  AdminDashboardContainer
} from 'ui'
// import OfferContainer from 'components/Offer/OfferContainer'
import { isAuthenticated } from 'services/auth'

import Login from 'pages/login'
import LayoutModeSelector from 'pages/layoutModeSelector'
import RouteDecisor from 'routeDecisor'
import PageUnderConstruction from 'pages/underConstruction'

// Buyer Pages
import BuyerAvailabilityBoard from 'pages/buyerPages/availabilityBoard'
import BuyerCreateOffer from 'pages/buyerPages/buyerCreateOffer'

// Broker Pages
import BrokerDashboardPage from 'pages/leadBrokerPages/pages/dashboardScreen'
import BrokerDocumentPage from 'pages/leadBrokerPages/pages/documentScreen'
import BrokerMainPage from 'pages/leadBrokerPages/pages/mainScreen'
import BrokerMenuPage from 'pages/leadBrokerPages/pages/menuScreen'
import BrokerLandingPageScreen from 'pages/leadBrokerPages/pages/landingPageScreen'
import BrokerProfileScreen from 'pages/leadBrokerPages/pages/brokerProfileScreen'
import BrokerOpportunitiesScreen from 'pages/leadBrokerPages/pages/brokerOpportunitiesScreen'
import BrokerCreateOpportunitiesScreen from 'pages/leadBrokerPages/pages/brokerCreateOpportunitiesScreen'
import BrokerUpdateOpportunitiesScreen from 'pages/leadBrokerPages/pages/brokerUpdateOpportunitiesScreen'
import BrokerReservationScreen from 'pages/leadBrokerPages/pages/brokerReservationScreen'
import BrokerCreateReservationScreen from 'pages/leadBrokerPages/pages/brokerCreateReservationScreen'

// Admin Pages
import OldAdminDashboard from 'pages/desktopMode/dashboard'
import AdminUsers from 'pages/desktopMode/users'
import AdminProjectManagement from 'pages/desktopMode/projectManagement/edit/EditGeneralInfo'
import AdminOffers from 'pages/desktopMode/offers'
import AdminNegotiate from 'pages/desktopMode/negotiate'
import AdminDeposits from 'pages/desktopMode/deposits'
import AdminOpportunities from 'pages/desktopMode/opportunities'
import AdminReservations from 'pages/desktopMode/reservations'
import AdminBrokerLeads from 'pages/desktopMode/brokerLeads'
import AdminOffer from 'pages/desktopMode/createOffer'
import AdminProfile from 'pages/desktopMode/profilePage'
import SchedulerMeetings from 'pages/desktopMode/profilePage/SchedulerMeetings'
import SchedulerSettings from 'pages/desktopMode/profilePage/SchedulerSettings'
import AdminCreateEditUser from 'pages/desktopMode/createEditUser'
import AdminCreateEditDeveloper from 'pages/desktopMode/createEditDeveloper'
import AdminCreateEditProject from 'pages/desktopMode/createEditProject'
import AdminCreateEditBuilding from 'pages/desktopMode/createEditBuilding'
import AdminCreateEditFloorPlan from 'pages/desktopMode/createEditFloorPlan'
import AdminCreateEditUnitGroup from 'pages/desktopMode/createEditUnitGroup'
import AdminCreateEditUnit from 'pages/desktopMode/createEditUnit'
import SalesPipeline from 'pages/desktopMode/salesPipeline'
import AdminReports from 'pages/desktopMode/AdminReports/AdminReports'
import AdminSMSBroadcast from 'pages/desktopMode/smsBroadcast'
import SalesReps from 'pages/team/salesReps'
import CoopBrokers from 'pages/team/coopBroker'
// import Contacts from 'pages/contacts/Contacts'
import NewContacts from 'pages/newContacts/NewContacts'
import AvailabilityBoard from 'pages/desktopMode/availabilityBoard'
import Allocation from 'pages/desktopMode/allocations'
// import AllocateInventory from 'pages/desktopMode/allocateInventory'
import UnitsInventory from 'pages/desktopMode/unitsInventory'
import UnitsInventoryGraph from 'pages/desktopMode/unitsInventoryGraph'
import EmailInbox from 'pages/desktopMode/emailInbox'
import AdminProjectFiles from 'pages/desktopMode/projectFiles'
import AdminProjectMap from 'pages/desktopMode/projectMap'
import AdminUnitInventoryMap from 'pages/desktopMode/unitInventoryMap'
import Settings from 'pages/settings'
import Calendar from 'pages/calendar'
import ContractTemplates from 'pages/contractTemplates'
import tasks from 'pages/tasks'

// admin new routes
import AdminDashboardNewDesign from 'pages/dashboard/_new'
import AdminDashboardNewTemporary from 'pages/dashboard/new'

import AdminPurchaseAdditionalParking from 'pages/desktopMode/purchaseAdditionalParking'
import AdminParkingSalesAndWaitlist from 'pages/desktopMode/parkingSalesAndWaitlist'
import AdminPurchaseAdditionalStorage from 'pages/desktopMode/purchaseAdditionalStorage'

import CreateOffer from 'pages/offer/new'
import OfferPreview from 'pages/offerPreview/new'
import CreateOpportunity from 'pages/opportunity/new'
import CreateReservation from 'pages/reservation/new'
import ReserveUnit from 'pages/reserveUnit'
import CreateProject from 'pages/desktopMode/projectManagement/create'

// PWA Pages
import PwaProjects from 'pages/pwaMode/projects'
import PwaProjectScreen from 'pages/pwaMode/projectScreen'
import PwaCreateOffer from 'pages/pwaMode/createOffer'
import PwaCreateOfferNew from 'pages/pwaMode/createOfferNew'
import PwaProjectOffers from 'pages/pwaMode/projectOffers'
import PwaProjectAvailabilityBoard from 'pages/pwaMode/projectAvailabilityBoard'
import PwaProjectFloorPlans from 'pages/pwaMode/projectFloorPlans'

// Buyer Discovery Survey
import BuyerDiscoverySurvey from 'pages/buyerDiscoverySurvey'

// Buyer Sign Contract
import BuyerSignContract from 'pages/buyerSignContract'

// Inventory Public Page
import InventoryPublicPage from 'pages/InventoryPublicPage'

// Edit Project
import EditProjectImages from 'pages/desktopMode/projectManagement/edit/EditProjectImages'
import EditProjectParking from 'pages/desktopMode/projectManagement/edit/EditProjectParking'
import EditProjectColors from 'pages/desktopMode/projectManagement/edit/EditProjectColors'
import EditProjectUpgrades from 'pages/desktopMode/projectManagement/edit/EditProjectUpgrades'
import EditProjectPayments from 'pages/desktopMode/projectManagement/edit/EditProjectPayments'

// Buildings
import AdminBuildings from 'pages/desktopMode/buildings'
import AddBuilding from 'pages/desktopMode/buildings/add/AddBuilding'
import AdminEditBuilding from 'pages/desktopMode/buildings/edit'
import CreateInventoryAllocation from 'pages/desktopMode/inventory/Allocation/AllocateInventory'
// import { useIntercom } from 'react-use-intercom'
import { updateLoggedUserObject } from 'utils'
import { useSelector } from 'react-redux'
import Profile from 'pages/profile/Profile'
import EmailCustomCampaigns from 'pages/emailCustomCampaigns'
import EmailCustomCampaignDetail from 'pages/emailCustomCampaigns/details'
import CreateBrokerLead from 'pages/leadPage/new'
import DynamicDropDown from 'pages/desktopMode/dynamicDropDown'
import ColumbiaComponent from 'components/ColumbiaComponent'
import { AdminDashboardComponent } from 'ui/containers/AdminContainer'
import ColumbiaSalesReport from 'components/ColumbiaSalesReport'
import ReportContainer from 'components/Report'
import BuyerHomeComponent from 'pages/buyerPages/buyerHome'
import BuyerDashBoard from 'pages/buyerPages/buyerDashboard'
import AdminLeadReport from 'pages/desktopMode/AdminLeadReport'
import AdminBrokerShannon from 'pages/desktopMode/brokerProfile/ShannonMckenzie'
import AdminReportSalesActivity from 'pages/desktopMode/AdminReports/AdminReportSalesActivity'
import AdminLeadStefan from 'pages/desktopMode/AdminLeadReport/AdminLeadStefan'
import AdminEventPage from 'pages/desktopMode/Events'
import AdminEventDetailPage from 'pages/desktopMode/Events/EventName'
import AdminCreateEditEventPage from 'pages/desktopMode/Events/CreateAndEditEvent'
import ParkingsInventory from 'pages/desktopMode/parkingsInventory'
import StoragesInventory from 'pages/desktopMode/storagesInventory'
import ParkingInventoryPublicPage from 'pages/parkingInventoryPublicPage'
import Inventories from 'pages/desktopMode/projectInventory'
import EmailCampaignBuilderPage from 'pages/emailCampaignsBuilder'
import EditOpportunity from 'pages/opportunity/EditPage/EditOpportunity'
import EditEmailTemplate from 'pages/emailCampaignsBuilder/editEmailTemplate'
import BuyerSurveyQuestionnaire from 'pages/buyerPages/buyerSurveyQuestionnaire'
import ProspectQualification from 'pages/prospectQualification'
import SalesTransaction from 'pages/salesTransaction'
import PostSalesSuccess from 'pages/postSalesSuccess'
import AdminRemiderSetting from 'pages/reminderSetting'
import PurchaseParking from 'pages/parkings/new'
import BuyerSignParkingContract from 'pages/buyerSignParkingContract'
import AdminNewDashboard from 'pages/newDashboard'
import AdminDashboardDeals from 'pages/dashboardDeals'
import StorageInventoryPublicPage from 'pages/storageInventoryPublicPage'
import BuyerSignReservationContract from 'pages/buyerSignReservationContract'
// import DashboardNewSalesRep from 'pages/dashboard/SalesRepDashboardNew'
import whiteCapDashboard from 'pages/whiteCapDashboard/DashboardWrapper'
import BookMeeting from 'pages/desktopMode/profilePage/MeetingBook'
import tasksCalendar from 'pages/tasksCalendar'
import DemandNotesDropdown from 'pages/desktopMode/demandNotes'
import EditContractTemplate from 'pages/contractTemplates/editContractTemplate'
import NewContractTemplate from 'pages/contractTemplates/newContractTemplate'
import EditProjectOffer from 'pages/desktopMode/projectManagement/edit/EditProjectOffer'
import EditProjectCustomSection from 'pages/desktopMode/projectManagement/edit/EditProjectCustomSection'
import AdminPermission from 'pages/adminPermission'
import NewWhiteCapDashboard from 'pages/newWhiteCapDashboard'
import EditProjectDepositOptions from 'pages/desktopMode/projectManagement/edit/EditProjectDepositOptions'
import NewContactsV2 from 'pages/newContacts/NewContactsV2'
import ListsManagement from 'pages/lists'
import ListDetailsPage from 'pages/lists/listDetails'
import HiddenHillsWebsiteForm from 'pages/hiddenHillsWebsiteForm'
import PondSideWebsiteForm from 'pages/pondSideWebsiteForm'
import OliveGroveWebsiteForm from 'pages/oliveGroveWebsiteForm'
import CorporateView from 'pages/corporateView'
import ReportCorporate from 'components/Report/ReportCorporate'
import corporateDashboard from 'pages/corporateDashboard'
import CorporateDemandNotesDropdown from 'pages/desktopMode/corporateDemandNotes'

// Route Decisor
export const ROUTE_DECISOR = '/'

// PUBLIC ROUTES
export const LOGIN = '/login'
export const LOGOUT = '/logout'
export const SET_PASSWORD = '/set-password'

// public path for meetings links

export const MEETING_LINK = '/book-meeting'
export const SCHEDULE_SETTING = '/scheduler_settings'
export const MEETING_LINK_BOOKING_AGAIN = '/book-meeting/:booking_ref'

// new admin routes
export const NEW_ADMIN_DASHBOARD = '/new/admin'
export const ADMIN_OFFER = '/new/admin/offer'
export const ADMIN_OFFER_PREVIEW = '/new/admin/offer_preview/:id'
export const ADMIN_OPPORTUNITY = '/new/admin/opportunity'
export const ADMIN_EDIT_OPPORTUNITY = '/new/admin/opportunity/edit/:id'
export const ADMIN_RESERVE_UNIT = '/new/admin/reserve_unit'
export const ADMIN_CREATE_INVENTORY_ALLOCATION = '/new/admin/allocations'

// PRIVATE ROUTES
export const LAYOUT_MODE_SELECTOR = '/layout_mode_selector'
export const ADMIN_DASHBOARD = '/admin'
export const ADMIN_PERMISSIONS = '/admin/permissions'
export const ADMIN_DASHBOARD_COLUMBIA = '/admin/columbia'
export const ADMIN_DASHBOARD_COLUMBIA_REPORT = '/admin/columbia/report'
export const ADMIN_DASHBOARD_OLD = '/admin/old'
export const ADMIN_DASHBOARD_NEW = '/admin/new'
export const ADMIN_DASHBOARD_NEW_TEMP_DASHBOARD = '/admin/new-dashboard'
export const ADMIN_USERS = '/admin/users'
export const ADMIN_LEADREPORT = '/admin/leadreport'
export const ADMIN_BROKER_SHANNON = '/admin/broker/shannon'
export const ADMIN_SALES_REPORT = '/admin/report/salesactivity'
export const ADMIN_LEAD_STEFAN = '/admin/lead/stefan'
export const ADMIN_EVENT_PAGE = '/admin/events'
export const ADMIN_EVENT_DETAIL_PAGE = '/admin/events/preview/:eventId'
export const ADMIN_CREATE_EVENT_PAGE = '/admin/events/create'
export const ADMIN_EDIT_EVENT_PAGE = '/admin/events/edit/:eventId'
// Project Management and its Submenus
export const ADMIN_PROJECT_MANAGEMENT = '/admin/project_management'
export const ADMIN_PROJECT_IMAGES = '/admin/project/images'
export const ADMIN_PROJECT_PARKING = '/admin/project/parking'
export const ADMIN_PROJECT_COLORS = '/admin/project/colors'
export const ADMIN_PROJECT_UPGRADES = '/admin/project/upgrades'
export const ADMIN_PROJECT_PAYMENTS = '/admin/project/payments'
export const ADMIN_PROJECT_OFFER_SETTING = '/admin/project/offer-setting'
export const ADMIN_PROJECT_CUSTOM_SECTION = '/admin/project/custom-section/:_id'
export const ADMIN_PROJECT_DEPOSIT_OPTIONS = '/admin/project/deposit-options'

// Buildings
export const ADMIN_BUILDINGS = '/admin/project/buildings'
export const ADMIN_EDIT_BUILDING = '/admin/project/buildings/view'
export const ADMIN_ADD_BUILDING = '/new/admin/project/buildings/add'

// Inventory and its Submenus
export const ADMIN_PROJECT_UNIT_INVENTORY = '/admin/project/units_inventory'
export const ADMIN_PROJECT_PARKING_INVENTORY = '/admin/project/parkings_inventory'
export const ADMIN_PROJECT_STORAGE_INVENTORY = '/admin/project/storages_inventory'
export const ADMIN_PROJECT_UNIT_INVENTORY_GRAPH = '/admin/project/units_inventory_graph'
export const ADMIN_PROJECT_ALLOCATIONS = '/admin/project/allocations'

// Files
export const ADMIN_PROJECT_FILES = '/admin/project/files'
export const ADMIN_PROJECT_MAP = '/admin/project/map'

// Unit Inventory map
export const ADMIN_UNIT_INVENTORY_MAP = '/admin/unit-inventory-map'

// Create Project
export const ADMIN_CREATE_PROJECT = '/new/admin/project/create'
export const ADMIN_PROJECT_IMAGES_NEW = '/new/admin/project/images'
export const ADMIN_PROJECT_PARKING_NEW = '/new/admin/project/parking'
export const ADMIN_PROJECT_COLORS_NEW = '/new/admin/project/colors'
export const ADMIN_PROJECT_UPGRADES_NEW = '/new/admin/project/upgrades'
export const ADMIN_PROJECT_PAYMENTS_NEW = '/new/admin/project/payments'
export const ADMIN_PROJECT_ALLOCATIONS_NEW = '/new/admin/project/allocations'

export const ADMIN_OFFERS = '/admin/offers'
export const ADMIN_OPPORTUNITIES = '/admin/opportunities'
export const ADMIN_RESERVATIONS = '/admin/reservations'
export const ADMIN_RESERVATION_NEW = '/admin/reservations/new'
export const ADMIN_RESERVATION_DETAIL = '/admin/reservations/:id'
export const ADMIN_BROKER_LEADS = '/admin/brokerLeads'
export const ADMIN_BROKER_LEADS_NEW = '/admin/brokerLeads/new'
export const ADMIN_BROKER_LEADS_DETAIL = '/admin/brokerLeads/:id'
export const ADMIN_PROFILE_PAGE = '/admin/profile'
export const ADMIN_SCHEDULER_MEETINGS = '/admin/scheduler_meetings'
export const ADMIN_SCHEDULER_SETTINGS = '/admin/scheduler_settings'
export const ADMIN_CREATE_EDIT_USER = '/admin/user'
export const ADMIN_CREATE_EDIT_DEVELOPER = '/admin/developer'
export const ADMIN_CREATE_EDIT_PROJECT = '/admin/project'
export const ADMIN_CREATE_EDIT_BUILDING = '/admin/building'
export const ADMIN_CREATE_EDIT_FLOOR_PLAN = '/admin/floorplan'
export const ADMIN_CREATE_EDIT_UNIT_GROUP = '/admin/unitgroup'
export const ADMIN_EMAIL_CAMPAIGNS = '/admin/campaigns'
export const ADMIN_EMAIL_CAMPAIGNS_BUILDER = '/admin/email-builder'
export const ADMIN_EMAIL_CAMPAIGNS_BUILDER_CORPORATE = '/admin/corporate/email-builder'
export const ADMIN_EDIT_EMAIL_TEMPLATE = '/admin/email-builder/edit/:templateId'
export const ADMIN_EMAIL_CAMPAIGNS_DETAIL = '/admin/campaigns/:id'
export const ADMIN_EMAIL_CUSTOM_CAMPAIGNS_BUILDER = '/admin/custom-campaigns-reports'
export const ADMIN_EMAIL_CUSTOM_CAMPAIGNS_BUILDER_CORPORATE = '/admin/corporate/custom-campaigns-reports'
export const ADMIN_EMAIL_CUSTOM_CAMPAIGNS_DETAIL = '/admin/custom-campaigns-reports/:id'
export const ADMIN_EMAIL_CUSTOM_CAMPAIGNS_DETAIL_CORPORATE = '/admin/corporate/custom-campaigns-reports/:id'
export const ADMIN_CREATE_EDIT_UNIT = '/admin/unit'
export const ADMIN_TEAM_SALES_REPS = '/admin/team/sales_reps'
export const ADMIN_TEAM_COOP_BROKER = '/admin/team/coop_broker'
export const ADMIN_CONTACTS = '/admin/contacts'
export const ADMIN_CORPORATE_CONTACTS = '/admin/corporate/contacts'
export const ADMIN_CORPORATE_TASKS = '/admin/corporate/tasks'
export const ADMIN_LISTS = '/admin/lists'
export const ADMIN_LISTS_CORPORATE = '/admin/corporate/lists'
export const ADMIN_LISTS_DETAIL = '/admin/lists/:list_id'
export const ADMIN_LISTS_DETAIL_CORPORATE = '/admin/corporate/lists/:list_id'
export const ADMIN_CONTACTS_ID = '/admin/contacts/:id'
export const ADMIN_CONTACTS_ID_CORPORATE = '/admin/corporate/contacts/:id'
export const ADMIN_NEW_CONTACTS = '/admin/newcontacts'
export const ADMIN_NEW_CONTACTS_V2 = '/admin/newcontacts-v2'
export const ADMIN_NEW_CONTACTS_ID = '/admin/newcontacts/:id'
export const ADMIN_NEW_CONTACTS_V2_ID = '/admin/newcontacts-v2/:id'
export const ADMIN_NEW_CONTACTS_V2_ID_CORPORATE = '/admin/newcontacts-v2/:id'
export const ADMIN_SMS_BROADCAST = '/admin/sms-broadcast'
export const ADMIN_SMS_BROADCAST_CORPORATE = '/admin/corporate/sms-broadcast'
export const SALES_PIPELINE = '/admin/sales_pipeline'
export const ADMIN_REPORTS = '/admin/reports'
export const ADMIN_PROSPECT_QUALIFICATION = '/admin/prospect_qualification'
export const ADMIN_SALES_TRANSACTION = '/admin/sales_transaction'
export const ADMIN_POST_SALES_SUCCESS = '/admin/post_sales_success'
export const ADMIN_NEGOTIATE = '/admin/negotiate'
export const ADMIN_PURCHASE_ADDITIONAL_PARKING = '/admin/purchase-additional-parking'
export const ADMIN_PURCHASE_ADDITIONAL_STORAGE = '/admin/purchase-additional-storage'
export const ADMIN_PARKING_SALES_AND_WAIT_LIST = '/admin/parking-sales-and-wait-list'
export const ADMIN_PURCHASE_PARKING = '/admin/purchase-parking'
export const PWA_PROJECTS = '/pwa'
export const PWA_PROJECT_SCREEN = '/pwa/project'
export const PWA_CREATE_OFFER = '/pwa/project/create_offer'
export const PWA_CREATE_OFFER_NEW = '/pwa/project/create_offer_new'
export const PWA_PROJECT_OFFERS = '/pwa/project/project_offers'
export const PWA_PROJECT_AVAILABILITY_BOARD =
  '/pwa/project/project_availability_board'
export const PWA_PROJECT_FLOOR_PLANS = '/pwa/project/project_floor_plans'
export const BUYER_DASHBOARD = '/buyer'
export const BUYER_HOME = '/buyer/home'
export const BUYER_HOME_DASHBOARD = '/buyer/dashboard'
export const BUYER_SIGN_DOCS = '/buyer/sign_docs'
export const BUYER_MAKE_PAYMENT = '/buyer/make_payment'
export const BUYER_CREATE_OFFER = '/buyer/create_offer'
export const BUYER_SURVEY_QUESTIONNAIRE = '/buyer/survey_questionnaire'

export const BROKER_HOME_PAGE = '/lead-broker'
export const BROKER_MENU_PAGE = '/lead-broker/menu'
export const BROKER_DASHBOARD_PAGE = '/lead-broker/dashboard'
export const BROKER_DOCUMENTS_PAGE = '/lead-broker/documents'
export const BROKER_LANDING_PAGE = '/lead-broker/brokerLeads'
export const BROKER_PROFILE_PAGE = '/lead-broker/profile'
// export const BROKER_CONTACTS_PAGE = '/lead-broker/contacts'
export const BROKER_OPPORTUNITIES_PAGE = '/lead-broker/opportunities'
export const BROKER_CREATE_OPPORTUNITY_PAGE = '/lead-broker/create_opportunity'
export const BROKER_UPDATE_OPPORTUNITY_PAGE = '/lead-broker/update_opportunity/:id'
export const BROKER_RESERVATION_PAGE = '/lead-broker/reservations'
export const BROKER_RESERVATION_DETAILS = '/lead-broker/reservations/:id'
export const BROKER_CREATE_RESERVATION_PAGE = '/lead-broker/create_reservations'
// export const BROKER_AVAILABILITY_BOARD = '/lead-broker/availability_board'

export const AVAILABILITY_BOARD = '/admin/availability_board'
export const ALLOCATIONS = '/admin/allocations'
export const ALLOCATE_INVENTORY = '/admin/allocate_inventory'
export const UNITS_INVENTORY = '/admin/units_inventory'
export const PARKINGS_INVENTORY = '/admin/parkings_inventory'
export const STORAGES_INVENTORY = '/admin/storages_inventory'
export const INVENTORIES = '/admin/inventories'
export const DEMAND_NOTES_SETTINGS = '/admin/demand_notes_settings'
export const CORPORATE_DEMAND_NOTES_SETTINGS = '/admin/corporate/demand_notes_settings'
export const PAGE_UNDER_CONSTRUCTION = '/page_under_construction'
export const ADMIN_MASTER_REPORT = '/admin/master_report'
export const ADMIN_MASTER_REPORT_CORPORATE = '/admin/corporate/master_report'
export const EMAIL_INBOX = '/admin/email_inbox'
export const SETTINGS = '/admin/settings'
export const CALENDAR = '/admin/calendar'
export const TASKS_CALENDAR = '/admin/tasks-calendar'
export const TASKS_CALENDAR_CORPORATE = '/admin/corporate/tasks-calendar'
export const CONTRACT_TEMPLATES = '/admin/contract_templates'
export const NEW_CONTRACT_TEMPLATES = '/admin/contract_templates/new/:token/:contractTemplateId/:contractType'
export const EDIT_CONTRACT_TEMPLATES = '/admin/contract_templates/:id'
export const TASKS = '/admin/tasks'
export const CORPORATE_VIEW = '/admin/corporate'
// old admin routes
export const ADMIN_OFFER_OLD = '/admin/offer'

// BUYER DISCOVERY SURVEY
export const BUYER_DISCOVERY_SURVEY = '/discovery_survey'

// BUYER SIGN CONTRACT
export const BUYER_SIGN_CONTRACT = '/public/sign'
export const BUYER_SIGN_PARKING_CONTRACT = '/public/sign/parking-contract'
export const BUYER_SIGN_RESERVATION_CONTRACT = '/public/sign/reservation-contract'

// INVENTORY
export const PUBLIC_INVENTORY_TABLE_SCREEN = '/public/inventory'
export const PUBLIC_PARKING_INVENTORY_TABLE_SCREEN = '/public/parking_inventory'
export const PUBLIC_STORAGE_INVENTORY_TABLE_SCREEN = '/public/storage_inventory'

// DESPOSITE PAYMENT
export const ADMIN_DEPOSIT_PAYMENT = '/admin/deposits'

// NEW ADMIN DASHBOARD
export const ADMIN_NEW_DASHBOARD = '/admin/dashboard/new'

// ADMIN DASHBOARD DEALS

export const ADMIN_DASHBOARD_DEALS = '/admin/dashboard/deals'

// ADMIN SALESREPS DASHBOARDADMIN_DASHBOARD_SALESREP

export const ADMIN_DASHBOARD_SALESREP = '/admin/dashboard/sales_rep'

export const ADMIN_DASHBOARD_CORPORATE = '/admin/dashboard/corporate'

// ADMIN SALESREPS DASHBOARD

export const ADMIN_DASHBOARD_WHITECAP = '/admin/dashboard'

export const ADMIN_DASHBOARD_2 = '/admin/dashboard2'

// ADMIN PROJECT REMINDER SETTINGS

export const ADMIN_REMINDER_SETTINGS = '/admin/project/reminder_setting'

// Lead registration forms

export const HIDDEN_HILLS_WEBSITE_FORM = '/registration/hidden-hills/:leadSource?'
export const PONDSIDE_WEBSITE_FORM = '/registration/pond-side/:leadSource?'
export const OLIVE_GROVE_WEBSITE_FORM = '/registration/olive-grove/:leadSource?'

export const ADMIN_PROJECT_DROPDOWNS = [
  {
    key: 'leadSource',
    path: '/admin/Lead_Source'
  },
  {
    key: 'contactType',
    path: '/admin/Contact_Type'
  },
  {
    key: 'leadStatus',
    path: '/admin/Lead_Status'
  },
  {
    key: 'brokerageCompany',
    path: '/admin/Brokerage_Company'
  },
  {
    key: 'buyerScore',
    path: '/admin/Buyer_Score'
  },
  {
    key: 'brokerScore',
    path: '/admin/Broker_Score'
  },
  {
    key: 'reasonForLost',
    path: '/admin/Reason_For_Buyer_Lost'
  },
  {
    key: 'leadApproach',
    path: '/admin/Buyer_Lead_Approach'
  },
  {
    key: 'currencyList',
    path: '/admin/Currency_List'
  },
  {
    key: 'fileTags',
    path: '/admin/File_Tags'
  },
  {
    key: 'floorNumbersInterest',
    path: '/admin/Floor_Numbers',
    type: 'opportunity'
  },
  {
    key: 'orientation',
    path: '/admin/Orientation',
    type: 'opportunity'
  },
  {
    key: 'floorPlansInterest',
    path: '/admin/Floor_Plans',
    type: 'opportunity'
  },
  {
    key: 'unitInterests',
    path: '/admin/Unit_Interests',
    type: 'opportunity'
  },
  {
    key: 'upgrades',
    path: '/admin/Upgrades',
    type: 'opportunity'
  },
  {
    key: 'numberOfBedrooms',
    path: '/admin/Number_Of_Bedrooms',
    type: 'opportunity'
  },
  {
    key: 'numberOfParkingSpots',
    path: '/admin/number_Of_Parking_Spots',
    type: 'opportunity'
  },
  {
    key: 'minimumSquareFeet',
    path: '/admin/Min_Square_Feet',
    type: 'opportunity'
  },
  {
    key: 'maximumSquareFeet',
    path: '/admin/Max_Square_Feet',
    type: 'opportunity'
  },
  {
    key: 'budget',
    path: '/admin/Budget',
    type: 'opportunity'
  },
  {
    key: 'primaryUse',
    path: '/admin/Primary_Use',
    type: 'opportunity'
  }
]

const ROUTES = [
  {
    path: ROUTE_DECISOR,
    KEY: ROUTE_DECISOR,
    exact: true,
    component: RouteDecisor
  },
  { path: LOGIN, key: LOGIN, exact: true, component: Login },
  { path: MEETING_LINK, key: MEETING_LINK, exact: true, component: BookMeeting },
  { path: MEETING_LINK_BOOKING_AGAIN, key: MEETING_LINK_BOOKING_AGAIN, exact: true, component: BookMeeting },
  { path: SCHEDULE_SETTING, key: SCHEDULE_SETTING, exact: false, component: SchedulerSettings },
  { path: LOGOUT, key: LOGOUT, exact: true, component: Login },
  { path: SET_PASSWORD, key: SET_PASSWORD, exact: false, component: Login },
  {
    path: ADMIN_DASHBOARD_2,
    key: ADMIN_DASHBOARD_2,
    exact: false,
    component: (props) => {
      if (!isAuthenticated()) {
        return <Redirect to={LOGIN} />
      }

      return (
        <AdminNewLayout2>
          <NewWhiteCapDashboard />
        </AdminNewLayout2>
      )
    }
  },
  {
    path: LAYOUT_MODE_SELECTOR,
    key: LAYOUT_MODE_SELECTOR,
    exact: true,
    component: (props) => {
      if (!isAuthenticated()) {
        return <Redirect to={LOGIN} />
      }

      return <LayoutModeSelector {...props} />
    }
  },
  {
    path: PAGE_UNDER_CONSTRUCTION,
    key: PAGE_UNDER_CONSTRUCTION,
    exact: false,
    component: (props) => {
      if (!isAuthenticated()) {
        return <Redirect to={LOGIN} />
      }

      return (
        <AdminLayout>
          <PageUnderConstruction {...props} />
        </AdminLayout>
      )
    },
    routes: [
      {
        path: ADMIN_DASHBOARD_COLUMBIA,
        key: ADMIN_DASHBOARD_COLUMBIA,
        exact: true,
        component: ColumbiaComponent
      }
    ]
  },
  {
    path: NEW_ADMIN_DASHBOARD,
    key: NEW_ADMIN_DASHBOARD,
    component: (props) => {
      if (!isAuthenticated()) {
        return <Redirect to={LOGIN} />
      }

      return (
        <AdminNewLayout>
          <RenderRoutes {...props} />
        </AdminNewLayout>
      )
    },
    routes: [
      {
        path: ADMIN_DASHBOARD_COLUMBIA,
        key: ADMIN_DASHBOARD_COLUMBIA,
        exact: true,
        component: ColumbiaComponent
      },
      {
        path: ADMIN_OFFER,
        key: ADMIN_OFFER,
        exact: true,
        component: CreateOffer
      },
      {
        path: ADMIN_OFFER_PREVIEW,
        key: ADMIN_OFFER_PREVIEW,
        exact: true,
        component: OfferPreview
      },
      {
        path: ADMIN_OPPORTUNITY,
        key: ADMIN_OPPORTUNITY,
        exact: true,
        component: CreateOpportunity
      },
      {
        path: ADMIN_EDIT_OPPORTUNITY,
        key: ADMIN_EDIT_OPPORTUNITY,
        exact: true,
        component: EditOpportunity
      },
      {
        path: ADMIN_RESERVATION_NEW,
        key: ADMIN_RESERVATION_NEW,
        exact: true,
        component: CreateReservation
      },
      {
        path: ADMIN_RESERVATION_DETAIL,
        key: ADMIN_RESERVATION_DETAIL,
        exact: true,
        component: CreateReservation
      },
      {
        path: ADMIN_BROKER_LEADS_NEW,
        key: ADMIN_BROKER_LEADS_NEW,
        exact: true,
        component: CreateBrokerLead
      },
      {
        path: ADMIN_BROKER_LEADS_DETAIL,
        key: ADMIN_BROKER_LEADS_DETAIL,
        exact: true,
        component: CreateBrokerLead
      },
      {
        path: ADMIN_CREATE_PROJECT,
        key: ADMIN_CREATE_PROJECT,
        exact: true,
        component: CreateProject
      },
      {
        path: ADMIN_ADD_BUILDING,
        key: ADMIN_ADD_BUILDING,
        exact: true,
        component: AddBuilding
      },
      {
        path: ADMIN_RESERVE_UNIT,
        key: ADMIN_RESERVE_UNIT,
        exact: true,
        component: ReserveUnit
      },
      {
        path: ADMIN_PROJECT_ALLOCATIONS_NEW,
        key: ADMIN_PROJECT_ALLOCATIONS_NEW,
        exact: true,
        component: CreateInventoryAllocation
      },
      // { path: ADMIN_CREATE_INVENTORY_ALLOCATION, key: ADMIN_CREATE_INVENTORY_ALLOCATION, exact: true, component: CreateInventoryAllocation }
      {
        path: ADMIN_CREATE_INVENTORY_ALLOCATION,
        key: ADMIN_CREATE_INVENTORY_ALLOCATION,
        exact: true,
        component: CreateInventoryAllocation
      }
    ]
  },
  {
    path: ADMIN_DASHBOARD,
    key: ADMIN_DASHBOARD,
    component: (props) => {
      if (!isAuthenticated()) {
        return <Redirect to={LOGIN} />
      }

      return (
        <AdminLayout>
          <RenderRoutes {...props} />
        </AdminLayout>
      )
    },
    routes: [
      { path: ADMIN_DASHBOARD, key: ADMIN_DASHBOARD, exact: true, component: AdminDashboardComponent },
      { path: ADMIN_DASHBOARD_NEW, key: ADMIN_DASHBOARD_NEW, exact: true, component: AdminDashboardNewDesign },
      { path: ADMIN_DASHBOARD_NEW_TEMP_DASHBOARD, key: ADMIN_DASHBOARD_NEW_TEMP_DASHBOARD, exact: true, component: AdminDashboardNewTemporary },
      { path: ADMIN_DASHBOARD_OLD, key: ADMIN_DASHBOARD_OLD, exact: true, component: OldAdminDashboard },
      { path: ADMIN_USERS, key: ADMIN_USERS, exact: true, component: AdminUsers },
      { path: ADMIN_PROJECT_MANAGEMENT, key: ADMIN_PROJECT_MANAGEMENT, exact: true, component: AdminProjectManagement },
      { path: ADMIN_PROJECT_IMAGES, key: ADMIN_PROJECT_IMAGES, exact: true, component: EditProjectImages },
      { path: ADMIN_PROJECT_PARKING, key: ADMIN_PROJECT_PARKING, exact: true, component: EditProjectParking },
      { path: ADMIN_PROJECT_COLORS, key: ADMIN_PROJECT_COLORS, exact: true, component: EditProjectColors },
      { path: ADMIN_PROJECT_UPGRADES, key: ADMIN_PROJECT_UPGRADES, exact: true, component: EditProjectUpgrades },
      { path: ADMIN_PROJECT_PAYMENTS, key: ADMIN_PROJECT_PAYMENTS, exact: true, component: EditProjectPayments },
      { path: ADMIN_PROJECT_OFFER_SETTING, key: ADMIN_PROJECT_OFFER_SETTING, exact: true, component: EditProjectOffer },
      { path: ADMIN_PROJECT_CUSTOM_SECTION, key: ADMIN_PROJECT_CUSTOM_SECTION, exact: true, component: EditProjectCustomSection },
      { path: ADMIN_PROJECT_DEPOSIT_OPTIONS, key: ADMIN_PROJECT_DEPOSIT_OPTIONS, exact: true, component: EditProjectDepositOptions },
      { path: ADMIN_LISTS, key: ADMIN_LISTS, exact: true, component: ListsManagement },
      { path: ADMIN_LISTS_CORPORATE, key: ADMIN_LISTS_CORPORATE, exact: true, component: ListsManagement },
      { path: ADMIN_LISTS_DETAIL, key: ADMIN_LISTS_DETAIL, exact: true, component: ListDetailsPage },
      { path: ADMIN_LISTS_DETAIL_CORPORATE, key: ADMIN_LISTS_DETAIL_CORPORATE, exact: true, component: ListDetailsPage },
      { path: ADMIN_BUILDINGS, key: ADMIN_BUILDINGS, exact: true, component: AdminBuildings },
      { path: ADMIN_PROJECT_UNIT_INVENTORY, key: ADMIN_PROJECT_UNIT_INVENTORY, exact: true, component: UnitsInventory },
      { path: ADMIN_PROJECT_PARKING_INVENTORY, key: ADMIN_PROJECT_PARKING_INVENTORY, exact: true, component: ParkingsInventory },
      { path: ADMIN_PROJECT_STORAGE_INVENTORY, key: ADMIN_PROJECT_STORAGE_INVENTORY, exact: true, component: StoragesInventory },
      { path: ADMIN_PROJECT_UNIT_INVENTORY_GRAPH, key: ADMIN_PROJECT_UNIT_INVENTORY_GRAPH, exact: true, component: UnitsInventoryGraph },
      { path: ADMIN_PROJECT_ALLOCATIONS, key: ADMIN_PROJECT_ALLOCATIONS, exact: true, component: Allocation },
      { path: ADMIN_PROJECT_FILES, key: ADMIN_PROJECT_FILES, exact: true, component: AdminProjectFiles },
      { path: ADMIN_PROJECT_MAP, key: ADMIN_PROJECT_MAP, exact: true, component: AdminProjectMap },
      { path: ADMIN_UNIT_INVENTORY_MAP, key: ADMIN_UNIT_INVENTORY_MAP, exact: true, component: AdminUnitInventoryMap },
      { path: ADMIN_EDIT_BUILDING, key: ADMIN_EDIT_BUILDING, exact: true, component: AdminEditBuilding },
      { path: ADMIN_OFFERS, key: ADMIN_OFFERS, exact: true, component: AdminOffers },
      { path: ADMIN_OPPORTUNITIES, key: ADMIN_OPPORTUNITIES, exact: true, component: AdminOpportunities },
      { path: ADMIN_RESERVATION_NEW, key: ADMIN_RESERVATION_NEW, exact: true, component: CreateReservation },
      { path: ADMIN_RESERVATION_DETAIL, key: ADMIN_RESERVATION_DETAIL, exact: true, component: CreateReservation },
      { path: ADMIN_RESERVATIONS, key: ADMIN_RESERVATIONS, exact: true, component: AdminReservations },
      { path: ADMIN_BROKER_LEADS_NEW, key: ADMIN_BROKER_LEADS_NEW, exact: true, component: CreateBrokerLead },
      { path: ADMIN_BROKER_LEADS_DETAIL, key: ADMIN_BROKER_LEADS_DETAIL, exact: true, component: CreateBrokerLead },
      { path: ADMIN_BROKER_LEADS, key: ADMIN_BROKER_LEADS, exact: true, component: AdminBrokerLeads },
      { path: ADMIN_OFFER_OLD, key: ADMIN_OFFER_OLD, exact: true, component: AdminOffer },
      { path: ADMIN_PROFILE_PAGE, key: ADMIN_PROFILE_PAGE, exact: true, component: AdminProfile },
      { path: ADMIN_SCHEDULER_MEETINGS, key: ADMIN_SCHEDULER_MEETINGS, exact: true, component: SchedulerMeetings },
      { path: ADMIN_CREATE_EDIT_USER, key: ADMIN_CREATE_EDIT_USER, exact: true, component: AdminCreateEditUser },
      { path: ADMIN_CREATE_EDIT_DEVELOPER, key: ADMIN_CREATE_EDIT_DEVELOPER, exact: true, component: AdminCreateEditDeveloper },
      { path: ADMIN_CREATE_EDIT_PROJECT, key: ADMIN_CREATE_EDIT_PROJECT, exact: true, component: AdminCreateEditProject },
      { path: ADMIN_CREATE_EDIT_BUILDING, key: ADMIN_CREATE_EDIT_BUILDING, exact: true, component: AdminCreateEditBuilding },
      { path: ADMIN_CREATE_EDIT_FLOOR_PLAN, key: ADMIN_CREATE_EDIT_FLOOR_PLAN, exact: true, component: AdminCreateEditFloorPlan },
      { path: ADMIN_CREATE_EDIT_UNIT_GROUP, key: ADMIN_CREATE_EDIT_UNIT_GROUP, exact: true, component: AdminCreateEditUnitGroup },
      { path: ADMIN_CREATE_EDIT_UNIT, key: ADMIN_CREATE_EDIT_UNIT, exact: true, component: AdminCreateEditUnit },
      { path: SALES_PIPELINE, key: SALES_PIPELINE, exact: true, component: SalesPipeline },
      { path: ADMIN_REPORTS, key: ADMIN_REPORTS, exact: true, component: AdminReports },
      { path: ADMIN_TEAM_SALES_REPS, key: ADMIN_TEAM_SALES_REPS, exact: true, component: SalesReps },
      { path: ADMIN_TEAM_COOP_BROKER, key: ADMIN_TEAM_COOP_BROKER, exact: true, component: CoopBrokers },
      { path: ADMIN_CONTACTS, key: ADMIN_CONTACTS, exact: true, component: NewContactsV2 },
      { path: ADMIN_CONTACTS_ID, key: ADMIN_CONTACTS_ID, exact: true, component: NewContactsV2 },
      { path: ADMIN_CONTACTS_ID_CORPORATE, key: ADMIN_CONTACTS_ID_CORPORATE, exact: true, component: NewContactsV2 },
      { path: ADMIN_EMAIL_CAMPAIGNS_BUILDER, key: ADMIN_EMAIL_CAMPAIGNS_BUILDER, exact: true, component: EmailCampaignBuilderPage },
      { path: ADMIN_EMAIL_CAMPAIGNS_BUILDER_CORPORATE, key: ADMIN_EMAIL_CAMPAIGNS_BUILDER_CORPORATE, exact: true, component: EmailCampaignBuilderPage },
      { path: ADMIN_EDIT_EMAIL_TEMPLATE, key: ADMIN_EDIT_EMAIL_TEMPLATE, exact: true, component: EditEmailTemplate },
      { path: ADMIN_EMAIL_CUSTOM_CAMPAIGNS_DETAIL, key: ADMIN_EMAIL_CUSTOM_CAMPAIGNS_DETAIL, exact: true, component: EmailCustomCampaignDetail },
      { path: ADMIN_EMAIL_CUSTOM_CAMPAIGNS_DETAIL_CORPORATE, key: ADMIN_EMAIL_CUSTOM_CAMPAIGNS_DETAIL_CORPORATE, exact: true, component: EmailCustomCampaignDetail },
      { path: ADMIN_EMAIL_CUSTOM_CAMPAIGNS_BUILDER, key: ADMIN_EMAIL_CUSTOM_CAMPAIGNS_BUILDER, exact: true, component: EmailCustomCampaigns },
      { path: ADMIN_EMAIL_CUSTOM_CAMPAIGNS_BUILDER_CORPORATE, key: ADMIN_EMAIL_CUSTOM_CAMPAIGNS_BUILDER_CORPORATE, exact: true, component: EmailCustomCampaigns },
      { path: ADMIN_NEW_CONTACTS, key: ADMIN_NEW_CONTACTS, exact: true, component: NewContacts },
      { path: ADMIN_NEW_CONTACTS_V2, key: ADMIN_NEW_CONTACTS_V2, exact: true, component: NewContactsV2 },
      { path: ADMIN_NEW_CONTACTS_ID, key: ADMIN_NEW_CONTACTS_ID, exact: true, component: NewContacts },
      { path: ADMIN_CORPORATE_CONTACTS, key: ADMIN_CORPORATE_CONTACTS, exact: true, component: NewContactsV2 },
      { path: ADMIN_NEW_CONTACTS_V2_ID, key: ADMIN_NEW_CONTACTS_V2_ID, exact: true, component: NewContactsV2 },
      { path: CORPORATE_VIEW, key: CORPORATE_VIEW, exact: true, component: CorporateView },
      { path: AVAILABILITY_BOARD, key: AVAILABILITY_BOARD, exact: true, component: AvailabilityBoard },
      { path: ADMIN_DEPOSIT_PAYMENT, key: ADMIN_DEPOSIT_PAYMENT, exact: true, component: AdminDeposits },
      { path: ALLOCATIONS, key: ALLOCATIONS, exact: true, component: Allocation },
      { path: UNITS_INVENTORY, key: UNITS_INVENTORY, exact: true, component: UnitsInventory },
      { path: PARKINGS_INVENTORY, key: PARKINGS_INVENTORY, exact: true, component: ParkingsInventory },
      { path: STORAGES_INVENTORY, key: STORAGES_INVENTORY, exact: true, component: StoragesInventory },
      { path: INVENTORIES, key: INVENTORIES, exact: true, component: Inventories },
      { path: DEMAND_NOTES_SETTINGS, key: DEMAND_NOTES_SETTINGS, exact: true, component: DemandNotesDropdown },
      { path: CORPORATE_DEMAND_NOTES_SETTINGS, key: CORPORATE_DEMAND_NOTES_SETTINGS, exact: true, component: CorporateDemandNotesDropdown },
      { path: EMAIL_INBOX, key: EMAIL_INBOX, exact: true, component: EmailInbox },
      {
        path: ADMIN_DASHBOARD_COLUMBIA, key: ADMIN_DASHBOARD_COLUMBIA, exact: true, component: ColumbiaComponent
      },
      {
        path: ADMIN_DASHBOARD_COLUMBIA_REPORT, key: ADMIN_DASHBOARD_COLUMBIA_REPORT, exact: true, component: ColumbiaSalesReport
      },
      {
        path: ADMIN_MASTER_REPORT, key: ADMIN_MASTER_REPORT, exact: true, component: ReportContainer
      },
      {
        path: ADMIN_MASTER_REPORT_CORPORATE, key: ADMIN_MASTER_REPORT_CORPORATE, exact: true, component: ReportCorporate
      },
      {
        path: ADMIN_LEADREPORT, key: ADMIN_LEADREPORT, exact: true, component: AdminLeadReport
      },
      {
        path: ADMIN_SALES_REPORT, key: ADMIN_SALES_REPORT, exact: true, component: AdminReportSalesActivity
      },
      {
        path: ADMIN_BROKER_SHANNON, key: ADMIN_BROKER_SHANNON, exact: true, component: AdminBrokerShannon
      },
      {
        path: ADMIN_LEAD_STEFAN, key: ADMIN_LEAD_STEFAN, exact: true, component: AdminLeadStefan
      },
      {
        path: ADMIN_EVENT_PAGE, key: ADMIN_EVENT_PAGE, exact: true, component: AdminEventPage
      },
      {
        path: ADMIN_EVENT_DETAIL_PAGE, key: ADMIN_EVENT_DETAIL_PAGE, exact: true, component: AdminEventDetailPage
      },
      {
        path: ADMIN_CREATE_EVENT_PAGE, key: ADMIN_CREATE_EVENT_PAGE, exact: true, component: AdminCreateEditEventPage
      },
      {
        path: ADMIN_EDIT_EVENT_PAGE, key: ADMIN_EDIT_EVENT_PAGE, exact: true, component: AdminCreateEditEventPage
      },
      {
        path: ADMIN_PROSPECT_QUALIFICATION, key: ADMIN_PROSPECT_QUALIFICATION, exact: true, component: ProspectQualification
      },
      {
        path: ADMIN_SALES_TRANSACTION, key: ADMIN_SALES_TRANSACTION, exact: true, component: SalesTransaction
      },
      {
        path: ADMIN_POST_SALES_SUCCESS, key: ADMIN_POST_SALES_SUCCESS, exact: true, component: PostSalesSuccess
      },
      { path: ADMIN_SMS_BROADCAST, key: ADMIN_SMS_BROADCAST, exact: true, component: AdminSMSBroadcast },
      { path: ADMIN_SMS_BROADCAST_CORPORATE, key: ADMIN_SMS_BROADCAST_CORPORATE, exact: true, component: AdminSMSBroadcast },
      { path: ADMIN_NEGOTIATE, key: ADMIN_NEGOTIATE, exact: true, component: AdminNegotiate },
      { path: ADMIN_PURCHASE_ADDITIONAL_PARKING, key: ADMIN_PURCHASE_ADDITIONAL_PARKING, exact: true, component: AdminPurchaseAdditionalParking },
      { path: ADMIN_PARKING_SALES_AND_WAIT_LIST, key: ADMIN_PARKING_SALES_AND_WAIT_LIST, exact: true, component: AdminParkingSalesAndWaitlist },
      { path: ADMIN_PURCHASE_PARKING, key: ADMIN_PURCHASE_PARKING, exact: true, component: PurchaseParking },
      { path: ADMIN_PURCHASE_ADDITIONAL_STORAGE, key: ADMIN_PURCHASE_ADDITIONAL_STORAGE, exact: true, component: AdminPurchaseAdditionalStorage },
      {
        path: ADMIN_NEW_DASHBOARD,
        key: ADMIN_NEW_DASHBOARD,
        exact: true,
        component: AdminNewDashboard
      },
      {
        path: ADMIN_DASHBOARD_DEALS,
        key: ADMIN_DASHBOARD_DEALS,
        exact: true,
        component: AdminDashboardDeals
      },
      {
        path: ADMIN_DASHBOARD_SALESREP,
        key: ADMIN_DASHBOARD_SALESREP,
        exact: true,
        // component: DashboardNewSalesRep
        component: whiteCapDashboard
      },
      {
        path: ADMIN_DASHBOARD_CORPORATE,
        key: ADMIN_DASHBOARD_CORPORATE,
        exact: true,
        component: corporateDashboard
      },
      {
        path: ADMIN_DASHBOARD_WHITECAP,
        key: ADMIN_DASHBOARD_WHITECAP,
        exact: true,
        component: whiteCapDashboard
      },
      {
        path: ADMIN_REMINDER_SETTINGS,
        key: ADMIN_REMINDER_SETTINGS,
        exact: true,
        component: AdminRemiderSetting
      },
      {
        path: ADMIN_PERMISSIONS,
        key: ADMIN_PERMISSIONS,
        exact: true,
        component: AdminPermission
      },
      { path: SETTINGS, key: SETTINGS, exact: true, component: Settings },
      { path: CALENDAR, key: CALENDAR, exact: true, component: Calendar },
      { path: TASKS_CALENDAR, key: TASKS_CALENDAR, exact: true, component: tasksCalendar },
      { path: TASKS_CALENDAR_CORPORATE, key: TASKS_CALENDAR_CORPORATE, exact: true, component: tasksCalendar },
      {
        path: CONTRACT_TEMPLATES,
        key: CONTRACT_TEMPLATES,
        exact: true,
        component: ContractTemplates
      },
      {
        path: NEW_CONTRACT_TEMPLATES,
        key: NEW_CONTRACT_TEMPLATES,
        exact: true,
        component: NewContractTemplate
      },
      {
        path: EDIT_CONTRACT_TEMPLATES,
        key: EDIT_CONTRACT_TEMPLATES,
        exact: true,
        component: EditContractTemplate
      },
      { path: TASKS, key: TASKS, exact: true, component: tasks },
      { path: ADMIN_CORPORATE_TASKS, key: ADMIN_CORPORATE_TASKS, exact: true, component: tasks },
      {
        path: '/admin/salesrep/profile',
        key: '/admin/salesrep/profile',
        exact: true,
        component: Profile
      },
      ...ADMIN_PROJECT_DROPDOWNS.map((dropdown) => ({
        path: dropdown.path,
        key: dropdown.key,
        exact: true,
        component: () => {
          return <DynamicDropDown itemKey={dropdown.key} />
        }
      }))
    ]
  },
  {
    path: PWA_PROJECTS,
    key: PWA_PROJECTS,
    component: (props) => {
      if (!isAuthenticated()) {
        return <Redirect to={LOGIN} />
      }
      return (
        <PwaLayout>
          <RenderRoutes {...props} />
        </PwaLayout>
      )
    },
    routes: [
      {
        path: PWA_PROJECTS,
        key: PWA_PROJECTS,
        exact: true,
        component: PwaProjects
      },
      {
        path: PWA_PROJECT_SCREEN,
        key: PWA_PROJECT_SCREEN,
        exact: true,
        component: PwaProjectScreen
      },
      {
        path: PWA_CREATE_OFFER,
        key: PWA_CREATE_OFFER,
        exact: true,
        component: PwaCreateOffer
      },
      {
        path: PWA_CREATE_OFFER_NEW,
        key: PWA_CREATE_OFFER_NEW,
        exact: true,
        component: PwaCreateOfferNew
      },
      {
        path: PWA_PROJECT_OFFERS,
        key: PWA_PROJECT_OFFERS,
        exact: true,
        component: PwaProjectOffers
      },
      {
        path: PWA_PROJECT_AVAILABILITY_BOARD,
        key: PWA_PROJECT_AVAILABILITY_BOARD,
        exact: true,
        component: PwaProjectAvailabilityBoard
      },
      {
        path: PWA_PROJECT_FLOOR_PLANS,
        key: PWA_PROJECT_FLOOR_PLANS,
        exact: true,
        component: PwaProjectFloorPlans
      }
    ]
  },
  {
    path: BUYER_DASHBOARD,
    key: BUYER_DASHBOARD,
    component: (props) => {
      if (!isAuthenticated()) {
        // Check if has a URL search to be passed
        return (
          <Redirect
            to={{
              pathname: LOGIN,
              search: props.location.search || ''
            }}
          />
        )
      }

      return (
        <BuyerLayout>
          <RenderRoutes {...props} />
        </BuyerLayout>
      )
    },
    routes: [
      {
        path: BUYER_DASHBOARD,
        key: BUYER_DASHBOARD,
        exact: true,
        component: BuyerAvailabilityBoard
      },
      {
        path: BUYER_CREATE_OFFER,
        key: BUYER_CREATE_OFFER,
        exact: true,
        component: BuyerCreateOffer
      },
      {
        path: BUYER_HOME,
        key: BUYER_HOME,
        exact: true,
        component: BuyerHomeComponent
      },
      {
        path: BUYER_HOME_DASHBOARD,
        key: BUYER_HOME_DASHBOARD,
        exact: true,
        component: BuyerDashBoard
      },
      {
        path: BUYER_SURVEY_QUESTIONNAIRE,
        key: BUYER_SURVEY_QUESTIONNAIRE,
        exact: true,
        component: BuyerSurveyQuestionnaire
      }
    ]
  },
  {
    path: BROKER_HOME_PAGE,
    key: BROKER_HOME_PAGE,
    component: (props) => {
      if (!isAuthenticated()) {
        // Check if has a URL search to be passed
        return (
          <Redirect
            to={{
              pathname: LOGIN,
              search: props.location.search || ''
            }}
          />
        )
      }

      return (
        <BrokerLayout>
          <RenderRoutes {...props} />
        </BrokerLayout>
      )
    },
    routes: [
      {
        path: BROKER_HOME_PAGE,
        key: BROKER_HOME_PAGE,
        exact: true,
        component: BrokerMainPage
      },
      // {
      //   path: BROKER_MENU_PAGE,
      //   key: BROKER_MENU_PAGE,
      //   exact: true,
      //   component: BrokerMenuPage
      // },
      {
        path: BROKER_DASHBOARD_PAGE,
        key: BROKER_DASHBOARD_PAGE,
        exact: true,
        component: BrokerDashboardPage
      },
      {
        path: BROKER_DOCUMENTS_PAGE,
        key: BROKER_DOCUMENTS_PAGE,
        exact: true,
        component: BrokerDocumentPage
      },
      {
        path: BROKER_LANDING_PAGE,
        key: BROKER_LANDING_PAGE,
        exact: true,
        component: BrokerLandingPageScreen
      },
      {
        path: BROKER_PROFILE_PAGE,
        key: BROKER_PROFILE_PAGE,
        exact: true,
        component: BrokerProfileScreen
      },
      // {
      //   path: BROKER_CONTACTS_PAGE,
      //   key: BROKER_CONTACTS_PAGE,
      //   exact: true,
      //   component: BrokerProfileScreen
      // },
      {
        path: BROKER_OPPORTUNITIES_PAGE,
        key: BROKER_OPPORTUNITIES_PAGE,
        exact: true,
        component: BrokerOpportunitiesScreen
      },
      {
        path: BROKER_CREATE_OPPORTUNITY_PAGE,
        key: BROKER_CREATE_OPPORTUNITY_PAGE,
        exact: true,
        component: BrokerCreateOpportunitiesScreen
      },
      {
        path: BROKER_UPDATE_OPPORTUNITY_PAGE,
        key: BROKER_UPDATE_OPPORTUNITY_PAGE,
        exact: true,
        component: BrokerUpdateOpportunitiesScreen
      },
      {
        path: BROKER_RESERVATION_PAGE,
        key: BROKER_RESERVATION_PAGE,
        exact: true,
        component: BrokerReservationScreen
      },
      {
        path: BROKER_RESERVATION_DETAILS,
        key: BROKER_RESERVATION_DETAILS,
        exact: true,
        component: BrokerCreateReservationScreen
      },
      {
        path: BROKER_CREATE_RESERVATION_PAGE,
        key: BROKER_CREATE_RESERVATION_PAGE,
        exact: true,
        component: BrokerCreateReservationScreen
      }
      // {
      //   path: BROKER_AVAILABILITY_BOARD,
      //   key: BROKER_AVAILABILITY_BOARD,
      //   exact: true,
      //   component: BrokerProfileScreen
      // }
    ]
  },
  {
    path: BROKER_HOME_PAGE,
    key: BROKER_HOME_PAGE,
    component: (props) => {
      if (!isAuthenticated()) {
        // Check if has a URL search to be passed
        return (
          <Redirect
            to={{
              pathname: LOGIN,
              search: props.location.search || ''
            }}
          />
        )
      }

      return (
        <BrokerLayout>
          <RenderRoutes {...props} />
        </BrokerLayout>
      )
    },
    routes: [
      {
        path: BROKER_HOME_PAGE,
        key: BROKER_HOME_PAGE,
        exact: true,
        component: BrokerMainPage
      },
      {
        path: BROKER_MENU_PAGE,
        key: BROKER_MENU_PAGE,
        exact: true,
        component: BrokerMenuPage
      },
      {
        path: BROKER_DASHBOARD_PAGE,
        key: BROKER_DASHBOARD_PAGE,
        exact: true,
        component: BrokerDashboardPage
      },
      {
        path: BROKER_DOCUMENTS_PAGE,
        key: BROKER_DOCUMENTS_PAGE,
        exact: true,
        component: BrokerDocumentPage
      },
      {
        path: BROKER_LANDING_PAGE,
        key: BROKER_LANDING_PAGE,
        exact: true,
        component: BrokerLandingPageScreen
      }
    ]
  },
  {
    path: BUYER_DISCOVERY_SURVEY,
    key: BUYER_DISCOVERY_SURVEY,
    component: (props) => <RenderRoutes {...props} />,
    routes: [
      {
        path: BUYER_DISCOVERY_SURVEY,
        key: BUYER_DISCOVERY_SURVEY,
        exact: true,
        component: BuyerDiscoverySurvey
      }
    ]
  },
  {
    path: BUYER_SIGN_CONTRACT,
    key: BUYER_SIGN_CONTRACT,
    component: (props) => <RenderRoutes {...props} />,
    routes: [
      {
        path: BUYER_SIGN_CONTRACT,
        key: BUYER_SIGN_CONTRACT,
        exact: true,
        component: BuyerSignContract
      },
      {
        path: BUYER_SIGN_PARKING_CONTRACT,
        key: BUYER_SIGN_PARKING_CONTRACT,
        exact: true,
        component: BuyerSignParkingContract
      },
      {
        path: BUYER_SIGN_RESERVATION_CONTRACT,
        key: BUYER_SIGN_RESERVATION_CONTRACT,
        exact: true,
        component: BuyerSignReservationContract
      }
    ]
  },
  // {
  //   path: BUYER_SIGN_PARKING_CONTRACT,
  //   key: BUYER_SIGN_PARKING_CONTRACT,
  //   component: (props) => <RenderRoutes {...props} />,
  //   routes: [
  //   ]
  // },
  {
    path: PUBLIC_INVENTORY_TABLE_SCREEN,
    key: PUBLIC_INVENTORY_TABLE_SCREEN,
    component: (props) => <RenderRoutes {...props} />,
    routes: [
      {
        path: PUBLIC_INVENTORY_TABLE_SCREEN,
        key: PUBLIC_INVENTORY_TABLE_SCREEN,
        exact: true,
        component: InventoryPublicPage
      }
    ]
  },
  {
    path: PUBLIC_PARKING_INVENTORY_TABLE_SCREEN,
    key: PUBLIC_PARKING_INVENTORY_TABLE_SCREEN,
    component: (props) => <RenderRoutes {...props} />,
    routes: [
      {
        path: PUBLIC_PARKING_INVENTORY_TABLE_SCREEN,
        key: PUBLIC_PARKING_INVENTORY_TABLE_SCREEN,
        exact: true,
        component: ParkingInventoryPublicPage
      }
    ]
  },
  {
    path: PUBLIC_STORAGE_INVENTORY_TABLE_SCREEN,
    key: PUBLIC_STORAGE_INVENTORY_TABLE_SCREEN,
    component: (props) => <RenderRoutes {...props} />,
    routes: [
      {
        path: PUBLIC_STORAGE_INVENTORY_TABLE_SCREEN,
        key: PUBLIC_STORAGE_INVENTORY_TABLE_SCREEN,
        exact: true,
        component: StorageInventoryPublicPage
      }
    ]
  },
  { path: HIDDEN_HILLS_WEBSITE_FORM, key: HIDDEN_HILLS_WEBSITE_FORM, exact: false, component: HiddenHillsWebsiteForm },
  { path: PONDSIDE_WEBSITE_FORM, key: PONDSIDE_WEBSITE_FORM, exact: false, component: PondSideWebsiteForm },
  { path: OLIVE_GROVE_WEBSITE_FORM, key: OLIVE_GROVE_WEBSITE_FORM, exact: false, component: OliveGroveWebsiteForm }
]

export default ROUTES

function RouteWithSubRoutes (route) {
  const { path, exact, component: Component, routes } = route
  return (
    <Route
      path={path}
      exact={exact}
      render={(props) => <Component {...props} routes={routes} />}
    />
  )
}

function AdminLayout ({ children }) {
  return (
    <Suspense fallback={<Loader />}>
      <AdminContainer>
        <Switch location={children.props.location}>
          {children}
          {/* <Route path='*' component={() => <h1>Not found</h1>} /> */}
        </Switch>
      </AdminContainer>
    </Suspense>
  )
}

function AdminNewLayout ({ children }) {
  return (
    <Suspense fallback={<Loader />}>
      {/* <OfferContainer> */}
      <AdminContainer>
        <Switch location={children.props.location}>{children}</Switch>
      </AdminContainer>
      {/* </OfferContainer> */}
    </Suspense>
  )
}

function AdminNewLayout2 ({ children }) {
  return (
    <Suspense fallback={<Loader />}>
      <AdminDashboardContainer>
        <Switch location={children.props.location}>{children}</Switch>
      </AdminDashboardContainer>
    </Suspense>
  )
}

function BuyerLayout ({ children }) {
  return (
    <Suspense fallback={<Loader />}>
      <BuyerContainer>
        <Switch location={children.props.location}>{children}</Switch>
      </BuyerContainer>
    </Suspense>
  )
}

function BrokerLayout ({ children }) {
  return (
    <Suspense fallback={<Loader />}>
      <BrokerContainer>
        <Switch location={children.props.location}>{children}</Switch>
      </BrokerContainer>
    </Suspense>
  )
}

function PwaLayout ({ children }) {
  return (
    <Suspense fallback={<Loader />}>
      <AnimatedTransitionWrapper history={children.props.history}>
        <TransitionGroup>
          <CSSTransition
            key={children.props.location.key}
            timeout={{ enter: 300, exit: 300 }}
            classNames='fade'
          >
            <section className='route-section'>
              <Switch location={children.props.location}>
                {children}
                {/* <Route path='*' component={() => <h1>Not found</h1>} /> */}
              </Switch>
            </section>
          </CSSTransition>
        </TransitionGroup>
      </AnimatedTransitionWrapper>
    </Suspense>
  )
}

export function RenderRoutes ({ routes }) {
  // const { update } = useIntercom()
  // @ts-ignore
  const userObject = useSelector((state) => state.authReducer.userObject)
  const hasUser = useRef(false)

  useEffect(() => {
    if (!userObject?._id) return
    if (hasUser.current) return
    updateLoggedUserObject(userObject, undefined, false)
    hasUser.current = true
  }, [userObject])

  return (
    <Switch>
      {routes.map((route, i) => {
        return <RouteWithSubRoutes key={i} {...route} />
      })}
      <Route component={() => <h1>Not Found!</h1>} />
    </Switch>
  )
}

AdminLayout.propTypes = {
  children: propTypes.node
}

BuyerLayout.propTypes = {
  children: propTypes.node
}

BrokerLayout.propTypes = {
  children: propTypes.node
}

PwaLayout.propTypes = {
  children: propTypes.node
}

RenderRoutes.propTypes = {
  routes: propTypes.array
}
