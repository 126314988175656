/* eslint-disable no-restricted-globals */
import React from 'react'
import { useDispatch } from 'react-redux'
import {
  HOVER_TOGGLER,
  SHOW_HIDE_SIDEBAR
} from 'store/actions/actionTypes'
import { Plus } from 'lucide-react'

const CollapseSideBarButton = () => {
  const dispatch = useDispatch()

  return (
    <div onClick={() => {
      dispatch({ type: SHOW_HIDE_SIDEBAR, payload: false })
      dispatch({ type: HOVER_TOGGLER, payload: false })
    }} className='absolute text-black w-5 h-5 cursor-pointer right-3 top-[1.2rem]'>
      <Plus className='w-5 h-5 rotate-45' />
    </div>
  )
}

export default CollapseSideBarButton
