// @ts-nocheck
/* eslint-disable */
import React, { useCallback, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
// import { IntercomProvider } from 'react-use-intercom';

import ROUTES, { RenderRoutes } from 'routes';
import { updateLoggedUserObject } from 'utils';

import TagManager from 'react-gtm-module';
import HistoryProvider from 'global/HistoryContext';
import * as PusherPushNotifications from '@pusher/push-notifications-web';
import { Toaster } from 'react-hot-toast';
import useProjectChange from 'hooks/useProjectChange';
import MainContactProvider from 'pages/newContacts/context/MainContactContext';
import { useSelector } from 'react-redux';
import { ContactProvider } from 'pages/contacts/context/Contacts';
import Plausible from 'plausible-tracker';
import { CallModal } from 'pages/contacts/CallModal';
import {
  bindChannel,
  subscribeToChannel,
  unbindChannel,
  unsubscribeToChannel,
} from 'services/pusher';
import moment from 'moment';
import { random, set } from 'lodash';
import ReportDownloadModal from 'components/ReportDownloadModal';
import { isMobile } from 'react-device-detect';
import ReportWaitingModal from 'components/ReportDownloadWaitingModal';
import ReportDownload from 'pages/reportDownload';

const plausible = Plausible({
  domain: window.location.hostname,
  hashMode: true,
});
plausible.enableAutoPageviews();

let beamsClient = null;

if (!isMobile) {
  beamsClient = new PusherPushNotifications.Client({
    instanceId: process.env.REACT_APP_PUSHER_INSTANCE_ID || '',
  });
}

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
};

TagManager.initialize(tagManagerArgs);

// Load stripe promise
const promise = loadStripe(
  'pk_test_51HEZN8GN9TL1P8ikv0MqANAe3n82XSECpOdokzDLBdOC8UPZS8XQyXWu1dFqyTCbrhoGeGYwDXk93w5MMACf0WGv00rsUJVztP'
);

// const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;
const App = () => {
  useProjectChange();
  const userSelector = (state) => state.authReducer.userObject;
  const userId = useSelector(userSelector);
  const [contact, setContact] = React.useState(null);
  const [call, setCall] = React.useState({});
  const [handleModal, setHandleModal] = React.useState(false);
  const [reportDownloadModal, setReportDownloadModal] = React.useState(false);
  const [reportUrl, setReportUrl] = React.useState(false);
  const [waitingModal, setWaitingModal] = React.useState(false);
  const handlePusher = async () => {
    const beamsTokenProvider = new PusherPushNotifications.TokenProvider({
      url: `${process.env.REACT_APP_BE_URL}/notification/auth`,
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    });
    beamsClient.getUserId().then(async (id) => {
      try {
        if (id !== userId?._id) {
          beamsClient.stop();
        }
        if (id === null) {
          const beam = await beamsClient.start();
          // @ts-ignore
          await beam.setUserId(userId?._id, beamsTokenProvider);
        }
        if (id === userId?._id) {
          console.log('connected to pusher');
        }
      } catch (error) {
        console.log('erro', error);
        beamsClient.stop();
      }
    });
  };

  useEffect(() => {
    updateLoggedUserObject();
  }, []);

  useEffect(() => {
    if (userId?._id && !isMobile) {
      handlePusher();
    }
  }, [userId?._id]);

  const onNewCallCallBack = useCallback(
    (newCall) => {
      setContact(newCall?.user);
      setCall({
        id: newCall?.id || Math.floor(Math.random() * 101),
        date: moment(newCall?.date).format('MMM DD, YYYY hh:mm A'),
        event: newCall?.description,
        duration: '-',
        time: newCall?.date,
        notes: newCall?.notes || '',
      });
      setHandleModal(true);
    },
    [call, setCall]
  );

  // const onNewCallCallBackReport = useCallback(
  //   (newCall) => {
  //     console.log('called');
  //     setWaitingModal(false);
  //     setReportUrl(newCall?.url);
  //     setReportDownloadModal(true);
  //   },
  //   [contact, setReportDownloadModal, setReportUrl]
  // );

  const setClose = () => {
    setHandleModal(false);
    setCall({});
  };

  const onSuccess = () => {
    console.log('success');
  };

  useEffect(() => {
    let callChannel = null;
    if (contact?.id && contact) {
      callChannel = subscribeToChannel(`call-${contact?.id}`);
      if (callChannel) {
        bindChannel(callChannel, onNewCallCallBack);
      }
    }
    return () => {
      if (contact?.id) {
        if (callChannel) {
          unbindChannel(callChannel, onNewCallCallBack);
        }
        unsubscribeToChannel(`call-${contact?.id}`);
      }
    };
  }, [contact, onNewCallCallBack]);

  useEffect(() => {
    let device_id = localStorage.getItem('device_id');
    if(!device_id) {
      device_id = random(100000, 999999999);
      localStorage.setItem('device_id', device_id);
    }
  },[])

  // useEffect(() => {
  //   let reportChannel = null;
  //   reportChannel = subscribeToChannel(`report-${userId?._id}`);
  //   if (reportChannel) {
  //     bindChannel(reportChannel, onNewCallCallBackReport);
  //   }
  //   return () => {
  //     if (reportChannel) {
  //       unbindChannel(reportChannel, onNewCallCallBackReport);
  //     }
  //     unsubscribeToChannel(`report-${userId?._id}`);
  //   };
  // }, [contact, onNewCallCallBackReport, userId?._id]);

  return (
    <>
      <div id='wrapper'>
        <Toaster />
        <Elements stripe={promise}>
          {/* <IntercomProvider appId={INTERCOM_APP_ID} autoBoot> */}
            <HistoryProvider>
              <MainContactProvider>
                <ContactProvider>
                  {handleModal && (
                    <CallModal
                      call={call}
                      show={handleModal}
                      setClose={setClose}
                      contact={contact}
                      onSuccess={onSuccess}
                    />
                  )}
                  <ReportDownload />
                  {/* {reportDownloadModal && (
                    <ReportDownloadModal
                      show={reportDownloadModal}
                      reportUrl={reportUrl}
                      handleClose={() => setReportDownloadModal(false)}
                    />
                  )}
                  {waitingModal && (
                    <ReportWaitingModal
                      waitingModal={waitingModal}
                      handleModal={setWaitingModal}
                    />
                  )} */}
                  <RenderRoutes routes={ROUTES} />
                </ContactProvider>
              </MainContactProvider>
            </HistoryProvider>
          {/* </IntercomProvider> */}
        </Elements>
      </div>
      {/* <div id="warning-message" className='h-screen w-full'>
        <div className='h-full w-full flex justify-center items-center'>
          <h2 className='text-2xl text-center mb-0'>This website is only viewable in landscape mode</h2>
        </div>
      </div> */}
    </>
  );
};

export default App;
