/* eslint-disable no-unused-vars */
import { objStore } from 'index'
import { sha256 } from 'js-sha256'
import JwtDecode from 'jwt-decode'

import { isAuthenticated, NAME_LOCALSTORAGE } from 'services/auth'
import { CHANGE_USER_OBJECT, UPDATE_LIST_OF_PROJECTS } from 'store/actions/actionTypes'
import { getProjects } from 'store/actions/projectActions'
import { getUsers } from 'store/actions/usersActions'
import { updateDarkModeClasses } from './updateDarkModeClass'

const INTERCOM_APP_HASH_SECRET = process.env.REACT_APP_INTERCOM_APP_HASH_SECRET
export const updateLoggedUserObject = async (userData = {}, intercomUpdate = undefined, fetchProjects = true) => {
  if (isAuthenticated()) {
    const token = localStorage.getItem(NAME_LOCALSTORAGE)
    const decodedToken = JwtDecode(token)

    const { _id } = decodedToken

    let localUserData = userData

    if (!Object.keys(localUserData).length) {
      localUserData = await getUsers(_id)
    }

    const {
      userType, darkMode,
      firstName, lastName, email,
      developerAdminData, salesRepData,
      brokerData, userAvatar, buyerData,
      statsSequence, providerToken
    } = localUserData
    if (window?.pendo) {
      window?.pendo?.initialize({
        visitor: {
          id: _id,
          full_name: `${firstName} ${lastName}`,
          email
        },
        account: {
          id: email
        }
      })
    }

    if (intercomUpdate && typeof intercomUpdate === 'function') {
      intercomUpdate({
        name: firstName + ' ' + lastName,
        userId: _id,
        email,
        userHash: sha256.hmac(INTERCOM_APP_HASH_SECRET, _id)
      })
    }

    let developerCompany = ''
    let developerCompanyLogo = ''
    let developerCompanyId = ''

    const baseObject = developerAdminData || salesRepData || brokerData

    if (baseObject && baseObject.developerCompany) {
      developerCompany = baseObject.developerCompany.companyName
      developerCompanyLogo = baseObject.developerCompany.logoImage
      developerCompanyId = baseObject.developerCompany._id
    }

    let projects

    if (salesRepData) {
      projects = [...salesRepData.projects]
    }

    // get the projects to update at the store
    fetchProjects && getProjects()
      .then((projects) => {
        const listOfProjects = projects.map(({ backgroundImage, logoImage, projectName, _id, developer }) =>
          ({ backgroundImage, logoImage, projectName, _id, developerId: developer._id }))

        objStore.store.dispatch({ type: UPDATE_LIST_OF_PROJECTS, payload: listOfProjects })
      })

    updateDarkModeClasses(darkMode)

    objStore.store.dispatch({
      type: CHANGE_USER_OBJECT,
      payload: {
        ...localUserData
      }
    })
  }
}
