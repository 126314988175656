import React from 'react'
import {
  AdminPageWrapper,
  PageHeader
} from 'components'
import { connect, useSelector } from 'react-redux'
import ProjectParkingsInventory from 'components/ProjectParkingsInventory'
import { ADMIN_PURCHASE_PARKING } from 'routes'
import { useHistory } from 'react-router-dom'

const Button = (props) => {
  return (
    <button
      className='text-softBlack text-center text-base font-medium bg-volt rounded py-2 px-6 space-x-1.5 flex items-center'
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  )
}

const ParkingSalesAndWaitlist = () => {
  // @ts-ignore
  const projectId = useSelector((state) => state.appReducer.appProject)
  const history = useHistory()

  const RightContent = () => (
    <div className='flex flex-row items-center'>
      <Button disabled={!projectId} onClick={() => history.push(ADMIN_PURCHASE_PARKING)}>
        Purchase / Join Waitlist
      </Button>
    </div>
  )

  return (
    <>
      <PageHeader title='Parking Sales & Waitlist' rightContent={<RightContent />} />
      <AdminPageWrapper fullscreen>
        <ProjectParkingsInventory />
      </AdminPageWrapper>
    </>
  )
}

const mapStateToProps = (state) => ({
  // appProject: state.appReducer.appProject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ParkingSalesAndWaitlist)

ParkingSalesAndWaitlist.propTypes = {
  // appProject: propTypes.string
}
