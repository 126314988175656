// @ts-nocheck
import { FadedAnimatedDiv } from 'components'
import { AnimatePresence } from 'framer-motion'
import ReservationDocuments from 'pages/buyerSignReservationContract/ReservationDocuments'
import React from 'react'
import { useSelector } from 'react-redux'
import { Modal } from 'ui'

const ContractSigningModal = ({ showingContracts, setShowingContracts, gettingContracts, reservationId, onClose }) => {
  const userSelector = state => state.authReducer.userObject
  const userObject = useSelector(userSelector)

  return (
    <Modal
      showModal={showingContracts}
      modalType='custom'
      canDismiss={false}
      cancelText='Close'
      fullHeight
      fullWidth
      onCancel={() => setShowingContracts(false)}
      showPaddingHorizontal={gettingContracts}
    >
      <AnimatePresence exitBeforeEnter initial={false}>
        {
          (!gettingContracts) && (
            <FadedAnimatedDiv
              key='contractsReview'
              style={{ height: '100%' }}
            >
              <ReservationDocuments
                userObject={userObject}
                setCloseDocument={onClose}
                reservationId={reservationId}
              />
            </FadedAnimatedDiv>
          )
        }
      </AnimatePresence>
    </Modal>
  )
}

export default ContractSigningModal
