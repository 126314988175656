/* eslint-disable */
// @ts-nocheck
import React, { useState } from "react";
import { Box, Modal } from "@material-ui/core";
import { CloseIcon } from "components";
import CustomInput from "components/CustomInput";
import { PlusSmIcon, PencilIcon } from "@heroicons/react/outline";
import { createOrUpdateCorporateDemandNotes } from "store/actions/demandNotes";
import Checkbox from "pages/offer/components/Checkbox";
import CustomButton from "components/CustomButton";

const AddForm = ({
  open,
  setOpen,
  isEdit,
  setIsEdit,
  refetch,
  initialValues,
  setInitialValues,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [optionName, setOptionName] = useState("");
  const [editingIndex, setEditingIndex] = useState(null);

  const handleMultiChange = (e) => {
    setInitialValues((prev) => ({
      ...prev,
      isMulti: !prev.isMulti,
    }));
  };

  const onSubmit = async () => {
    await createOrUpdateCorporateDemandNotes(initialValues);
    setInitialValues({
      label: "",
      options: [],
      isMulti: false,
    });
    refetch();
    setOpen(false);
    setIsEdit(false);
  };

  const handleOptions = () => {
    if (optionName.trim()) {
      setInitialValues((prevState) => ({
        ...prevState,
        options: [
          ...prevState.options,
          {
            label: optionName.trim(),
          },
        ],
      }));
      setOptionName("");
      setOpenModal(false);
    }
  };

  const handleEditOption = (index, newValue) => {
    const updatedOptions = initialValues.options.map((op, i) =>
      i === index ? { ...op, label: newValue } : op
    );
    setInitialValues({ ...initialValues, options: updatedOptions });
  };

  const handleRemoveOption = (indexToRemove) => {
    setInitialValues((prevState) => ({
      ...prevState,
      options: prevState.options.filter((_, index) => index !== indexToRemove),
    }));
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
        setIsEdit(false);
        setInitialValues({
          label: "",
          options: [],
          isMulti: false,
        });
      }}
    >
      <div
        className="relative h-fit max-h-[90%] flex overflow-auto w-full md:w-[40%] flex-col font-openSans bg-white p-10 rounded shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
      >
        <div
          onClick={() => {
            setOpen(false);
            setIsEdit(false);
            setInitialValues({
              label: "",
              options: [],
              isMulti: false,
            });
          }}
          className="flex cursor-pointer justify-end items-end w-full"
        >
          <CloseIcon />
        </div>
        <Box className="font-bold text-xl mb-4 w-full text-center">
          {isEdit ? "Update" : "Create New Demand Note"}
        </Box>
        <div className="w-full flex flex-col gap-y-4">
          <CustomInput
            label="DEMAND NOTES NAME"
            placeholder="Enter name"
            type="text"
            value={initialValues?.label || ""}
            classes="font-openSans w-full text-base"
            containerClasses="w-full"
            onChange={(e) => {
              setInitialValues((prev) => ({ ...prev, label: e.target.value }));
            }}
          />
          <div className="w-full">
            <Checkbox
              checked={initialValues.isMulti || false}
              handleChange={handleMultiChange}
              label="Allow multiple selections"
              color="primary"
            />
          </div>
          <div className="w-full">
            <div className="text-sm uppercase font-light text-gray-500 mb-2">
              OPTIONS
            </div>
            {initialValues.options &&
              initialValues.options.map((option, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between py-2 border-b border-gray-200"
                >
                  {editingIndex === index ? (
                    <CustomInput
                      type="text"
                      value={option.label}
                      onChange={(e) => handleEditOption(index, e.target.value)}
                      classes="font-openSans text-base w-full"
                      containerClasses="w-full mr-2"
                      onBlur={() => setEditingIndex(null)}
                      autoFocus
                    />
                  ) : (
                    <span>{option.label}</span>
                  )}
                  <div className="flex items-center">
                    <button
                      onClick={() => setEditingIndex(index)}
                      className="text-gray-500 hover:text-gray-700 mr-2"
                    >
                      <PencilIcon className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleRemoveOption(index)}
                      className="text-gray-500 hover:text-gray-700"
                    >
                      <svg
                        width="14"
                        height="18"
                        viewBox="0 0 14 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.50016 15.4167C1.50016 16.425 2.32516 17.25 3.3335 17.25H10.6668C11.6752 17.25 12.5002 16.425 12.5002 15.4167V4.41667H1.50016V15.4167ZM13.4168 1.66667H10.2085L9.29183 0.75H4.7085L3.79183 1.66667H0.583496V3.5H13.4168V1.66667Z"
                          fill="currentColor"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              ))}
            <button
              onClick={() => setOpenModal(!openModal)}
              className="flex items-center gap-x-2 mt-2 text-gray-600 hover:text-gray-800"
            >
              <PlusSmIcon className="h-5 w-5" />
              <span className="text-sm">Add Options</span>
            </button>
            {openModal && (
              <div className="mt-2">
                <CustomInput
                  type="text"
                  label=""
                  placeholder="Enter Option Name"
                  classes="font-openSans text-base w-full"
                  containerClasses="w-full"
                  style={{ maxHeight: "48px" }}
                  value={optionName}
                  onChange={(e) => setOptionName(e.target.value)}
                />
                <CustomButton className="mt-2" handleClick={handleOptions}>
                  <span className="font-medium py-2 px-4 text-base flex gap-2 items-center">
                    Add
                  </span>
                </CustomButton>
              </div>
            )}
          </div>
        </div>

        <CustomButton className="w-full mt-4" handleClick={() => onSubmit()}>
          <span className="font-medium py-2 px-4 text-base">
            {isEdit ? "Update" : "Create New Demand Note"}
          </span>
        </CustomButton>
      </div>
    </Modal>
  );
};

export default AddForm;
