/*eslint-disable */
// @ts-nocheck
import React from 'react';
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DownloadIcon,
} from '@heroicons/react/outline';
import useContactContext from 'pages/newContacts/hooks/useContactContext';
import { isMobile } from 'react-device-detect';
import { FiDownload } from 'react-icons/fi';
import { Select, Tooltip } from 'antd';
import { Filter, ListIcon, RefreshCw } from 'lucide-react';
import { FilterIcon } from '@heroicons/react/solid';
import { BiSort } from 'react-icons/bi';
import { ReactComponent as DownIcon } from 'assets/icons/down.svg';
import useContactContextV2 from 'pages/newContacts/hooks/useContactContextV2';
import { set } from 'lodash';
// import FilterDrawer from 'pages/newContacts/FilterDrawer/FilterDrawer';
import FilterBox from 'pages/newContacts/FilterComponent/FilterBox';
import FilterDrawer from 'pages/newContacts/FilterDrawer/FilterDrawer';
import useGetLists from 'hooks/useGetLists';
import { placeholder } from 'glamor';
import { DeleteOutlined } from '@ant-design/icons';
import { Modal } from '@material-ui/core';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import CustomButton from 'components/CustomButton';
import { updateUserDeleteStatusMultiple } from 'store/actions/usersActions';
import SearchBox from '../../SearchBox/SearchBox';
import toast from 'react-hot-toast';
import AddToProjectDrawer from 'pages/newContacts/AddToProjectDrawer/AddToProjectDrawer'; // Import the new drawer

const TablePaginationV2 = ({
  loadingAnimation,
  closeLoadingAnimation,
  customContacts = null,
  contacts,
}) => {
  const {
    state,
    setFilters,
    page,
    setPage,
    LIMIT,
    openDownloadContactsModal,
    openCreateContactModal,
    applyBuyerFilter,
    applyBrokerFilter,
    applyDefaultFilter,
    openFilterDrawer,
    toggleFilterDrawer,
    openAddToListDrawer,
    openAssignSalesRep,
    refetchFilteredUser,
    refreshUsers,
    emptyCheckedSelections,
    openAddToProjectDrawer,
    view
  } = useContactContext();
  const [tempTest, setTempTest] = React.useState('All');
  const [tempPageValue, setTempPageValue] = React.useState(1);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const getEndLimitForPages = () =>
    Math.ceil(state?.totalRecords / state.filters.limit);
  const { lists, isLoading, refetch } = useGetLists();
  const toPreviousPage = () => {
    if (page <= 1) return;
    setPage(page - 1);
    setFilters({ ...state.filters, page: page - 2 });
    if (state.search === '') {
      setFilters({ ...state.filters, page: page - 2 });
    } else {
      setSearch(state.search, { ...state.filters, page: page - 2 });
    }
  };

  const toNextPage = () => {
    if (page * state.filters.limit >= state?.totalRecords) return;
    setPage(page + 1);
    setFilters({ ...state.filters, page });
    if (state.search === '') {
      setFilters({ ...state.filters, page });
    } else {
      setSearch(state.search, { ...state.filters, page });
    }
  };

  const toSpecificPage = (pageNumber) => {
    setPage(pageNumber);
    if (state.search === '') {
      setFilters({ ...state.filters, page: pageNumber - 1, limit: 10 });
    } else {
      setSearch(state.search, {
        ...state.filters,
        page: pageNumber - 1,
      });
    }
  };

  const toFirstPage = () => {
    setPage(1);
    setFilters({ ...state.filters, page: 0 });
  };

  const toLastPage = () => {
    setPage(getEndLimitForPages());
    setFilters({
      ...state.filters,
      page: getEndLimitForPages() - 1,
    });
  };
  const isValidPage = (pageNumber) => pageNumber > 0;

  const openDeleteContactsModal = () => {
    setOpenDeleteModal(true);
  };

  const handleSoftDelete = async () => {
    let contactIds = Object.keys(state.checkedContacts).filter(
      (key) => state.checkedContacts[key] === true
    );
    let count = contactIds.length;
    try {
      const response = await updateUserDeleteStatusMultiple(contactIds).then(
        () => {
          toast.success(`${count} contacts deleted successfully`);
          setOpenDeleteModal(false);
          refetchFilteredUser();
        }
      );
    } catch (error) {
      console.log('Error in deleting user', error);
    }
  };

  return (
    <div className='flex flex-row gap-y-4 flex-wrap px-2 py-4 bg-white rounded-tb-[8px] border-[rgb(46,43,46,0.4)] gap-x-2 justify-between items-center'>
      <div className='flex items-center gap-x-3'>
        <SearchBox />
        <div className='flex items-center gap-x-3'>
          <select
            className='outline-none focus:!border-gray-300 w-[200px] font-bold border-gray-300 shadow-sm rounded-md h-[38px]'
            onChange={(e) => {
              setFilters({
                ...state.filters,
                lists: e.target.value === 'All' ? '' : e.target.value,
                leadStatus: '',
              });

            }}
            value={state.filters?.lists || 'All'}
          >
            <option value='All'>All</option>
            {lists &&
              lists.map((list) => {
                return (
                  <option key={list._id} className='!py-2' value={list._id}>
                    {list.title}
                  </option>
                );
              })}
          </select>
        </div>
        <div
          onClick={openFilterDrawer}
          className='relative cursor-pointer shadow-sm inline-flex items-center rounded-[8px] border border-gray-300 px-3 py-2 text-sm font-medium text-black hover:bg-gray-50 focus:z-20'
        >
          <Filter className='h-4 w-4' />
          <span className='ml-2'>Filters</span>
        </div>
        {/* <div className='relative cursor-pointer shadow-sm inline-flex items-center rounded-[8px] border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-black hover:bg-gray-50 focus:z-20'>
          <BiSort className='h-4 w-4' />
          <span className='ml-2'>Sort</span>
        </div> */}
        {Object.values(state.checkedContacts).filter((val) => val === true)
          .length > 0 && (
            <div
              onClick={openAddToListDrawer}
              className='relative cursor-pointer shadow-sm inline-flex items-center rounded-[8px] border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-black hover:bg-gray-50 focus:z-20'
            >
              <ListIcon className='h-4 w-4' />
              <span className='ml-2'>Manage List</span>
            </div>
          )}
        {Object.values(state.checkedContacts).filter((val) => val === true)
          .length > 0 && (
            <div
              onClick={openAssignSalesRep}
              className='relative cursor-pointer shadow-sm inline-flex items-center rounded-[8px] border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-black hover:bg-gray-50 focus:z-20'
            >
              <ListIcon className='h-4 w-4' />
              <span className='ml-2'>Assign Sales Rep</span>
            </div>
          )}

        {Object.values(state.checkedContacts).filter((val) => val === true)
          .length > 0 && view === 'corporate' && (
            <div
              onClick={openAddToProjectDrawer}
              className='relative cursor-pointer shadow-sm inline-flex items-center rounded-[8px] border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-black hover:bg-gray-50 focus:z-20'
            >
              <span className='ml-2'>Add to Project</span>
            </div>
          )}
      </div>

      <div className=''>
        <nav
          className='isolate inline-flex items-center gap-x-4 rounded-[8px] '
          aria-label='Pagination'
        >
          <div className='flex items-center gap-x-3'>
            <div className='text-sm text-gray-700'>Page Size: </div>
            <div className='min-w-[80px]'>
              <select
                value={state.filters.limit}
                className='h-[38px] font-bold border-gray-300 shadow-sm rounded-[8px]'
                onChange={(e) => {
                  setFilters({
                    ...state.filters,
                    page: 0,
                    limit: e.target.value,
                  });
                  setPage(1);
                }}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={500}>500</option>
              </select>
            </div>
          </div>

          <div className='flex items-center'>
            <a
              onClick={toPreviousPage}
              className='relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-black hover:bg-gray-50 focus:z-20'
            >
              <span className='sr-only'>Previous</span>
              <ChevronLeftIcon className='h-5 w-5' aria-hidden='true' />
            </a>
            <div className='relative h-[38px] w-[38px] inline-flex items-center border-[1px] border-gray-300 bg-white text-sm font-medium text-black hover:bg-gray-50 focus:z-20'>
              <input
                onChange={(e) => {
                  const value = parseInt(e.target.value, 10);
                  if (
                    isNaN(value) ||
                    value < 1 ||
                    value > getEndLimitForPages()
                  )
                    return;
                  setFilters({
                    ...state.filters,
                    page: value - 1,
                  });
                  setPage(value);

                }}
                value={page}
                type='text'
                className='border-none !mb-0 p-1 text-sm flex text-center items-center justify-center'
              />
            </div>
            {/* {isValidPage(
              page + 1 < getEndLimitForPages() - 1
                ? page + 1
                : getEndLimitForPages() - 2
            ) ? (
              <a
                onClick={() =>
                  toSpecificPage(
                    page < getEndLimitForPages() - 2
                      ? page
                      : getEndLimitForPages() - 3
                  )
                }
                aria-current='page'
                className={`relative z-10 inline-flex items-center border border-gray-300 ${
                  page ===
                  (page < getEndLimitForPages() - 2
                    ? page
                    : getEndLimitForPages() - 3)
                    ? 'bg-indigo-50 text-indigo-600'
                    : 'bg-white text-black'
                } px-4 py-2 text-sm font-medium focus:z-20`}
              >
                {page < getEndLimitForPages() - 2
                  ? page
                  : getEndLimitForPages() - 3}
              </a>
            ) : null}
            {isValidPage(
              page + 1 < getEndLimitForPages() - 1
                ? page + 1
                : getEndLimitForPages() - 2
            ) ? (
              <a
                onClick={() =>
                  toSpecificPage(
                    page + 1 < getEndLimitForPages() - 1
                      ? page + 1
                      : getEndLimitForPages() - 2
                  )
                }
                className={`relative inline-flex items-center border border-gray-300 ${
                  page ===
                  (page + 1 < getEndLimitForPages() - 1
                    ? page + 1
                    : getEndLimitForPages() - 2)
                    ? 'bg-indigo-50 text-indigo-600'
                    : 'bg-white text-black'
                } px-4 py-2 text-sm font-medium hover:bg-gray-50 focus:z-20`}
              >
                {page + 1 < getEndLimitForPages() - 1
                  ? page + 1
                  : getEndLimitForPages() - 2}
              </a>
            ) : null}
            {(customContacts || contacts).slice(
              (page - 1) * LIMIT,
              LIMIT * page
            ).length >
              LIMIT - 1 &&
            (page + 1 < getEndLimitForPages() - 1
              ? page + 1
              : getEndLimitForPages() - 2) !==
              getEndLimitForPages() - 2 ? (
              <>
                <span className='relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700'>
                  ...
                </span>
                <a className='relative hidden items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-black hover:bg-gray-50 focus:z-20 md:inline-flex'>
                  ...
                </a>
              </>
            ) : null}
            {isValidPage(getEndLimitForPages() - 1) ? (
              <a
                onClick={() => toSpecificPage(getEndLimitForPages() - 1)}
                className={`relative inline-flex items-center border border-gray-300 ${
                  page === getEndLimitForPages() - 1
                    ? 'bg-indigo-50 text-indigo-600'
                    : 'bg-white text-black'
                } px-4 py-2 text-sm font-medium hover:bg-gray-50 focus:z-20`}
              >
                {getEndLimitForPages() - 1}
              </a>
            ) : null} */}
            {/* <a
              onClick={() => toSpecificPage(getEndLimitForPages())}
              className={`relative inline-flex items-center border border-gray-300 ${
                page === getEndLimitForPages()
                  ? 'bg-indigo-50 text-indigo-600'
                  : 'bg-white text-black'
              } px-4 py-2 text-sm font-medium hover:bg-gray-50 focus:z-20`}
            >
              {getEndLimitForPages()}
            </a> */}
            <a
              onClick={toNextPage}
              className='relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-black hover:bg-gray-50 focus:z-20'
            >
              <span className='sr-only'>Next</span>
              <ChevronRightIcon className='h-5 w-5' aria-hidden='true' />
            </a>
          </div>
          <div className='flex gap-x-2'>
            <a
              onClick={refreshUsers}
              className='relative cursor-pointer inline-flex items-center justify-center rounded-[8px] border px-3 py-2 border-gray-300 bg-white text-sm font-medium text-black hover:bg-gray-50 focus:z-20'
            >
              <Tooltip title='Refresh'>
                <span className='sr-only'>Refresh</span>
                <RefreshCw className='h-5 w-5' />
              </Tooltip>
            </a>
            <a
              onClick={openDownloadContactsModal}
              className='relative cursor-pointer inline-flex items-center justify-center rounded-[8px] border px-3 py-2 border-gray-300 bg-white text-sm font-medium text-black hover:bg-gray-50 focus:z-20'
            >
              <Tooltip title='Download Contacts'>
                <span className='sr-only'>Downlaod</span>
                <FiDownload className='h-5 w-5' />
              </Tooltip>
            </a>
            <a
              onClick={openCreateContactModal}
              className='relative cursor-pointer inline-flex items-center justify-center rounded-[8px] border px-3 py-2 border-gray-300 bg-white text-sm font-medium text-black hover:bg-gray-50 focus:z-20'
            >
              <span className='text-[22px]'>+</span>
              <span className='ml-2 font-medium text-sm'>Add Contact</span>
            </a>
            {Object.values(state.checkedContacts).filter((val) => val === true)
              .length > 0 && (
                <a
                  onClick={openDeleteContactsModal}
                  className='relative cursor-pointer flex items-center justify-center rounded-[8px] border px-3 py-2 border-gray-300 bg-white text-sm font-medium text-black hover:bg-gray-50 focus:z-20'
                >
                  <Tooltip title='Delete Contacts'>
                    <DeleteOutlined className='h-5' />
                  </Tooltip>
                </a>
              )}
            {openDeleteModal && (
              <Modal open={openDeleteModal}>
                <div
                  style={{ width: isMobile ? '95vw' : 400 }}
                  className='bg-grey-8 p-2 md:p-6 relative top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
                >
                  <button
                    className='absolute right-3 top-3'
                    onClick={() => setOpenDeleteModal(false)}
                  >
                    <CrossIcon />
                  </button>
                  <div className='flex flex-col gap-y-6 justify-center items-center'>
                    <div className='mt-6 text-[18px]'>
                      Are you sure you want delete (
                      {
                        Object.values(state.checkedContacts).filter(
                          (val) => val === true
                        ).length
                      }
                      ) Contact?
                    </div>
                    <div className='flex gap-x-6'>
                      <CustomButton
                        handleClick={() => {
                          handleSoftDelete();
                        }}
                      >
                        <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
                          Yes
                        </span>
                      </CustomButton>
                      <CustomButton
                        handleClick={() => {
                          setOpenDeleteModal(false);
                        }}
                      >
                        <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
                          No
                        </span>
                      </CustomButton>
                    </div>
                  </div>
                </div>
              </Modal>
            )}
          </div>
        </nav>
      </div>
    </div>
  );
};

export default TablePaginationV2;
