import React from 'react'

const Header = () => {
  return (
    <>
      <div
        className='bg-grey-7 fixed flex justify-between items-center px-8 pt-8 pb-6 border-b-2 border-softBlack'
        style={{ width: 'calc(100% - 88px)' }}
      >
        <p className='my-0 text-4xl font-bold'>Purchase Additional parking</p>
      </div>
    </>
  )
}

export default Header
