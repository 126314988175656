import React, { useEffect, useState } from 'react'
import propTypes from 'prop-types'
import { PageHeader } from 'components'
import { Select } from 'antd'
import { ReactComponent as DownIcon } from 'assets/icons/down.svg'
import { ReactComponent as TickIcon } from 'assets/icons/tickk.svg'
import { useHistory } from 'react-router-dom'
import useGetAllUserEmailContacts from 'hooks/useGetAllUserEmailContacts'
import './style/style.css'
import { getUsers } from 'store/actions/usersActions'
import { VerticalCardContact } from 'components/VerticalCardProfile'
import Loader from '../dynamicDropDown/Loader'

const Button = (props) => {
  return (
    <button className={`text-softBlack text-center text-base italic border border-softBlack rounded font-medium py-1.5 px-4 space-x-1.5 flex items-center ${props.className}`}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  )
}

const demoReasonForEdit = [
  {
    id: 'demoReasonForEdit1',
    name: 'Mistake in contract Data'
  },
  {
    id: 'demoReasonForEdit2',
    name: 'Change of Deposit amount'
  },
  {
    id: 'demoReasonForEdit3',
    name: 'Change of Deposit Due Date'
  },
  {
    id: 'demoReasonForEdit4',
    name: 'Change of equipment, appliances and furnishings'
  },
  {
    id: 'demoReasonForEdit5',
    name: 'Decoration allowance (Price Discount)'
  },
  {
    id: 'demoReasonForEdit6',
    name: 'Other'
  }
]

const demoRequiresApproval = [
  {
    id: 'demoRequiresApproval1',
    name: 'Yes'
  },
  {
    id: 'demoRequiresApproval2',
    name: 'No'
  }
]

const staticContactId = '6471a0791de59aa3f25605cf'

const Negotiate = () => {
  const history = useHistory()
  const { contacts } = useGetAllUserEmailContacts()
  const [currentNegotiationStep, setCurrentNegotiationStep] = useState(1)
  const [selectedReasonForEdit, setSelectedReasonForEdit] = useState(null)
  const [selectedRequiresApproval, setSelectedRequiresApproval] = useState(null)
  const [selectedUsersToResign, setSelectedUsersToResign] = useState([])
  const [editDetails, setEditDetails] = useState('')
  const [usersListToResign, setUsersListToResign] = useState([])
  const [contact, setContact] = useState({})

  useEffect(() => {
    if (contacts.length) {
      setUsersListToResign(contacts.map((contact) => ({
        ...contact,
        // @ts-ignore
        name: contact.emails[0].email
      })))
    }
  }, [contacts])

  useEffect(() => {
    getUsers(staticContactId).then((res) => setContact(res))
  }, [])

  const RightContent = () => {
    if (currentNegotiationStep === 1) {
      return (
        <div className='flex flex-row items-center'>
          <Button
            onClick={() => history.push('/admin/offers')}
          >
            Cancel
          </Button>
        </div>
      )
    }
    return null
  }

  const CustomSelect = ({ title, value, onChange, options = [], multipleSelection = false, showSearch = false, placeholder = 'Select' }) => {
    return (
      <div>
        <p className='mt-0 mb-2 text-base font-medium' style={{ letterSpacing: '0.16px' }}>{title}</p>
        <Select
          showArrow
          filterOption={(input, option) =>
            (option?.label ?? '')
              .toString()
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          value={value}
          onChange={onChange}
          className='ant-picker-input1'
          suffixIcon={<DownIcon />}
          getPopupContainer={(node) => node.parentNode}
          mode={multipleSelection ? 'tags' : null}
          showSearch={showSearch}
          placeholder={placeholder}
        >
          {options?.map((el) => (
            <Select.Option key={el.id} label={el.name} value={el.id}>
              {el.name}
            </Select.Option>
          ))}
        </Select>
      </div>
    )
  }

  const CustomTextArea = ({ label, value = '', placeholder, onChange }) => {
    return (
      <div className='text-left'>
        <p className='mt-0 mb-2 text-base font-medium' style={{ letterSpacing: '0.16px' }}>{label}</p>
        <textarea
          value={value}
          placeholder={placeholder}
          className='rounded h-24 font-normal border border-softBlack30 w-full text-base text-grey-9'
          onChange={onChange}
        />
      </div>
    )
  }

  // const ProfileView = () => {
  //   return (
  //     <div className='w-80 h-full overflow-y-auto thin-scrollbar bg-white border-r border-zinc-800 border-opacity-30 flex-col justify-start items-start inline-flex'>
  //       <div className='self-stretch h-80 p-4 bg-white flex-col justify-start items-start gap-2 flex'>
  //         <div className='self-stretch h-64 p-4 rounded-lg flex-col justify-start items-center gap-4 flex border-2 border-softBlack_70'>
  //           <div className='self-stretch h-32 flex-col justify-start items-center gap-3 flex'>
  //             <div className='w-16 h-16 relative border-4 border-orange-400 rounded-full flex justify-center item-center'>
  //               <img className='w-14 h-14 rounded-3xl' src='/austin-jones.png' />
  //             </div>
  //             <div className='self-stretch h-12 py-px flex-col justify-center items-center gap-1 flex'>
  //               <div className='text-zinc-800 text-xl font-semibold leading-7'>Anita Cheong</div>
  //               <div className='text-zinc-800 text-opacity-70 text-base font-normal leading-snug'>Buyer</div>
  //             </div>
  //           </div>
  //           <div className='justify-start items-start gap-2 inline-flex'>
  //             <div className='px-4 py-2 rounded border border-zinc-800 border-opacity-50 justify-start items-center gap-2 flex'>
  //               <svg width='22' height='23' viewBox='0 0 22 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
  //                 <g clipPath='url(#clip0_16_86)'>
  //                   <path d='M18.3979 4.30304H3.97954C2.98828 4.30304 2.18626 5.11407 2.18626 6.10534L2.17725 16.9191C2.17725 17.9104 2.98828 18.7214 3.97954 18.7214H18.3979C19.3892 18.7214 20.2002 17.9104 20.2002 16.9191V6.10534C20.2002 5.11407 19.3892 4.30304 18.3979 4.30304ZM18.0375 8.13292L11.6663 12.116C11.378 12.2962 10.9995 12.2962 10.7111 12.116L4.34 8.13292C4.11472 7.98874 3.97954 7.74543 3.97954 7.4841C3.97954 6.88033 4.63738 6.51987 5.15104 6.83527L11.1887 10.6111L17.2264 6.83527C17.7401 6.51987 18.3979 6.88033 18.3979 7.4841C18.3979 7.74543 18.2628 7.98874 18.0375 8.13292Z' fill='#2E2B2E' />
  //                 </g>
  //                 <defs>
  //                   <clipPath id='clip0_16_86'>
  //                     <rect width='21.6276' height='21.6276' fill='white' transform='translate(0.374573 0.698486)' />
  //                   </clipPath>
  //                 </defs>
  //               </svg>
  //               <div className='text-center text-zinc-800 text-base font-normal leading-snug'>Message</div>
  //             </div>
  //             <div className='h-full p-2 rounded border border-zinc-800 border-opacity-50 justify-start items-center gap-2 flex'>
  //               <svg width='18' height='19' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
  //                 <g clipPath='url(#clip0_16_93)'>
  //                   <path d='M5.02983 8.63995C6.06796 10.6802 7.74049 12.3455 9.78069 13.3908L11.3667 11.8048C11.5614 11.6101 11.8497 11.5453 12.1021 11.6318C12.9095 11.8985 13.7818 12.0427 14.6757 12.0427C15.0722 12.0427 15.3967 12.3671 15.3967 12.7636V15.2796C15.3967 15.6761 15.0722 16.0005 14.6757 16.0005C7.9063 16.0005 2.4201 10.5143 2.4201 3.74491C2.4201 3.3484 2.74452 3.02399 3.14102 3.02399H5.66424C6.06075 3.02399 6.38516 3.3484 6.38516 3.74491C6.38516 4.64606 6.52935 5.51116 6.79609 6.31859C6.87539 6.57091 6.81771 6.85207 6.61586 7.05393L5.02983 8.63995Z' fill='#2E2B2E' />
  //                 </g>
  //                 <defs>
  //                   <clipPath id='clip0_16_93'>
  //                     <rect width='17.3021' height='17.3021' fill='white' transform='translate(0.257385 0.861206)' />
  //                   </clipPath>
  //                 </defs>
  //               </svg>
  //             </div>
  //             <div className='h-full p-2 rounded border border-zinc-800 border-opacity-50 justify-start items-center gap-2 flex'>
  //               <svg width='18' height='19' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
  //                 <path d='M11.0559 9.51226C11.0559 9.88655 10.9449 10.2524 10.737 10.5636C10.529 10.8748 10.2335 11.1174 9.88767 11.2606C9.54187 11.4039 9.16137 11.4413 8.79428 11.3683C8.42719 11.2953 8.08999 11.1151 7.82533 10.8504C7.56067 10.5857 7.38044 10.2485 7.30742 9.88145C7.2344 9.51436 7.27188 9.13386 7.41511 8.78807C7.55834 8.44227 7.8009 8.14672 8.1121 7.93878C8.42331 7.73084 8.78919 7.61985 9.16347 7.61985C9.66537 7.61985 10.1467 7.81923 10.5016 8.17412C10.8565 8.52902 11.0559 9.01036 11.0559 9.51226ZM9.16347 5.99778C9.53776 5.99778 9.90363 5.88679 10.2148 5.67885C10.526 5.47091 10.7686 5.17535 10.9118 4.82956C11.0551 4.48377 11.0925 4.10327 11.0195 3.73617C10.9465 3.36908 10.7663 3.03189 10.5016 2.76723C10.237 2.50257 9.89976 2.32233 9.53266 2.24931C9.16557 2.1763 8.78507 2.21377 8.43928 2.357C8.09348 2.50024 7.79793 2.74279 7.58999 3.054C7.38205 3.3652 7.27106 3.73108 7.27106 4.10537C7.27106 4.60727 7.47044 5.08861 7.82533 5.4435C8.18023 5.7984 8.66157 5.99778 9.16347 5.99778ZM9.16347 13.0267C8.78919 13.0267 8.42331 13.1377 8.1121 13.3457C7.8009 13.5536 7.55834 13.8492 7.41511 14.195C7.27188 14.5408 7.2344 14.9213 7.30742 15.2883C7.38044 15.6554 7.56067 15.9926 7.82533 16.2573C8.08999 16.522 8.42719 16.7022 8.79428 16.7752C9.16137 16.8482 9.54187 16.8108 9.88767 16.6675C10.2335 16.5243 10.529 16.2817 10.737 15.9705C10.9449 15.6593 11.0559 15.2934 11.0559 14.9192C11.0559 14.4173 10.8565 13.9359 10.5016 13.581C10.1467 13.2261 9.66537 13.0267 9.16347 13.0267Z' fill='#2E2B2E' />
  //               </svg>
  //             </div>
  //           </div>
  //           <div className='h-10 rounded-md justify-center items-center gap-3 inline-flex'>
  //             <div className='text-zinc-800 text-base font-normal leading-snug'>Contract Writing</div>
  //             <StepsIcon />
  //           </div>
  //         </div>
  //       </div>
  //       <div className='self-stretch h-44 px-4 pb-4 bg-white flex-col justify-start items-start gap-2 flex'>
  //         <div className='self-stretch bg-white justify-start items-start gap-6 inline-flex'>
  //           <div className='w-28 bg-white flex-col justify-start items-start gap-2 inline-flex'>
  //             <div className='h-10 justify-start items-center gap-1 inline-flex'>
  //               <svg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
  //                 <path d='M16.4375 14.9136C16.39 14.9958 16.3217 15.0641 16.2395 15.1115C16.1573 15.159 16.064 15.184 15.9691 15.1839H2.99253C2.89766 15.1838 2.8045 15.1588 2.72238 15.1113C2.64026 15.0638 2.57208 14.9955 2.52469 14.9133C2.4773 14.8312 2.45237 14.738 2.45239 14.6431C2.45242 14.5482 2.4774 14.4551 2.52483 14.3729C3.55417 12.5934 5.14042 11.3173 6.9916 10.7124C6.07592 10.1673 5.3645 9.3367 4.96658 8.34813C4.56866 7.35956 4.50625 6.26769 4.78893 5.24022C5.07161 4.21274 5.68376 3.30647 6.53136 2.66057C7.37896 2.01467 8.41515 1.66486 9.4808 1.66486C10.5465 1.66486 11.5826 2.01467 12.4302 2.66057C13.2779 3.30647 13.89 4.21274 14.1727 5.24022C14.4554 6.26769 14.3929 7.35956 13.995 8.34813C13.5971 9.3367 12.8857 10.1673 11.97 10.7124C13.8212 11.3173 15.4074 12.5934 16.4368 14.3729C16.4843 14.455 16.5094 14.5482 16.5096 14.6432C16.5097 14.7381 16.4848 14.8313 16.4375 14.9136Z' fill='#2E2B2E' fillOpacity='0.5' />
  //               </svg>
  //               <div className='text-zinc-800 text-opacity-50 text-base font-normal leading-snug'>Broker</div>
  //             </div>
  //             <div className='h-10 justify-start items-center gap-1 inline-flex'>
  //               <svg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
  //                 <path d='M16.4375 14.9136C16.39 14.9958 16.3217 15.0641 16.2395 15.1115C16.1573 15.159 16.064 15.184 15.9691 15.1839H2.99253C2.89766 15.1838 2.8045 15.1588 2.72238 15.1113C2.64026 15.0638 2.57208 14.9955 2.52469 14.9133C2.4773 14.8312 2.45237 14.738 2.45239 14.6431C2.45242 14.5482 2.4774 14.4551 2.52483 14.3729C3.55417 12.5934 5.14042 11.3173 6.9916 10.7124C6.07592 10.1673 5.3645 9.3367 4.96658 8.34813C4.56866 7.35956 4.50625 6.26769 4.78893 5.24022C5.07161 4.21274 5.68376 3.30647 6.53136 2.66057C7.37896 2.01467 8.41515 1.66486 9.4808 1.66486C10.5465 1.66486 11.5826 2.01467 12.4302 2.66057C13.2779 3.30647 13.89 4.21274 14.1727 5.24022C14.4554 6.26769 14.3929 7.35956 13.995 8.34813C13.5971 9.3367 12.8857 10.1673 11.97 10.7124C13.8212 11.3173 15.4074 12.5934 16.4368 14.3729C16.4843 14.455 16.5094 14.5482 16.5096 14.6432C16.5097 14.7381 16.4848 14.8313 16.4375 14.9136Z' fill='#2E2B2E' fillOpacity='0.5' />
  //               </svg>
  //               <div className='text-zinc-800 text-opacity-50 text-base font-normal leading-snug'>Sales Rep</div>
  //             </div>
  //           </div>
  //           <div className='grow shrink basis-0 bg-white flex-col justify-start items-start gap-2 inline-flex'>
  //             <div className='h-10 bg-white justify-end items-center gap-1 inline-flex'>
  //               <div className='px-2 py-1 bg-[#F0EEE9] rounded-md justify-center items-center gap-1 flex'>
  //                 <img className='w-6 h-6 relative rounded-full' src='/jocelyn-passaquindici.png' />
  //                 <div className='text-zinc-800 text-sm font-normal leading-tight'>Roger Banister</div>
  //               </div>
  //             </div>
  //             <div className='h-10 bg-white justify-end items-center gap-1 inline-flex'>
  //               <div className='px-2 py-1 bg-[#F0EEE9] rounded-md justify-center items-center gap-1 flex'>
  //                 <img className='w-6 h-6 relative rounded-full' src='/stefan-dupuis.png' />
  //                 <div className='text-zinc-800 text-sm font-normal leading-tight'>Alex Mulligan</div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>

  //     </div>
  //   )
  // }

  return (
    <>
      <PageHeader
        title='Edit Contract | Purchase and Sale'
        rightContent={<RightContent />}
      />
      <div className='h-[calc(100vh-164px)]'>
        {
          currentNegotiationStep === 1 &&
          <div className='h-full flex'>
            <div className='w-[350px] border-r border-zinc-800 border-opacity-30'>
              {
                !(contact?._id?.length)
                  ? <div className='h-full w-full flex justify-center items-center text-lg font-medium'>
                    <Loader />
                  </div>
                  : <VerticalCardContact contact={contact} isNegotiation />
              }
            </div>
            {/* <ProfileView /> */}
            <div className='h-full flex-1 flex'>
              <div className='w-[60%] h-full'>
                <iframe
                  src='https://dharmikjagodana.github.io/pdf.js/web/viewer.html'
                  className='h-full w-full'
                />
              </div>
              <div className='flex-1 h-fit rounded flex flex-col gap-y-4 p-6 lg:p-8'>
                <CustomSelect
                  title='Reason for Edit'
                  value={selectedReasonForEdit}
                  onChange={(value) => setSelectedReasonForEdit(value)}
                  options={demoReasonForEdit}
                  placeholder='Select reason'
                />
                <CustomTextArea
                  label='Edit Details'
                  placeholder='Describe the edit in detail'
                  value={editDetails}
                  onChange={(e) => setEditDetails(e.target.value)}
                />
                <CustomSelect
                  title='Requires Approval'
                  value={selectedRequiresApproval}
                  onChange={(value) => setSelectedRequiresApproval(value)}
                  options={demoRequiresApproval}
                  placeholder='Select option'
                />
                <CustomSelect
                  title='Users to Resign'
                  value={selectedUsersToResign}
                  onChange={(value) => setSelectedUsersToResign(value)}
                  options={usersListToResign}
                  showSearch={true}
                  multipleSelection={true}
                  placeholder='Select users'
                />
                <div className='mt-6 flex flex-wrap justify-end items-center gap-1.5'>
                  <Button
                  // onClick={() => setCurrentNegotiationStep(2)}
                  >
                    Save Draft
                  </Button>
                  <Button
                    className='border-volt bg-volt'
                    onClick={() => setCurrentNegotiationStep(2)}
                  >
                    Send for Signatures
                  </Button>
                </div>
              </div>
            </div>
          </div>
        }
        {
          currentNegotiationStep === 2 &&
          <div className='h-full flex flex-col justify-center items-center gap-12'>
            <h3 className='text-4xl font-bold m-0'>Contract Edit Complete</h3>
            <div className='bg-volt h-36 w-36 rounded-lg flex justify-center items-center'>
              <TickIcon />
            </div>
            <div className='flex flex-col items-center gap-6'>
              <Button
                className='border-volt bg-volt text-lg'
                onClick={() => history.push('/admin/offers')}
              >
                Return to Dashboard
              </Button>
              <Button
                className='text-sm'
                onClick={() => {
                  setCurrentNegotiationStep(1)
                  // history.push('/admin/negotiate')
                }}
              >
                Edit another Document
              </Button>
            </div>
          </div>
        }
      </div>
    </>
  )
}

export default Negotiate

Button.propTypes = {
  className: propTypes.string,
  disabled: propTypes.bool,
  onClick: propTypes.func,
  children: propTypes.node
}
