import React, { useEffect, useState } from 'react'

import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'
import { Oval } from 'react-loader-spinner'

import { Modal } from '@material-ui/core'
import { getProjectUnits } from 'store/actions/unitActions'
import { useSelector } from 'react-redux'
import Skeleton from 'react-loading-skeleton'
import { AVAILABILITY_BG_COLOR, formatMoney } from 'utils'
import demoUnitImage from 'assets/img/unit-1201.png'
import { createOrUpdateUnitPolygon } from 'store/actions/unitPolygonActions'

const AssignPolygonToUnitModal = ({
  show,
  setShow,
  refetch,
  polygonsData,
  handleCancel
}) => {
  const projectSelector = state => state.appReducer.appProject
  const appProject = useSelector(projectSelector)
  const [loading, setLoading] = useState(false)
  const [unitsData, setUnitsData] = useState([])
  const [selectedUnit, setSelectedUnit] = useState(null)

  const handleSubmit = () => {
    if (validate()) {
      setLoading(true)
      const temp = {
        unit: selectedUnit,
        polygon: polygonsData
      }
      createOrUpdateUnitPolygon(temp)
        .then((data) => {
          refetch()
          setLoading(false)
          setShow(false)
        })
        .catch((err) => {
          setLoading(false)
          console.log(err)
        })
    }
  }

  const handleClose = () => {
    handleCancel()
    setShow(false)
    setSelectedUnit(null)
  }

  const validate = () => {
    if (!selectedUnit?._id || !(polygonsData.length > 0)) {
      return false
    }
    return true
  }

  useEffect(() => {
    setLoading(true)
    getProjectUnits(appProject).then((res) => {
      setUnitsData(res)
      setLoading(false)
    })
  }, [])

  const UnitCard = ({ id, unitNumber, price, status, onClick }) => (
    <div
      onClick={onClick}
      className={`col-span-1 p-4 flex items-center justify-center gap-4 rounded-md cursor-pointer transition-all duration-200 border-2 ${selectedUnit?._id === id
        ? 'border-volt bg-gray-2'
        : 'bg-gray-1 hover:bg-gray-2 border-zinc-200'
        }`}
    >
      <img
        src={demoUnitImage}
        className='w-1/2'
      />
      <div className='flex-1 h-full flex flex-col justify-between gap-2'>
        <div className='flex flex-col gap-1'>
          <h4 className='text-lg font-semibold mb-0'>Unit #{unitNumber ?? ''}</h4>
          <div
            className={
              'rounded uppercase text-xs font-medium text-softBlack h-6.75 p-1 flex justify-center items-center min-w-20 w-fit px-2'
            }
            style={{
              backgroundColor: AVAILABILITY_BG_COLOR[status.toLowerCase()]
            }}
          >
            {status}
          </div>
        </div>
        <p className='text-base font-semibold mb-0'>Price: {formatMoney(price) ?? ''}</p>
      </div>
    </div>
  )

  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div
        className='font-openSans relative bg-grey-8 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-10 px-8 rounded shadow-lg max-h-[calc(100vh-150px)] flex flex-col'
        style={{ width: 1015 }}
      >
        <button className='absolute right-10' onClick={handleClose}>
          <CrossIcon />
        </button>
        <h1 className='font-bold text-xl text-center p-0 m-0 mb-6'>
          Choose unit to assign
        </h1>
        {
          loading
            ? <div className='grid grid-cols-3 gap-4'>
              <Skeleton height={150} />
              <Skeleton height={150} />
              <Skeleton height={150} />
              <Skeleton height={150} />
              <Skeleton height={150} />
              <Skeleton height={150} />
            </div>
            : <div className='grid grid-cols-3 gap-4'>
              {
                unitsData.map((unit) => (
                  <UnitCard
                    id={unit._id}
                    key={unit._id}
                    unitNumber={unit.unitNumber}
                    price={unit.price}
                    status={unit.status}
                    onClick={() => setSelectedUnit(unit)}
                  />
                ))
              }
            </div>
        }
        <div className='mt-6 flex justify-center gap-6'>
          <button
            className='w-fit py-3 px-6 text-base border border-softBlack_70 rounded font-medium flex gap-2 items-center italic'
            // disabled={!validate() || loading}
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className='w-fit py-3 px-6 text-base bg-volt border border-volt rounded font-medium flex gap-2 items-center'
            disabled={!validate() || loading}
            onClick={handleSubmit}
          >
            {loading && <Oval height='18' width='18' color='black' />}
            <div className='italic'>Assign Unit</div>
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default AssignPolygonToUnitModal
