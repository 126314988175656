/* eslint-disable */
import React, { useContext, useEffect } from 'react';
import { Box, Button } from '@material-ui/core';
import AddForm from './AddForm';
import Table from './Table';
import { DemandNotesContextProvider } from './context';
import ConfirmDelete from './confirmDelete';
import { DemandNotesContext } from './context';

// import PageHeader from './PageHeader';

const DemandNotesDropdown = () => {
  const [open, setOpen] = React.useState(false);
  const { label } = useContext(DemandNotesContext);
  const [isEdit, setIsEdit] = React.useState(false);
  useEffect(() => {
    if (isEdit) {
      setOpen(true);
    }
  }, [isEdit]);

  return (
    <DemandNotesContextProvider>
      <div className='h-full w-full flex flex-col'>
        <ConfirmDelete />
        <div className='w-full p-5 flex flex-row items-center font-normal text-xl border-b-2 border-black'>
          Demand Notes DropDown Settings
        </div>
        <div className='h-full w-full flex flex-row'>
          <Box display='flex' padding='20px' flex={0.6}>
            <Table setIsEdit={setIsEdit} />
          </Box>
          <div className='mt-12 max-w-[200px]'>
            <Button
              onClick={() => {
                setOpen(!open);
              }}
              style={{
                backgroundColor: '#EDDF65',
                width: '100%',
                boxShadow: '0px 0px 0px rgba(0, 0, 0, 0)',
              }}
              variant='contained'
            >
              {isEdit ? 'Update' : 'Create New'}
            </Button>
          </div>
          {open && (
            <AddForm
              open={open}
              setOpen={setOpen}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              setRefetch={() => {
                console.log('refetch');
              }}
            />
          )}
        </div>
      </div>
    </DemandNotesContextProvider>
  );
};

export default DemandNotesDropdown;
