/* eslint-disable space-before-function-paren */
import CustomButton from 'components/CustomButton'
import CustomSelect from 'components/CustomSelect'
import CustomModal from 'components/Modal'
import TableGrid from 'components/TableGrid/Table'
import { useGetUsersCounts } from 'hooks/useGetUsers'
import useLeadSource from 'hooks/useLeadSource'
import useLeadStatus from 'hooks/useLeadStatus'
import useProjectId from 'hooks/useProjectId'
import { Loader, SaveAllIcon } from 'lucide-react'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { GetDemandNotes } from 'store/actions/demandNotes'
import { createSmartList, getSmartListPreview, updateSmartList } from 'store/actions/usersActions'

// Main Component
export default function CreateListDialog(props) {
  return (
    <div>
      <CreateList refreshData={
        () => {
          if (props.refreshData && typeof props?.refreshData === 'function') props?.refreshData()
        }
      } />
    </div>
  )
}

// Create List Component
function CreateList(props) {
  // @ts-ignore
  const { leadSources, loading: loadingLeadSources } = useLeadSource()
  const { leadStatuses, loading: loadingLeadStatuses } = useLeadStatus()
  const [demandNotes, setDemandNotes] = useState([])

  // @ts-ignore
  const appProject = useSelector(state => state?.appReducer?.appProject)
  const [show, setShow] = useState(false)
  const [creating, setCreating] = useState(false)
  const [updating, setUpdating] = useState(false)
  const [previewing, setPreviewing] = useState(false)
  useEffect(() => {
    if (!show) return
    GetDemandNotes().then((result) => {
      setDemandNotes(result)
    })
  }, [show])
  if (loadingLeadSources || loadingLeadStatuses) return <div>Loading...</div>
  const handleClose = () => {
    setShow(false)
  }
  async function updateList(id, data) {
    setUpdating(true)
    updateSmartList(id, data)
      .then(() => {
        setUpdating(false)
      })
      .catch((error) => {
        setUpdating(false)
        console.error('Error updating list', error)
      }).finally(() => {
        setShow(false)
        if (props.refreshData && typeof props?.refreshData === 'function') props?.refreshData()
      })
  }

  async function getDataPreviewForFilter(filter) {
    return new Promise((resolve, reject) => {
      setPreviewing(true)
      // @ts-ignore
      getSmartListPreview(filter)
        .then((data) => {
          resolve(data)
          setPreviewing(false)
        })
        .catch((error) => {
          console.error('Error getting preview data', error)
        }).finally(() => {
          setPreviewing(false)
          if (props.refreshData && typeof props?.refreshData === 'function') props?.refreshData()
        })
    })
  }

  async function createList(data) {
    setCreating(true)
    createSmartList(data, appProject)
      .then(() => {
        setCreating(false)
      })
      .catch((error) => {
        setCreating(false)
        console.error('Error creating list', error)
      }).finally(() => {
        setShow(false)
        if (props.refreshData && typeof props?.refreshData === 'function') props?.refreshData()
      })
  }

  return (
    <div className='w-full'>
      <button
        onClick={() => setShow(true)}
        className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
      >
        Create List
      </button>
      <CustomModal
        customStyles={{
          width: '100%',
          height: '100%'
        }}
        isOpen={show}
        closeModal={handleClose}
        contentLabel="Add Or Edit Contact Modal"
      >
        <Content
          listId={null}
          listData={null}
          demandNotes={demandNotes}
          leadSources={leadSources.values}
          leadStatuses={leadStatuses.values}
          closeModal={handleClose}
          previewing={previewing}
          updateList={(id, data) => {
            if (updating) return
            updateList(id, data)
          }}
          getDataPreviewForFilter={(data) => {
            return getDataPreviewForFilter(data)
          }}
          createList={(data) => {
            if (creating) return
            createList(data)
          }} />
      </CustomModal>
    </div >
  )
}

function getInWithOption(
  key,
  options = []
) {
  return {
    label: 'In',
    key,
    inputs: [{
      key: '$in',
      label: 'In',
      type: 'select-multiple',
      options
    }]
  }
}

function getNotInWithOption(
  key,
  options = []
) {
  return {
    label: 'Not In',
    key,
    inputs: [{
      key: '$nin',
      label: 'Not In',
      type: 'select-multiple',
      options
    }]
  }
}
// Content Component inside the Modal
const Content = (
  props
) => {
  const OPS = {
    CONTAINS: 'contains',
    EQUALS: 'equals',
    NOT_EQUALS: 'not-equals',
    RATINGS: {
      IN: 'in',
      NOT_IN: 'not-in'
    },
    LEAD_STATUS: {
      IN: 'in',
      NOT_IN: 'not-in'
    },
    LEAD_SOURCE: {
      IN: 'in',
      NOT_IN: 'not-in'
    },
    USER_TYPE: {
      IN: 'in',
      NOT_IN: 'not-in'
    },
    DEMAND_NOTES: {
      IN: 'in',
      NOT_IN: 'not-in'
    },
    IN_ARRAY: 'in-array',
    NOT_IN_ARRAY: 'not-in-array'
  }

  const opEqual = {
    label: 'Is',
    key: OPS.EQUALS,
    inputs: [{
      key: '$eq',
      label: 'Label',
      type: 'text',
      defaultValue: 'value'
    }]
  }

  const opNotEqual = {
    label: 'Is not',
    key: OPS.NOT_EQUALS,
    inputs: [{
      key: '$ne',
      label: 'Label',
      type: 'text',
      defaultValue: 'value'
    }]
  }

  const contains = {
    label: 'Contains',
    key: OPS.CONTAINS,
    inputs: [{
      key: '$contains',
      label: 'Label',
      type: 'text',
      defaultValue: 'value'
    }]
  }

  const doesNotContains = {
    label: 'Does not contain',
    key: OPS.NOT_CONTAINS,
    inputs: [{
      key: '$notContains',
      label: 'Label',
      type: 'text',
      defaultValue: 'value'
    }]
  }

  const opRatingsIn = {
    label: 'In',
    key: OPS.RATINGS.IN,
    inputs: [{
      key: '$in',
      label: 'In',
      type: 'select-multiple',
      options: [
        { label: 'A', value: 'A' },
        { label: 'B', value: 'B' },
        { label: 'C', value: 'C' },
        { label: 'Unrated', value: 'Unrated' }
      ]
    }]
  }
  const opRatingsNotIn = {
    label: 'Not In',
    key: OPS.RATINGS.NOT_IN,
    inputs: [{
      key: '$nin',
      label: 'Not In',
      type: 'select-multiple',
      options: [
        { label: 'A', value: 'A' },
        { label: 'B', value: 'B' },
        { label: 'C', value: 'C' },
        { label: 'Unrated', value: 'Unrated' }
      ]
    }]
  }

  const userTypeIn = {
    label: 'Is',
    key: OPS.USER_TYPE.IN,
    inputs: [{
      key: '$in',
      label: 'In',
      type: 'select',
      options: [
        { label: 'Buyer', value: 'Buyer' },
        { label: 'DeveloperAdmin', value: 'DeveloperAdmin' },
        { label: 'CoopBroker', value: 'CoopBroker' },
        { label: 'LeadBroker', value: 'LeadBroker' },
        { label: 'SalesRep', value: 'SalesRep' }
      ]
    }]
  }

  const userTypeNotIn = {
    label: 'Is Not',
    key: OPS.USER_TYPE.NOT_IN,
    inputs: [{
      key: '$nin',
      label: 'Not In',
      type: 'select',
      options: [
        { label: 'Buyer', value: 'Buyer' },
        { label: 'DeveloperAdmin', value: 'DeveloperAdmin' },
        { label: 'CoopBroker', value: 'CoopBroker' },
        { label: 'LeadBroker', value: 'LeadBroker' },
        { label: 'SalesRep', value: 'SalesRep' }
      ]
    }]
  }

  const leadStatusOptions = props.leadStatuses.map((status) => {
    return {
      label: status,
      value: status
    }
  })

  const leadSourceOptions = props.leadSources.map((source) => {
    return {
      label: source,
      value: source
    }
  })
  const opLeadStatusIn = {
    label: 'In',
    key: OPS.LEAD_STATUS.IN,
    inputs: [{
      key: '$in',
      label: 'In',
      type: 'select-multiple',
      options: leadStatusOptions
    }]
  }

  const opLeadStatusNotIn = {
    label: 'Not In',
    key: OPS.LEAD_STATUS.NOT_IN,
    inputs: [{
      key: '$nin',
      label: 'Not In',
      type: 'select-multiple',
      options: leadStatusOptions
    }]
  }
  const opLeadSourceIn = {
    label: 'In',
    key: OPS.LEAD_SOURCE.IN,
    inputs: [{
      key: '$in',
      label: 'In',
      type: 'select-multiple',
      options: leadSourceOptions
    }]
  }

  const opLeadSourceNotIn = {
    label: 'Not In',
    key: OPS.LEAD_SOURCE.NOT_IN,
    inputs: [{
      key: '$nin',
      label: 'Not In',
      type: 'select-multiple',
      options: leadSourceOptions
    }]
  }

  const AvailableFilters = [{
    label: 'First Name',
    key: 'firstName',
    ops: [opEqual, opNotEqual, contains, doesNotContains]
  },
  {
    label: 'Last Name',
    key: 'lastName',
    ops: [opEqual, opNotEqual]
  }, {
    label: 'Email',
    key: 'email',
    ops: [opEqual, opNotEqual]
  }, {
    label: 'Ratings',
    key: 'buyerData.rating',
    ops: [
      opRatingsIn,
      opRatingsNotIn
    ]
  },
  {
    label: 'Lead Status',
    key: 'buyerData.leadStatus',
    ops: [opLeadStatusIn, opLeadStatusNotIn]
  },
  {
    label: 'Lead Source',
    key: 'buyerData.leadSource',
    ops: [opLeadSourceIn, opLeadSourceNotIn]
  },
  {
    label: 'User Type',
    key: 'userType',
    ops: [
      userTypeIn,
      userTypeNotIn
    ]
  },
  ...props?.demandNotes?.map((note) => {
    const options = note.options.map((option) => {
      return {
        label: option.label,
        value: option.value
      }
    })
    const inOp = getInWithOption(`buyerData.customDemands.${note._id}`, options)
    const notInOp = getNotInWithOption(`buyerData.customDemands.${note._id}`, options)
    const final = [inOp, notInOp]
    return {
      label: `(Demand) ${note.label}`,
      key: `buyerData.customDemands.${note._id}`,
      ops: final
    }
  })
  ]

  const [title, setTitle] = useState(props.listData?.title || '')
  const [filters, setFilters] = useState([])
  const [editing] = useState(!!props.listId)
  const [previewContacts, setPreviewContacts] = useState([])
  const [previewCounts, setPreviewCounts] = useState(0)
  const { projectId } = useProjectId()
  const { count: usersCounts, isLoading: isLoadingCounts } = useGetUsersCounts(projectId)
  const tableRef = useRef(null)
  function addFilter() {
    const [filter] = AvailableFilters
    const [defaultOp] = filter.ops
    const newFilter = {
      key: filter.key,
      op: defaultOp?.key ?? '',
      inputs: {}
    }
    setFilters([...filters, newFilter])
  }

  function removeFilter(index) {
    setFilters(filters.filter((_, i) => i !== index))
  }

  function getQueryFromFilter() {
    const mongoQuery = filters.map((filter) => {
      const { key, op, inputs } = filter
      const query = {}
      query[key] = {}
      if (op === OPS.EQUALS) {
        if (inputs?.$eq) {
          query[key] = { $eq: inputs?.$eq }
        }
      } else if (op === OPS.NOT_EQUALS) {
        if (inputs?.$ne) {
          query[key] = { $ne: inputs?.$ne }
        }
      } else if (op === OPS.IN_ARRAY) {
        const value = inputs?.$in
        const array = []
        if (typeof value === 'string') {
          array.push(value)
        } else if (typeof value === 'object' && Array.isArray(value)) {
          array.push(...value)
        }
        query[key] = { $in: array }
      } else if (op === OPS.NOT_IN_ARRAY) {
        const value = inputs?.$nin
        const array = []
        if (typeof value === 'string') {
          array.push(value)
        } else if (typeof value === 'object' && Array.isArray(value)) {
          array.push(...value)
        }
        query[key] = { $nin: array }
      } else if (op === OPS.CONTAINS) {
        if (inputs?.$contains) {
          query[key] = { $regex: inputs?.$contains, $options: 'i' }
        }
      } else if (op === OPS.RATINGS.IN) {
        if (inputs?.$in) {
          const value = inputs?.$in
          const array = []
          if (typeof value === 'string') {
            array.push(value)
          } else if (typeof value === 'object' && Array.isArray(value)) {
            array.push(...value)
          }
          query[key] = {
            $in: array
          }
        }
      } else if (op === OPS.RATINGS.NOT_IN) {
        if (inputs?.$nin) {
          const value = inputs?.$nin
          const array = []
          if (typeof value === 'string') {
            array.push(value)
          } else if (typeof value === 'object' && Array.isArray(value)) {
            array.push(...value)
          }
          query[key] = {
            $nin: array
          }
        }
      } else if (op === OPS.LEAD_STATUS.IN) {
        if (inputs?.$in) {
          const value = inputs?.$in
          const array = []
          if (typeof value === 'string') {
            array.push(value)
          } else if (typeof value === 'object' && Array.isArray(value)) {
            array.push(...value)
          }
          query[key] = {
            $in: array
          }
        }
      } else if (op === OPS.LEAD_STATUS.NOT_IN) {
        if (inputs?.$nin) {
          const value = inputs?.$nin
          const array = []
          if (typeof value === 'string') {
            array.push(value)
          } else if (typeof value === 'object' && Array.isArray(value)) {
            array.push(...value)
          }
          query[key] = {
            $nin: array
          }
        }
      } else if (op === OPS.LEAD_SOURCE.IN) {
        if (inputs?.$in) {
          const value = inputs?.$in
          const array = []
          if (typeof value === 'string') {
            array.push(value)
          } else if (typeof value === 'object' && Array.isArray(value)) {
            array.push(...value)
          }
          query[key] = {
            $in: array
          }
        }
      } else if (op === OPS.LEAD_SOURCE.NOT_IN) {
        if (inputs?.$nin) {
          const value = inputs?.$nin
          const array = []
          if (typeof value === 'string') {
            array.push(value)
          } else if (typeof value === 'object' && Array.isArray(value)) {
            array.push(...value)
          }
          query[key] = {
            $nin: array
          }
        }
      } else {
        // delete the key if it is not used
        delete query[key]
      }
      return query
    }).filter((query) => {
      return Object.keys(query).length > 0
    })

    // loop through demand notes keys and
    // add them to the query
    const demandQuery = {}
    filters.forEach((filter) => {
      const { key, inputs } = filter
      // if buyerData.customDemands
      if (key.includes('buyerData.customDemands')) {
        const demandNote = props?.demandNotes.find((note) => {
          return key.includes(note._id)
        })
        if (demandNote) {
          const value = inputs?.$in
          // create mongodb for array match for custom demands
          if (value) {
            // {"buyerData.customDemands":{"$elemMatch":{"label":"Are you interested?","value":{"$in":["Yes"]}}}}
            demandQuery[key] = {
              $elemMatch: {
                label: demandNote.label,
                value: { $in: value }
              }
            }
          }
        }
      }
    })
    Object.keys(demandQuery).forEach((key) => {
      // @ts-ignore
      mongoQuery.push({
        'buyerData.customDemands': demandQuery[key]
      })
    })
    return mongoQuery
  }

  function handleSubmit() {
    const mongoQuery = getQueryFromFilter()
    if (editing) {
      props.updateList(props.listId, {
        title,
        filters: mongoQuery
      })
    } else {
      props.createList({ title, filters: mongoQuery })
    }
  }

  function getDataForPreview() {
    const mongoQuery = getQueryFromFilter()
    props.getDataPreviewForFilter(mongoQuery).then((data) => {
      setPreviewCounts(data.counts)
      setPreviewContacts(data.users)
    }).catch((error) => {
      console.error('Error getting preview data', error)
    })
  }

  function updateInputField(filterIndex, inputKey, value) {
    const newFilters = [...filters]
    if (!newFilters[filterIndex].inputs) {
      newFilters[filterIndex].inputs = {}
    }
    newFilters[filterIndex].inputs[inputKey] = value
    setFilters(newFilters)
  }

  return (
    <div className='font-openSans flex flex-col gap-y-2 h-full px-4 md:py-3.5 lg:py-5 rounded min-w-3xl w-full'>
      <div className='w-full flex justify-between'>
        <div className='text-2xl font-bold text-gray-800'>
          Create List
        </div>
        <div className='flex flex-row gap-x-2'>
          <CustomButton
            className='whitespace-nowrap min-w-[150px] px-3.5 py-1.5 flex gap-x-1'
            handleClick={
              () => {
                handleSubmit()
              }
            }>
            <SaveAllIcon size='20' className='text-gray-400' />
            <span>
              Create List
            </span>
          </CustomButton>
          <button
            onClick={() => {
              props?.closeModal()
            }}
            className='bg-red-500 text-white px-4 py-2 text-sm rounded-lg hover:bg-red-600'
          >
            Cancel
          </button>
        </div>
      </div>
      <div className='sm:grid grid-cols-1 xl:grid-cols-2 gap-4 h-full'>
        <div className='order-0 sm:order-1 border border-border px-4 py-2 rounded-sm flex flex-col gap-y-4'>
          {props.previewing && (
            <div className='w-full bg-gray-300 p-2 flex items-center justify-center rounded'>
              <div className='flex gap-x-1.5 items-center'>
                <Loader size='20' className='animate-spin' />
                <span>Loading...</span>
              </div>
            </div>
          )}
          <div className='grid grid-cols-3'>
            <div className='border border-border px-3 py-4 shadow-sm rounded-md flex flex-col gap-y-2'>
              <div className='flex text-xl'>
                Filter Contacts
              </div>
              <div className='text-3xl font-bold'>
                {previewCounts}
              </div>
            </div>
          </div>
          <div className='border-t border-border py-2'>
            <div>
              <div className='text-xl font-light text-black'>
                Contacts Preview ({previewContacts.length})
              </div>
              <div className='text-xs font-extralight'>
                {'Preview will show only the first 20 contacts that match the filters.'}
              </div>
            </div>
            <TableGrid
              style={{ height: '470px', minHeight: '470px' }}
              rowSelectKey='id'
              columnDefs={[
                {
                  headerName: 'Email',
                  field: 'email',
                  sortable: true,
                  checkboxSelection: true,
                  flex: 1
                },
                {
                  headerName: 'First Name',
                  field: 'firstName',
                  sortable: true
                },
                {
                  headerName: 'Last Name',
                  field: 'lastName',
                  sortable: true
                },
                {
                  headerName: 'User Type',
                  field: 'userType',
                  sortable: true,
                  cellRenderer: ({ value }) => {
                    const userType = value
                    const availableTags = [
                      ['buyer', 'bg-blue-500 text-white'],
                      ['DeveloperAdmin', 'bg-red-500 text-white'],
                      ['CoopBroker', 'bg-green-500 text-white'],
                      ['LeadBroker', 'bg-green-500 text-white'],
                      ['SalesRep', 'bg-orange-500 text-white']
                    ]
                    return <div>
                      {/* eslint-disable-next-line array-callback-return */}
                      {availableTags.map(([tag, color]) => {
                        if (userType?.toLowerCase() === tag.toLowerCase()) {
                          return (
                            <div className={
                              `capitalize text-gray-800 border border-gray-300 px-2 py-1 rounded-full text-xs ${color}`
                            }>
                              {tag}
                            </div>
                          )
                        }
                      })}
                    </div>
                  }
                }
              ]}
              getData={async (
                filter,
                pagination,
                sorting
              ) => {
                // eslint-disable-next-line no-async-promise-executor
                return new Promise(async (resolve) => {
                  resolve(previewContacts)
                })
              }} >
              {(params) => {
                tableRef.current = params
              }}
            </TableGrid>
          </div>
        </div>
        <div className='sm:order-0  border border-border px-2 py-2 rounded-sm'>
          <div className='grid grid-cols-3'>
            <div className='flex flex-col'>
              <div className='flex text-lg'>
                Total Contacts
              </div>
              <div className='text-xl font-bold'>
                {isLoadingCounts ? 'Loading...' : usersCounts}
              </div>
            </div>
          </div>
          <div className='my-4'>
            <label className='text-sm font-bold text-gray-600'>List Title</label>
            <input type='text' value={title} onChange={(e) => setTitle(e.target.value)} className='border border-border px-3.5 py-1.5 w-full' />
          </div>
          <div className='flex flex-col gap-y-2 my-4'>
            <div className='text-sm font-bold text-gray-600'>
              List Segment Filters
            </div>
            {filters.map((filter, index) => {
              const filterKey = filter.key
              const filterInputs = filter.inputs || {}
              const selectedFilter = AvailableFilters.find((f) => f.key === filterKey)
              if (!selectedFilter) return null
              const { ops } = selectedFilter
              const availableOps = ops
              const selectedOp = availableOps.find((op) => op.key === filter.op)
              const availableInputs = selectedOp?.inputs || []
              return (
                <div
                  key={index}
                  className='flex flex-row justify-between border border-gray-300 px-3 py-2 bg-gray-100 rounded-lg items-center'>
                  <div className='flex gap-x-2'>
                    <div>
                      <select
                        value={filterKey}
                        onChange={(e) => {
                          const _value = e.target.value
                          const newFilters = [...filters]
                          newFilters[index].key = _value
                          const [defaultOp] = AvailableFilters.find((f) => f.key === _value)?.ops
                          newFilters[index].op = defaultOp?.key ?? ''
                          newFilters[index].inputs = {}
                          setFilters(newFilters)
                        }}
                        className='border border-border rounded-full px-3.5 py-1.5 min-w-fit pr-8'
                      >
                        {AvailableFilters.map((filter) => {
                          return (
                            <option key={`filter-${filter.key}`} value={filter.key}>
                              {filter.label}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                    <div>
                      <select
                        id='op'
                        value={filter.op}
                        onChange={(e) => {
                          const _value = e.target.value
                          const newFilters = [...filters]
                          newFilters[index].op = _value
                          setFilters(newFilters)
                        }}
                        className='border border-border rounded-full px-3.5 py-1.5 min-w-fit pr-8 w-full'
                      >
                        {availableOps.map((op) => {
                          return (
                            <option
                              key={`${op.key}-${op.label}`} value={op.key}>
                              {op.label}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                    <div>
                      {availableInputs.map((input, index) => {
                        const type = input.type
                        const inputKey = input.key
                        const inputVal = filterInputs[inputKey]
                        const options = input.options
                        const selectedOptions = []
                        if (typeof inputVal === 'object' && Array.isArray(inputVal)) {
                          inputVal.forEach((val) => {
                            const selectedOption = options.find((option) => option.value === val)
                            if (selectedOption) {
                              selectedOptions.push(selectedOption.label)
                            }
                          })
                        }
                        return (
                          <div
                            key={index}
                          >
                            {type === 'text' && (
                              <input
                                type='text'
                                value={inputVal}
                                onChange={(e) => {
                                  const _value = e.target.value
                                  updateInputField(
                                    index,
                                    inputKey,
                                    _value
                                  )
                                }}
                                className='border border-border rounded-full px-3.5 py-1.5 min-w-fit pr-6'
                              />
                            )}
                            {type === 'select' && (
                              <select
                                value={inputVal}
                                onChange={(e) => {
                                  const _value = e.target.value
                                  updateInputField(
                                    index,
                                    inputKey,
                                    _value
                                  )
                                }}
                                className='border border-border rounded-full px-3.5 py-1.5 min-w-fit pr-6'
                              >
                                <option value=''>Select Option</option>
                                {options.map((option) => {
                                  return (
                                    <option key={option.value} value={option.value}>
                                      {option.label}
                                    </option>
                                  )
                                })}
                              </select>
                            )}
                            {type === 'select-multiple' && (
                              <CustomSelect
                                multiple
                                disabled={false}
                                label={''}
                                options={options.map((option) => {
                                  return ({
                                    id: option.value,
                                    value: option.label
                                  })
                                })}
                                selectedOption={selectedOptions}
                                setSelectedOption={(value) => {
                                  updateInputField(
                                    index,
                                    inputKey,
                                    value
                                  )
                                }}
                                labelClasses={'py-0'} inputClasses={'h-[38px] bg-white border border-gray-900 rounded-full px-3.5 py-0 pr-6 min-w-[200px]'} defaultValue={null} />
                            )}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  <div className='h-full flex items-center justify-center'>
                    <button onClick={() => {
                      removeFilter(index)
                    }}
                      className='bg-red-500 text-white text-sm px-2 py-1 rounded-lg hover:bg-red-600'>
                      Remove
                    </button>
                  </div>
                </div>
              )
            }
            )}
            <div>
              <button onClick={() => {
                addFilter()
              }} className='bg-gray-200 border border-gray-400 text-black  hover:text-white px-3.5 py-1.5 hover:bg-gray-600'>
                + Add Filter
              </button>
            </div>
          </div>
          <div className='flex flex-row gap-x-2'>
            <div>
              <CustomButton
                className='whitespace-nowrap px-2 py-2'
                disabled={props.previewing}
                handleClick={
                  () => {
                    getDataForPreview()
                  }
                }>
                {props.previewing ? <Loader size='20' className='animate-spin' /> : null}
                {props.previewing ? 'Loading...' : 'Reload Preview'}
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}
