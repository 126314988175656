import React, { useEffect, useMemo, useState } from 'react'

import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'
import { Oval } from 'react-loader-spinner'

import { Modal } from '@material-ui/core'
import Skeleton from 'react-loading-skeleton'
import { formatMoney } from 'utils'
import demoImage from 'assets/img/unit-1201.png'
import { createOrUpdateUnitPolygon } from 'store/actions/unitPolygonActions'
import useInventory from '../hooks/useInventory'
import { createOrUpdateParkingPolygon } from 'store/actions/parkingPolygonActions'
import { createOrUpdateStoragePolygon } from 'store/actions/storagePolygonActions'

const AssignPolygonToInventoryItemModal = ({
  show,
  setShow,
  refetch,
  polygonsData,
  handleCancel,
  allItemsPolygonsData,
  type,
  inventoryItemsData
}) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [selectedItem, setSelectedItem] = useState(null)
  const { state: { statusColors }, selectedParkingLevel, selectedStorageLevel } = useInventory()

  const handleSubmit = () => {
    if (validate() && type === 'Unit') {
      setLoading(true)
      const temp = {
        unit: selectedItem,
        polygon: polygonsData
      }
      createOrUpdateUnitPolygon(temp)
        .then(() => {
          refetch()
          setLoading(false)
          setShow(false)
        })
        .catch((err) => {
          setLoading(false)
          console.log(err)
        })
    }
    if (validate() && type === 'Parking') {
      setLoading(true)
      const temp = {
        parking: selectedItem,
        polygon: polygonsData
      }
      createOrUpdateParkingPolygon(temp)
        .then(() => {
          refetch(selectedItem.floorNumber)
          setLoading(false)
          setShow(false)
        })
        .catch((err) => {
          setLoading(false)
          console.log(err)
        })
    }
    if (validate() && type === 'Storage') {
      setLoading(true)
      const temp = {
        storage: selectedItem,
        polygon: polygonsData
      }
      createOrUpdateStoragePolygon(temp)
        .then(() => {
          refetch(selectedItem.floorNumber)
          setLoading(false)
          setShow(false)
        })
        .catch((err) => {
          setLoading(false)
          console.log(err)
        })
    }
  }

  const handleClose = () => {
    handleCancel()
    setShow(false)
    setSelectedItem(null)
  }

  const validate = () => {
    if (!selectedItem?._id || !(polygonsData.length > 0)) {
      return false
    }
    return true
  }

  useEffect(() => {
    if (type === 'Unit') {
      setData(inventoryItemsData.map((unit) => {
        return {
          ...unit,
          isAssigned: allItemsPolygonsData.filter((elm) => elm.unit === unit._id).length > 0
        }
      }))
    }
    if (type === 'Parking') {
      setData(inventoryItemsData.filter((parking) => parking.parkingLevel === selectedParkingLevel.parkingLevel).map((parking) => {
        return {
          ...parking,
          isAssigned: allItemsPolygonsData?.filter((elm) => elm.parking === parking._id).length > 0
        }
      }))
    }
    if (type === 'Storage') {
      setData(inventoryItemsData.filter((storage) => storage.storageLevel === selectedStorageLevel.storageLevel).map((storage) => {
        return {
          ...storage,
          isAssigned: allItemsPolygonsData?.filter((elm) => elm.storage === storage._id).length > 0
        }
      }))
    }
  }, [selectedParkingLevel, selectedStorageLevel])

  const isAllItemsAssigned = useMemo(() => {
    return data.filter((item) => item.isAssigned).length === data.length
  }, [data])

  const Card = ({ id, unitNumber, price, status, onClick, isAssigned }) => (
    <div className='relative col-span-1'>
      <div
        onClick={() => {
          if (!isAssigned) {
            onClick()
          }
        }}
        className={`p-4 w-full flex items-center justify-center gap-4 rounded-md transition-all duration-200 border-2 ${selectedItem?._id === id
          ? 'border-volt bg-gray-2'
          : !isAssigned && 'bg-gray-1 hover:bg-gray-2 border-zinc-200'
          } ${!isAssigned ? 'cursor-pointer' : 'opacity-20 cursor-not-allowed'}`}
      >
        <img
          src={demoImage}
          className='w-1/2'
        />
        <div className='flex-1 h-full flex flex-col justify-between gap-2'>
          <div className='flex flex-col gap-1'>
            <h4 className='text-lg font-semibold mb-0'>{type} #{unitNumber ?? ''}</h4>
            <div
              className={
                'rounded uppercase text-xs font-medium text-softBlack h-6.75 p-1 flex justify-center items-center min-w-20 w-fit px-2'
              }
              style={{
                backgroundColor: statusColors[status.toLowerCase()]?.bgColor,
                color: statusColors[status.toLowerCase()]?.textColor
              }}
            >
              {status}
            </div>
          </div>
          <p className='text-base font-semibold mb-0'>Price: {formatMoney(price) ?? ''}</p>
        </div>
      </div>
      {
        isAssigned &&
        <div className='absolute top-0 w-full h-full flex justify-center items-center'>
          <p className='bg-volt/70 text-xl font-semibold p-1.5 px-3 rounded-md mb-0'>Assigned</p>
        </div>
      }
    </div>
  )

  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div
        className='font-openSans relative bg-grey-8 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-10 px-6 rounded shadow-lg max-h-[calc(100vh-150px)] flex flex-col overflow-hidden'
        style={{ width: 1015 }}
      >
        <button className='absolute right-10' onClick={handleClose}>
          <CrossIcon />
        </button>
        <h1 className='font-bold text-xl text-center p-0 m-0 mb-6'>
          Choose {type.toLowerCase()} to assign
        </h1>
        {
          loading
            ? <div className='grid grid-cols-3 gap-4 px-2'>
              <Skeleton height={150} />
              <Skeleton height={150} />
              <Skeleton height={150} />
              <Skeleton height={150} />
              <Skeleton height={150} />
              <Skeleton height={150} />
            </div>
            : <div className='flex-1 grid grid-cols-3 gap-4 overflow-y-auto px-2 thin-scrollbar'>
              {
                data.map((item) => (
                  <Card
                    id={item._id}
                    key={item._id}
                    unitNumber={type === 'Parking' ? item.stall : item.unitNumber}
                    price={item.price}
                    status={item.status}
                    onClick={() => setSelectedItem(item)}
                    isAssigned={item.isAssigned}
                  />
                ))
              }
            </div>
        }
        {
          (!loading && isAllItemsAssigned) &&
          <p className='text-red-500 text-center text-xl font-medium flex justify-center items-center mt-4 mb-0'>
            All {type.toLowerCase()} slots are currently taken and no slots are available for assignment
          </p>
        }
        <div className='mt-6 flex justify-center gap-6'>
          <button
            className='w-fit py-3 px-6 text-base border border-softBlack_70 rounded font-medium flex gap-2 items-center italic'
            // disabled={!validate() || loading}
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className='w-fit py-3 px-6 text-base bg-volt border border-volt rounded font-medium flex gap-2 items-center'
            disabled={!validate() || loading}
            onClick={handleSubmit}
          >
            {loading && <Oval height='18' width='18' color='black' />}
            <div className='italic'>Assign {type}</div>
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default AssignPolygonToInventoryItemModal
