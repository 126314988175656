import React from 'react'
import { Select, Tag } from 'antd'
import { ReactComponent as DownIcon } from 'assets/icons/down.svg'

const CustomSelect = ({
  label,
  value,
  handleChange,
  options,
  showSearch = false,
  isLeadStatus = false,
  isName = false,
  isDemandNotes = false,
  mode = '',
  optionFilterProp = 'label'
}) => {
  const tagRender = (props) => {
    if (!(mode === 'tags')) return null
    const { label, value, closable, onClose } = props
    const onPreventMouseDown = (event) => {
      event.preventDefault()
      event.stopPropagation()
    }
    return (
      <Tag
        color={value}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        className='text-black'
      >
        {label}
      </Tag>
    )
  }

  return (
    <div
      className='w-full flex flex-col gap-y-1.5'
    >
      <div
        className='text-softBlack_70/50 w-full text-xs top-6 font-normal uppercase'
      >
        {label}
      </div>
      {
        !mode?.length &&
        <Select
          showArrow
          value={value?.length ? value : null}
          onChange={handleChange}
          className='ant-picker-input1'
          getPopupContainer={(node) => node.parentNode}
          suffixIcon={<DownIcon />}
          showSearch={showSearch}
          placeholder={'Select ' + `${label.length > 30 ? label.slice(0, 30).toLowerCase() + '...' : label.toLowerCase()}`}
          optionFilterProp={optionFilterProp}
        >
          {options?.map((_item, index) => (
            <Select.Option value={isName ? _item._id : _item.value} key={index} label={(isName && !isDemandNotes) ? _item?.firstName + ' ' + _item?.lastName : _item.label}>
              {
                isLeadStatus
                  ? <div>
                    {
                      value === _item.value
                        ? <div className='w-fit flex items-center justify-center gap-x-1 p-1 px-3 rounded-full bg-green-100'>
                          <div className='h-3 w-3 bg-green-500 rounded-full' />
                          <h6 className='text-xs font-medium m-0 text-green-800'>{value}</h6>
                        </div>
                        : <>{_item.label}</>
                    }
                  </div>
                  : <div className='flex gap-2 items-center'>
                    {(isName && !isDemandNotes) ? _item?.firstName + ' ' + _item?.lastName : _item.label}
                  </div>
              }
            </Select.Option>
          ))}
        </Select>
      }
      {
        mode === 'tags' &&
        <Select
          showArrow
          value={value}
          onChange={handleChange}
          className='ant-picker-input1'
          getPopupContainer={(node) => node.parentNode}
          suffixIcon={<DownIcon />}
          showSearch={showSearch}
          mode={mode}
          placeholder={'Select ' + `${label.length > 30 ? label.slice(0, 30).toLowerCase() + '...' : label.toLowerCase()}`}
          tagRender={tagRender}
          optionFilterProp={optionFilterProp}
        >
          {options?.map((_item, index) => (
            <Select.Option value={_item._id} key={index} label={_item.label}>
              <div className='inline-block text-black'>
                {_item.label}
              </div>
            </Select.Option>
          ))}
        </Select>
      }
    </div>
  )
}

export default CustomSelect
