export const buyerNavItems = [
  {
    title: 'Dashboard',
    icon: '/dashboard-icon.svg',
    path: '/buyer/dashboard'
  },
  {
    title: 'Offers',
    icon: '/offers-icon.svg',
    path: '/buyer/dashboard'
  },
  {
    title: 'Notifications',
    icon: '/notification-icon.svg',
    path: '/buyer/dashboard'
  },
  {
    title: 'Calender',
    icon: '/calender-icon.svg',
    path: '/buyer/dashboard'
  },
  {
    title: 'Documents',
    icon: '/documents-icon.svg',
    path: '/buyer/dashboard'
  },
  {
    title: 'Deposits',
    icon: '/deposits-icon.svg',
    path: '/buyer/dashboard'
  },
  {
    title: 'Profile',
    icon: '/profile-icon.svg',
    path: '/buyer/dashboard'
  },
  {
    title: 'Survey Questionnaire',
    icon: '/profile-icon.svg',
    path: '/buyer/survey_questionnaire'
  }
]
