// @ts-nocheck
/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from '@material-ui/core';
import moment from 'moment';
import { Oval } from 'react-loader-spinner';
import { Button, message } from 'antd';
import { ReactComponent as MailIcon } from 'assets/icons/bulkContact.svg';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import clsx from 'clsx';
import { createOrUpdateCall } from 'store/actions/callsActions';
import { isMobile } from 'react-device-detect';
import CustomButton from './CustomButton';
import { getUserDataByType } from 'utils/getUserDataByType';
import { GetDemandNotes } from 'store/actions/demandNotes';
import { ContactContext } from './context/Contacts';
import toast from 'react-hot-toast';
import CustomSelect from 'components/VerticalCardProfile/components/CustomSelect';
import NoteCard from 'components/NotesCard';
import useUpdateUsers from 'hooks/useUpdateUsers';
import { NotesTab } from './NotesTab';
import AddForm from 'pages/desktopMode/demandNotes/AddForm';
import { on } from 'events';
import TaskModal from 'pages/tasks/components/TaskModal';
import { getTasks } from 'store/actions/taskActions';
import { set } from 'lodash';
import { defaultRatingOptions } from 'utils';
import EmailModal from './EmailModal';
import NotesLists from 'components/NoteListBar';
import useGetUserNotes from 'hooks/useGetUserNotes';
import { convert } from 'html-to-text';

// import { AddParticipants } from './AddParticipants'
// import CustomTimeInput from 'components/CustomTimeInput'

const initialEmptyValues = {
  description: '',
  date: '',
  startedAt: '',
  finishedAt: '',
  notes: '',
  participants: [],
  callStatus: '',
};

export const callStatus = [
  {
    _id: 'Spoke to someone',
    label: 'Spoke to someone',
    value: 'Spoke to someone',
  },
  {
    _id: 'Left a voicemail',
    label: 'Left a voicemail',
    value: 'Left a voicemail',
  },
  { _id: 'Other', label: 'Other', value: 'Other' },
];

const CallIcon = () => {
  return (
    <svg
      width='18'
      height='19'
      viewBox='0 0 18 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_16_93)'>
        <path
          d='M5.02983 8.63995C6.06796 10.6802 7.74049 12.3455 9.78069 13.3908L11.3667 11.8048C11.5614 11.6101 11.8497 11.5453 12.1021 11.6318C12.9095 11.8985 13.7818 12.0427 14.6757 12.0427C15.0722 12.0427 15.3967 12.3671 15.3967 12.7636V15.2796C15.3967 15.6761 15.0722 16.0005 14.6757 16.0005C7.9063 16.0005 2.4201 10.5143 2.4201 3.74491C2.4201 3.3484 2.74452 3.02399 3.14102 3.02399H5.66424C6.06075 3.02399 6.38516 3.3484 6.38516 3.74491C6.38516 4.64606 6.52935 5.51116 6.79609 6.31859C6.87539 6.57091 6.81771 6.85207 6.61586 7.05393L5.02983 8.63995Z'
          fill='#2E2B2E'
        />
      </g>
      <defs>
        <clipPath id='clip0_16_93'>
          <rect
            width='17.3021'
            height='17.3021'
            fill='white'
            transform='translate(0.257385 0.861206)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const CallModal = ({ call, show, setClose, contact, onSuccess }) => {
  const appProject = useSelector((state) => state.appReducer.appProject);
  const userObject = useSelector((state) => state.authReducer.userObject);
  const { userType } = contact ?? {};
  const userDataObject = getUserDataByType(userType, contact);
  const [loading, setLoading] = useState(false);
  const { createOrUpdateUser } = useUpdateUsers();
  const [isEdit, setIsEdit] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [isAircallDialogOpen, setIsAircallDialogOpen] = useState(false);
  const [showEmailBox, setShowEmailBox] = useState(false);
  const { notes } = useGetUserNotes(contact?._id);
  const [openTaskModal, setOpenTaskModal] = useState(false);
  const [notesList, setNotesList] = useState([]);
  const handleCloseTaskModal = () => {
    setOpenTaskModal(false);
  };

  const [refetch, setRefetch] = React.useState(false);
  useEffect(() => {
    if (isEdit) {
      setOpen(true);
    }
  }, [isEdit]);

  useEffect(() => {
    if (isEdit === false) {
      setRefetch(!refetch);
    }
  }, [isEdit]);

  const handleRefetch = () => {
    setOpenTaskModal(false);
  };
  useEffect(() => {
    const notesData = [];
    if (notes) {
      notes.forEach(function (note, index) {
        notesData.push({ ...note, edit: false });
      });
      setNotesList(notesData);
    }
  }, [notes]);

  const [cardColor, setCardColor] = useState({
    color: '',
    bgColor: '',
  });

  useEffect(() => {
    setCardColor(
      defaultRatingOptions.filter(
        (elm) => elm.value === contact?.buyerData?.rating
      )[0]
    );
  }, [defaultRatingOptions, contact?.buyerData?.rating]);

  const [customDemands, setCustomDemands] = useState(
    userDataObject?.customDemands ?? []
  );

  const [selectedBudgetOption, setSelectedBudgetOption] = useState(
    userDataObject.budget
  );
  const [selectedTimeLineToPurchase, setSelectedTimeLineToPurchase] = useState(
    userDataObject.timelineToPurchase
  );
  const [selectedProjectInterests, setSelectedProjectInterests] = useState(
    userDataObject.projectInterests
  );
  const [selectedFloorInterests, setSelectedFloorInterests] = useState(
    userDataObject.floorInterests
  );
  const [selectedNeedsFinancing, setSelectedNeedsFinancing] = useState(
    userDataObject.needsFinancing
  );

  const [extraDemands, setExtraDemands] = useState(null);
  const { dropdownData, updateContact } = useContext(ContactContext);

  const getCustomDemandFields = async () => {
    const data  = await GetDemandNotes();
    if (data) {
      const formatedData = await data.map((item) => {
        return {
          label: item.label,
          options: item.options.map((option) => {
            return {
              label: option.label,
              value: option.value,
              _id: option.value,
            };
          }),
        };
      });
      setExtraDemands(formatedData);
    }
  };

  const handleSelectionChange = async (value, name, setValue, key) => {
    if (!value) return;
    setValue(value);
    const prevValue = { ...contact };
    const newUserData = { ...contact };
    if (newUserData?.userType === 'Buyer') {
      newUserData.buyerData[key] = value;
    } else if (newUserData?.userType === 'LeadBroker') {
      newUserData.leadBrokerData[key] = value;
    }
    try {
      updateContact(newUserData);
      await createOrUpdateUser(newUserData, false, false);
      toast.success(`${name} updated successfully`);
    } catch (err) {
      setValue(prevValue);
      console.error(`${name} couldn't be updated`, err);
    }
  };

  const ButtonV2 = ({ title = '', Icon, onClick }) => {
    return (
      <button
        className={clsx(
          'rounded border border-zinc-800 border-opacity-50 justify-start items-center gap-2 flex',
          title?.length > 0 ? 'px-4 py-2' : 'p-2'
        )}
        onClick={onClick}
      >
        {Icon}
        {title?.length > 0 && (
          <div className='text-center text-zinc-800 text-base font-normal leading-snug'>
            {title}
          </div>
        )}
      </button>
    );
  };

  useEffect(() => {
    getCustomDemandFields();
  }, [refetch]);
  const initialValues = {
    description: call?.event ?? initialEmptyValues.description,
    callStatus: call?.callStatus ?? initialEmptyValues.callStatus,
    date: call?.date
      ? moment(call?.date).format('YYYY-MM-DD')
      : initialEmptyValues.date,
    startedAt: call?.startedAt
      ? moment(new Date(call?.startedAt)).format('HH:mm')
      : initialEmptyValues.startedAt,
    finishedAt: call?.finishedAt
      ? moment(new Date(call?.finishedAt)).format('HH:mm')
      : initialEmptyValues.finishedAt,
    notes: call?.notes ?? initialEmptyValues.notes,
    participants:
      call?.participants?.length > 0
        ? call?.participants.filter((_p) => _p !== contact._id)
        : initialEmptyValues.participants,
  };

  const [callDetails, setCallDetails] = useState({
    ...initialEmptyValues,
  });

  useEffect(() => {
    if (call?.id?.length > 0) {
      setCallDetails({
        ...callDetails,
        ...initialValues,
      });
    }
  }, [call]);

  const onChange = (fieldName, value) => {
    setCallDetails({ ...callDetails, [fieldName]: value });
  };

  const onValidate = () => {
    const requiredFields = ['description', 'date'];
    for (let i = 0; i < requiredFields?.length; i++) {
      if (!callDetails[requiredFields[i]]?.length) {
        return false;
      }
    }
    return true;
  };

  const onSave = async () => {
    const params = {
      _id: call?.id,
      project: appProject,
      host: userObject._id,
      event: call?.event,
      ...callDetails,
      participants: [...callDetails.participants, contact._id],
    };
    console.log(params);
    // check finishedAt greater than startedAt
    // if (params.finishedAt <= params.startedAt) {
    //   message.error('finished time must be greater than start time')
    //   return false
    // }

    // covert finishedAt and startedAt into date object
    if (params.startedAt) {
      params.startedAt = new Date(
        `${moment().format('YYYY-MM-DD')} ${params.startedAt}`
      );
    }
    if (params.finishedAt) {
      params.finishedAt = new Date(
        `${moment().format('YYYY-MM-DD')} ${params.finishedAt}`
      );
    }

    setLoading(true);
    try {
      await createOrUpdateCall(params);
      if (!call?.id) {
        setCallDetails({ ...initialEmptyValues });
      }
      setClose();
      onSuccess();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      message.error(err);
      console.error(err);
    }
  };

  const handleClose = () => {
    setCallDetails({ ...initialEmptyValues });
    setClose();
  };

  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div
        className='font-openSans relative overflow-auto h-[90%] bg-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-5 md:p-6 rounded shadow-lg'
        style={{ width: isMobile ? '95%' : '75%' }}
      >
        <button className='absolute right-5 md:right-8' onClick={handleClose}>
          <CrossIcon />
        </button>
        <h1 className='font-bold text-xl text-center p-0 m-0'>
          Call Summary | {contact?.firstName} {contact?.lastName}
        </h1>
        <div className='flex flex-col md:flex-row mt-4 gap-2'>
          <div className='flex basis-1/4 h-fit border-[1px] rounded-[8px] bg-white py-3 flex-col gap-y-4'>
            <div className='px-8 pt-6 flex flex-col gap-y-5'>
              <div className='flex flex-col gap-y-3'>
                <div className='flex flex-col items-center justify-center gap-3'>
                  <div
                    className='w-14 h-14 relative border-4 rounded-full flex justify-center item-center'
                    style={{
                      borderColor: cardColor?.color ?? 'rgb(45, 42, 45)',
                    }}
                  >
                    <div className='h-12 w-12 border border-transparent rounded-full flex items-center justify-center bg-gray-200 text-text-softBlack_70/50 font-bold'>
                      {contact?.firstName[0]}
                      {contact?.lastName[0]}
                    </div>
                  </div>
                  <div className='self-stretch py-px flex flex-col justify-center items-center gap-1'>
                    <h6 className='text-zinc-800 text-xl font-semibold leading-7 m-0 text-center'>
                      {contact?.firstName + ' ' + contact?.lastName}
                    </h6>
                    <p className='text-zinc-800 text-opacity-70 text-base font-normal leading-snug m-0'>
                      {userType}
                    </p>
                  </div>
                </div>
                <div className='gap-3 flex flex-col'>
                  <div className='flex gap-x-4 items-center'>
                    <ButtonV2
                      Icon={<MailIcon className='h-5 w-5' />}
                      onClick={() => setShowEmailBox(true)}
                    />
                    <div className='text-[16px] font-semibold'>
                      {contact?.email}
                    </div>
                  </div>
                  <div className='flex gap-x-4 items-center'>
                    <ButtonV2
                      Icon={<CallIcon />}
                      onClick={() => setIsAircallDialogOpen(true)}
                    />
                    <div className='text-[16px] font-semibold'>
                      {contact?.buyerData?.phoneNumber ||
                        contact?.leadBrokerData?.phoneNumber}
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex flex-col gap-y-6'>
                <div className='flex flex-col'>
                  <div className='text-[18px] font-bold'>Call Goal</div>
                </div>
                <div className='flex flex-col gap-y-1'>
                  <div className='text-[18px] font-bold'>Call Status</div>
                  <CustomSelect
                    label=''
                    value={
                      callDetails?.callStatus || initialEmptyValues.callStatus
                    }
                    handleChange={(value) => {
                      onChange('callStatus', value);
                      createOrUpdateCall({
                        _id: call?.id,
                        project: appProject,
                        host: userObject._id,
                        event: call?.event,
                        ...callDetails,
                        participants: [
                          ...callDetails.participants,
                          contact._id,
                        ],
                        callStatus: value,
                      }).then(() => {
                        onSuccess();
                      });
                    }}
                    options={callStatus}
                    isName
                    isDemandNotes
                  />
                </div>
                <div className='flex flex-col gap-y-[6px]'>
                  <CustomButton
                    handleClick={() => {
                      setOpenTaskModal(true);
                    }}
                  >
                    <span className='font-semibold px-4 py-2 text-base'>
                      Create Task
                    </span>
                  </CustomButton>
                </div>
              </div>
            </div>
            <div className='w-full my-6 h-[1px] bg-black'></div>
            <div className='px-8 pt-2 flex flex-col gap-y-3'>
              <div className='flex flex-col gap-y-3'>
                <div className='text-[20px] font-bold'>Air call Events</div>
                <div className='text-[14px] ml-2 text-grey-3 bg-[green] text-white w-fit rounded-[8px] px-2 py-1'>
                   {convert(call.event)}
                </div>
              </div>
            </div>
            <div className='w-full my-6 h-[1px] bg-black'></div>
            <div className='px-8 pt-2 flex flex-col gap-y-3'>
              <div className='flex flex-col gap-y-4'>
                <div className='text-[20px] font-bold'>Transcript</div>
                <div className='text-[20px] font-bold'>Sentiment</div>
              </div>
            </div>
          </div>
          <div className='basis-2/4 border-[1px] rounded-[8px] bg-white py-4 px-4  flex-col gap-y-4'>
            <div className='flex flex-col gap-y-3'>
              <div className='text-[20px] font-bold text-center'>
                Call Notes
              </div>
              <div className='flex-1  py-4 border text-base border-softBlack-10 rounded'>
                <div className='font-openSans text-softBlack'>
                  <textarea
                    name='note'
                    className='bg-transparent border-none focus:ring-0 focus:ring-offset-0 appearance-none w-full resize-none overflow-auto'
                    cols='70'
                    rows={8}
                    value={callDetails?.notes}
                    placeholder={'Type your note here ...'}
                    onChange={(e) => {
                      onChange('notes', e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className='flex justify-end'>
                <Button
                  onClick={() => {
                    onSave();
                  }}
                  style={{
                    backgroundColor: '#EDDF65',
                    width: '20%',
                  }}
                  variant='contained'
                >
                  {'Save'}
                </Button>
              </div>
              <div className='w-full mt-1 h-[1px] bg-[#8282824d]'></div>
              <div className='flex flex-col gap-y-4 mt-2'>
                <div className='font-bold w-full text-center text-[18px]'>
                  Notes
                </div>
                <NotesLists
                  contact={contact}
                  notesList={notesList}
                  isEditable={false}
                  user={userObject}
                />
              </div>
            </div>
          </div>
          <div className='flex basis-1/4 border-[1px]  bg-white p-4 rounded-[6px] flex-col gap-y-4'>
            <h3 className='text-[24px] font-bold text-center'>Demand Notes</h3>
            <CustomSelect
              label='Budget'
              value={selectedBudgetOption}
              handleChange={(value) =>
                handleSelectionChange(
                  value,
                  'Budget',
                  setSelectedBudgetOption,
                  'budget'
                )
              }
              options={dropdownData.budgetOptions}
              isDemandNotes
              isName
            />
            <CustomSelect
              label='Timeline to Purchase'
              value={selectedTimeLineToPurchase}
              handleChange={(value) =>
                handleSelectionChange(
                  value,
                  'Timeline to Purchase',
                  setSelectedTimeLineToPurchase,
                  'timelineToPurchase'
                )
              }
              options={dropdownData.timelineToPurchase}
              isDemandNotes
              isName
            />
            <CustomSelect
              label='Project Interests'
              value={selectedProjectInterests}
              handleChange={(value) =>
                handleSelectionChange(
                  value,
                  'Project Interests',
                  setSelectedProjectInterests,
                  'projectInterests'
                )
              }
              options={dropdownData.projectInterests}
              mode='tags'
              isDemandNotes
              isName
            />
            <CustomSelect
              label='Floor Interests'
              value={selectedFloorInterests}
              handleChange={(value) =>
                handleSelectionChange(
                  value,
                  'Floor Interests',
                  setSelectedFloorInterests,
                  'floorInterests'
                )
              }
              options={dropdownData.floorInterests}
              mode='tags'
              isDemandNotes
              isName
            />
            <CustomSelect
              label='Needs Financing'
              value={selectedNeedsFinancing}
              handleChange={(value) =>
                handleSelectionChange(
                  value,
                  'Needs Financing',
                  setSelectedNeedsFinancing,
                  'needsFinancing'
                )
              }
              options={dropdownData.needFinancing}
              isDemandNotes
              isName
            />
            {extraDemands &&
              extraDemands.map((demand, index) => {
                return (
                  <CustomSelect
                    key={index}
                    label={demand.label}
                    value={
                      customDemands.find(
                        (customDemand) => customDemand.label === demand.label
                      )?.value ?? ''
                    }
                    handleChange={(value) => {
                      const newCustomDemands = [...customDemands];
                      const demandIndex = newCustomDemands.findIndex(
                        (customDemand) => customDemand.label === demand.label
                      );
                      if (demandIndex === -1) {
                        newCustomDemands.push({
                          label: demand.label,
                          value: value,
                        });
                      } else {
                        newCustomDemands[demandIndex] = {
                          label: demand.label,
                          value: value,
                        };
                      }
                      setCustomDemands(newCustomDemands);
                      const newUserData = { ...contact, id: contact._id };
                      if (newUserData?.userType === 'Buyer') {
                        newUserData.buyerData.customDemands = newCustomDemands;
                      } else if (newUserData?.userType === 'LeadBroker') {
                        newUserData.leadBrokerData.customDemands =
                          newCustomDemands;
                      }
                      try {
                        updateContact(newUserData);
                        createOrUpdateUser(newUserData, false, false);
                        toast.success(`${demand.label} updated successfully`);
                      } catch (err) {
                        setCustomDemands(customDemands);
                        console.error(
                          `${demand.label} couldn't be updated`,
                          err
                        );
                      }
                    }}
                    options={demand.options}
                    isDemandNotes
                    isName
                  />
                );
              })}
            <div className='mt-2'>
              <Button
                onClick={() => {
                  setOpen(!open);
                }}
                style={{
                  backgroundColor: '#EDDF65',
                  width: '100%',
                  boxShadow: '0px 0px 0px rgba(0, 0, 0, 0)',
                }}
                variant='contained'
              >
                {'Create Demand Note'}
              </Button>
            </div>
            {open && (
              <AddForm
                open={open}
                setOpen={setOpen}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                setRefetch={setRefetch}
              />
            )}
            {openTaskModal && (
              <TaskModal
                show={openTaskModal}
                setClose={handleCloseTaskModal}
                contact={contact}
                refetch={handleRefetch}
              />
            )}
            <Modal
              open={isAircallDialogOpen}
              onClose={() => setIsAircallDialogOpen(false)}
            >
              <div className='w-full h-screen flex justify-center items-center font-openSans absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded shadow-lg'>
                <div className='w-fit flex relative bg-grey-8 overflow-x-hidden overflow-y-auto flex-col pt-10 rounded'>
                  <button
                    className='absolute right-4 top-4'
                    onClick={() => setIsAircallDialogOpen(false)}
                  >
                    <CrossIcon />
                  </button>
                  <div className='flex flex-col gap-6'>
                    <div>
                      <iframe
                        allow='microphone; autoplay; clipboard-read; clipboard-write; hid'
                        src='https://phone.aircall.io?integration=generic'
                        className='w-[376px] h-[666px]'
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
            {showEmailBox && (
              <EmailModal
                show={showEmailBox}
                handleClose={() => setShowEmailBox(!showEmailBox)}
                contact={contact}
                onEmailSent={() => {
                  if (setSelectedTabHeader) {
                    setSelectedTabHeader('Email');
                  }
                  toast.success('Email Sent successfully');
                }}
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
