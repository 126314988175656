// @ts-nocheck
/* eslint-disable */
import React, { useState } from 'react'
import { Modal } from '@material-ui/core'
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'
import UseCSV from '@usecsv/react'
import { api } from 'services/api'

const dynamicColumnsForStorage = [
  {
    name: 'storageNumber',
    displayName: 'Storage Number',
    example: '1',
    mustBeMatched: true,
    valueCannotBeBlank: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'status',
    displayName: 'status',
    example: 'Available',
    // mustBeMatched: true,
    // valueCannotBeBlank: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'unit',
    displayName: 'unit',
    example: '1000',
    // mustBeMatched: true,
    // valueCannotBeBlank: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'storageType',
    displayName: 'Storage Type',
    example: 'Regular',
    // mustBeMatched: true,
    // valueCannotBeBlank: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'allocationType',
    displayName: 'Allocation Type',
    example: 'UNITS',
    // mustBeMatched: true,
    // valueCannotBeBlank: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'storageLevel',
    displayName: 'Storage Level',
    example: 'P1',
    // mustBeMatched: true,
    // valueCannotBeBlank: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'floorNumber',
    displayName: 'Floor Number',
    example: '10',
    // mustBeMatched: true,
    // valueCannotBeBlank: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'unitNumber',
    displayName: 'unitNumber',
    example: '101',
    // mustBeMatched: true,
    // valueCannotBeBlank: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'description',
    displayName: 'description',
    example: 'test description',
    // mustBeMatched: true,
    // valueCannotBeBlank: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'price',
    displayName: 'price',
    example: '1000',
    mustBeMatched: true,
    valueCannotBeBlank: true,
    validationFormat: { type: 'string' }
  }
]


const AddBulkStorages = ({ show, onSuccess, setShow, building }) => {

  const buttonStyle = {
    backgroundColor: '#EDDF65',
    height: '45px',
    paddingLeft: '24px',
    paddingRight: '24px'
  }


  const [isCSVModalDisplay, setIsCSVModalDisplay] = useState(false)
  const handleDownload = async () => {
    try {
      const response = await api.get(
        `/storage/${building}/xlsx-file`,
        {},
        { responseType: 'blob' }
      )
      if (response.ok) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'ExampleFile.xlsx')
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
      } else {
        console.error('Error fetching file:', response.status)
      }
    } catch (error) {
      console.error('Error fetching file:', error)
    }
  }

  const renderButton = (openModal) => {
    return (
      <button
        className={`bg-volt p-2 px-4 text-lg rounded font-medium ${isCSVModalDisplay ? 'hidden' : 'block'}`}
        onClick={() => {
          openModal()
          setIsCSVModalDisplay(true)
        }}
      >
        Start importing bulk storages
      </button>
    )
  }

  const handleClose = () => {
    setShow(false)
    setIsCSVModalDisplay(false)
    onSuccess()
  }

  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby='Add Or Edit Bulk Storages Modal'
      aria-describedby='Add Or Edit Bulk Storages description'
    >
      <div
        className='relative flex flex-col font-openSans bg-grey-8 p-10 rounded shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
        style={{ width: 'calc(100% - 50px)', height: 'calc(100% - 50px)' }}
      >
        <button className='absolute right-10' onClick={handleClose}>
          <CrossIcon />
        </button>
        <h1 className='font-bold text-xl text-center'>Bulk Upload Storages</h1>
        <button className='w-fit' style={buttonStyle} onClick={handleDownload}>
          Download Example
        </button>
        <div className='relative w-full h-full flex justify-center items-center'>
          <UseCSV
            importerKey={process.env.REACT_APP_BULK_STORAGE_IMPORTER_KEY}
            dynamicColumns={dynamicColumnsForStorage}
            render={(openModal) => renderButton(openModal)}
            importerDisplay='inline'
            onClose={handleClose}
            metadata={{
              buildingId: building
            }}
          />
          {
            isCSVModalDisplay &&
            <div
              style={{
                position: 'relative',
                width: '100%',
                height: '100%',
                marginTop: '20px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              id='usecsv-importer-inline-wrapper'
            />
          }
        </div>
      </div>
    </Modal>
  )
}

export default AddBulkStorages
