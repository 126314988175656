// @ts-nocheck
/* eslint-disable */
import React, { useState, useContext, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
// import { HorizontalCardContact } from 'components/HorizontalCardProfile'

// import TableHeader from 'components/TableHeader'

import { ContactContext } from './context/Contacts';

// import { InfoTab } from './InfoTab'
import MeetingTab from './MeetingTab';
import { TaskTab } from './TaskTab';
import { NotesTab } from './NotesTab';
import EmailTab from './EmailTab';
import MessagesTab from './MessagesTab';
import ActivityTab from './ActivityTab';

import EditContactModal from './EditContactModal';
import BrokerClientsTab from './BrokerClientsTab/BrokerClientsTab';
import { useHistory } from 'react-router-dom';
import { HistoryContext } from 'global/HistoryContext';

import { CallTab } from './CallTab';
import { FadedAnimatedDiv } from 'components';
import Searching from './Searching';
import { VerticalCardContact } from 'components/VerticalCardProfile';
import ContactTabHeader from 'components/ContactTabHeader';

import { ReactComponent as ActivityIcon } from '../../assets/icons/icn_ballot.svg';
import { ReactComponent as ProfileIcon } from '../../assets/icons/icn_id.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/icn_calendar_today.svg';
import { ReactComponent as CallsIcon } from '../../assets/icons/icn_phone.svg';
import { ReactComponent as EmailIcon } from '../../assets/icons/icn_email.svg';
import { ReactComponent as SMSIcon } from '../../assets/icons/icn_text_snippet.svg';
import { ReactComponent as TasksIcon } from '../../assets/icons/icn_check_circle.svg';
import { ReactComponent as NotesIcon } from '../../assets/icons/icn_write.svg';
import './styles/customScrollbar.css';
import { ProfileTab } from './ProfileTab';
import { DemandTab } from './DemandTab';
import { isMobile } from 'react-device-detect';
import CustomButton from './CustomButton';
import { Drawer, makeStyles } from '@material-ui/core';

const ContactItem = ({ showBackButton = true }) => {
  const {
    updateContact,
    getUsersData,
    selectedContactObject: contact,
    isLoading,
  } = useContext(ContactContext);
  const params = new URLSearchParams(window.location.search);
  let header = params.get('tab')
  const [selectedHeader, setSelectedHeader] = useState(
    isMobile ? 'Calls' : window.openTasksTab ? 'Tasks' : header ? header.toUpperCase() : 'Activity'
  );
  console.log(selectedHeader)

  const [selectedEmailThread, setSelectedEmailThread] = useState('');
  const userObject = useSelector((state) => state.authReducer.userObject);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const { lastAction } = useContext(HistoryContext);
  const history = useHistory();
  const [showMoreInfo, setShowMoreInfo] = useState(false);

  const onSuccess = () => {
    getUsersData();
  };

  const getTableHeaders = useCallback(() => {
    const headers = isMobile
      ? [
          {
            key: 'calls',
            title: 'Calls',
            Icon: CallsIcon,
          },
          {
            key: 'email',
            title: 'Email',
            Icon: EmailIcon,
          },
          {
            key: 'sms',
            title: 'SMS',
            Icon: SMSIcon,
          },
          {
            key: 'activity',
            title: 'Activity',
            Icon: ActivityIcon,
          },
          {
            key: 'broker-clients',
            title: 'Broker Clients',
            Icon: ActivityIcon,
          },
          {
            key: 'profile',
            title: 'Profile',
            Icon: ProfileIcon,
          },
          {
            key: 'calendar',
            title: 'Calendar',
            Icon: CalendarIcon,
          },
          {
            key: 'tasks',
            title: 'Tasks',
            Icon: TasksIcon,
          },
          {
            key: 'notes',
            title: 'Notes',
            Icon: NotesIcon,
          },
          {
            key: 'demand_notes',
            title: 'Demand Notes',
            Icon: ActivityIcon,
          },
        ]
      : [
          {
            key: 'activity',
            title: 'Activity',
            Icon: ActivityIcon,
          },
          {
            key: 'demand_notes',
            title: 'Demand Notes',
            Icon: ActivityIcon,
          },
          {
            key: 'broker-clients',
            title: 'Broker Clients',
            Icon: ActivityIcon,
          },
          {
            key: 'profile',
            title: 'Profile',
            Icon: ProfileIcon,
          },
          {
            key: 'calendar',
            title: 'Calendar',
            Icon: CalendarIcon,
          },
          {
            key: 'calls',
            title: 'Calls',
            Icon: CallsIcon,
          },
          {
            key: 'email',
            title: 'Email',
            Icon: EmailIcon,
          },
          {
            key: 'sms',
            title: 'SMS',
            Icon: SMSIcon,
          },
          {
            key: 'tasks',
            title: 'Tasks',
            Icon: TasksIcon,
          },
          {
            key: 'notes',
            title: 'Notes',
            Icon: NotesIcon,
          },
        ];
    if (contact?.userType !== 'LeadBroker') {
      return headers.filter((header) => header.title !== 'Broker Clients');
    }
    return headers;
  }, [contact]);

  // const matchHeader = (header) => selectedHeader === header && !isLoading

  const LoadingContacts = useCallback(
    () => (
      <FadedAnimatedDiv className='h-full w-full flex flex-col items-center justify-center'>
        <Searching title={'Loading contact info...'} />
      </FadedAnimatedDiv>
    ),
    []
  );

  const leadStatusBadgeColorFinder = useCallback((title) => {
    if (title.includes('New')) {
      return {
        text: '#03543F',
        bg: '#DEF7EC',
      };
    }
    if (title.includes('Active')) {
      return {
        text: '#5521B5',
        bg: '#EDEBFE',
      };
    }
    if (title.includes('Opportunity')) {
      return {
        text: '#1E429F',
        bg: '#E1EFFE',
      };
    }
    if (title.includes('Reservation')) {
      return {
        text: '#42389D',
        bg: '#E5EDFF',
      };
    }
    if (title.includes('Owner')) {
      return {
        text: '#723B13',
        bg: '#FDF6B2',
      };
    }
    if (title.includes('Lost')) {
      return {
        text: '#9B1C1C',
        bg: '#FDE8E8',
      };
    }
    return {
      text: '#111928',
      bg: '#F3F4F6',
    };
  }, []);

  const LeadStatusBadge = ({ title, haveDotIcon = false }) => {
    const badgeColor = leadStatusBadgeColorFinder(title);
    const badgeStyle = {
      backgroundColor: badgeColor?.bg,
      color: badgeColor?.text,
    };
    if (badgeColor) {
      return (
        <div
          className='px-3 py-0.5 rounded-md justify-center items-center gap-1 inline-flex'
          style={badgeStyle}
        >
          {haveDotIcon && (
            <div
              className='w-2 h-2 rounded-full'
              style={{
                backgroundColor: badgeColor?.text,
              }}
            />
          )}
          <div className='text-center text-xs font-normal'>{title}</div>
        </div>
      );
    }
    return null;
  };

  const useStyles = makeStyles((theme) => ({
    hide: {
      display: 'none',
    },
    drawer: {
      width: '100%',
      flexShrink: 0,
    },
    drawerPaper: {
      top: '121px !important',
      width: '100%',
      height: 'calc(100vh - 121px) !important',
      overflow: 'hidden',
      boxShadow: '-10px 0px 15px 0px rgba(46, 43, 46, 0.05)',
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    if (history.location.search) {
      const params = new URLSearchParams(window.location.search);
      const tab = params.get('tab');
      if (tab) {
        const { title } = getTableHeaders().find((header) => header.key.includes(tab));
        setSelectedHeader(title);
      }
    }
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    params.set('tab', selectedHeader.toLowerCase());
    // params.set('id', params.get('id'));
    history.push(`${history.location.pathname}?${params.toString()}`);
  }, [selectedHeader]);

  if (isLoading) {
    return <LoadingContacts />;
  }

  return (
    <div className='w-full flex h-full'>
      <EditContactModal
        showCreateModal={showCreateModal}
        setShowCreateModal={setShowCreateModal}
        onSuccess={onSuccess}
        contact={contact}
        updateContact={updateContact}
      />
      {showBackButton && (
        <div>
          <button
            onClick={() => {
              lastAction ? history.goBack() : history.push('/admin/contacts');
            }}
            className='px-2 text-gray-400 hover:text-yellow-400'
          >
            {'< Back'}
          </button>
        </div>
      )}
      {!isMobile ? (
        <div className='h-full'>
          <VerticalCardContact
            contact={contact}
            setSelectedTabHeader={(header) => setSelectedHeader(header)}
            isShowQuickActionButton
          />
        </div>
      ) : (
        <Drawer
          open={showMoreInfo}
          variant='persistent'
          anchor='right'
          classes={{
            paper: classes.drawerPaper,
          }}
          // className='z-[15] flex-col justify-start items-start gap-4'
        >
          <VerticalCardContact
            contact={contact}
            setSelectedTabHeader={(header) => setSelectedHeader(header)}
            isShowQuickActionButton
          />
        </Drawer>
      )}
      <div className='flex-1 h-full flex flex-col overflow-hidden'>
        {isMobile && (
          <div className='ps-[45px] pe-3 border-b border-softBlack_70/30 flex justify-between items-center'>
            <div className='flex items-center gap-3 md:gap-4'>
              <div className='rounded-full bg-gray-200 text-softBlack p-3 my-2 w-10 h-10 flex justify-center items-center font-sans font-medium hover:shadow hover:cursor-pointer'>
                {`${contact.firstName?.[0] ?? ''}${
                  contact?.lastName?.[0] ?? ''
                }`}
              </div>
              <div className='flex flex-col gap-0.5 font-medium'>
                <div>
                  {(contact.firstName + ' ' + contact?.lastName ?? '').slice(
                    0,
                    18
                  )}
                  {(contact.firstName + ' ' + contact?.lastName).length > 18 &&
                    '...'}
                </div>
                <div className='flex gap-1 items-center'>
                  <LeadStatusBadge title='Buyer' />
                  <LeadStatusBadge
                    title={
                      contact[
                        contact.userType?.toLowerCase?.() === 'buyer'
                          ? 'buyerData'
                          : 'leadBrokerData'
                      ]?.leadStatus
                    }
                    haveDotIcon
                  />
                </div>
              </div>
            </div>
            <CustomButton handleClick={() => setShowMoreInfo(!showMoreInfo)}>
              <span className='p-2 px-4 font-medium text-base'>
                {showMoreInfo ? 'Back' : 'Info'}
              </span>
            </CustomButton>
          </div>
        )}
        <div className='thin-scrollbar min-h-[54px] overflow-x-auto border-b border-softBlack_70/30'>
          <ContactTabHeader
            headers={getTableHeaders()}
            handleSelectHeader={(header) => setSelectedHeader(header)}
            selectedHeader={selectedHeader}
          />
        </div>
        <div className='w-full md:min-w-[400px] h-[calc(100%-53px)] overflow-auto'>
          {selectedHeader === 'Broker Clients' && (
            <BrokerClientsTab contact={contact} />
          )}
          {selectedHeader === 'Calendar' && <MeetingTab contact={contact} />}
          {selectedHeader === 'Tasks' && <TaskTab contact={contact} />}
          {!['Calendar', 'Tasks'].includes(selectedHeader) && (
            <div className='rounded-md h-full'>
              {/* {matchHeader('Profile') && <InfoTab contact={contact} user={userObject?._id} />} */}
              {selectedHeader === 'Notes' && (
                <NotesTab contact={contact} user={userObject?._id} />
              )}
              {selectedHeader === 'Profile' && <ProfileTab contact={contact} />}
              {selectedHeader === 'Demand Notes' && (
                <DemandTab contact={contact} />
              )}
              {selectedHeader === 'Activity' && (
                <ActivityTab
                  contact={contact}
                  setSelectedHeader={setSelectedHeader}
                  setSelectedEmailThread={setSelectedEmailThread}
                />
              )}
              {selectedHeader === 'Email' && (
                <EmailTab
                  contact={contact}
                  selectedEmailThread={selectedEmailThread}
                />
              )}
              {selectedHeader === 'SMS' && <MessagesTab contact={contact} />}
              {selectedHeader === 'Calls' && <CallTab contact={contact} />}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactItem;
