/* eslint-disable no-debugger */
// @ts-nocheck
import React, { useState } from 'react'
import clsx from 'clsx'

import Checkbox from 'components/Checkbox'

import { ReactComponent as ArrowIcon } from './Arrow.svg'
import PlusIcon from 'assets/icons/plus.svg'

const CustomSelect = ({
  labelClasses,
  label,
  options,
  inputClasses,
  selectedOption,
  setSelectedOption,
  fonts = '',
  defaultValue,
  disabled = false,
  fieldName = 'options',
  multiple = false,
  toIterateField = 'value',
  createNewOptionText = '',
  setOpenCreationModal = null,
  isDarkFont = false
}) => {
  const [open, setOpen] = useState(false)

  const toggle = () => {
    if (!disabled) {
      setOpen((prev) => !prev)
    }
  }

  const selectOption = (id) => {
    if (multiple) {
      const tmpSelectedOption = Array.isArray(selectedOption)
        ? [...selectedOption]
        : []
      if (!tmpSelectedOption.includes(id)) {
        tmpSelectedOption.push(id)
      } else {
        tmpSelectedOption.splice(tmpSelectedOption.indexOf(id), 1)
      }
      setSelectedOption(tmpSelectedOption)
    } else {
      setSelectedOption(id)
      setOpen(false)
    }
  }

  const isOptionChecked = (id) => {
    if (multiple) {
      return selectedOption?.includes(id)
    } else {
      return selectedOption === id
    }
  }

  let selectedOptionData = ''
  if (multiple) {
    selectedOptionData = []
    selectedOption?.map((_option, index) => {
      const tempOption = options?.find((option) => option.id === _option)
      selectedOptionData.push(tempOption?.value)
      return true
    })
    selectedOptionData = selectedOptionData.join()
  } else {
    selectedOptionData = options?.find(
      (_option) =>
        _option.id === selectedOption || _option._id === selectedOption
    )
    selectedOptionData = selectedOptionData?.value || selectedOptionData?.name
  }

  return (
    <div>
      {
        label?.length > 0 &&
        <p
          className={`mt-0 mb-2 ${isDarkFont ? 'text-softBlack' : 'text-softBlack70'} text-sm font-light uppercase ${labelClasses}`}
          style={{ letterSpacing: '0.16px' }}
        >
          {label}
        </p>
      }
      <div className='relative' tabIndex="0" onBlur={() => setOpen(false)}>
        <div
          onClick={toggle}
          className={clsx(
            `relative cursor-pointer rounded border h-12 flex items-center pl-4 w-full ${inputClasses} `,
            {
              'border-softBlack30': !open,
              'border-black': open
            }
          )}
        >
          <span className={`text-base text-softBlack70 truncate pr-6 capitalize ${fonts}`}>
            {defaultValue || selectedOptionData || 'Select ' + fieldName}
          </span>
          <div
            className={`absolute right-3.5 top-3.5 transition duration-200 ${open && 'rotate-180'
              }`}
          >
            <ArrowIcon />
          </div>
        </div>
        {open && (
          <div
            className='absolute zIndex-1 transition duration-200 bg-white w-full border border-softBlack30 rounded left-0 top-13 py-4 flex flex-col'
            style={{ zIndex: 9, maxHeight: '250px', overflowY: 'auto' }}
          >
            {options?.map((_option, index) => (
              <Checkbox
                key={index}
                label={_option[toIterateField]}
                labelClasses={`font-normal capitalize ${fonts}`}
                checked={isOptionChecked(_option._id || _option.id)}
                handleChange={() => selectOption(_option._id || _option.id)}
                containerClasses='px-4 py-2 hover:bg-blue-100'
                iconClasses='w-3.5 h-3.5'
              />
            ))}
            {createNewOptionText.length > 0 && (
              <div
                className='px-4 py-2 flex items-center hover:bg-blue-100 hover:cursor-pointer'
                onClick={() => {
                  if (setOpenCreationModal) {
                    setOpenCreationModal(true)
                  }
                }}
              >
                <img
                  src={PlusIcon}
                  alt='Plus Icon'
                  style={{ marginRight: 10 }}
                />
                <label className='text-base hover:cursor-pointer'>
                  {createNewOptionText}
                </label>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default CustomSelect
