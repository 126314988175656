import React from 'react'
import clsx from 'clsx'

const CustomRadio = ({
  label,
  checked,
  onClick
}) => {
  return (
    <div onClick={onClick} className='flex gap-2.25 items-center cursor-pointer'>
      <div className={clsx('w-4 h-4 rounded-full', {
        'border-1.5 border-softBlack30': !checked,
        'bg-black': checked
      })} />
      {label && <p className='my-0 text-base text-softBlack'>{label}</p>}
    </div>
  )
}

export default CustomRadio
