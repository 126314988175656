/* eslint-disable */
// @ts-nocheck
import React, { useEffect, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "../../../assets/css/ag-theme-balham.css";
import { classNames } from "utils";
import { Card, Modal } from "@material-ui/core";
import useGetProjectUnits from "hooks/useGetProjectUnits";
import { groupBy } from "..";
import { getFilteredDeals } from "store/actions/dealAction";
import {
  getUnitsByBuildingId,
  getUnitsByBuildingIdTableView,
} from "store/actions/unitActions";
import { useState } from "react";
import moment from "moment";
import { IoMdClose } from "react-icons/io";
import CustomSelect from "components/CustomSelect";
import CustomInput from "components/CustomInput";
import { getParkingInventory } from "store/actions/parkingActions";
import InventoryEditDrawer from "./InventoryEditDrawer";
import Skeleton from "react-loading-skeleton";
import { api } from "services/api";

export default function TableViewUnit({
  selectedBuilding,
  projectId,
  showCards = true,
}) {
  const [filteredUnits, setFilteredUnits] = useState([]);
  const [tempTypesOfAllocatedParking, setTempTypesOfAllocatedParking] =
    useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [typesOfAllocatedParking, setTypesOfAllocatedParking] = useState([]);
  const [parkingType, setParkingType] = useState([]);
  const [allocationModal, setAllocationModal] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [editDetails, setEditDetails] = useState({});
  const [tempRefetch, setTempRefetch] = useState(false);
  const [parkingTypeData, setParkingTypeData] = useState([{}]);
  const [parkingAllocationValues, setParkingAllocationValues] = useState({});
  const parkingColor = ["#59b099", "red", "yellow", "black"];
  const [totalAssignedParkingAllocate, setTotalAssignedParkingAllocate] =
    useState(null);
  const parkingTypeArray = () => {
    const parkingStatus = [
      { name: "Available", value: value.totalParkings || 0 },
      { name: "Sold", value: 0 },
      { name: "Allocated", value: 0 },
      { name: "Assigend", value: 0 },
    ];
    const parkingData = parkingStatus.map((parking, index) => {
      const color = parkingColor[index % parkingColor.length];
      return { name: parking.name, value: parking.value, color: color };
    });
    setParkingTypeData(parkingData);
  };
  //   const handleFilteredDeals = async () => {
  //     let data = await getFilteredDeals({});
  //     console.log(data);
  //   };
  const initialEmptyValues = {
    building: selectedBuilding,
    options: [],
    parkingLevels: ["P1", "P2"],
    totalParkings: null,
  };
  const [value, setValues] = useState({
    ...initialEmptyValues,
  });

  const handleTotalParkingsAllocate = () => {
    const totalParking = parkingAllocationValues?.options?.reduce(
      (sum, obj) => {
        const objSum = obj.parkingTypes.reduce((objSum, parkingType) => {
          const typeSum = parkingType.options.reduce(
            (typeSum, option) => typeSum + Number(option.value),
            0
          );
          return objSum + typeSum;
        }, 0);
        return sum + objSum;
      },
      0
    );
    setTotalAssignedParkingAllocate(totalParking);
  };

  const parkingAllocationStatus = [
    {
      name: "Allocated",
      value: totalAssignedParkingAllocate,
    },
    {
      name: "Unallocated",
      value:
        parkingAllocationValues.allocatedParkings -
        totalAssignedParkingAllocate,
    },
  ];

  useEffect(() => {
    const handleParkingInventory = async () => {
      const { data } = await api.get(
        `/parking/${selectedBuilding}/allocateInventory`
      );
      const parkingLevelsAllocate = data.data[0].parkingLevels;
      const parkingLevels = value.parkingLevels;
      const fetchedOptions = data.data[0].options;

      setParkingAllocationValues({
        ...data.data[0],
      });
    };
    const handleBuildingInventory = async () => {
      try {
        const { data } = await api.get(
          `/parking/${selectedBuilding}/inventory`
        );
        if (data.data && data.data.length > 0) {
          setValues(data.data[0]);
        } else {
          setValues({ ...initialEmptyValues });
        }
      } catch (error) {
        console.error("Error fetching building inventory:", error);
      }
    };
    handleBuildingInventory();
    handleParkingInventory();
    parkingTypeArray();
  }, []);

  useEffect(() => {
    handleTotalParkingsAllocate();
  }, [parkingAllocationValues]);

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      filter: true,
      resizable: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
    };
  }, []);

  const _getParkingType = async (parking) => {
    let parkingInventory = await getParkingInventory(selectedBuilding);
    parkingInventory = parkingInventory.map((parking) => ({
      parkingType: parking.options.map((op) => op.name),
    }));
    if (parkingInventory.length) {
      setParkingType(
        parkingInventory[0].parkingType.map((pt) => ({
          value: pt,
          id: pt,
        }))
      );
    }
  };

  useEffect(() => {
    _getParkingType();
  }, [selectedBuilding]);

  const handleAddParking = () => {
    const newId = tempTypesOfAllocatedParking.length + 1;
    const newBgColor = colors[newId % colors.length]; // Rotate through colors
    const newAllocation = {
      id: newId,
      count: 1,
      title: "",
      bgColor: newBgColor,
    };

    setTempTypesOfAllocatedParking((prev) => [...prev, newAllocation]);
  };

  const openAllocationModal = () => {
    setAllocationModal(true);
  };
  const colors = ["#FFBE9A", "#16A34A40", "#FFD700", "#FF0000"];
  useEffect(() => {
    setIsLoading(true);
    const units = getUnitsByBuildingIdTableView(selectedBuilding).then(
      (data) => {
        let mapped = data.map((unit) => {
          return {
            ...unit,
            unitNumber: unit.unitNumber,
            buyerName: unit.buyerName,
            soldAt: unit.avesdoData?.acceptedDate,
            floorNumber: unit.floorNumber,
            unitType: unit.unitType,
            status: unit.status,
            area: unit.area,
            isOnWaitlist: unit?.avesdoData,
            acceptedDate: unit?.avesdoData?.acceptedDate,
            totalParkingStalls:
              (unit?.numberParkingIncluded || 0) +
              (unit?.numberParkingOptional || 0),
          };
        });
        setFilteredUnits(mapped);
        setIsLoading(false);
      }
    );
  }, [selectedBuilding, tempRefetch]);

  const columnDefs = [
    {
      headerName: "Unit Name",
      field: "unitNumber",
      filter: true,
      width: 140,
      pinned: "left",
      headerCheckboxSelection: true,
    },
    {
      headerName: "Strata Lot Number",
      field: "strataLotNumber",
      width: 120,
    },
    {
      headerName: "Status",
      field: "status",
      width: 150,
      cellRenderer: ({ value }) => {
        const status = value;
        const availableTags = [
          ["available", "bg-blue-500 text-white"],
          ["soldfirm", "bg-red-500 text-white"],
          ["open", "bg-green-500 text-white"],
          ["assigned", "bg-green-500 text-white"],
          ["under contract", "bg-orange-500 text-white"],
        ];
        return (
          <div>
            {/* eslint-disable-next-line array-callback-return */}
            {availableTags.map(([tag, color]) => {
              if (status.toLowerCase() === tag.toLowerCase()) {
                return (
                  <div
                    className={`capitalize text-gray-800 border border-gray-300 px-2 py-1 rounded-full text-xs ${color}`}
                  >
                    {tag}
                  </div>
                );
              }
            })}
          </div>
        );
      },
    },
    {
      headerName: "Bedrooms",
      field: "numberOfBedrooms",
      width: 100,
    },
    {
      headerName: "Bathrooms",
      field: "numberOfBathrooms",
      width: 100,
    },
    {
      headerName: "Inventory Type",
      field: "inventoryType",
      cellRenderer: ({ value }) => {
        return (
          <div>
            <div
              className={`px-3 border bg-gray-300 py-0.5 rounded-md md:rounded-md justify-center items-center gap-1 inline-flex`}
            >
              <div className="text-center text-xs font-medium">
                {value ?? ""}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      headerName: "Price",
      field: "price",
      width: 120,
    },
    {
      headerName: "Lot Group",
      field: "unitGroup",
      filter: true,
      cellRenderer: (params) => {
        return (
          <div>
            {params.data?.unitGroup ? params.data?.unitGroup?.name : "-"}
          </div>
        );
      },
    },
    {
      headerName: "Floor Plan",
      field: "floorPlan",
      cellRenderer: (params) => {
        return <div>{params.value?.name}</div>;
      },
    },
    {
      headerName: "Floor Number",
      field: "floorNumber",
      width: 100,
    },
    {
      headerName: "Leasing Term",
      field: "leasingTerm",
      width: 140,
    },
    {
      headerName: "Area",
      field: "area",
      width: 120,
    },
    {
      headerName: "Plat",
      field: "plat",
    },
    {
      headerName: "Sub Phase",
      field: "subPhase",
    },
    {
      headerName: "Block",
      field: "block",
    },
    {
      headerName: "Plat Lot Number",
      field: "platLotNumber",
    },
    {
      headerName: "Sales Lot Number",
      field: "salesLotNumber",
    },
    {
      headerName: "Lot Type",
      field: "lotType",
    },
    {
      headerName: "Lot Width",
      field: "lotWidth",
    },
    { headerName: "Square Footage", field: "squareFootage" },
    { headerName: "Dimensions", field: "dimensions" },
    { headerName: "Buildable Square Footage", field: "buildableSquareFootage" },
    { headerName: "Buildable Dimensions", field: "buildableDimensions" },
    { headerName: "Front Yard Dimension", field: "frontYardDimension" },
    { headerName: "Left Yard Dimension", field: "leftYardDimension" },
    { headerName: "Right Yard Dimension", field: "rightYardDimension" },
    { headerName: "Rear Yard Dimension", field: "rearYardDimension" },
    { headerName: "Mooring Line Type", field: "mooringLineType" },
    { headerName: "Mooring Line", field: "mooringLine" },
    { headerName: "Mooring Dimension", field: "mooringDimension" },
    { headerName: "Water Access", field: "waterAccess" },
    { headerName: "Dock Type", field: "dockType" },
    { headerName: "Dock Allowed", field: "dockAllowed" },
    { headerName: "Home Height", field: "homeHeight" },
    { headerName: "Pool", field: "pool" },
    { headerName: "Sector", field: "sector" },
    { headerName: "STR", field: "STR" },
    { headerName: "Neighborhood", field: "neighborhood" },
    {
      headerName: "Building Number",
      field: "buildingNumber",
    },
    {
      headerName: "BLDG Type Key",
      field: "bldgeTypeKey",
    },
    {
      headerName: "Floor Plan Key",
      field: "floorPlanKey",
    },
    {
      headerName: "Stage",
      field: "stage",
    },
    {
      headerName: "Max HOuse Width Ft",
      field: "maxHouseWidthFt",
    },
    {
      headerName: "Max House Width M",
      field: "maxHouseWidthM",
    },
    {
      headerName: "Area Sq Ft",
      field: "areaSqFt",
    },
    {
      headerName: "Area Sq M",
      field: "areaSqM",
    },
    {
      headerName: "Style Key",
      field: "styleKey",
    },
    {
      headerName: "Style",
      field: "style",
    },
    {
      headerName: "Grade Key",
      field: "gradeKey",
    },
    {
      headerName: "Grade",
      field: "grade",
    },
    {
      headerName: "List Price",
      field: "listPrice",
    },
    {
      headerName: "Status Key",
      field: "statusKey",
    },
    {
      headerName: "Notes",
      field: "notes",
    },
    {
      headerName: "ETA",
      field: "ETA",
    },
    {
      headerName: "ETA month",
      field: "ETAMonth",
    },
  ];

  return (
    <div className="flex w-full h-full">
      {isLoading ? (
        <div className="h-full w-full flex flex-col gap-y-4">
          <Skeleton height={130} />
          <Skeleton height={130} />
          <Skeleton height={130} />
          <Skeleton height={130} />
          <Skeleton height={130} />
        </div>
      ) : (
        <div className="w-full flex flex-col gap-y-5">
          <div
            className={classNames(
              "h-full ag-theme-alpine w-full transition-all relative",
              isDrawerOpen ? "mr-[350px]" : "mr-0"
            )}
          >
            <AgGridReact
              domLayout="autoHeight"
              defaultColDef={defaultColDef}
              rowHeight={40}
              columnDefs={columnDefs}
              rowData={filteredUnits}
            />
          </div>
        </div>
      )}
      <div className="absolute right-0 top-0 bottom-0 z-[999]">
        <InventoryEditDrawer
          editDetails={editDetails}
          setEditDetails={setEditDetails}
          closeInfoDrawer={() => setIsDrawerOpen(false)}
          isInfoDrawerOpen={isDrawerOpen}
          isAbleToPurchase
          refetch={() => {
            setTempRefetch(!tempRefetch);
          }}
          type={"Unit"}
          selectedBuilding={selectedBuilding}
          isFullHeight={false}
        />
      </div>
    </div>
  );
}
