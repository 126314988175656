/*eslint-disable */
// @ts-nocheck
import { Drawer, makeStyles } from '@material-ui/core';
import ContactItem from 'pages/contacts/ContactItem';
import { ContactContext } from 'pages/contacts/context/Contacts';
import React, { useCallback, useContext, useEffect } from 'react';
import { IoMdClose } from 'react-icons/io';
import useContactContext from '../hooks/useContactContext';
import { FadedAnimatedDiv } from 'components';
import Searching from 'pages/contacts/Searching';
import { isMobile } from 'react-device-detect';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { ADMIN_NEW_CONTACTS } from 'routes';
import useContactContextV2 from '../hooks/useContactContextV2';

const ContactDetailDrawerV2 = ({ resetPath = '/admin/contacts' }) => {
  const { state, closeContactDetailDrawer, setFilters } = useContactContext();
  const {
    selectedContact,
    isLoading,
    selectedContactObject,
    setSelectedContact,
    setSelectedContactObject
  } = useContext(ContactContext);
  const history = useHistory();
  const location = useLocation();
  const useStyles = makeStyles((theme) => ({
    hide: {
      display: 'none',
    },
    drawer: {
      width: isMobile ? '100%' : 'calc(100% - 360px)',
      flexShrink: 0,
    },
    drawerPaper: {
      width: isMobile ? '100%' : 'calc(100% - 360px)',
      height: '100vh',
      overflow: 'hidden',
      boxShadow: '-10px 0px 15px 0px rgba(46, 43, 46, 0.05)',
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    if (state.selectedContact?._id) {
      setSelectedContact(state.selectedContact?._id);
    }
  }, [state.selectedContact?._id]);

  const LoadingContacts = useCallback(
    () => (
      <FadedAnimatedDiv className='h-full w-full flex flex-col justify-center items-center'>
        <Searching
          title={`Loading ${state.selectedContact?.firstName || 'Contact'}'s info...`}
        />
      </FadedAnimatedDiv>
    ),
    [state.selectedContact?.firstName]
  );

  return (
    <Drawer
      open={state.isContactDetailDrawerOpen}
      variant='persistent'
      anchor='right'
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className='pt-16 relative h-full'>
        {!isLoading && (
          <button
            onClick={() => {
              closeContactDetailDrawer(resetPath);
              setSelectedContact(null);
            }}
            className='absolute left-[0.7rem] md:left-6 top-[5rem] md:top-[5.7rem] z-[10]'
          >
            <div className='text-black hover:text-black'>
              <IoMdClose fontSize='22px' />
            </div>
          </button>
        )}
        {isLoading && <LoadingContacts />}
        {/* {state.selectedContact?.userType !== 'LeadBroker' && selectedContact && !isLoading && selectedContactObject?._id && <ContactItem */}
        {selectedContact && !isLoading && selectedContactObject?._id && (
          <ContactItem showBackButton={false} />
        )}
        {/* {state.selectedContact?.userType === 'LeadBroker' && !isLoading && <LeadBrokerContactItem />} */}
      </div>
    </Drawer>
  );
};

export default ContactDetailDrawerV2;
