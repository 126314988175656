import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { createOrUpdateBuilding } from 'store/actions/buildingActions'

import { ReactComponent as Done } from '../../../../assets/icons/done.svg'
import { Oval } from 'react-loader-spinner'

import { ADMIN_BUILDINGS } from 'routes'

import NoHeader from 'components/Header/NoHeader'
import CustomInput from 'components/CustomInput'
import CustomSelect from 'components/CustomSelect'
import { ImageUploaderPrimary } from 'components/ImageUploader'
import { MessageModal } from 'components/MessageModal'

import useGetBuildings from 'hooks/useGetBuildigs'
import useCurrencyList from 'hooks/useCurrencyList'

const AddBuilding = (props) => {
  // @ts-ignore
  const { appProject: projectId } = useSelector((state) => state.appReducer)
  const userObject = useSelector((state) => state.authReducer.userObject)
  const buildingId = props.history.location.state?.id
  const {
    // @ts-ignore
    buildings: { data: buildings }
  } = useGetBuildings(buildingId || '')
  const { formattedCurrencyList: currencyData } = useCurrencyList()
  const [loading, setLoading] = useState(false)
  const [isDeleted, setIsDeleted] = useState(false)
  const [showAddBuilingSuccessModal, setShowAddBuilingSuccessModal] =
    useState(false)
  const [values, setValues] = useState({
    name: '',
    address: '',
    totalDollarValue: '',
    totalDollarValueCurrencyType: '',
    numberOfUnits: '',
    image: '',
    displayImage: ''
  })

  // Setting the values of the form
  useEffect(() => {
    if (buildings?._id) {
      setValues({
        _id: buildings._id,
        name: buildings.name,
        address: buildings.address || '',
        totalDollarValue: buildings.totalDollarValue,
        totalDollarValueCurrencyType: buildings.totalDollarValueCurrencyType,
        numberOfUnits: buildings.numberOfUnits,
        image: buildings.image || '',
        displayImage: buildings.image || ''
      })
    }
  }, [buildings])

  const onChange = (field, value) => {
    setValues({ ...values, [field]: value })
  }

  const onSubmitHandler = () => {
    setLoading(true)
    const temp = { ...values, createdBy: userObject._id, project: projectId }
    delete temp.displayImage
    delete temp.image
    createOrUpdateBuilding(temp)
      .then((res) => {
        console.log(res)
        setLoading(false)
        setShowAddBuilingSuccessModal(true)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const AddImage = async (e) => {
    const file = e.target.files[0]
    const url = URL.createObjectURL(e.target.files[0])

    setValues({
      ...values,
      image: file,
      displayImage: url
    })
    setIsDeleted(false)
  }

  const deleteBuildingImage = () => {
    setValues({ ...values, image: '', displayImage: '' })
    setIsDeleted(true)
  }

  const onValidate = () => {
    const requiredFields = [
      'name',
      'address',
      'totalDollarValue',
      'totalDollarValueCurrencyType',
      'numberOfUnits',
      'image'
    ]
    for (let i = 0; i < requiredFields.length; i++) {
      if (values[requiredFields[i]] === '') {
        return false
      }
    }
    return true
  }

  const MessageHtml = ({ building }) => {
    return (
      <>
        <span className='italic font-light'>{building}</span> was successfully
        created
      </>
    )
  }

  return (
    <div>
      <NoHeader
        redirect={ADMIN_BUILDINGS}
        title={`${values._id ? 'Update' : 'New'}  Building`}
      />
      <div className='px-8 pb-8' style={{ paddingTop: '7.5rem' }}>
        <div className='w-full'>
          <div className='p-6 bg-grey-8 rounded'>
            <h1 className='font-black text-lg pt-3 font-openSans'>
              General Information
            </h1>
            <div className='border-b-2 border-black my-2' />
            <h2 className='font-openSans'>
              Please enter the details about the building
            </h2>
            <>
              <div className='grid grid-cols-2  gap-6 border-0 p-0 mt-10 rounded-none shadow-none'>
                <div>
                  <CustomInput
                    label='Builings Name *'
                    labelClassName='text-xs italic font-openSans font-light'
                    placeholder='Enter name'
                    value={values.name}
                    onChange={(e) => onChange('name', e.target.value)}
                    classes='font-openSans text-base'
                    style={{ maxHeight: '48px' }}
                    required='required'
                    containerClasses={undefined}
                  />
                  <div className='mt-6'>
                    <CustomInput
                      label='Total Value *'
                      labelClassName='text-xs italic font-openSans font-light'
                      placeholder='e.g. 1 000 000'
                      value={values?.totalDollarValue}
                      onChange={(e) =>
                        onChange('totalDollarValue', e.target.value)
                      }
                      classes='font-openSans text-base'
                      style={{ maxHeight: '48px' }}
                      required='required'
                      containerClasses={undefined}
                    />
                  </div>
                </div>
                <div>
                  <CustomInput
                    label='Buildings Address *'
                    labelClassName='text-xs italic font-openSans font-light'
                    placeholder='Enter address'
                    value={values?.address}
                    onChange={(e) => onChange('address', e.target.value)}
                    classes='font-openSans text-base'
                    style={{ maxHeight: '48px' }}
                    required='required'
                    containerClasses={undefined}
                  />
                  <div className='grid grid-cols-2 gap-6 mt-6'>
                    <CustomSelect
                      label='Currency *'
                      labelClasses='text-xs italic font-openSans font-light'
                      inputClasses='h-10 bg-white font-openSans'
                      selectedOption={values?.totalDollarValueCurrencyType}
                      options={currencyData}
                      setSelectedOption={(e) =>
                        onChange('totalDollarValueCurrencyType', e)
                      }
                      fonts={'font-openSans'}
                      fieldName={'currency'}
                    />
                    <CustomInput
                      label='Number of Units *'
                      labelClassName='text-xs italic font-openSans font-light'
                      placeholder='Enter amount'
                      value={values.numberOfUnits}
                      onChange={(e) =>
                        onChange('numberOfUnits', e.target.value)
                      }
                      classes='font-openSans text-base'
                      style={{ maxHeight: '48px' }}
                      required='required'
                      type='number'
                      min={1}
                      containerClasses={undefined}
                    />
                  </div>
                </div>
              </div>
              <div className='mt-10'>
                <ImageUploaderPrimary
                  imgsrc={values.displayImage}
                  imgTitle={values.displayImage.substring(
                    values.displayImage.lastIndexOf('/') + 1
                  )}
                  imgFileType={values.displayImage
                    ?.substring(values.displayImage?.lastIndexOf('.') + 1)
                    ?.toUpperCase()}
                  onFileChange={(e) => AddImage(e)}
                  onDelete={() => deleteBuildingImage()}
                  isDeleted={isDeleted}
                />
              </div>
              <div className='flex flex-center items-center'>
                <button
                  onClick={onSubmitHandler}
                  disabled={!onValidate()}
                  className='m-auto bg-volt px-6 py-3 mt-6 rounded font-semibold flex items-center font-openSans italic font-light'
                >
                  {loading && <Oval height='18' width='18' color='black' />}
                  {!loading && <Done className='h-4 w-4 mr-2' />}
                  {values._id ? 'Update' : 'Add'} Building
                </button>
              </div>
            </>
          </div>
        </div>
      </div>
      <MessageModal
        show={showAddBuilingSuccessModal}
        setShow={setShowAddBuilingSuccessModal}
        title={`Building ${values._id ? 'Updated' : 'Created'}`}
        message={`values.name + ' was successfully ${
          values._id ? 'updated' : 'created'
        }`}
        messageHtml={<MessageHtml building={values.name} />}
        primaryBtn={false}
        secondaryBtn={true}
      />
    </div>
  )
}

export default AddBuilding
