import React from 'react'
import { connect } from 'react-redux'
import { buyerNavItems } from '../buyerNavItems/buyerNavItems'
import { useHistory } from 'react-router-dom'

const BuyerDashBoard = () => {
  const history = useHistory()

  return (
    <div className='w-full'>
      <div className='bg-[#566A75] pl-6 fixed z-[999] h-full py-6 pr-[26px] flex flex-col gap-y-4 w-[252px]'>
        <div className='flex mx-auto '>
            <img src='/logo.png' alt='logo' className='w-28 h-28'/>
        </div>
        <div className=''>
          <ul className='flex flex-col gap-y-6'>
            {
              buyerNavItems.map((elm) => (
                <li
                  key={elm.title}
                  onClick={() => history.push(elm.path)}
                  className='flex items-center gap-x-2 cursor-pointer text-white hover:bg-white hover:text-black px-2 py-[6px] rounded-md italic text-lg font-light truncate transition-all'
                >
                  <img src={elm.icon} alt={elm.title} className='text-white' />
                  {elm.title}
                </li>
              ))
            }
          </ul>
        </div>
      </div>
      <div className='relative h-full ml-[252px] '>
        <img
        src='/buyer-dashboard-no-shadow.png'
        className='w-full fixed h-full object-cover '
        />
        <div className='w-full gap-5 p-6 flex flex-col items-center justify-center top-0 absolute'>
          <div className=' rounded-lg w-full pt-[22px] pb-6 flex items-center  justify-center flex-col gap-y-2 bg-[rgba(0,0,0,0.6)] backdrop-blur-md'>
            <div>
              <img src='/logo.png' className='h-[84.64px]' />
            </div>
            <div className='text-[40px] text-[#EFA581] font-bold'>
              The Pacifica
            </div>
            <div className='text-center font-bold text-[40px] text-white'>
              Welcome Back, Stefan
            </div>
          </div>
          <div className='flex flex-1   w-full gap-8 justify-between'>
            <div className='bg-[#FCFCFA] max-h-2xl max-w-sm w-full px-7 py-6 text-[24px] rounded-lg'>
              <div className='mb-4 text-center'>Purchase Timeline</div>
              <div className='flex flex-col gap-y-7'>
                <div className='flex gap-x-7 relative'>
                    <div className='w-14 h-14 bg-[#E8CB78] flex items-center justify-center rounded-full'><img src='/right-arrow.png' /></div>
                    <div>
                        <div className='italic font-light text-[14px]'>July 16th  2023</div>
                        <div className='text-[20px] font-bold text-[#7a7a7a]'>Reservation</div>
                    </div>
                    <div className='w-[2px] h-7 -bottom-7 ml-7 bg-[#2E2B2E4D] absolute'></div>
                </div>
                <div className='flex gap-x-7 relative'>
                    <div className='w-14 h-14 bg-[#E8CB78] flex items-center justify-center rounded-full'><img src='/right-arrow.png' /></div>
                    <div>
                        <div className='italic font-light text-[14px]'>July 16th  2023</div>
                        <div className='text-[20px] font-bold text-[#7a7a7a]'>Reservation</div>
                    </div>
                    <div className='w-[2px] h-7 -bottom-7 ml-7 bg-[#2E2B2E4D] absolute'></div>
                </div>
                <div className='flex gap-x-7 relative'>
                    <div className='w-14 h-14 bg-[#E8CB78] flex items-center justify-center rounded-full'><img src='/right-arrow.png' /></div>
                    <div>
                        <div className='italic font-light text-[14px]'>July 16th  2023</div>
                        <div className='text-[20px] font-bold text-[#7a7a7a]'>Reservation</div>
                    </div>
                    <div className='w-[2px] h-7 -bottom-7 ml-7 bg-[#2E2B2E4D] absolute'></div>
                </div>
                <div className='flex gap-x-7 relative'>
                    <div className='w-14 h-14 bg-[#E3E3E1] flex items-center justify-center rounded-full'></div>
                    <div>
                        <div className='italic font-light text-[14px]'>July 16th  2023</div>
                        <div className='text-[20px] font-bold text-[#2E2B2E]'>Reservation</div>
                    </div>
                    <div className='w-[2px] h-7 -bottom-7 ml-7 bg-[#2E2B2E4D] absolute'></div>
                </div>
                <div className='flex gap-x-7 relative'>
                    <div className='w-14 h-14 bg-[#E3E3E1] flex items-center justify-center rounded-full'></div>
                    <div>
                        <div className='italic text-[14px]'>July 16th  2023</div>
                        <div className='text-[20px] font-bold text-[#2E2B2E]'>Reservation</div>
                    </div>
                    <div className='w-[2px] h-7 -bottom-7 ml-7 bg-[#2E2B2E4D] absolute'></div>
                </div>
                <div className='flex gap-x-7 relative'>
                    <div className='w-14 h-14 bg-[#E3E3E1] flex items-center justify-center rounded-full'></div>
                    <div>
                        <div className='italic text-[14px]'>July 16th  2023</div>
                        <div className='text-[20px] font-bold text-[#2E2B2E]'>Reservation</div>
                    </div>
                </div>
              </div>
            </div>
            <div className='bg-[#FCFCFA] max-h-2xl max-w-sm w-full px-7 pt-8 text-[24px] rounded-lg'>
              <div className='flex flex-col gap-y-6'>
                <div className=''>
                  <img src='/residence-diagram.png' />
                </div>
                <div className='w-full h-[1px] bg-black'></div>
                <div className='text-[#2E2B2E] flex flex-col gap-y-1'>
                  <div>
                      <button className='bg-[#B8E04A] italic font-light text-[16px] p-1'>SOLD FIRM</button>
                  </div>
                  <div className='text-[32px] font-bold'>
                    Residence #1201
                  </div>
                  <div className='text-[21px] flex items-center gap-x-1'>
                    <div>
                      <img src='/residence-icon.png' />
                    </div>
                    <div>
                      1818 Pacifica
                    </div>
                  </div>
                  <div className='italic text-[27px]'>
                    CA$1,320,040
                  </div>

                </div>
              </div>
            </div>
            <div className='bg-[#FCFCFA] max-h-2xl max-w-sm w-full px-2 py-4 text-[24px] rounded-lg'>
              <div className='flex flex-col gap-y-4'>
                <div className='flex gap-x-5 relative rounded-[4px] text-[#2E2B2E] bg-[#7D63451F] py-4 pl-8 pr-8  border-[#E8CB78] border-2'>
                    <div className=''><img src='/austin-jones.png' /></div>
                    <div className='flex flex-1 flex-col gap-y-1'>
                        <div className=' text-[17px]'>Your Sales Rep</div>
                        <div className='flex items-center  justify-between'>
                          <div className=' text-[20px] font-bold '>Austin Jones</div>
                          <div className='w-8 h-8  bg-[#F2F2F1] flex items-center justify-center'>
                            <img src='/call-icon.svg' />
                          </div>
                        </div>
                        <div className='flex items-center  justify-between'>
                          <div className=' text-[13px] font-light '>AJ@milborne.com</div>
                          <div className='w-8 h-8  bg-[#F2F2F1] flex items-center justify-center'>
                            <img src='/message-icon.svg' />
                          </div>
                        </div>
                    </div>
                </div>
                <div className='flex gap-x-5 relative rounded-[4px] text-[#2E2B2E] bg-[#7D63451F] py-4 pl-8 pr-8  border-[#E8CB78] border-2'>
                    <div className=''><img src='/shannon-mckenzie.png' /></div>
                    <div className='flex flex-1 flex-col gap-y-1'>
                        <div className=' text-[17px]'>Your Broker</div>
                        <div className='flex items-center  justify-between'>
                          <div className=' text-[15px] font-bold '>Shannon Mckenzie</div>
                          <div className='w-8 h-8  bg-[#F2F2F1] flex items-center justify-center'>
                            <img src='/call-icon.svg' />
                          </div>
                        </div>
                        <div className='flex items-center  justify-between'>
                          <div className=' text-[13px] font-light '>s.mc@sotherbys.ca</div>
                          <div className='w-8 h-8  bg-[#F2F2F1] flex items-center justify-center'>
                            <img src='/message-icon.svg' />
                          </div>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col gap-2'>
                    <div className=' flex justify-between w-full gap-x-4'>
                    <div className='flex items-center w-full justify-center flex-col pt-4 pb-2 px-8 bg-[#7D63451F] rounded-[4px] gap-y-2'>
                      <img src='/calender-card-icon.png' />
                      <div className='italic text-[16px] font-bold'>Calendar</div>
                    </div>
                    <div className='flex flex-col  items-center w-full justify-center pt-4 pb-2 px-8 bg-[#7D63451F] rounded-[4px] gap-y-2'>
                      <img src='/calender-card-icon.png' className='' />
                      <div className='italic text-[16px] font-bold'>Calendar</div>
                    </div>
                    </div>
                    <div className=' flex justify-between w-full gap-x-4'>
                    <div className='flex flex-col  items-center w-full justify-center pt-4 pb-2 px-8 bg-[#7D63451F] rounded-[4px] gap-y-2'>
                      <img src='/calender-card-icon.png' />
                      <div className='italic text-[16px] font-bold'>Calendar</div>
                    </div>
                    <div className='flex flex-col  items-center w-full justify-center pt-4 pb-2 px-8 bg-[#7D63451F] rounded-[4px] gap-y-2'>
                      <div><img src='/calender-card-icon.png' /></div>
                      <div className='italic text-[16px] font-bold'>Calendar</div>
                    </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject
})
const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(BuyerDashBoard)
