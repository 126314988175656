/* eslint multiline-ternary: ["error", "never"] */
// @ts-nocheck
/* eslint-disable */
import React, { useEffect, useState } from 'react';
import morrisonWalkNorthVancouverBg from '../../assets/img/morrison-walk-north-vancouver.jpg';
import morrisonWalkLogo from '../../assets/img/morrison-walk-logo.jpeg';
import keySvg from '../../assets/img/key.svg';
import { api } from 'services/api';
import { Oval } from 'react-loader-spinner';
import CustomInput from 'components/CustomInput';
import { useSelector } from 'react-redux';
import { ProjectData } from 'utils/projectConfig';
import placeHolderBg from '../../assets/img/bg.png';
import PacificaLogo from '../../assets/img/pacifica-logo.png';

const ReminderSettingPage = () => {
  const [projectLogo, setProjectLogo] = useState(null);
  const appReducer = useSelector((state) => state.appReducer);
  const [projectName, setProjectName] = useState('');
  const [projectBackgroundImage, setProjectBackgroundImage] = useState('');

  useEffect(() => {
    const list = appReducer?.listOfProjects;
    const selectedProjectId = appReducer?.appProject;

    if (!list || !selectedProjectId) return;

    const activeProject = list.find(
      (project) => project._id === selectedProjectId
    );
    setProjectName(activeProject?.projectName);
    setProjectBackgroundImage(activeProject?.backgroundImage);
    setProjectLogo(activeProject?.logoImage);
  }, [appReducer]);

  const _id = useSelector((state) => state.appReducer.appProject);
  const buttonStyle = {
    backgroundColor: '#2e2b2e',
    height: '45px',
    color: 'white',
    paddingLeft: '24px',
    paddingRight: '24px',
    fontSize: '18px',
    borderRadius: '8px',
  };

  const style = {
    height: '680px',
    width: '100%',
  };

  const [reminderData, setReminderData] = useState({});

  const [initialDeposit, setInitialDeposit] = useState({
    dueDate: '',
    firstReminder: '',
    secondReminder: '',
    thirdReminder: '',
  });
  const [firstDeposit, setfirstDeposit] = useState({
    dueDate: '',
    firstReminder: '',
    secondReminder: '',
    thirdReminder: '',
  });
  const [secondDeposit, setSecondDeposit] = useState({
    dueDate: '',
    firstReminder: '',
    secondReminder: '',
    thirdReminder: '',
  });
  const [thirdDeposit, setThirdDeposit] = useState({
    dueDate: '',
    firstReminder: '',
    secondReminder: '',
    thirdReminder: '',
  });
  const [fourthDeposit, setFourthDeposit] = useState({
    dueDate: '',
    firstReminder: '',
    secondReminder: '',
    thirdReminder: '',
  });
  const [loading, setLoading] = useState(false);
  const handleButton = () => {
    const reminderData = {
      initialDeposit,
      firstDeposit,
      secondDeposit,
      thirdDeposit,
      fourthDeposit,
    };

    setReminderData(reminderData);
  };
  useEffect(() => {
    const sendReminderData = async () => {
      setLoading(true);
      try {
        await api.post(`/projects/${_id}/reminderSettings`, {
          data: {
            depositOptions: reminderData,
            projectId: _id,
          },
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error('Error:', error);
      }
    };

    if (Object.keys(reminderData).length !== 0) {
      sendReminderData();
    }
  }, [reminderData]);

  useEffect(() => {
    const getReminderData = async () => {
      try {
        const { data } = await api.get(`/projects/${_id}/reminderSettings`);
        console.log(data.data);
        setInitialDeposit(data.data[0].depositOptions?.initialDeposit);
        setfirstDeposit(data.data[0].depositOptions?.firstDeposit || {});
        setSecondDeposit(data.data[0].depositOptions?.secondDeposit || {});
        setThirdDeposit(data.data[0].depositOptions?.thirdDeposit || {});
        setFourthDeposit(data.data[0].depositOptions?.fourthDeposit || {});
        return data.data;
      } catch (error) {
        console.error('Error:', error);
      }
    };
    getReminderData();
  }, []);

  return (
    <div className='relative w-full p-12 pt-14'>
      <div
        className='absolute z-[-1] w-full h-[70vh] top-0 left-0 bg-cover bg-no-repeat'
        style={{
          backgroundImage: `url(${
                    projectBackgroundImage?.length > 0
              ? projectBackgroundImage
              : placeHolderBg
          })`,
        }}
      />
      <img
        className='max-h-24'
        src={projectLogo ?? PacificaLogo}
        alt='logo'
      />
      <div
        style={style}
        className='w-full bg-white mt-20 rounded-[8px] h-[100%]'
      >
        <div className='flex justify-between h-24 items-center bg-[#f3f3f3] rounded-t-[8px]  px-6 '>
          <div className='flex gap-2 items-center'>
            <img src={keySvg} className='bg-[#EDDF65]  rounded-full p-1' />
            <div className='text-[18px] font-bold'>Deposit Reminder Set Up</div>
          </div>
          <button onClick={handleButton} style={buttonStyle}>
            {loading ? (
              <Oval height='18' width='18' color='black' />
            ) : (
              'Save Settings'
            )}
          </button>
        </div>
        <div className='h-[1px] w-full bg-softBlack'></div>
        <div className='grid grid-cols-12 col-span-12 px-6 pt-8 gap-x-10 gap-y-6'>
          <ul className='grid col-span-12 items-center text-[#323232] font-bold grid-cols-12 gap-10'>
            <li className='col-span-2 text-[20px] text-black '></li>
            <li className='col-span-2 text-end'>Due Date</li>
            <li className='col-span-2 text-end'>Reminder 1</li>
            <li className='col-span-2 text-end'>Reminder 2</li>
            <li className='col-span-2 text-end'>Reminder 3</li>
          </ul>
          <ul className='grid col-span-12 grid-cols-12   items-center gap-10'>
            <li className='col-span-2 text-[20px] text-black font-bold '>
              Initial Deposit
            </li>
            <li className='col-span-2'>
              <CustomInput
                type='number'
                label=''
                placeholder='Due date'
                classes='font-openSans text-base'
                style={{ maxHeight: '48px' }}
                value={initialDeposit.dueDate || ''}
                onChange={(e) => {
                  setInitialDeposit({
                    ...initialDeposit,
                    dueDate: e.target.value,
                  });
                }}
              />
            </li>
            <li className='col-span-2'>
              <CustomInput
                type='number'
                label=''
                placeholder='Reminder 1'
                classes='font-openSans text-base'
                style={{ maxHeight: '48px' }}
                value={initialDeposit.firstReminder || ''}
                onChange={(e) => {
                  setInitialDeposit({
                    ...initialDeposit,
                    firstReminder: e.target.value,
                  });
                }}
              />
            </li>
            <li className='col-span-2'>
              <CustomInput
                type='number'
                label=''
                placeholder='Reminder 2'
                classes='font-openSans text-base'
                style={{ maxHeight: '48px' }}
                value={initialDeposit.secondReminder || ''}
                onChange={(e) => {
                  setInitialDeposit({
                    ...initialDeposit,
                    secondReminder: e.target.value,
                  });
                }}
              />
            </li>
            <li className='col-span-2'>
              <CustomInput
                type='number'
                label=''
                placeholder='Reminder 3'
                classes='font-openSans text-base'
                style={{ maxHeight: '48px' }}
                value={initialDeposit.thirdReminder || ''}
                onChange={(e) => {
                  setInitialDeposit({
                    ...initialDeposit,
                    thirdReminder: e.target.value,
                  });
                }}
              />
            </li>
          </ul>
          <ul className='grid col-span-12 grid-cols-12   items-center gap-10'>
            <li className='col-span-2 text-[20px] text-black font-bold '>
              1st Deposit
            </li>
            <li className='col-span-2'>
              <CustomInput
                label=''
                type='number'
                placeholder='Due date'
                classes='font-openSans text-base'
                style={{ maxHeight: '48px' }}
                value={firstDeposit.dueDate || ''}
                onChange={(e) => {
                  setfirstDeposit({
                    ...firstDeposit,
                    dueDate: e.target.value,
                  });
                }}
              />
            </li>
            <li className='col-span-2'>
              <CustomInput
                label=''
                type='number'
                placeholder='Reminder 1'
                classes='font-openSans text-base'
                style={{ maxHeight: '48px' }}
                value={firstDeposit.firstReminder || ''}
                onChange={(e) => {
                  setfirstDeposit({
                    ...firstDeposit,
                    firstReminder: e.target.value || '',
                  });
                }}
              />
            </li>
            <li className='col-span-2'>
              <CustomInput
                type='number'
                label=''
                placeholder='Reminder 2'
                classes='font-openSans text-base'
                style={{ maxHeight: '48px' }}
                value={firstDeposit.secondReminder || ''}
                onChange={(e) => {
                  setfirstDeposit({
                    ...firstDeposit,
                    secondReminder: e.target.value || '',
                  });
                }}
              />
            </li>
            <li className='col-span-2'>
              <CustomInput
                type='number'
                label=''
                placeholder='Reminder 3'
                classes='font-openSans text-base'
                style={{ maxHeight: '48px' }}
                value={firstDeposit.thirdReminder || ''}
                onChange={(e) => {
                  setfirstDeposit({
                    ...firstDeposit,
                    thirdReminder: e.target.value || '',
                  });
                }}
              />
            </li>
          </ul>
          <ul className='grid col-span-12 grid-cols-12   items-center gap-10'>
            <li className='col-span-2 text-[20px] text-black font-bold '>
              2nd Deposit
            </li>
            <li className='col-span-2'>
              <CustomInput
                type='number'
                label=''
                placeholder='Due date'
                classes='font-openSans text-base'
                style={{ maxHeight: '48px' }}
                value={secondDeposit.dueDate || ''}
                onChange={(e) => {
                  setSecondDeposit({
                    ...secondDeposit,
                    dueDate: e.target.value || '',
                  });
                }}
              />
            </li>
            <li className='col-span-2'>
              <CustomInput
                label=''
                type='number'
                placeholder='Reminder 1'
                classes='font-openSans text-base'
                style={{ maxHeight: '48px' }}
                value={secondDeposit.firstReminder || ''}
                onChange={(e) => {
                  setSecondDeposit({
                    ...secondDeposit,
                    firstReminder: e.target.value || '',
                  });
                }}
              />
            </li>
            <li className='col-span-2'>
              <CustomInput
                type='number'
                label=''
                placeholder='Reminder 2'
                classes='font-openSans text-base'
                style={{ maxHeight: '48px' }}
                value={secondDeposit.secondReminder || ''}
                onChange={(e) => {
                  setSecondDeposit({
                    ...secondDeposit,
                    secondReminder: e.target.value || '',
                  });
                }}
              />
            </li>
            <li className='col-span-2'>
              <CustomInput
                type='number'
                label=''
                placeholder='Reminder 3'
                classes='font-openSans text-base'
                style={{ maxHeight: '48px' }}
                value={secondDeposit.thirdReminder || ''}
                onChange={(e) => {
                  setSecondDeposit({
                    ...secondDeposit,
                    thirdReminder: e.target.value || '',
                  });
                }}
              />
            </li>
          </ul>
          <ul className='grid col-span-12 grid-cols-12   items-center gap-10'>
            <li className='col-span-2 text-[20px] text-black font-bold '>
              3rd Deposit
            </li>
            <li className='col-span-2'>
              <CustomInput
                label=''
                type='number'
                placeholder='Due date'
                classes='font-openSans text-base'
                style={{ maxHeight: '48px' }}
                value={thirdDeposit.dueDate || ''}
                onChange={(e) => {
                  setThirdDeposit({
                    ...thirdDeposit,
                    dueDate: e.target.value || '',
                  });
                }}
              />
            </li>
            <li className='col-span-2'>
              <CustomInput
                type='number'
                label=''
                placeholder='Reminder 1'
                classes='font-openSans text-base'
                style={{ maxHeight: '48px' }}
                value={thirdDeposit.firstReminder || ''}
                onChange={(e) => {
                  setThirdDeposit({
                    ...thirdDeposit,
                    firstReminder: e.target.value || '',
                  });
                }}
              />
            </li>
            <li className='col-span-2'>
              <CustomInput
                type='number'
                label=''
                placeholder='Reminder 2'
                classes='font-openSans text-base'
                style={{ maxHeight: '48px' }}
                value={thirdDeposit.secondReminder || ''}
                onChange={(e) => {
                  setThirdDeposit({
                    ...thirdDeposit,
                    secondReminder: e.target.value || '',
                  });
                }}
              />
            </li>
            <li className='col-span-2'>
              <CustomInput
                label=''
                type='number'
                placeholder='Reminder 3'
                classes='font-openSans text-base'
                style={{ maxHeight: '48px' }}
                value={thirdDeposit.thirdReminder || ''}
                onChange={(e) => {
                  setThirdDeposit({
                    ...thirdDeposit,
                    thirdReminder: e.target.value || '',
                  });
                }}
              />
            </li>
          </ul>
          <ul className='grid col-span-12 grid-cols-12   items-center gap-10'>
            <li className='col-span-2 text-[20px] text-black font-bold '>
              4th Deposit
            </li>
            <li className='col-span-2'>
              <CustomInput
                label=''
                type='number'
                placeholder='Due date'
                classes='font-openSans text-base'
                style={{ maxHeight: '48px' }}
                value={fourthDeposit.dueDate || ''}
                onChange={(e) => {
                  setFourthDeposit({
                    ...firstDeposit,
                    dueDate: e.target.value || '',
                  });
                }}
              />
            </li>
            <li className='col-span-2'>
              <CustomInput
                type='number'
                label=''
                placeholder='Reminder 1'
                classes='font-openSans text-base'
                style={{ maxHeight: '48px' }}
                value={fourthDeposit.firstReminder || ''}
                onChange={(e) => {
                  setFourthDeposit({
                    ...fourthDeposit,
                    firstReminder: e.target.value || '',
                  });
                }}
              />
            </li>
            <li className='col-span-2'>
              <CustomInput
                type='number'
                label=''
                placeholder='Reminder 2'
                classes='font-openSans text-base'
                style={{ maxHeight: '48px' }}
                value={fourthDeposit.secondReminder || ''}
                onChange={(e) => {
                  setFourthDeposit({
                    ...fourthDeposit,
                    secondReminder: e.target.value || '',
                  });
                }}
              />
            </li>
            <li className='col-span-2'>
              <CustomInput
                type='number'
                label=''
                placeholder='Reminder 3'
                classes='font-openSans text-base'
                style={{ maxHeight: '48px' }}
                value={fourthDeposit.thirdReminder || ''}
                onChange={(e) => {
                  setFourthDeposit({
                    ...fourthDeposit,
                    thirdReminder: e.target.value || '',
                  });
                }}
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ReminderSettingPage;
