/*eslint-disable */
// @ts-nocheck
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import TableBody from "./TableBody/TableBody";
import TableHead from "./TableHead/TableHead";
import { ArrowDownIcon, FadedAnimatedDiv, LoadingIcon } from "components";
import Searching from "pages/contacts/Searching";
import "../../../../assets/css/ag-theme-balham.css";
import TablePagination from "./TablePagination/TablePaginationV2";
import { parsePhoneNumber } from "libphonenumber-js";
import useContactContext from "pages/newContacts/hooks/useContactContext";
// import {
//   filterAssignmentsBySaleRepId,
//   sortContacts
// } from 'pages/newContacts/context/MainContactContext'
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { isMobile } from "react-device-detect";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import buyer from "components/CreateOffer/buyer";
import { useHistory, Link, useParams } from "react-router-dom";
import { BiDownArrow } from "react-icons/bi";
import { ReactComponent as CallsIcon } from "../../../../assets/icons/icn_phone.svg";
import { ReactComponent as EmailIcon } from "../../../../assets/icons/icn_email.svg";
import { ReactComponent as SMSIcon } from "../../../../assets/icons/icn_text_snippet.svg";
import { ReactComponent as TasksIcon } from "../../../../assets/icons/icn_check_circle.svg";
import { ReactComponent as NotesIcon } from "../../../../assets/icons/icn_write.svg";
import {
  ArrowDownFromLine,
  ArrowDownNarrowWide,
  CheckCheckIcon,
  CheckIcon,
  FullscreenIcon,
  ListChecks,
  ListFilter,
  ListIcon,
  LucideArrowDown,
  LucideArrowDownAz,
  LucideArrowDownNarrowWide,
  LucideArrowDownWideNarrow,
  MenuIcon,
} from "lucide-react";
import TablePaginationV2 from "./TablePagination/TablePaginationV2";
import useContactContextV2 from "pages/newContacts/hooks/useContactContextV2";
import successAnimation from "../../../../assets/lottieJsons/avesdoLoading.json";
import { LottieWrapper } from "components";
import useLeadStatus from "hooks/useLeadStatus";
import {
  getFilterContacts,
  getUsers,
  updateContactType,
  updateLeadStatus,
  updateRating,
  updateSalesRep,
} from "store/actions/usersActions";
import TablePaginationV2Bottom from "./TablePagination/TablePaginationV2Bottom";
import {
  getCorporatDemandNotes,
  GetDemandNotes,
} from "store/actions/demandNotes";
import useUpdateUsers from "hooks/useUpdateUsers";
import useBuyerScore from "hooks/useBuyerScore";
import { on } from "events";
import { FullscreenExitOutlined, ShrinkOutlined } from "@ant-design/icons";
import Select from "react-select";
import { ReactComponent as FullScreen } from "assets/icons/full-screen-arrow.svg";
import { ADMIN_CONTACTS, ADMIN_NEW_CONTACTS_V2 } from "routes";
import { Checkbox, Modal } from "@material-ui/core";
import { Divider, Tag } from "antd";
import CreateNewList from "pages/newContacts/AddToListDrawer/CreateNewList/CreateNewList";
import CreateNewListV2 from "pages/newContacts/AddToListDrawer/CreateNewList/CreateNewListFromTable";
import useGetLists from "hooks/useGetLists";
import { RiPencilFill } from "react-icons/ri";
import useListsMutation from "hooks/useListsMutation";
import { MdClose } from "react-icons/md";
import useContactTypes from "hooks/useContactTypes";
import { Select as AntSelect } from "antd";
import useGetSalesReps from "hooks/useGetSalesReps";
import { Phone, MessageSquare, Mail, Edit } from "lucide-react"; // Import the icons you want to use
import zIndex from "@material-ui/core/styles/zIndex";
export const formatPhoneNumber = (phoneNumberString) => {
  if (!phoneNumberString) return "-";
  try {
    const phoneNumber = parsePhoneNumber(phoneNumberString, "US");
    if (phoneNumber) {
      const countryCode = phoneNumber.countryCallingCode;
      const nationalNumber = phoneNumber.nationalNumber;
      return `+${countryCode}-${nationalNumber.slice(
        0,
        3
      )}-${nationalNumber.slice(3, 6)}-${nationalNumber.slice(6)}`;
    }
  } catch (error) {}
  return phoneNumberString;
};
const UserTableV2 = () => {
  const {
    state,
    isUserLoading: isLoading,
    filtering: isFiltering,
    setSelectedContact,
    setFilters,
    toggleContactsSortingType,
    toggleCheckContact,
    refetchFilteredUser,
    emptyCheckedSelections,
    toggleCheckAllContacts,
    toggleCheckList,
    view,
  } = useContactContext();

  // @ts-ignore
  const [localState, setLocalState] = React.useState("asc");
  const bodyRef = useRef(null);
  const { createOrUpdateUser } = useUpdateUsers(true);
  const history = useHistory();
  const userObject = useSelector((state) => state.authReducer.userObject);
  const gridRef = React.useRef(null);
  const [listButtonManage, setListButtonManage] = React.useState([]);
  const [tempSave, setTempSave] = React.useState(null);
  const [demandNotes, setDemandNotes] = React.useState([]);
  const [rowHeight, setRowHeight] = React.useState(
    state.isContactDetailDrawerOpen || isMobile ? 100 : 40
  );
  const { leadStatuses } = useLeadStatus();
  const { contactTypeData } = useContactTypes();
  const { scores } = useBuyerScore();
  const [salesReps, setSalesReps] = useState([]);
  const [contacts, setContacts] = React.useState([]);
  const [saveStatus, setSaveStatus] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [menuOpen, setIsMenuOpen] = useState(false);
  const [listId, setListId] = useState();
  const [listSearch, setListSearch] = useState("");
  const { id } = useParams();
  const { addUsersToLists } = useListsMutation();
  const {
    lists,
    isLoading: isListLoading,
    refetch: listRefetch,
  } = useGetLists();

  const getSalesReps = () => {
    getUsers("", "?userType=SalesRep").then((users) => {
      const salesReps = users.map((user) => ({
        id: user._id,
        value: `${user.firstName} ${user.lastName}`,
      }));
      setSalesReps(salesReps);
    });
  };
  const [createListModal, setCreateListModal] = useState(false);
  const [addToListModal, setAddToListModal] = useState(false);
  const LoadingContacts = useCallback(
    () => (
      <FadedAnimatedDiv className="h-full w-full min-h-[600px] flex flex-col items-center mt-20">
        <Searching title="Loading contacts..." />
      </FadedAnimatedDiv>
    ),
    []
  );
  useEffect(() => {
    getSalesReps();
  }, []);
  const handleAddToList = () => {
    let filters = state.filters;
    delete filters["page"];
    delete filters["limit"];
    delete filters["search"];
    getFilterContacts({
      ...filters,
      userType: state.contactUserType,
    }).then((data) => {
      const contacts = data.data;
      if (contacts.length === 0) {
        toast.error("No contacts found");
        return;
      }
      const listOfUsers = contacts.map((contact) => contact._id);
      const listOfIds = Object.keys(state.checkedLists).filter(
        (key) => state.checkedLists[key] === true
      );
      const previousData = {};
      lists
        ?.filter((list) => listOfIds.includes(list?._id))
        .forEach((list) => {
          previousData[list?._id] = list.users;
        });
      addUsersToLists({ listOfIds, listOfUsers, previousData });
      setAddToListModal(false);
    });
  };
  useEffect(() => {
    setRowHeight(state.isContactDetailDrawerOpen || isMobile ? 100 : 40);
  }, [state.isContactDetailDrawerOpen || isMobile]);

  useEffect(() => {
    if (saveStatus === "Saved") {
      const timeoutId = setTimeout(() => {
        setSaveStatus("");
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [saveStatus]);

  useEffect(() => {
    if (Object.keys(state.checkedContacts).length === 0) {
      if (gridRef.current?.api) {
        gridRef.current.api.applyTransaction({
          update: contacts.map((contact) => ({
            ...contact,
            select: false,
          })),
        });
        gridRef.current?.api.refreshCells({ force: true });
      }
    }
  }, [state.checkedContacts]);

  const colors = [
    { text: "#dc2626", bg: "#FDE8E8" }, // Red
    { text: "#f97316", bg: "#FFEDD5" }, // Orange
    { text: "#16a34a", bg: "#D1FAE5" }, // Green
    { text: "#2563eb", bg: "#DBEAFE" }, // Blue
    { text: "#7c3aed", bg: "#EDE9FE" }, // Purple
    { text: "#171717", bg: "#D1D5DB" }, // Dark Grey
    { text: "#facc15", bg: "#FEF9C3" }, // Yellow
    { text: "#e11d48", bg: "#FEE2E2" }, // Pink
  ];
  const leadStatusBadgeColorFinder = useCallback((title) => {
    if (
      title.includes("Attempted To Contact") ||
      title.includes("attempted to Contact") ||
      title.includes("Attempted to contact")
    ) {
      return colors[0];
    }

    if (title.includes("Connected") || title.includes("connected")) {
      return colors[1];
    }

    if (title.includes("New") || title.includes("new")) {
      return colors[2];
    }
    if (title.includes("Open Deal") || title.includes("open deal")) {
      return colors[3];
    }
    if (title.includes("Reservation") || title.includes("reservation")) {
      return colors[4];
    }
    if (title.includes("Unqualified") || title.includes("unqualified")) {
      return colors[5];
    }
    if (title.includes("New Lead") || title.includes("new lead")) {
      return colors[6];
    }

    if (title.includes("Owner") || title.includes("owner")) {
      return colors[7];
    }
    return {
      text: "#111928",
      bg: "#F3F4F6",
    };
  }, []);

  const onBtShowNoRows = useCallback(() => {
    gridRef.current.api.showNoRowsOverlay();
  }, []);

  const onBtHide = useCallback(() => {
    if (gridRef.current?.api) {
      gridRef.current.api.hideOverlay();
    } else {
      return;
    }
  }, []);

  const onBtShowLoading = useCallback(() => {
    gridRef.current.api.showLoadingOverlay();
  }, []);

  const userTypeBadgeColor = useCallback((title) => {
    if (title === "LeadBroker") {
      return colors[3];
    }

    if (title === "Buyer") {
      return colors[5];
    }
    return {
      text: "#111928",
      bg: "#F3F4F6",
    };
  }, []);

  const ContactTypeBadge = ({ value }) => {
    let options = contactTypeData?.values || [];

    const badgeColor = colors[options.findIndex((option) => option === value)];
    const badgeStyle = {
      backgroundColor: badgeColor?.bg,
      color: badgeColor?.text,
    };
    if (badgeColor) {
      return (
        <>
          {value && (
            <div
              className="px-3 py-0.5 rounded-md md:rounded-md justify-center items-center gap-1 inline-flex"
              style={badgeStyle}
            >
              <div className="text-center text-xs font-medium">
                {value || "-"}
              </div>
            </div>
          )}
        </>
      );
    }
    return null;
  };

  const DropDownSalesRep = forwardRef((props, ref) => {
    const [value, setValue] = useState(props.value);
    const options = salesReps || [];
    const selectOptions = options.map((option) => ({
      value: option._id || option.id,
      label: option.name || option.value,
    }));
    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return value;
        },
        isCancelBeforeStart() {
          return false;
        },
        isCancelAfterEnd() {
          return false;
        },
      };
    });

    const handleChange = (event) => {
      setValue(event.value);
      if (props.onValueChange) {
        props.onValueChange(event.value, props.data);
      }
      if (props.api) {
        props.api.stopEditing();
      }
    };

    const tableRef = props.tableRef;
    useEffect(() => {
      const handleClickOutside = (event) => {
        props.api.stopEditing();
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [props.api]);

    return (
      <Select
        ref={ref}
        value={value}
        onChange={handleChange}
        options={selectOptions}
        className="w-full"
        isSearchable={false}
        menuPortalTarget={document.body}
        value={selectOptions.find((option) => option.value === value)}
        menuIsOpen={props.api.getEditingCells().length > 0}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          menu: (provided) => ({
            ...provided,
            marginTop: 0,
            marginBottom: 0,
          }),
          menuList: (provided) => ({
            ...provided,
            paddingTop: 0,
            paddingBottom: 0,
          }),
          option: (provided) => ({
            ...provided,
            backgroundColor: "transparent",
            color: "inherit",
            cursor: "pointer",
            ":hover": {
              backgroundColor: "rgba(0, 0, 0, 0.1)",
            },
          }),
          control: (provided) => ({
            ...provided,
            minWidth: 120, // Adjust as needed
          }),
        }}
      ></Select>
    );
  });

  const DropdownCellEditor = forwardRef((props, ref) => {
    let type = props.type;
    let salesRepKey = (data) => {
      if (data === "Buyer") return "buyerData";
      if (data === "LeadBroker") return "leadBrokerData";
      return "";
    };
    const [value, setValue] = useState(props.value);
    const options = props.options || [];
    const selectOptions = options.map((option) => ({
      value: option,
      label: option,
    }));
    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return value;
        },
        isCancelBeforeStart() {
          return false;
        },
        isCancelAfterEnd() {
          return false;
        },
      };
    });

    const CustomOption = ({ innerProps, label, isFocused, isSelected }) => (
      <div
        {...innerProps}
        className={`flex items-center justify-center py-2 cursor-pointer ${
          isFocused || isSelected ? "bg-gray-100" : "bg-white"
        }`}
      >
        <LeadStatusBadge title={label} haveDotIcon={false} />
      </div>
    );

    const handleChange = (event) => {
      setValue(event.value);
      if (props.onValueChange) {
        props.onValueChange(event.value, props.data);
      }
      if (props.api) {
        props.api.stopEditing();
      }
    };

    const tableRef = props.tableRef;
    useEffect(() => {
      const handleClickOutside = (event) => {
        props.api.stopEditing();
      };

      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [props.api]);

    return (
      <Select
        ref={ref}
        onChange={handleChange}
        className="w-full"
        options={selectOptions}
        isSearchable={false}
        components={{ Option: CustomOption }}
        value={selectOptions.find((option) => option.value === value)}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          menu: (provided) => ({
            ...provided,
            marginTop: 0,
            marginBottom: 0,
          }),
          menuList: (provided) => ({
            ...provided,
            paddingTop: 0,
            paddingBottom: 0,
          }),
          option: (provided) => ({
            ...provided,
            backgroundColor: "transparent",
            color: "inherit",
            cursor: "pointer",
          }),
          control: (provided) => ({
            ...provided,
            minWidth: 120, // Adjust as needed
          }),
        }}
        formatOptionLabel={({ label }) => (
          <LeadStatusBadge title={label} haveDotIcon={false} />
        )}
        menuIsOpen={props.api.getEditingCells().length > 0}
      />
    );
  });

  const DropdownCellEditorContactType = forwardRef((props, ref) => {
    let type = props.type;
    let salesRepKey = (data) => {
      if (data === "Buyer") return "buyerData";
      if (data === "LeadBroker") return "leadBrokerData";
      return "";
    };
    const [value, setValue] = useState(props.value);
    const options = props.options || [];
    const selectOptions = options.map((option) => ({
      value: option,
      label: option,
    }));
    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return value;
        },
        isCancelBeforeStart() {
          return false;
        },
        isCancelAfterEnd() {
          return false;
        },
      };
    });

    const CustomOption = ({ innerProps, label, isFocused, isSelected }) => (
      <div
        {...innerProps}
        className={`flex items-center justify-center py-2 cursor-pointer ${
          isFocused || isSelected ? "bg-gray-100" : "bg-white"
        }`}
      >
        <ContactTypeBadge value={label} />
      </div>
    );

    const handleChange = (event) => {
      setValue(event.value);
      if (props.onValueChange) {
        props.onValueChange(event.value, props.data);
      }
      if (props.api) {
        props.api.stopEditing();
      }
    };

    const tableRef = props.tableRef;
    useEffect(() => {
      const handleClickOutside = (event) => {
        props.api.stopEditing();
      };

      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [props.api]);

    return (
      <Select
        ref={ref}
        onChange={handleChange}
        className="w-full"
        options={selectOptions}
        isSearchable={false}
        components={{ Option: CustomOption }}
        value={selectOptions.find((option) => option.value === value)}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          menu: (provided) => ({
            ...provided,
            marginTop: 0,
            marginBottom: 0,
          }),
          menuList: (provided) => ({
            ...provided,
            paddingTop: 0,
            paddingBottom: 0,
          }),
          option: (provided) => ({
            ...provided,
            backgroundColor: "transparent",
            color: "inherit",
            cursor: "pointer",
          }),
          control: (provided) => ({
            ...provided,
            minWidth: 120, // Adjust as needed
          }),
        }}
        formatOptionLabel={({ label }) => <ContactTypeBadge value={label} />}
        menuIsOpen={props.api.getEditingCells().length > 0}
      />
    );
  });

  const DropdownCellEditorCorporateDemands = forwardRef((props, ref) => {
    let type = props.type;
    let salesRepKey = (data) => {
      if (data === "Buyer") return "buyerData";
      if (data === "LeadBroker") return "leadBrokerData";
      return "";
    };
    let values = props.value;
    const options = props.options || [];
    let selectedValues =
      values?.map((item) => {
        return options.find((option) => option.value === item)?.value;
      }) || [];

    let filterUndefined = selectedValues.filter((item) => item !== undefined);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [value, setValue] = useState(filterUndefined);
    const selectOptions = options.map((option) => ({
      value: option.value,
      label: option.label,
    }));
    const isMulti = props.isMulti;

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return value;
        },
        isCancelBeforeStart() {
          return false;
        },
        isCancelAfterEnd() {
          return false;
        },
      };
    });

    const updateCell = useCallback(
      (newValue) => {
        if (props.api) {
          const updatedData = { ...props.data };
          updatedData[props.column.colId] = newValue;
          props.api.applyTransactionAsync({ update: [updatedData] });
          props.api.refreshCells({ force: true });
        }
      },
      [props.api, props.data, props.column.colId]
    );
    const handleChange = useCallback(
      (newValue) => {
        let updatedValue;
        if (isMulti) {
          updatedValue = newValue;
        } else {
          updatedValue =
            newValue.length > 0 ? [newValue[newValue.length - 1]] : [];
        }
        setValue(updatedValue);
        if (props.onValueChange) {
          props.onValueChange(updatedValue, props.data, props.column.colId);
        }
        updateCell(updatedValue);
      },
      [props.onValueChange, props.data, props.column.colId, updateCell, isMulti]
    );

    const tableRef = props.tableRef;
    useEffect(() => {
      const handleClickOutside = (event) => {
        props.api.stopEditing();
      };

      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [props.api]);

    useEffect(() => {
      setDropdownOpen(true);
    }, []);

    return view === "corporate" ? (
      <AntSelect
        mode={"multiple"}
        value={value}
        onChange={(value) => {
          handleChange(value);
        }}
        options={selectOptions}
        className="w-full"
        style={{ width: "100%" }}
        open={dropdownOpen}
        onDropdownVisibleChange={(open) => setDropdownOpen(open)}
        dropdownRender={(menu) => (
          <div onMouseDown={(e) => e.stopPropagation()}>{menu}</div>
        )}
        tagRender={(props) => {
          const { label, value: val, closable, onClose } = props;
          return (
            <Tag
              color="#3682eb"
              closable={closable && isMulti}
              onClose={(e) => {
                e.preventDefault();
                e.stopPropagation();
                const newValue = value.filter((v) => v !== val);
                handleChange(newValue);
              }}
              style={{ marginRight: 3 }}
            >
              {label}
            </Tag>
          );
        }}
      />
    ) : (
      <Select
        ref={ref}
        value={value}
        onChange={handleChange}
        options={selectOptions}
        className="w-full"
        isSearchable={false}
        menuPortalTarget={document.body}
        value={selectOptions.find((option) => option.value === value)}
        menuIsOpen={props.api.getEditingCells().length > 0}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          menu: (provided) => ({
            ...provided,
            marginTop: 0,
            marginBottom: 0,
          }),
          menuList: (provided) => ({
            ...provided,
            paddingTop: 0,
            paddingBottom: 0,
          }),
          option: (provided) => ({
            ...provided,
            backgroundColor: "transparent",
            color: "inherit",
            cursor: "pointer",
            ":hover": {
              backgroundColor: "rgba(0, 0, 0, 0.1)",
            },
          }),
          control: (provided) => ({
            ...provided,
            minWidth: 120, // Adjust as needed
          }),
        }}
      >
        {/* {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))} */}
      </Select>
    );
  });

  const DropdownCellEditorCustomDemands = forwardRef((props, ref) => {
    let type = props.type;
    let salesRepKey = (data) => {
      if (data === "Buyer") return "buyerData";
      if (data === "LeadBroker") return "leadBrokerData";
      return "";
    };
    const [value, setValue] = useState(props.value);
    const options = props.options || [];
    const selectOptions = options.map((option) => ({
      value: option,
      label: option,
    }));

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return value;
        },
        isCancelBeforeStart() {
          return false;
        },
        isCancelAfterEnd() {
          return false;
        },
      };
    });

    const handleChange = (event) => {
      setValue(event.value);
      if (props.onValueChange) {
        props.onValueChange(event.value, props.data, props.column.colId);
      }
      if (props.api) {
        props.api.stopEditing();
      }
    };

    return (
      <Select
        ref={ref}
        value={value}
        onChange={handleChange}
        options={selectOptions}
        className="w-full"
        isSearchable={false}
        menuPortalTarget={document.body}
        value={selectOptions.find((option) => option.value === value)}
        menuIsOpen={props.api.getEditingCells().length > 0}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          menu: (provided) => ({
            ...provided,
            marginTop: 0,
            marginBottom: 0,
          }),
          menuList: (provided) => ({
            ...provided,
            paddingTop: 0,
            paddingBottom: 0,
          }),
          option: (provided) => ({
            ...provided,
            backgroundColor: "transparent",
            color: "inherit",
            cursor: "pointer",
            ":hover": {
              backgroundColor: "rgba(0, 0, 0, 0.1)",
            },
          }),
          control: (provided) => ({
            ...provided,
            minWidth: 120, // Adjust as needed
          }),
        }}
      >
        {/* {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))} */}
      </Select>
    );
  });

  const LeadStatusBadge = ({ title, haveDotIcon = false, onClick }) => {
    const badgeColor = leadStatusBadgeColorFinder(title);
    const badgeStyle = {
      backgroundColor: badgeColor?.bg,
      color: badgeColor?.text,
    };
    if (badgeColor) {
      return (
        <div
          onClick={onClick}
          className="px-3 py-0.5 rounded-md md:rounded-md justify-center items-center gap-1 inline-flex"
          style={badgeStyle}
        >
          {haveDotIcon && !isMobile && (
            <div
              className="w-2 h-2 rounded-full"
              style={{
                backgroundColor: badgeColor?.text,
              }}
            />
          )}
          <div className="text-center text-xs font-medium">{title}</div>
        </div>
      );
    }
    return null;
  };

  useEffect(() => {
    if (view === "corporate") {
      const getCorporateCustomDemandFields = async () => {
        const data = await getCorporatDemandNotes();
        if (data) {
          const formatedData = await data
            .filter((item) => item.label !== "")
            .map((item) => {
              return {
                label: {
                  name: item.label,
                  _id: item._id,
                },
                values: [
                  ...item.options.map((option) => {
                    return {
                      label: option.label,
                      value: option.id,
                    };
                  }),
                ],
                isMulti: item.isMulti,
              };
            });
          setDemandNotes(formatedData);
        }
      };
      getCorporateCustomDemandFields();
    } else {
      const getCustomDemandFields = async () => {
        const data = await GetDemandNotes();
        if (data) {
          const formatedData = await data
            .filter((item) => item.label !== "")
            .map((item) => {
              return {
                label: item.label,
                values: item.options,
              };
            });
          setDemandNotes(formatedData);
        }
      };
      getCustomDemandFields();
    }
  }, [view]);

  const [hoveredRowId, setHoveredRowId] = useState(null);

  const UserImage = (props) => {
    const { hoveredRowId, setHoveredRowId } = props;
    const [isHovered, setIsHovered] = useState(false);
    const nameRef = useRef(null);
    let colors  = getHighlightStyle(props)
    const fullName = `${props.data.firstName} ${props.data.lastName}`;
    const handleIconClick = (tab) => {
      history.push({
        pathname: history.location.pathname,
        search: `?_id=${props.data._id}&tab=${tab}`,
      });
      setSelectedContact(props.data);
    };
    return (
      <div

        style={{
          backgroundColor: colors ? colors?.backgroundColor : "transparent"
        }}
        className="relative w-full h-full"
        onMouseEnter={() => setIsHovered(props.data._id)}
        onMouseLeave={() => setIsHovered(null)}
      >
        <div
          ref={nameRef}
          className={`transition-opacity duration-300 ${
            isHovered ? "opacity-40" : "opacity-100"
          }`}
        >
          {fullName}
        </div>
        {isHovered && (
          <div className="absolute right-0 top-0 bottom-0 h-full flex items-center gap-x-2 p-1 rounded">
            <CallsIcon
              onClick={() => handleIconClick("calls")}
              className="w-5 h-5 cursor-pointer text-gray-600"
            />
            <EmailIcon
              onClick={() => handleIconClick("email")}
              className="w-5 h-5 cursor-pointer text-gray-600"
            />
            <SMSIcon
              onClick={() => handleIconClick("sms")}
              className="w-5 h-5 cursor-pointer text-gray-600"
            />
            <TasksIcon
              onClick={() => handleIconClick("tasks")}
              className="w-5 h-5 cursor-pointer text-gray-600"
            />
            <NotesIcon
              onClick={() => handleIconClick("notes")}
              className="w-5 h-5 cursor-pointer text-gray-600"
            />
          </div>
        )}
      </div>
    );
  };

  const UserTypeBadge = (props) => {
    const badgeColor = userTypeBadgeColor(props.data.userType);
    const badgeStyle = {
      backgroundColor: badgeColor?.bg,
      color: badgeColor?.text,
    };
    return (
      <div
        className="px-3 py-0.5 rounded-md md:rounded-md justify-center items-center gap-1 inline-flex"
        style={badgeStyle}
      >
        <div className="text-center text-xs font-medium">
          {props.data.userType}
        </div>
      </div>
    );
  };

  const salesRepKey = (data) => {
    if (data === "Buyer") return "buyerData";
    if (data === "LeadBroker") return "leadBrokerData";
    return "";
  };

  const isToday = (date) => {
    return moment(date).isSame(moment(), "day");
  };

  const getHighlightStyle = (params) => {
    if (
      params.data[salesRepKey(params.data.userType)].lastContactedAt !==
      undefined
    ) {
      if (
        isToday(params.data[salesRepKey(params.data.userType)].lastContactedAt)
      ) {
        return { backgroundColor: "#eddf65" };
      }
    }
    return null;
  };

  const LeadStatusComponent = (props) => {
    const [click, setClick] = React.useState(false);
    return (
      <LeadStatusBadge
        onClick={() => {
          setClick(true);
        }}
        title={
          props?.data?.buyerData?.leadStatus ||
          props?.data?.leadBrokerData?.leadStatus ||
          "-"
        }
      />
    );
  };

  const CustomDateFilter = (props) => {
    const {
      toggleContactsSortingType,
      setFilters,
      state,
      localState,
      setLocalState,
    } = props;
    const handleClick = () => {
      toggleContactsSortingType();
      setFilters({
        ...state.filters,
        sortType: localState === "desc" ? "asc" : "desc",
      });
      setLocalState(localState === "desc" ? "asc" : "desc");
    };

    return (
      <div onClick={handleClick} className="">
        Date Register
        <span className="ml-2 text-[16px] font-[500]">
          {localState === "asc" ? "↓" : "↑"}
        </span>
      </div>
    );
  };

  const MobileUserCard = (props) => {
    return (
      <div className="flex items-start w-[100%] gap-x-3">
        <div className="flex items-start gap-x-2">
          <div className="flex flex-col gap-y-1">
            <div className="text-[12px] leading-[14px]">
              {props.data.firstName} {props.data.lastName}
            </div>
            <div className="flex gap-x-3 items-center">
              <span className="text-xs">User Type:</span>
              <div className="px-3 py-0.5 rounded-md justify-center items-center gap-1 inline-flex bg-[#F3F4F6]">
                <div className="text-center text-xs font-medium">
                  {props.data.userType}
                </div>
              </div>
            </div>
            <div className="flex gap-x-3 items-center">
              <span className="text-xs">Lead Status:</span>
              <LeadStatusBadge
                title={
                  props?.data?.buyerData?.leadStatus ||
                  props?.data?.leadBrokerData?.leadStatus ||
                  "-"
                }
                haveDotIcon={false}
              />
            </div>
            <div className="text-xs  text-zinc-400">
              {props.data.dateRegistered}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const loadingOverlayComponent = useMemo(() => {
    return CustomLoadingCellRenderer;
  }, []);

  const components = useMemo(() => {
    return {
      leadStatus: LeadStatusComponent,
      userImage: UserImage,
      mobileUserCard: MobileUserCard,
      userTypeBadge: UserTypeBadge,
    };
  }, [UserImage, LeadStatusComponent, MobileUserCard]);

  const getOrderedColumns = (columns, savedOrder) => {
    if (columns.length !== savedOrder.length) return columns;
    const orderedColumns = [];
    const columnMap = {};

    columns.forEach((col) => {
      columnMap[col.field] = {
        ...col,
      };
    });

    savedOrder.forEach((col) => {
      if (columnMap[col.colId]) {
        const orderedCol = {
          ...col,
          ...columnMap[col.colId],
        };
        orderedColumns.push(orderedCol);
        delete columnMap[col.colId];
      }
    });

    Object.values(columnMap).forEach((col) => {
      orderedColumns.push(col);
    });

    return orderedColumns;
  };
  // const handleRowClick = (e, contactData) => {
  //   e.preventDefault();
  // setSelectedContact(contactData);
  //   history.push(`${ADMIN_NEW_CONTACTS_V2}/${contactData._id}`,{
  //     filters: state.filters,
  //   });
  // };

  const columns = (userType) => {
    const salesRepKey = (data) => {
      if (data === "Buyer") return "buyerData";
      if (data === "LeadBroker") return "leadBrokerData";
      return "";
    };
    let result = [
      {
        headerName: "",
        field: "open",
        width: 60,
        pinned: "left",
        sortable: false,
        cellRenderer: (props) => (
          <FullScreen className=" scale-[.8] flex items-center justify-center" />
        ),
        cellStyle: getHighlightStyle,
        highlightToday: true,
      },
      {
        headerName: "",
        field: "checkbox",
        width: 70,
        sortable: false,
        pinned: "left",
        cellRenderer: (params) => {
          const handleChange = (e) => {
            const updatedData = { ...params.data, select: e.target.checked };
            params.api.applyTransaction({ update: [updatedData] });
            params.api.refreshCells({ force: true });
            toggleCheckContact(updatedData._id);
          };

          return (
            <Checkbox
              color="primary"
              type="checkbox"
              checked={params.data.select}
              onChange={handleChange}
            />
          );
        },
        headerComponent: (params) => (
          <Checkbox
            color="primary"
            type="checkbox"
            checked={
              Object.values(state.checkedContacts).filter((val) => val === true)
                .length === contacts.length && contacts.length > 0
            }
            onChange={(e) => {
              toggleCheckAllContacts();
              params.api.applyTransaction({
                update: contacts.map((contact) => ({
                  ...contact,
                  select: e.target.checked,
                })),
              });
              params.api.refreshCells({ force: true });
            }}
          />
        ),
        cellStyle: getHighlightStyle,
        highlightToday: true,
      },
      {
        headerName: "Name",
        field: "fullName",
        width: 300,
        resizable: true,
        pinned: "left",
        editable: true,
        cellStyle: getHighlightStyle,
        cellRenderer: (params) => (
          <UserImage
            
            {...params}
            hoveredRowId={hoveredRowId}
            setHoveredRowId={setHoveredRowId}
          />
        ),
        // onCellMouseEnter: (params) => {
        //   setHoveredRowId(params.data._id);
        //   params.api.refreshCells({
        //     force: true,
        //     columns: ["fullName"],
        //   });
        // },
        // onCellMouseLeave: (params) => {
        //   setHoveredRowId(null);
        //   params.api.refreshCells({
        //     force: true,
        //     columns: ["fullName"],
        //   });
        // },
        onCellClicked: (function () {
          let lastEditTime = 0;
          let clickTimeout = null;
          let clickCount = 0;
          return (params) => {
            if (params.event.target.closest(".absolute")) {
              return; // Do nothing if the click was on an icon
            }
            const currentTime = new Date().getTime();
            if (currentTime - lastEditTime < 500) {
              return;
            }
            if (isEditing) return;
            clickCount++;
            if (clickCount === 1) {
              clickTimeout = setTimeout(() => {
                clickCount = 0;
                params.api.refreshCells({ force: true });
                const updatedRowNode = params.api.getRowNode(params.node.id);
                const updatedData = updatedRowNode.data;
                setSelectedContact(updatedData);
                history.push({
                  pathname: `${ADMIN_CONTACTS}`,
                  search: `?_id=${updatedData._id}`,
                });
              }, 300);
            } else if (clickCount === 2) {
              clearTimeout(clickTimeout);
              clickCount = 0;
              setIsEditing(true);
              params.api.startEditingCell({
                rowIndex: params.node.rowIndex,
                colKey: params.column.getColId(),
              });
            }
          };
        })(),
        onCellValueChanged: (function () {
          let lastEditTime = 0;
          return (params) => {
            lastEditTime = new Date().getTime();
          };
        })(),
      },
      {
        headerName: "Date Registered",
        field: "createdAt",
        width: 200,
        sortable: false,
        headerComponent: (params) => (
          <CustomDateFilter
            toggleContactsSortingType={toggleContactsSortingType}
            setFilters={setFilters}
            state={state}
            localState={localState}
            setLocalState={setLocalState}
          />
        ),
        valueFormatter: (p) => {
          if (p.value) {
            return moment(p.value).format("MM/DD/YYYY");
          } else {
            return "-";
          }
        },
      },
  
      {
        headerName: "User Type",
        field: "userType",
        width: 150,
        resizable: true,
        cellRenderer: "userTypeBadge",
      },
      {
        headerName: "Email",
        field: "email",
        width: 250,
        editable: true,
        resizable: true,
        valueFormatter: (p) => {
          if (!p.value) {
            return "-";
          }
        },
      },
      {
        headerName: "Phone Number",
        field: "phoneNumber",
        width: 180,
        resizable: true,
        editable: true,
        // valueGetter: (params) => {
        //   return (
        //     params.data[salesRepKey(params.data.userType)]?.phoneNumber || '-'
        //   );
        // },
        valueFormatter: (p) => formatPhoneNumber(p.value),
      },
      {
        headerName: "Sales Rep Owner",
        field: "salesRep",
        width: 180,
        resizable: true,
        valueGetter: (params) => {
          return params.data?.salesRep;
        },
        resizable: true,
        editable: true,
        cellEditor: DropDownSalesRep,
        cellEditorParams: {
          type: "salesRep",
          tableRef: bodyRef,
          options: salesReps?.values || [],
          onValueChange: (newValue, data) => {
            const updatedData = new Object({
              ...data,
              salesRep: newValue,
            });
            // update the current row only
            gridRef.current.api.applyTransaction({
              update: [updatedData],
            });
            gridRef.current.api.refreshCells({ force: true });
            updateSalesRep({
              salesRep: newValue,
              contactId: data?._id,
            }).then(() => {
              refetchFilteredUser();
              setSaveStatus("Saved");
            });
          },
        },
        onCellClicked: (params) => {
          params.api.startEditingCell({
            rowIndex: params.rowIndex,
            colKey: params.column.getColId(),
          });
        },
      },
      {
        headerName: "Buyer Score",
        width: 180,
        field: "rating",
        valueGetter: (params) => {
          return params.data[salesRepKey(params.data.userType)]?.rating || "-";
        },
        resizable: true,
        editable: true,
        cellEditor: DropdownCellEditor,
        cellEditorParams: {
          type: "rating",
          tableRef: bodyRef,
          options: scores?.values || [],
          onValueChange: (newValue, data) => {
            const userType = contacts[0]?.userType;
            const key = salesRepKey(userType);
            const updatedData = {
              ...data,
              [key]: {
                ...data[key],
                rating: newValue,
              },
            };
            gridRef.current.api.applyTransaction({
              update: [updatedData],
            });
            gridRef.current.api.refreshCells({ force: true });
            updateRating(newValue, data?._id).then(() => {
              refetchFilteredUser();
              setSaveStatus("Saved");
            });
          },
        },
        onCellClicked: (params) => {
          params.api.startEditingCell({
            rowIndex: params.rowIndex,
            colKey: params.column.getColId(),
          });
        },
      },
      {
        headerName: "Lead Status",
        width: 200,
        field: "leadStatus",
        resizable: true,
        valueGetter: (params) => {
          return (
            params.data[salesRepKey(params.data.userType)]?.leadStatus || "-"
          );
        },
        cellRenderer: "leadStatus",
        editable: true,
        cellEditor: DropdownCellEditor,
        cellEditorParams: {
          type: "leadStatus",
          tableRef: gridRef,
          options: leadStatuses?.values || [],
          onValueChange: (newValue, data) => {
            const userType = contacts[0]?.userType;
            const key = salesRepKey(userType);
            const updatedData = {
              ...data,
              [key]: {
                ...data[key],
                leadStatus: newValue,
              },
            };
            gridRef.current.api.applyTransaction({
              update: [updatedData],
            });
            gridRef.current.api.refreshCells({ force: true });
            updateLeadStatus(newValue, data?._id).then(() => {
              refetchFilteredUser();
              setSaveStatus("Saved");
            });
          },
        },
        onCellClicked: (params) => {
          setIsEditing(true);
          params.api.startEditingCell({
            rowIndex: params.rowIndex,
            colKey: params.column.getColId(),
          });
        },
        // valueFormatter: (p) => currencyFormate(p.value),
      },
      {
        headerName: "Project",
        width: 200,
        field: "project",
        resizable: true,
        cellRenderer: (params) => {
          let key = {
            Buyer: "buyerData",
            LeadBroker: "leadBrokerData",
          };
          let projects =
            params?.data?.[key[params.data.userType]]?.assignments || [];
          let filtered = projects.filter(
            (item) => item.project !== null && item.project !== undefined
          );
          return (
            <div className="flex gap-x-2">
              {filtered.length !== 0 &&
                filtered.map((project, index) => (
                  <div
                    key={index}
                    className="px-3 py-0.5 rounded-md justify-center items-center gap-1 inline-flex bg-[#c5ddff]"
                  >
                    <div className="text-center text-black text-[12px] leading-[14px] font-normal">
                      {project?.project?.projectName}
                    </div>
                  </div>
                ))}
            </div>
          );
        },
      },
      {
        headerName: "Last Contacted At",
        width: 200,
        field: "lastContactedAt",
        resizable: true,
        cellRenderer: (params) => {
          return (
            <div>
              {params.value
                ? moment(params.value).format("MM/DD/YYYY hh:mm A")
                : ""}
            </div>
          );
        },
      },
      {
        headerName: "Contact Type",
        width: 200,
        field: "contactType",
        resizable: true,
        valueGetter: (params) => {
          return (
            params.data[salesRepKey(params.data.userType)]?.contactType || "-"
          );
        },
        cellRenderer: ContactTypeBadge,
        editable: true,
        cellEditor: DropdownCellEditorContactType,
        cellEditorParams: {
          type: "contactType",
          tableRef: gridRef,
          options: contactTypeData?.values || [],
          onValueChange: (newValue, data) => {
            const userType = contacts[0]?.userType;
            const key = salesRepKey(userType);
            const updatedData = {
              ...data,
              [key]: {
                ...data[key],
                contactType: newValue,
              },
            };
            gridRef.current.api.applyTransaction({
              update: [updatedData],
            });
            gridRef.current.api.refreshCells({ force: true });
            updateContactType(newValue, data?._id).then(() => {
              refetchFilteredUser();
              setSaveStatus("Saved");
            });
          },
        },
        onCellClicked: (params) => {
          setIsEditing(true);
          params.api.startEditingCell({
            rowIndex: params.rowIndex,
            colKey: params.column.getColId(),
          });
        },
        // valueFormatter: (p) => currencyFormate(p.value),
      },
      {
        headerName: "Tags",
        field: "tags",
        width: 240,
        resizable: true,
        cellRenderer: (params) => {
          return (
            <div className="flex gap-x-2">
              {params?.data?.buyerData?.tags?.map((tag) => (
                <div
                  key={tag}
                  className="px-3 py-0.5 rounded-md justify-center items-center gap-1 inline-flex bg-[#3682eb]"
                >
                  <div className="text-center text-white text-[10px] leading-[14px] font-normal">
                    {tag}
                  </div>
                </div>
              ))}
            </div>
          );
        },
      },
      ...demandNotes?.map((demand) => {
        return {
          headerName: demand.label?.name,
          field: demand.label?._id,
          width: 200,
          resizable: true,
          uqKey: "corporateDemands",
          editable: true,
          cellRenderer: (params) => {
            let values = params.value;
            let options = demand.values?.map((item) => item);
            let selectedValues;
            if (Array.isArray(values)) {
              selectedValues =
                values?.map((item) => {
                  return options.find((option) => option.value === item)?.label;
                }) || [];
            } else {
              selectedValues = [values];
              selectedValues = selectedValues.filter(
                (item) => item !== undefined
              );
            }

            let filterUndefined = selectedValues.filter(
              (item) => item !== undefined
            );
            return (
              <div className="flex gap-x-2">
                {filterUndefined &&
                  filterUndefined?.length > 0 &&
                  filterUndefined?.map((tag) => (
                    <div
                      key={tag}
                      className="px-3 py-0.5 rounded-md justify-center items-center gap-1 inline-flex bg-[#3682eb]"
                    >
                      <div className="text-center text-white text-[10px] leading-[14px] font-normal">
                        {tag}
                      </div>
                    </div>
                  ))}
              </div>
            );
          },
          cellEditor: DropdownCellEditorCorporateDemands,
          cellEditorParams: {
            type: "demand",
            options: demand.values,
            isMulti: demand.isMulti,
            onValueChange: (newValue, data, colId) => {
              setSaveStatus("Saving...");
              const salesRepDataKey = salesRepKey(data.userType);
              const newUserData = {
                ...data,
              };
              const newCustomDemands =
                newUserData[salesRepDataKey].corporateDemands || [];
              const demandIndex = newCustomDemands.findIndex(
                (customDemand) => customDemand.label === colId
              );
              if (demandIndex === -1) {
                newCustomDemands.push({
                  label: colId,
                  values: [...newValue],
                });
              } else {
                newCustomDemands[demandIndex] = {
                  label: colId,
                  values: [...newValue],
                };
              }
              newUserData[salesRepDataKey].corporateDemands = newCustomDemands;
              gridRef.current.api.applyTransactionAsync({
                update: [
                  {
                    ...newUserData,
                  },
                ],
              });
              gridRef.current.api.refreshCells({ force: true });
              createOrUpdateUser(newUserData, false).then(() => {
                setSaveStatus("Saved");
                refetchFilteredUser();
              });
            },
          },
          onCellClicked: (params) => {
            params.api.startEditingCell({
              rowIndex: params.rowIndex,
              colKey: params.column.getColId(),
            });
          },
        };
      }),
      ...demandNotes?.map((demand) => {
        return {
          headerName: demand?.label,
          field: demand?.label,
          width: 150,
          resizable: true,
          editable: true,
          uqKey: "customDemands",
          cellEditor: DropdownCellEditorCustomDemands,
          cellEditorParams: {
            type: "demand",
            options: demand.values?.map((item) => item.value),
            onValueChange: (newValue, data, colId) => {
              setSaveStatus("Saving...");
              const salesRepDataKey = salesRepKey(data.userType);
              const newUserData = {
                ...data,
              };
              newUserData[colId] = newValue;

              const newCustomDemands =
                newUserData[salesRepDataKey].customDemands || [];

              const demandIndex = newCustomDemands?.findIndex(
                (customDemand) => customDemand.label === colId
              );
              if (demandIndex === -1) {
                newCustomDemands.push({
                  label: colId,
                  value: newValue,
                });
              } else {
                newCustomDemands[demandIndex] = {
                  label: colId,
                  value: newValue,
                };
              }
              newUserData[salesRepDataKey].customDemands = newCustomDemands;
              gridRef.current.api.applyTransactionAsync({
                update: [
                  {
                    ...newUserData,
                  },
                ],
              });
              gridRef.current.api.refreshCells({ force: true });
              createOrUpdateUser(newUserData, false).then(() => {
                setSaveStatus("Saved");
                refetchFilteredUser();
              });
            },
          },
          onCellClicked: (params) => {
            params.api.startEditingCell({
              rowIndex: params.rowIndex,
              colKey: params.column.getColId(),
            });
          },
        };
      }),
    ];
    if (userType === "LeadBroker") {
      result.filter((item) => item.field !== "Tags");
    }
    if (view === "project") {
      result = result.filter(
        (item) => item.field !== "project" && item?.uqKey !== "corporateDemands"
      );
    } else {
      result = result.filter((item) => item.uqKey !== "customDemands");
    }
    const savedColumnOrder = JSON.parse(localStorage.getItem("myColumnOrder"));
    return savedColumnOrder
      ? getOrderedColumns(result, savedColumnOrder)
      : result;
  };
  const style = {
    height: "460px",
    width: "100%",
    overflow: "auto",
  };
  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
    };
  }, []);

  useEffect(() => {
    // setPage(1)
  }, [state.contacts, state?.contactUserType]);

  const filteredContacts = useMemo(() => {
    const _contacts = state.contacts.map((contact) => {
      let customDemads = {};
      if (contact.userType === "Buyer") {
        contact?.buyerData?.customDemands?.forEach((demand) => {
          customDemads[demand.label] = demand.value;
        });
      }
      if (contact.userType === "LeadBroker") {
        contact?.leadBrokerData?.customDemands?.forEach((demand) => {
          customDemads[demand.label] = demand.value;
        });
      }

      let corporateDemands = {};
      if (contact.userType === "Buyer") {
        contact?.buyerData?.corporateDemands?.forEach((demand) => {
          corporateDemands[demand.label] = demand.values;
        });
      }
      if (contact.userType === "LeadBroker") {
        contact?.leadBrokerData?.corporateDemands?.forEach((demand) => {
          corporateDemands[demand.label] = demand.values;
        });
      }
      const salesRepKey =
        contact?.userType?.toLowerCase?.() === "buyer"
          ? "buyerData"
          : "leadBrokerData";
      return {
        ...contact,
        // dateRegistered: moment(contact?.createdAt).isValid()
        //   ? moment(contact?.createdAt).format('MM/DD/YYYY')
        //   : '',
        salesRep:
          contact[salesRepKey]?.assignments[0]?.salesRep?.firstName ?? "-",
        // name: contact?.firstName + ' ' + contact?.lastName,
        select: false,
        phoneNumber: contact[salesRepKey]?.phoneNumber,
        ...customDemads,
        ...corporateDemands,
        lastContactedAt: contact[salesRepKey]?.lastContactedAt,
      };
    });

    const _filterContacts = _contacts.filter((contact) => {
      const salesType = contact?.userType?.toLowerCase?.();

      if (state?.contactUserType?.toLowerCase?.() === "leadbroker") {
        return (
          salesType?.indexOf(state?.contactUserType?.toLowerCase?.()) !== -1 &&
          (contact?.deleted === false || contact?.deleted === undefined)
        );
      } else if (state?.contactUserType?.toLowerCase?.() === "buyer") {
        return (
          salesType?.indexOf(state?.contactUserType?.toLowerCase?.()) !== -1 &&
          (contact?.deleted === false || contact?.deleted === undefined)
        );
      } else if (state?.contactUserType?.toLowerCase?.() === "default") {
        return true;
      } else {
        return false;
      }
    });
    if (gridRef.current?.api) {
      onBtHide();
    }
    emptyCheckedSelections();
    setContacts(_filterContacts);
    return _filterContacts ?? [];
  }, [
    refetchFilteredUser,
    userObject,
    state?.sortType,
    state?.contacts,
    state?.contactUserType,
    state?.filters?.salesRepOwner,
    state.filters,
    state.refreshUsers,
  ]);

  useEffect(() => {
    if (isFiltering) {
      if (gridRef.current?.api) {
        onBtShowLoading();
      }
    }
    if (!isFiltering) {
      if (gridRef.current?.api) {
        onBtHide();
      }
    }
  }, [isFiltering]);

  // useEffect(() => {
  //   const searchParams = new URLSearchParams(history.location.search);
  //   let id = searchParams.get('id');
  //   if (id !== ':id' && id && id !== 'newcontacts-v2') {
  //     setSelectedContact(id);
  //   }
  // }, []);

  useEffect(() => {
    if (filteredContacts.length > 0) {
      const params = new URLSearchParams(window.location.search);
      const id = params.get("_id");
      if (id) {
        const matched = filteredContacts.find((contact) => {
          return contact._id === id;
        });
        setSelectedContact(matched);
      }
    }
  }, [filteredContacts]);

  useEffect(() => {
    if (filteredContacts?.length) {
      // toast.success(`Found ${filteredContacts?.length} contacts`);
    }
  }, [filteredContacts?.length]);

  const getRowId = useCallback((params) => String(params.data._id), []);
  return (
    <div className="flex flex-col justify-center">
      <div
        className="absolute bg-[white] z-[9999] font-semibold text-[14px] top-4 right-4 "
        style={{
          color: saveStatus === "Saved" ? "green" : "",
        }}
      >
        <div className="flex items-center">
          {saveStatus === "Saved" && (
            <span>
              <CheckIcon className="mr-2" color="green" />
            </span>
          )}
          {saveStatus === "Saving..." && (
            <span>
              <LoadingIcon className="mr-2" />
            </span>
          )}
          {saveStatus}
        </div>
      </div>
      <div
        // style={{ paddingLeft: '2.7rem', paddingRight: '1.9rem' }}
        className={`inline-block align-middle ${
          !state.isContactDetailDrawerOpen || isMobile ? "w-full" : "w-[300px]"
        }`}
      >
        {isLoading ? (
          <LoadingContacts />
        ) : (
          <>
            <TablePaginationV2
              loadingAnimation={onBtShowLoading}
              closeLoadingAnimation={onBtHide}
              customContacts={null}
              contacts={contacts}
            />
            <div className="flex relative items-center gap-4">
              <div className="text-sm text-[#393939]  text-end my-2 w-full">
                Showing
                <span className="font-bold ml-1">
                  {state.totalRecords}{" "}
                </span>{" "}
                contacts out of{" "}
                <span className="font-bold ml-1">
                  {state.totalRecordsAll || 0}{" "}
                </span>
              </div>
              <div
                onClick={() => {
                  setIsMenuOpen(!menuOpen);
                }}
                className="cursor-pointer hover:bg-[#dddddd] rounded p-1"
              >
                <MenuIcon />
              </div>
              {menuOpen && (
                <div className="absolute top-10 z-[9999] shadow-md right-0 bg-white border rounded-md py-3 px-3">
                  <div className="flex flex-col">
                    <div
                      onClick={() => {
                        setCreateListModal(true);
                        setIsMenuOpen(false);
                      }}
                      className="hover:bg-[#eeeeee] flex items-center gap-x-2 cursor-pointer p-2 rounded-md"
                    >
                      <div>Create List</div>
                    </div>
                    <div className="w-full h-[1px] my-2 bg-[gray]"></div>
                    <div
                      onClick={() => {
                        setAddToListModal(true);
                        setIsMenuOpen(false);
                      }}
                      className="hover:bg-[#eeeeee] flex items-center gap-x-2 cursor-pointer p-2 rounded-md"
                    >
                      <div>Add To List</div>
                    </div>
                  </div>
                </div>
              )}
              {createListModal && (
                <Modal
                  open={createListModal}
                  onClose={() => setCreateListModal(false)}
                >
                  <div className="top-1/2 left-1/2 flex flex-col items-center justify-center -translate-x-1/2 -translate-y-1/2 relative">
                    <CreateNewListV2
                      closeModal={() => setCreateListModal(false)}
                    />
                  </div>
                </Modal>
              )}
              {addToListModal && (
                <Modal
                  open={addToListModal}
                  onClose={() => setAddToListModal(false)}
                >
                  <div className="top-1/2 left-1/2 flex flex-col items-center justify-center -translate-x-1/2 -translate-y-1/2 relative">
                    <div className="bg-white relative rounded p-6 h-max-content min-w-[400px] flex flex-col gap-5 border-2 border-gray-2">
                      <MdClose
                        onClick={() => {
                          setAddToListModal(false);
                        }}
                        className="absolute cursor-pointer right-3 top-3 h-6 w-6"
                      />
                      <div className="text-3xl font-bold mt-6">
                        ADD TO LISTS
                      </div>
                      <div className="flex w-full">
                        <input
                          placeholder="search"
                          onChange={(e) => setListSearch(e.currentTarget.value)}
                        />
                      </div>
                      <div className="w-full flex items-center justify-end">
                        <button
                          disabled={
                            !Object.values(state.checkedLists).filter(
                              (val) => val === true
                            ).length
                          }
                          onClick={handleAddToList}
                          className="py-2 px-4 text-lg bg-[#18181B] text-white rounded-lg font-medium flex gap-2 items-center justify-center"
                        >
                          Add to list
                        </button>
                      </div>
                      <div
                        style={{ maxHeight: "50%" }}
                        className="flex flex-col gap-2 overflow-y-auto"
                      >
                        {isLoading && "loading lists...."}
                        {!isLoading &&
                          lists?.length &&
                          lists
                            .filter((list) =>
                              listSearch
                                ? list.title
                                    .toLowerCase()
                                    .includes(listSearch.toLowerCase().trim())
                                : true
                            )
                            .reverse()
                            .map((list) => (
                              <div className="flex flex-row w-full justify-between items-center gap-5 border-2 border-gray-2 rounded p-2">
                                <div className="flex flex-row items-center gap-2">
                                  <input
                                    checked={
                                      state.checkedLists?.[list?._id] ?? false
                                    }
                                    onChange={(e) => toggleCheckList(list?._id)}
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6 hover:cursor-pointer"
                                  />
                                  <div>{list.title}</div>
                                </div>
                              </div>
                            ))}
                      </div>
                    </div>
                  </div>
                </Modal>
              )}
            </div>

            <div className="w-full overflow-hidden ag-theme-alpine ">
              <AgGridReact
                domLayout="autoHeight"
                ref={gridRef}
                rowHeight={40}
                headerHeight={40}
                pagination={true}
                // rowSelection='multiple'
                // onRowSelected={(e) => {
                //   toggleCheckContact(e.data._id);
                // }}
                onCellValueChanged={async (e) => {
                  setSaveStatus("Saving...");
                  if (e.column.colId === "fullName") {
                    const firstName = e.newValue?.split(" ")[0] || "";
                    const lastName =
                      e.newValue?.split(" ").slice(1).join(" ") || "";
                    const fullName = e.newValue;

                    const updatedData = {
                      ...e.data,
                      _id: e.data._id,
                      firstName,
                      lastName,
                      fullName,
                    };
                    e.api.applyTransaction({
                      update: [updatedData],
                    });
                    await e.api.refreshCells({
                      force: true,
                    });
                    const params = {
                      id: e.data._id,
                      firstName,
                      lastName,
                    };
                    await createOrUpdateUser(params, false)
                      .then(() => {
                        refetchFilteredUser();
                        setSaveStatus("Saved");
                      })
                      .catch((err) => {
                        e.api.applyTransaction({
                          update: [e.data],
                        });
                      });
                  }
                  if (e.column.colId === "email") {
                    let params = {
                      id: e.data._id,
                      email: e.newValue,
                      firstName: e.data.firstName,
                    };
                    e.api.applyTransaction({
                      update: [
                        {
                          _id: e.data._id,
                          [e.column.colId]: e.newValue,
                          ...e.data,
                        },
                      ],
                    });
                    await e.api.refreshCells({
                      force: true,
                    });
                    await createOrUpdateUser(params, false).then(
                      async (data) => {
                        refetchFilteredUser();
                        setSaveStatus("Saved");
                      }
                    );
                  }
                  if (e.column.colId === "phoneNumber") {
                    let key = {
                      Buyer: "buyerData",
                      LeadBroker: "leadBrokerData",
                    };
                    let userKey = key[e.data.userType];
                    let params = {
                      id: e.data._id,
                      firstName: e.data.firstName,
                    };
                    params[userKey] = {
                      ...e.data[userKey],
                      phoneNumber: e.newValue,
                    };
                    e.api.applyTransaction({
                      update: [
                        {
                          _id: e.data._id,
                          [e.column.colId]: e.newValue,
                          ...e.data,
                        },
                      ],
                    });
                    await e.api.refreshCells({
                      force: true,
                      rowNodes: [e.node],
                    });
                    await createOrUpdateUser(params, false).then(() => {
                      refetchFilteredUser();
                      setSaveStatus("Saved");
                    });
                  }
                  setSaveStatus("Saved");
                }}
                onColumnMoved={(params) => {
                  var columnState = JSON.stringify(
                    params.columnApi.getColumnState()
                  );
                  localStorage.setItem("myColumnOrder", columnState);
                }}
                // singleClickEdit={true}
                // enableGroupEdit={true}
                getRowId={getRowId}
                suppressDragLeaveHidesColumns={true}
                suppressRowClickSelection={true}
                suppressPaginationPanel={true}
                loadingOverlayComponent={loadingOverlayComponent}
                columnDefs={columns(state.contactUserType)}
                rowData={contacts}
                onCellEditingStopped={(e) => {
                  setIsEditing(false);
                }}
                // onCellEditingStarted={(e) => {
                //   setIsEditing(true);
                // }}
                defaultColDef={defaultColDef}
                components={components}
                onCellClicked={(e) => {
                  if (
                    e.column.colId === "open" ||
                    e.column.colId === "createdAt"
                  ) {
                    e.api.refreshCells({ force: true });
                    const updatedRowNode = e.api.getRowNode(e.node.id);
                    const updatedData = updatedRowNode.data;
                    setSelectedContact(updatedData);
                    history.push({
                      pathname: `${history.location.pathname}`,
                      search: `?_id=${updatedData._id}`,
                    });
                  }
                }}
              />
            </div>

            <TablePaginationV2Bottom
              customContacts={null}
              contacts={contacts}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default UserTableV2;

const CustomLoadingCellRenderer = (props) => {
  return (
    <div role="presentation">
      <div
        role="presentation"
        style={{
          height: 100,
          width: 100,
          margin: "0 auto",
        }}
      >
        <LottieWrapper
          animation={successAnimation}
          autoplay
          loop={true}
          className="-mt-40 md:mt-0 max-w-sm"
        />
        {/* <h1 className={fade ? 'fade mt-4 text-[16px]' : 'mt-4 text-[16px]'}>
          {messages[currentIndex]}
        </h1> */}
      </div>
    </div>
  );
};
