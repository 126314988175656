import React from 'react'
import '../styles/style.css'
import { Select } from 'antd'
import { ReactComponent as DownIcon } from 'assets/icons/down.svg'

const CustomSelect = ({
  label = '',
  value,
  handleChange,
  options,
  showSearch = false,
  placeholder = '',
  labelClass = '',
  disabled = false,
  optionFilterProp = 'label'
}) => {
  return (
    <div
      className='w-full flex flex-col gap-y-1.5'
    >
      {
        label.length > 0 &&
        <div
          className={`text-softBlack_70/50 w-full text-xs top-6 font-normal uppercase ${labelClass}`}
        >
          {label}
        </div>
      }
      <Select
        showArrow
        value={value?.length ? value : null}
        onChange={handleChange}
        className='ant-picker-input1'
        getPopupContainer={(node) => node.parentNode}
        suffixIcon={<DownIcon />}
        showSearch={showSearch}
        placeholder={placeholder.length > 0 ? placeholder : 'Select value'}
        disabled={disabled}
        optionFilterProp={optionFilterProp}
      >
        {options?.map((_item, index) => (
          <Select.Option value={_item.value} key={index} label={_item.label}>
            <div className={`flex gap-2 items-center ${value === _item.value && 'font-semibold'}`}>
              {_item.label}
            </div>
          </Select.Option>
        ))}
      </Select>
    </div>
  )
}

export default CustomSelect
